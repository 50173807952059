import { Box, HStack, VStack } from "@chakra-ui/layout";
import { Icon, Text, useMediaQuery } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { OperatorType, TableList } from "../../common/types/campaign";
import {
  CONNECTOR,
  DynamicListChild,
  DynamicListChildL1,
  TYPE,
  ValueTypes,
} from "../../common/types/campaign";
import AddPropertyButton from "./AddPropertyButton";
import RemoveRowCloseButton from "../RemoveRowCloseButton";
import { FilterGroupBox } from "./FilterGroupBox";
import DynamicListLogicGate from "./DynamicListLogicGate";
import {
  DynamicListValueFields,
  ValueSelectFields,
} from "./DynamicListValueFields";
import {
  FILTER_TABLE_NAME,
  FILTER_TYPE,
} from "../../common/constants/campaign";
import { DynamicListContext } from "./DynamicList";
import { FaRegIdCard } from "react-icons/fa";
import { selectDynamicList } from "./dynamicListSlice";

enum Fields {
  FILTER = "filter",
  OPERATOR = "operator",
  VALUE = "value",
  CONNECTOR = "connector",
  VALIDATION_ERROR = "validation_error",
}

enum PERSON_PROPERTY_PREFIXES {
  INT_PERSON_ORG_MAP = "internal_person_organisation_mapping.",
  INT_ACC = "internal_account.",
  INT_PERSON = "",
}

const NON_EMPTY_PREFIXES = [
  PERSON_PROPERTY_PREFIXES.INT_PERSON_ORG_MAP,
  PERSON_PROPERTY_PREFIXES.INT_ACC,
];

function isPersonTable(prefix: PERSON_PROPERTY_PREFIXES) {
  return prefix === PERSON_PROPERTY_PREFIXES.INT_PERSON;
}

function findPrefixOfSelectedFilter(selectedField: string) {
  if (selectedField.startsWith(PERSON_PROPERTY_PREFIXES.INT_ACC)) {
    return PERSON_PROPERTY_PREFIXES.INT_ACC;
  } else if (
    selectedField.startsWith(PERSON_PROPERTY_PREFIXES.INT_PERSON_ORG_MAP)
  ) {
    return PERSON_PROPERTY_PREFIXES.INT_PERSON_ORG_MAP;
  } else if (selectedField) {
    return PERSON_PROPERTY_PREFIXES.INT_PERSON;
  } else {
    return null;
  }
}

function filterListWithSelectedPersonTable(
  key: string,
  selectedPersonTable: PERSON_PROPERTY_PREFIXES | null
) {
  if (selectedPersonTable === null) {
    return true;
  } else if (
    !isPersonTable(selectedPersonTable) &&
    key.startsWith(selectedPersonTable)
  ) {
    return true;
  } else if (
    isPersonTable(selectedPersonTable) &&
    !NON_EMPTY_PREFIXES.some((prefix) => key.startsWith(prefix))
  ) {
    return true;
  }
  return false;
}

export default function PersonFilterGroup({
  data,
  label,
  id,
  onChange,
  onRemove,
  isReadOnly,
}: {
  data: DynamicListChild;
  label: string;
  id: string;
  onChange: (data: any) => void;
  onRemove: () => void;
  isReadOnly?: boolean;
}) {
  const { activeErrorCheck } = useContext(DynamicListContext);

  const selectedPersonTable = useMemo(() => {
    let selectedField = "";
    data.children.forEach((row) => {
      if (row.filter) {
        selectedField = row.filter;
      }
    });
    if (data.children.length === 1) return null;
    return findPrefixOfSelectedFilter(selectedField);
  }, [data.children]);

  function onDataChange(
    field: string,
    index: number,
    value: string | number | CONNECTOR | ValueTypes
  ) {
    const dataCopy = cloneDeep(data);
    switch (field) {
      case Fields.FILTER:
        dataCopy.children[index].filter = value as string;
        dataCopy.children[index].operator = null;
        dataCopy.children[index].value = [];
        dataCopy.children[index].validation_error = "";
        break;
      case Fields.OPERATOR:
        dataCopy.children[index].operator = value as string;
        dataCopy.children[index].value = [];
        dataCopy.children[index].validation_error = "";
        break;
      case Fields.VALUE:
        dataCopy.children[index].value = value as ValueTypes;
        dataCopy.children[index].validation_error = "";
        break;
      case Fields.CONNECTOR:
        dataCopy.children[index].connector = value as CONNECTOR;
        break;
      case Fields.VALIDATION_ERROR:
        dataCopy.children[index].validation_error = value as string;
    }
    onChange(dataCopy);
  }

  function onRemoveRow(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.children = dataCopy.children.filter((_, i) => i !== index);
    onChange(dataCopy);
  }

  function onAddRow() {
    const dataCopy = cloneDeep(data);

    const newChild: DynamicListChildL1 = {
      type: TYPE.EXPRESSION,
      filter_type: FILTER_TYPE.PERSON,
      filter: null,
      property: null,
      operator: null,
      value: [],
      connector: CONNECTOR.AND,
    };

    if (dataCopy.children) {
      dataCopy.children.push(newChild);
    } else {
      dataCopy.children = [newChild];
    }

    onChange(dataCopy);
  }

  function onChangeFilterGroupName(value: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.name = value;
    onChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        py: "0",
        px: "0",
        spacing: "1",
      }
    : {
        py: "2",
        px: "3",
        spacing: "4",
        bg: "grayV2.200",
      };

  return (
    <FilterGroupBox
      id={id}
      label={label}
      groupName={data.name}
      onRemove={onRemove}
      onGroupNameChange={onChangeFilterGroupName}
      isReadOnly={isReadOnly}
    >
      <VStack alignItems="flex-start" {...wrapperStyle} w="100%">
        {data.children &&
          data.children.map((child, index) => {
            return (
              <Box key={index} width="100%">
                <PersonFilterGroupRow
                  onFilterGroupChange={(field, value) =>
                    onDataChange(field, index, value)
                  }
                  filterGroup={{
                    ...child,
                    validation_error: activeErrorCheck
                      ? child.validation_error
                      : "",
                    connector:
                      index !== 0
                        ? child.connector || CONNECTOR.EMPTY
                        : CONNECTOR.EMPTY,
                  }}
                  showRemoveButton={!!(data.children.length > 1)}
                  onRemoveRow={() => onRemoveRow(index)}
                  onAddRow={() => onAddRow()}
                  showAddButton={index === data.children.length - 1}
                  isReadOnly={isReadOnly}
                  selectedPersonTable={selectedPersonTable}
                />
              </Box>
            );
          })}
      </VStack>
    </FilterGroupBox>
  );
}

function PersonFilterGroupRow({
  onFilterGroupChange,
  filterGroup,
  showRemoveButton,
  onRemoveRow,
  showAddButton,
  onAddRow,
  isReadOnly,
  selectedPersonTable,
}: {
  onFilterGroupChange: (
    field: Fields,
    value: string | number | CONNECTOR | ValueTypes
  ) => void;
  filterGroup: DynamicListChildL1;
  showRemoveButton: boolean;
  onRemoveRow: () => void;
  showAddButton: boolean;
  onAddRow: () => void;
  isReadOnly?: boolean;
  selectedPersonTable: PERSON_PROPERTY_PREFIXES | null;
}) {
  const [isSmallerThan1024] = useMediaQuery("(max-width: 1024px)");

  const { operators, filterList } = useSelector(selectDynamicList);

  const { activeErrorCheck, campaignContext } = useContext(DynamicListContext);

  const tableList = useMemo(
    () => filterList[campaignContext]?.[FILTER_TYPE.PERSON],
    [campaignContext, filterList]
  );

  const {
    filter,
    operator,
    value,
    validation_error: validationError,
    connector,
  } = filterGroup;

  const formatTableList = useCallback(
    (list: TableList) => {
      return Object.entries((list as TableList)[FILTER_TABLE_NAME.PERSON] ?? {})
        .filter(([key, value]) => {
          if (value.ui_hidden) return false;
          return filterListWithSelectedPersonTable(key, selectedPersonTable);
        })
        .map(([key, value]) => {
          return { label: value.display_name || key, value: key };
        });
    },
    [selectedPersonTable]
  );

  const getOperatorList = useCallback(
    (colType: string) => {
      return operators.data?.[colType as OperatorType];
    },
    [operators]
  );

  const tableArray: { label: string; value: string }[] = useMemo(
    () => formatTableList(tableList?.data ?? {}),
    [formatTableList, tableList?.data]
  );

  const colType = useMemo(
    () => (filter ? findColType(filter, tableList?.data) : null),
    [filter, tableList]
  );

  const operatorList = useMemo(
    () => (colType ? getOperatorList(colType) : null),
    [getOperatorList, colType]
  );

  const operatorDetails = useMemo(
    () => (operator && operatorList ? operatorList[operator] : null),
    [operator, operatorList]
  );

  function setFilter(value: string) {
    onFilterGroupChange(Fields.FILTER, value);
  }
  function setOperator(value: string) {
    onFilterGroupChange(Fields.OPERATOR, value);
  }
  function setValue(value: ValueTypes) {
    onFilterGroupChange(Fields.VALUE, value);
  }
  function setValidatonError(msg: string) {
    onFilterGroupChange(Fields.VALIDATION_ERROR, msg);
  }
  function setConnector(value: CONNECTOR) {
    onFilterGroupChange(Fields.CONNECTOR, value);
  }

  function setValueHandler(values: ValueTypes) {
    const invalid =
      operatorDetails?.arguments_types?.length &&
      values.length &&
      values.every((val) => !val);

    setValidatonError(invalid ? "Invalid values" : "");
    setValue(values);
  }

  function findColType(filter: string, filterList?: TableList): string {
    return (
      (filterList as TableList)?.[FILTER_TYPE.PERSON]?.[filter]?.type ?? null
    );
  }

  const wrapperStyle = isReadOnly
    ? {
        spacing: "1",
      }
    : {
        spacing: "2",
        gridGap: "1",
      };

  function renderFilterGroupRow() {
    return (
      <HStack
        alignItems="flex-start"
        justifyContent="space-between"
        maxWidth="700px"
      >
        <Box id="person-activity-selector">
          <ValueSelectFields
            options={tableArray}
            value={filter || ""}
            onChange={setFilter}
            validationError={validationError}
            isReadOnly={isReadOnly}
          />
        </Box>

        {filter && colType && operatorList && (
          <Box>
            <ValueSelectFields
              options={Object.values(operatorList).map((op) => ({
                label: op.display,
                value: op.id,
              }))}
              value={operator || ""}
              onChange={setOperator}
              validationError={validationError}
              isReadOnly={isReadOnly}
            />
          </Box>
        )}

        {operator && colType && operatorDetails && (
          <HStack>
            <DynamicListValueFields
              value={value as ValueTypes}
              validationError={validationError}
              onChange={(val) => setValueHandler(val)}
              argumentTypes={operatorDetails.arguments_types}
              helperText={operatorDetails.display_2}
              isReadOnly={isReadOnly}
              filterValue={filter ?? ""}
              noOfArguments={operatorDetails.arguments}
              activeErrorCheck={activeErrorCheck}
            />
          </HStack>
        )}
      </HStack>
    );
  }

  if (isReadOnly) {
    return (
      <VStack alignItems="flex-start" spacing="1">
        {connector && (
          <DynamicListLogicGate
            operator={connector}
            handleChange={setConnector}
            isReadOnly={isReadOnly}
          />
        )}
        <HStack alignItems="center" width="100%" wrap="wrap" {...wrapperStyle}>
          <Icon as={FaRegIdCard} color="brand.blue" mr={1} />
          {renderFilterGroupRow()}
        </HStack>
      </VStack>
    );
  } else {
    return (
      <HStack alignItems="flex-start" wrap="wrap" {...wrapperStyle}>
        {connector ? (
          <DynamicListLogicGate
            operator={connector}
            handleChange={setConnector}
          />
        ) : (
          <HStack h="33px">
            <Text fontSize="sm">...a person property of...</Text>
          </HStack>
        )}

        {renderFilterGroupRow()}
        {showRemoveButton && <RemoveRowCloseButton onClick={onRemoveRow} />}
        {showAddButton && operator && (
          <AddPropertyButton iconOnly={isSmallerThan1024} onClick={onAddRow} />
        )}
      </HStack>
    );
  }
}
