import {
  Flex,
  Icon,
  IconButton,
  Text,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import {
  useEffect,
  ChangeEvent,
  useCallback,
  useRef,
  ReactNode,
  useMemo,
} from "react";
import { useState } from "react";
import { FaArrowLeft, FaEllipsisV, FaCog } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import urls from "../../../urls";
import {
  getTemplate,
  resetTemplateDetails,
  selectTemplate,
  updateTemplate,
  updateTemplateName,
  setIsTemplateDirty,
  setUpdatingTemplate,
} from "./templateSlice";
import {
  selectSettings,
  resetLoadingStateEmailDefault,
} from "../settings/settingsSlice";
import {
  downloadFile,
  formatLastSaved,
  isLoading,
  isSuccess,
  validateEmailConfigInputs,
  validateLinksInTemplate,
} from "../../../common/helper/commonHelper";
import { VARIABLE_TYPE } from "../../../common/constants/campaign";
import SpinnerContainer from "../../../components/SpinnerContainer";
import SendPreviewEmailPopover from "./components/SendPreviewEmailPopover";
import { PreventNavigationModal } from "../../../components/PreventNavigationModal";
import {
  getAllColumns,
  listAllEmailTokens,
  selectEmailToken,
} from "../emailtoken/emailTokenSlice";
import IButton, { BUTTON } from "../../../components/IButton";
import { cloneDeep, set } from "lodash";
import BEEIntegration from "./BeePlugin/BeeIntegration";
import BeePlugin from "@mailupinc/bee-plugin";
import {
  EmailConfigWithSubject,
  SenderMeta,
  TemplateAdditionalProperties,
  TemplateBase,
  TEMPLATE_EDITOR_MODE,
} from "../../../common/types/template";
import EmailHtmlEditor from "./EmailHtmlEditor";
import { toast } from "react-toastify";
import EmailConfigureInput from "../../../components/EmailConfigureInput";
import IModal from "../../../components/IModal";
import EditNameModal, {
  ASSET_NAME_ACTIONS,
} from "../../../components/EditNameModal";
import EditableHeading from "../../../components/EditableHeading";
import VisualTemplates from "./components/VisualTemplates";
import { LOADING_STATES } from "../../../common/constants/common";
import { useAppDispatch } from "../../../store";
import {
  EMAIL_CONFIG_DATA_INIT,
  VALUE_META_TYPE,
} from "../../../common/constants/template";

const ONE_MIN = 60000;

function Header({
  goToTemplates,
  openRenameModal,
  templateName,
  subject,
  children,
  isLoading,
}: {
  goToTemplates: () => void;
  openRenameModal: () => void;
  templateName?: string;
  subject: string;
  children: ReactNode;
  isLoading?: boolean;
}) {
  return (
    <Flex alignItems="center" justifyContent="space-between" px={4} py={2}>
      <Flex alignItems="center">
        <IconButton
          size="md"
          aria-label="Go back"
          variant="ghost"
          name="return-button"
          onClick={goToTemplates}
          icon={<Icon fontSize="20px" as={FaArrowLeft} />}
          mr={4}
        />
        <Flex flexDirection="column">
          <EditableHeading
            name={templateName ?? ""}
            openModal={openRenameModal}
            isLoading={isLoading}
          />
          {subject && (
            <Text
              fontSize={12}
              color="gray"
              py={2}
              maxW="200px"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {subject}
            </Text>
          )}
        </Flex>
      </Flex>
      {children}
    </Flex>
  );
}

export default function EmailTemplateEditor() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    templateDetails: {
      data: templateDetails,
      loading: fetchingTemplateDetails,
    },
    templateName,
    updatingTemplate,
    visualTemplateList,
    isTemplateDirty,
  } = useSelector(selectTemplate);
  const { globalDefault, fetchingPersonOrgMapping } =
    useSelector(selectSettings);
  const {
    allColumnsList,
    emailTokenList: {
      listAll: { data: allEmailTokenList },
    },
  } = useSelector(selectEmailToken);

  let { id } = useParams<{ id: string }>();
  const [template, setTemplate] = useState("");
  const [templateJson, setTemplateJson] = useState("");

  const [isOpenRenameModal, setIsOpenRenameModal] = useState(false);
  const [activeErrorCheck, setActiveErrorCheck] = useState(false);
  const [inputs, setInputs] = useState(EMAIL_CONFIG_DATA_INIT);
  const [errors, setErrors] = useState(EMAIL_CONFIG_DATA_INIT);
  const [emailSettingsValid, setEmailSettingsValid] = useState("");
  const [lastSaved, setLastSaved] = useState("just now");
  const [showVisualTemplates, setShowVisualTemplates] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const tokenVariables: { name: string; value: string; type: VARIABLE_TYPE }[] =
    useMemo(
      () =>
        allEmailTokenList.map((token) => {
          return {
            name: token.name,
            value: `{{token("${token.name}")}}`,
            type: VARIABLE_TYPE.TOKEN,
          };
        }),
      [allEmailTokenList]
    );

  const columnVariables: {
    name: string;
    value: string;
    type: VARIABLE_TYPE;
  }[] = useMemo(
    () =>
      allColumnsList.data.map((column) => {
        return {
          name: column.name,
          value: `{{column("${column.name}")}}`,
          type: VARIABLE_TYPE.COLUMN,
        };
      }),
    [allColumnsList.data]
  );

  const tokenColumnVariables = useMemo(() => {
    return [...tokenVariables, ...columnVariables];
  }, [columnVariables, tokenVariables]);

  useEffect(() => {
    if (id) {
      dispatch(getTemplate({ id }));
      return () => {
        dispatch(resetTemplateDetails());
      };
    }
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetLoadingStateEmailDefault());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      isSuccess(fetchingTemplateDetails) &&
      templateDetails.template_type === TEMPLATE_EDITOR_MODE.DRAG_DROP &&
      !templateDetails.content &&
      !templateDetails.content_json
    ) {
      setShowVisualTemplates(true);
    }
  }, [templateDetails, fetchingTemplateDetails]);

  useEffect(() => {
    if (
      isSuccess(fetchingTemplateDetails) &&
      templateDetails.template_type === TEMPLATE_EDITOR_MODE.DRAG_DROP &&
      !templateDetails.subject &&
      templateDetails.content_json
    ) {
      setSettingsOpen(true);
    }
  }, [templateDetails, fetchingTemplateDetails]);

  const updateLastSavedStatus = useCallback((updatedAt: string) => {
    setLastSaved(formatLastSaved({ updatedAt }));
  }, []);

  function renameTemplateHandler(newName: string) {
    setIsOpenRenameModal(false);
    dispatch(
      updateTemplateName({
        templateId: templateDetails.template_id,
        name: newName,
      })
    );
  }

  useEffect(() => {
    updateLastSavedStatus(templateDetails.updated_at);
    const intervalId = setInterval(
      () => updateLastSavedStatus(templateDetails.updated_at),
      ONE_MIN
    );
    setTemplate(templateDetails.content);

    setTemplateJson(templateDetails.content_json || "");
    const initState = {
      subject: templateDetails.subject ?? "",
      from_email: {
        name:
          templateDetails.from_email?.name ??
          globalDefault.data?.from_email?.name ??
          "",
        email:
          templateDetails.from_email?.email ??
          globalDefault.data?.from_email?.email ??
          "",
      },
      reply_to: templateDetails.reply_to ?? globalDefault.data?.reply_to ?? "",
      sender_meta: {
        from_email:
          templateDetails.sender_meta?.from_email ?? VALUE_META_TYPE.TEXT,
        from_name: VALUE_META_TYPE.TOKEN,
        reply_to: templateDetails.sender_meta?.reply_to ?? VALUE_META_TYPE.TEXT,
      },
    };
    setInputs(initState);

    return () => clearInterval(intervalId);
  }, [templateDetails, updateLastSavedStatus, globalDefault.data]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    // for codemirror inputs, it's not an event which activates this function and it takes initial state for variable
    setInputs((prev) => {
      const newData = cloneDeep(prev);
      set(newData, event.target.name, event.target.value);
      //work-around
      setActiveErrorCheck((old) => {
        old && validateInputs(newData);
        return old;
      });
      if (editorRef.current) editorRef.current.inputs = newData;
      return newData;
    });
    dispatch(setIsTemplateDirty(true));
  }

  function validateInputs(data = editorRef.current?.inputs || inputs) {
    const { validity, errors } = validateEmailConfigInputs(data);
    setErrors(errors);
    const invalidLinksMsg = validateLinksInTemplate(template);
    let errorMsg = validity ? [] : ["Email settings is invalid"];
    setEmailSettingsValid(errorMsg.length ? errorMsg[0] : "");
    if (!(template || templateJson))
      errorMsg.push("Email content shouldn't be empty");
    if (invalidLinksMsg) errorMsg.push(invalidLinksMsg);

    return errorMsg;
  }

  function isValid() {
    setActiveErrorCheck(true);
    const errorMsg = validateInputs();
    if (errorMsg.length) {
      toast.warn(errorMsg.join(", "));
      return false;
    } else {
      return true;
    }
  }

  async function saveTemplate(html?: string) {
    const inputData = editorRef.current?.inputs ?? inputs;
    if (isValid()) {
      let data: TemplateBase & TemplateAdditionalProperties & SenderMeta = {
        template_id: templateDetails.template_id,
        content: html || template,
        ...inputData,
      };
      dispatch(updateTemplate(data));
    }
  }

  async function saveVisualTemplate(html?: string) {
    const inputData = editorRef.current?.inputs ?? inputs;
    if (isValid()) {
      setUpdatingTemplate(LOADING_STATES.LOADING);
      let data: TemplateBase & TemplateAdditionalProperties & SenderMeta = {
        template_id: templateDetails.template_id,
        content: html ?? "",
        ...inputData,
      };

      // Delay to update the state before saving the Template to backend
      // Bee limitation - onChange function calls only when edtior rest for a certain timeout.
      const DELAY_UNIT = 500;
      await new Promise((res) => setTimeout(res, DELAY_UNIT));

      setTemplate((oldTemplate) => {
        const templateContent = html || oldTemplate;
        data.content = templateContent;
        return templateContent;
      });
      setTemplateJson((oldTemplateJson) => {
        if (oldTemplateJson) data.content_json = oldTemplateJson;
        return oldTemplateJson;
      });

      dispatch(updateTemplate(data));
    }
  }

  function goToTemplates() {
    navigate(urls.email);
  }

  // Support for old urls | Replacing old urls to new
  useEffect(() => {
    navigate(`${urls.email}/${id}`, { replace: true });
  }, [id, navigate]);

  useEffect(() => {
    dispatch(listAllEmailTokens());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(fetchingPersonOrgMapping)) {
      dispatch(getAllColumns());
    }
  }, [dispatch, fetchingPersonOrgMapping]);

  const editorRef = useRef<{ bee: BeePlugin; inputs: EmailConfigWithSubject }>(
    null
  );

  function setTemplateWithIsDirty(isDirty: boolean, code: string) {
    dispatch(setIsTemplateDirty(isDirty));
    setTemplate(code);
  }

  function onChangeBeeTemplate(json: string) {
    editorRef.current?.bee.save();
    dispatch(setIsTemplateDirty(true));
  }

  function onSaveBee(json: unknown, html: unknown) {
    //initialised as soon as we open the visual editor hence setTemplateWithIsDirty
    //can't be used here
    setTemplateJson(json as string);
    setTemplate(html as string);
  }

  function onPreviewAction() {
    setShowPreview((oldValue) => !oldValue);
  }

  function exportHtml() {
    const file = new Blob([template], {
      type: "text/plain",
    });
    downloadFile(file, `${templateName}.html`);
  }

  const [previewPopupOpen, setPreviewPopupOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  function LastSavedStatus() {
    return (
      <Flex
        fontSize="12px"
        mr="10px"
        style={{ gap: "6px" }}
        alignItems="center"
        color="gray.400"
      >
        {isLoading(updatingTemplate) ? "saving template" : lastSaved}
      </Flex>
    );
  }

  function HtmlEditorOptions() {
    return (
      <HStack>
        <LastSavedStatus />
        <IButton
          variant={BUTTON.SECONDARY}
          mr={3}
          onClick={() => setPreviewPopupOpen(!previewPopupOpen)}
        >
          Send test email
        </IButton>
        <IButton
          isDisabled={!isTemplateDirty}
          variant={BUTTON.PRIMARY}
          name="save"
          onClick={() => saveTemplate()}
          isLoading={isLoading(updatingTemplate)}
        >
          Save
        </IButton>
      </HStack>
    );
  }

  function BeeEditorOptions() {
    return (
      <HStack style={{ gap: "10px" }}>
        <LastSavedStatus />
        <Tooltip
          label={activeErrorCheck && emailSettingsValid}
          defaultIsOpen={
            activeErrorCheck && !!emailSettingsValid && !settingsOpen
          }
        >
          <IButton
            onClick={() => setSettingsOpen(true)}
            aria-label="Settings"
            leftIcon={<FaCog />}
            name="settings"
          >
            Email settings
          </IButton>
        </Tooltip>

        <IButton
          isDisabled={!isTemplateDirty}
          onClick={() => saveVisualTemplate()}
          variant={BUTTON.PRIMARY}
          name="save"
          isLoading={isLoading(updatingTemplate)}
        >
          Save
        </IButton>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Actions"
            name="actions"
            variant="ghost"
            icon={<FaEllipsisV />}
          />
          <MenuList>
            <MenuItem onClick={() => editorRef.current?.bee.togglePreview()}>
              Preview
            </MenuItem>
            <MenuItem onClick={exportHtml}>Export html</MenuItem>
            <MenuItem
              onClick={() => {
                setPreviewPopupOpen(true);
              }}
            >
              Send test email
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    );
  }

  function EditorOptions({ type }: { type: boolean }) {
    if (type) return <BeeEditorOptions />;
    return <HtmlEditorOptions />;
  }

  return (
    <>
      <Header
        goToTemplates={
          showPreview
            ? editorRef.current?.bee.togglePreview ?? goToTemplates
            : goToTemplates
        }
        templateName={templateName}
        subject={
          templateDetails.template_type === TEMPLATE_EDITOR_MODE.DRAG_DROP &&
          !showVisualTemplates
            ? `Subject: ${inputs.subject ? inputs.subject : "empty"}`
            : ""
        }
        openRenameModal={() => setIsOpenRenameModal(true)}
        isLoading={isLoading(fetchingTemplateDetails)}
      >
        {!isLoading(fetchingTemplateDetails) && !showVisualTemplates && (
          <EditorOptions
            type={
              templateDetails.template_type === TEMPLATE_EDITOR_MODE.DRAG_DROP
            }
          />
        )}
      </Header>
      <Divider />
      <SpinnerContainer
        loading={
          isLoading(fetchingTemplateDetails) ||
          (templateDetails.template_type === TEMPLATE_EDITOR_MODE.DRAG_DROP &&
            showVisualTemplates &&
            isLoading(visualTemplateList.loading))
        }
      >
        {showVisualTemplates ? (
          <VisualTemplates toggleNext={() => setShowVisualTemplates(false)} />
        ) : templateDetails.template_type === TEMPLATE_EDITOR_MODE.DRAG_DROP ? (
          <BEEIntegration
            templateJson={templateDetails.content_json || ""}
            variables={tokenColumnVariables}
            editorRef={editorRef}
            onSave={onSaveBee}
            onChange={onChangeBeeTemplate}
            onTogglePreview={onPreviewAction}
          />
        ) : (
          <EmailHtmlEditor
            inputs={inputs}
            errors={errors}
            handleChange={handleChange}
            template={template}
            setTemplate={setTemplateWithIsDirty}
          />
        )}
      </SpinnerContainer>
      <SendPreviewEmailPopover
        isOpen={previewPopupOpen}
        onClose={() => setPreviewPopupOpen(false)}
        data={{ ...inputs, content: template }}
        isValid={isValid}
      />
      <IModal
        header={{ title: "Email settings" }}
        isOpen={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        primaryButton={{
          label: "Done",
          props: {
            onClick: () => setSettingsOpen(false),
          },
        }}
      >
        <EmailConfigureInput
          inputs={inputs}
          onChange={handleChange}
          errors={errors}
          width="100%"
          inputPaddingX="0"
        />
      </IModal>

      <EditNameModal
        value={templateName ?? ""}
        action={ASSET_NAME_ACTIONS.RENAME}
        isOpen={isOpenRenameModal}
        onClose={() => setIsOpenRenameModal(false)}
        onSubmit={renameTemplateHandler}
        asset="email"
        validateName={(name: string) => {
          return name.length < 3
            ? "Name must be at least 3 characters long"
            : "";
        }}
        validateOnClick
      />
      <PreventNavigationModal
        isActive={isTemplateDirty}
        onCancel={() => dispatch(setIsTemplateDirty(isTemplateDirty))}
        onConfirm={() => dispatch(setIsTemplateDirty(false))}
      />
    </>
  );
}
