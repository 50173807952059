import { cloneDeep, isArray, isEqual } from "lodash";
import {
  OperatorType,
  ValueTypes,
  OperatorArgType,
} from "../../common/types/campaign";
import InputFormControl from "./InputFormControl";
import { Box, HStack, Text } from "@chakra-ui/react";
import DropdownWithSearch, { MultiSelectDropdown } from "../DropdownWithSearch";
import {
  isArgumentMany,
  isBlank,
  isFulfilled,
  validateUrl,
  openAssetInNewTab,
} from "../../common/helper/commonHelper";
import { useMemo, useState, useCallback } from "react";
import MultiValueInput from "../MultiValueInput";
import NumberField, { NUMBER_FIELD_TYPES } from "../NumberField";
import { format } from "date-fns";
import IconWithTooltip from "../IconWithTooltip";
import {
  GenericOptionPropsType,
  ReactSelectDefaultOptionsType,
  ReactSelectDefaultOptionsWithClick,
  ListFilterParams,
  DropdownPaginatedListType,
} from "../../common/types/common";
import {
  CAMPAIGN_LIST_INIT,
  PROPERTY_FILTER_ID,
  TEMPLATE_LIST_INIT,
  WEBSITE_ACTIVITY_META,
} from "../../common/constants/campaign";
import { TemplatePreviewModal } from "../TemplatePreviewModal";
import { FaEye } from "react-icons/fa";
import { AssetPreviewComponent } from "../OptionHelper";
import { ASSET_TYPES } from "../../common/constants/common";
import AsyncSearchableDropdown from "./AsyncSearchableDropdown";
import {
  getReferrerList,
  getCustomParamsInPageVisits,
  getPageVisitUrlList,
} from "./dynamicListSlice";
import { useAppDispatch } from "../../store";
import {
  getReferrerListApi,
  getPageVisitUrlListApi,
  getCustomParamsInPageVisitsApi,
} from "../../common/api/campaign/dynamicList";

const WEB_ACTIVITY_META_FILTERS = Object.values(WEBSITE_ACTIVITY_META);
const STR_IS_EQUAL = "string_is";

function getLabelValuePair(data: string[] | null) {
  return data ? data.map((value) => ({ label: value, value })) : null;
}

function getMultiValueLabel(
  options: ReactSelectDefaultOptionsType[],
  value: string[]
) {
  const filterValues = options.filter((option) => value.includes(option.value));
  const labels = filterValues.map((option) => option.label);
  return labels.join(" or ");
}

function getSingleValueLabel(
  options: ReactSelectDefaultOptionsType[],
  value: string
) {
  return options.find((option) => option.value === value)?.label ?? null;
}

export function MultiValueInputFields({
  value,
  filterValue,
  onChange,
  isReadOnly,
  type,
  activeErrorCheck,
  isDisabled,
}: {
  value: (string | number | null)[];
  filterValue: string;
  onChange: (value: string | number | string[] | number[]) => void;
  isReadOnly?: boolean;
  type: string;
  activeErrorCheck?: boolean;
  isDisabled?: boolean;
}) {
  const inputType = useMemo(
    () =>
      [OperatorType.FLOAT, OperatorType.INTEGER].includes(type as OperatorType)
        ? "number"
        : "text",
    [type]
  );

  function onInputChange(inputs: string[]) {
    if (type === OperatorType.FLOAT || type === OperatorType.INTEGER) {
      onChange(inputs.map((input) => parseFloat(input)));
    } else {
      onChange(inputs);
    }
  }

  const readOnlyString = useMemo(() => value.join(", "), [value]);
  return isReadOnly ? (
    <Text> {readOnlyString} </Text>
  ) : (
    <Box minW="300px">
      <MultiValueInput
        inputList={value.map(String)}
        onInputListChange={onInputChange}
        errorMsg={
          activeErrorCheck && !value.length ? "Inputs should not be empty" : ""
        }
        filterValue={filterValue as string}
        inputType={inputType}
        chipsContainerProps={{
          borderRadius: "6px",
        }}
        isDisabled={isDisabled}
      />
    </Box>
  );
}

export function ValueInputFields({
  value,
  validationError,
  type,
  onChange,
  isReadOnly,
  isDisabled,
}: {
  value: string | number;
  validationError?: string;
  type: string;
  onChange: (value: string | number | string[]) => void;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}) {
  let jsInputType: "text" | "number" | "date" = "text";
  let displayValue = value;

  if (type === OperatorType.DATE) {
    jsInputType = "date";
    // value does not have timezone, is just date string. ex: 2023-06-01
    const dateValue = new Date(value);
    // new Date() adds browser timezone to date string, remove that
    dateValue.setMinutes(
      dateValue.getMinutes() + dateValue.getTimezoneOffset()
    );

    // change date formatting using date-fns
    displayValue = value ? format(dateValue, "MMM dd, yyyy") : "";
  }

  function getOperatorField() {
    switch (type) {
      case OperatorType.INTEGER:
      case OperatorType.FLOAT:
        return (
          <NumberField
            type={
              type === OperatorType.INTEGER
                ? NUMBER_FIELD_TYPES.INTEGER
                : NUMBER_FIELD_TYPES.FLOAT
            }
            value={value}
            onValueChange={(value: any) => onChange(value ?? "")}
            isDisabled={isDisabled}
            isInvalid={!!validationError}
            w="180px"
          />
        );
      default:
        return (
          <InputFormControl
            width="180px"
            type={jsInputType}
            value={value}
            onChange={({ target: { value: val } }) => onChange(val)}
            validationMessage={validationError}
            isDisabled={isDisabled}
          />
        );
    }
  }

  return isReadOnly ? <Text>"{displayValue}"</Text> : getOperatorField();
}

export function ValueSelectFields({
  value,
  validationError,
  options,
  filter,
  onChange,
  loading,
  isReadOnly,
  isDisabled,
  isMulti = false,
  onMultiValueChange,
  ...props
}: {
  options: ReactSelectDefaultOptionsType[];
  validationError?: string;
  value: string | string[] | null;
  filter?: string;
  onChange: (value: string) => void;
  onMultiValueChange?: (val: string[]) => void;
  loading?: boolean;
  isMulti?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}) {
  const ComponentBasedOnFilter = useMemo(() => {
    switch (filter) {
      case PROPERTY_FILTER_ID.CAMPAIGN:
        return {
          Option: AssetPreviewComponent<ReactSelectDefaultOptionsWithClick>,
        };
      case PROPERTY_FILTER_ID.TEMPLATE:
        const TemplateDropdownOption = (
          props: GenericOptionPropsType<ReactSelectDefaultOptionsWithClick>
        ) => (
          <AssetPreviewComponent<ReactSelectDefaultOptionsWithClick>
            icon={FaEye}
            {...props}
          />
        );

        return {
          Option: TemplateDropdownOption,
        };
      case PROPERTY_FILTER_ID.FORM:
        return {
          Option: AssetPreviewComponent<ReactSelectDefaultOptionsWithClick>,
        };
      // can add other components for respective filters
      default:
        return undefined;
    }
  }, [filter]);

  const [selectedTemplate, setSelectedTemplate] = useState("");

  const isTemplateFilter = useMemo(
    () => filter === PROPERTY_FILTER_ID.TEMPLATE,
    [filter]
  );

  const transformedOptions = useMemo(() => {
    switch (filter) {
      case PROPERTY_FILTER_ID.CAMPAIGN:
        return options.map((option) => {
          return {
            ...option,
            onPreview: !isEqual(option.value, CAMPAIGN_LIST_INIT[0].value)
              ? (id: string) => openAssetInNewTab(ASSET_TYPES.CAMPAIGN, id)
              : undefined,
          };
        });
      case PROPERTY_FILTER_ID.TEMPLATE:
        return options.map((option) => {
          return {
            ...option,
            onPreview: !isEqual(option.value, TEMPLATE_LIST_INIT[0].value)
              ? setSelectedTemplate
              : undefined,
          };
        });
      case PROPERTY_FILTER_ID.FORM:
        return options.map((option) => {
          return {
            ...option,
            onPreview: (id: string) => openAssetInNewTab(ASSET_TYPES.FORMS, id),
          };
        });
      // can add other components for respective filters
      default:
        return options;
    }
  }, [filter, options]);

  if (isReadOnly) {
    return (
      <Text>
        {isMulti
          ? getMultiValueLabel(options, value as string[])
          : getSingleValueLabel(options, value as string)}
      </Text>
    );
  } else {
    const commonDropdownProps = {
      isLoading: loading,
      isInvalid: !!validationError,
      isDisabled: isDisabled,
      isSearchable: true,
      controlStyle: {
        minWidth: "100px",
        height: "32px",
        minHeight: "32px",
      },
      menuListStyle: {
        maxWidth: "900px",
      },
    };
    return (
      <>
        {isMulti ? (
          <MultiSelectDropdown
            options={options}
            value={options.filter((option) =>
              (value as string[]).includes(option.value)
            )}
            onChange={(val) => {
              onMultiValueChange?.(val.map(({ value }) => value));
            }}
            {...commonDropdownProps}
          />
        ) : (
          <>
            <DropdownWithSearch
              options={transformedOptions}
              value={
                transformedOptions.find((option) => option.value === value) ??
                null
              }
              onChange={(option) => {
                onChange(option?.value ?? "");
              }}
              id="value-select-fields"
              components={ComponentBasedOnFilter}
              {...commonDropdownProps}
              {...props}
            />
            {isTemplateFilter && (
              <TemplatePreviewModal
                isOpen={!!selectedTemplate}
                onClose={() => setSelectedTemplate("")}
                templateId={selectedTemplate}
              />
            )}
          </>
        )}
      </>
    );
  }
}

export function AsyncWebActivityMetaField({
  metaField,
  value,
  onChange,
  domainPath = false,
  validationError,
  isReadOnly,
}: {
  metaField: WEBSITE_ACTIVITY_META;
  value: string;
  onChange: (val: string) => void;
  domainPath?: boolean;
  isReadOnly?: boolean;
  validationError?: string;
}) {
  const [{ options, loadingList, totalPageCount, page }, setOptions] =
    useState<DropdownPaginatedListType>({
      options: null,
      loadingList: false,
      totalPageCount: null,
      page: 1,
    });
  const dispatch = useAppDispatch();

  const getOptions = useCallback(
    async (field: WEBSITE_ACTIVITY_META, data: ListFilterParams) => {
      let resp = null;

      switch (field) {
        case WEBSITE_ACTIVITY_META.VISITOR_SOURCE:
          resp = await dispatch(getReferrerList(data));
          if (isFulfilled(resp.meta.requestStatus)) {
            const {
              data,
              pagination: { total_pages },
            } = resp.payload as Awaited<ReturnType<typeof getReferrerListApi>>;
            return {
              options: getLabelValuePair(data),
              totalPageCount: total_pages,
            };
          }
          break;

        case WEBSITE_ACTIVITY_META.CUSTOM_PARAM:
          resp = await dispatch(getCustomParamsInPageVisits(data));
          if (isFulfilled(resp.meta.requestStatus)) {
            const {
              data,
              pagination: { total_pages },
            } = resp.payload as Awaited<
              ReturnType<typeof getCustomParamsInPageVisitsApi>
            >;
            return {
              options: getLabelValuePair(data),
              totalPageCount: total_pages,
            };
          }
          break;

        case WEBSITE_ACTIVITY_META.WEBPAGE_URL:
          resp = await dispatch(getPageVisitUrlList(data));
          if (isFulfilled(resp.meta.requestStatus)) {
            const {
              data,
              pagination: { total_pages },
            } = resp.payload as Awaited<
              ReturnType<typeof getPageVisitUrlListApi>
            >;
            return {
              options: getLabelValuePair(data),
              totalPageCount: total_pages,
            };
          }
      }
      return null;
    },
    [dispatch]
  );

  async function loadOptions(
    search: string,
    page: number,
    filterApplied?: boolean
  ) {
    setOptions((prev) => {
      return { ...prev, loadingList: true, page };
    });

    const data = await getOptions(metaField, { search, page, pageSize: 50 });
    const options = data?.options ?? [];
    const pageCount = data?.totalPageCount ?? null;

    setOptions((prev) => {
      return {
        ...prev,
        loadingList: false,
        options: filterApplied
          ? options
          : [...(prev.options ?? []), ...options],
        totalPageCount: pageCount,
      };
    });
  }

  const trimUrl = useCallback(
    (val: string) => {
      if (domainPath) {
        if (validateUrl(val)) {
          const url = new URL(val);
          return url.hostname + `${url.pathname === "/" ? "" : url.pathname}`;
        } else {
          const removeQuery = val.includes("?") ? val.split("?")[0] : val;
          return removeQuery;
        }
      }
      return val;
    },
    [domainPath]
  );

  return (
    <AsyncSearchableDropdown
      paginatedData={{
        options,
        loadingList,
        totalPageCount,
        page,
      }}
      value={value}
      validateText={domainPath ? trimUrl : undefined}
      loadOptions={loadOptions}
      onChange={onChange}
      isReadOnly={isReadOnly}
      invalidMsg={validationError}
    />
  );
}

export function DynamicListValueFields({
  value,
  validationError,
  onChange,
  argumentTypes,
  argumentSelectList,
  operator,
  helperText,
  isReadOnly,
  filterValue,
  noOfArguments,
  activeErrorCheck,
  isLoading = false,
  isDisabled,
  filter,
}: {
  value: ValueTypes;
  validationError?: string;
  onChange: (val: ValueTypes) => void;
  argumentTypes: OperatorArgType;
  operator?: string;
  argumentSelectList?: { label: string; value: string }[];
  helperText?: string | string[];
  isReadOnly?: boolean;
  filterValue?: string;
  noOfArguments?: string;
  activeErrorCheck?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  filter?: PROPERTY_FILTER_ID | string;
}) {
  function onChangeHandler(
    val: string | number | string[] | number[],
    index: number
  ) {
    let dataCopy = cloneDeep(value);
    if (typeof val === "string" || typeof val === "number") {
      dataCopy[index] = val;
    } else {
      dataCopy = val;
    }
    onChange(dataCopy);
  }
  if (
    filter &&
    WEB_ACTIVITY_META_FILTERS.includes(filter as WEBSITE_ACTIVITY_META) &&
    operator === STR_IS_EQUAL
  ) {
    return (
      <AsyncWebActivityMetaField
        metaField={filter as WEBSITE_ACTIVITY_META}
        value={value ? (value[0] as string) : ""}
        onChange={(val) => {
          onChange([val]);
        }}
        domainPath={true}
        isReadOnly={isReadOnly}
        validationError={validationError}
      />
    );
  }

  return (
    <>
      {argumentTypes &&
        argumentTypes.map((type, i) => {
          if (typeof type === "string") {
            if (argumentSelectList) {
              return (
                <Box key={i}>
                  <ValueSelectFields
                    options={argumentSelectList}
                    value={(value?.[i] as string) || ""}
                    onChange={(val) => onChangeHandler(val, i)}
                    validationError={validationError}
                    isReadOnly={isReadOnly}
                    loading={isLoading}
                    isDisabled={isDisabled}
                    filter={filter}
                  />
                </Box>
              );
            } else if (isArgumentMany(noOfArguments)) {
              return (
                <MultiValueInputFields
                  value={(value as (string | number)[]) ?? []}
                  filterValue={filterValue as string}
                  onChange={(val) => onChangeHandler(val, 0)}
                  isReadOnly={isReadOnly}
                  type={type}
                  activeErrorCheck={activeErrorCheck || !!validationError}
                  isDisabled={isDisabled}
                />
              );
            } else {
              return (
                <HStack key={i}>
                  {i === 1 && <Text>and</Text>}
                  <ValueInputFields
                    type={type}
                    value={
                      !isBlank(value?.[i]) ? (value[i] as string | number) : ""
                    }
                    onChange={(val) => onChangeHandler(val, i)}
                    validationError={validationError}
                    isReadOnly={isReadOnly}
                    isDisabled={isDisabled}
                  />
                </HStack>
              );
            }
          } else if (typeof type === "object") {
            const isMany = isArgumentMany(noOfArguments);
            return (
              <Box key={i}>
                <ValueSelectFields
                  options={
                    type?.map(({ display, id }) => ({
                      label: display,
                      value: id,
                    })) ?? []
                  }
                  value={
                    isMany ? (value as string[]) : (value?.[i] as string) || ""
                  }
                  onChange={(val) => onChangeHandler(val, i)}
                  isMulti={isMany}
                  onMultiValueChange={(val) => onChangeHandler(val, 0)}
                  validationError={validationError}
                  isReadOnly={isReadOnly}
                  isDisabled={isDisabled}
                />
              </Box>
            );
          } else {
            throw new Error("Unknown Argument Type: " + type);
          }
        })}
      {!isReadOnly && helperText && (
        <IconWithTooltip
          label={isArray(helperText) ? helperText.join(", ") : helperText}
          color="gray.400"
          tooltipProps={{ fontSize: "xs" }}
        />
      )}
    </>
  );
}

function ValueBadge({ value }: { value: string | number }) {
  return (
    <Text
      ml={1}
      borderRadius="sm"
      color="white"
      backgroundColor="gray.400"
      maxW="100px"
      px={1}
      isTruncated
    >
      {value}
    </Text>
  );
}

export function ValueFieldsReadonly({
  value,
  argumentTypes,
  isMany,
  limit,
}: {
  value: ValueTypes;
  argumentTypes: (string | string[])[] | null;
  isMany?: boolean;
  limit?: number;
}) {
  if (isMany) {
    return (
      <>
        {value.slice(0, limit).map((val, index) => {
          return <ValueBadge value={`${val}`} key={index} />;
        })}
        {limit && value.length > limit ? <ValueBadge value={"..."} /> : ""}
      </>
    );
  } else
    return (
      <>
        {argumentTypes &&
          argumentTypes.map((type, i) => {
            if (typeof value[i] === "object") {
              return (
                <>
                  {(value[i] as string[]).map((val, index) => {
                    return (
                      <>
                        {index !== 0 && " and"}
                        <ValueBadge value={val[index] as string} />
                      </>
                    );
                  })}
                </>
              );
            } else if (typeof type === "string") {
              return (
                <>
                  {i !== 0 && " and"}
                  <ValueBadge value={`${value[i]}`} key={i} />
                </>
              );
            } else if (typeof type === "object") {
              return <ValueBadge value={value[i] as string} key={i} />;
            } else {
              throw new Error("Unknown Argument Type: " + type);
            }
          })}
      </>
    );
}
