import {
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Skeleton,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function SubHeader({
  title,
  heading,
  isLoading,
  returnUrl,
  children,
}: {
  title?: string;
  heading?: ReactNode;
  isLoading?: boolean;
  returnUrl?: string;
  children?: ReactNode;
}) {
  const navigate = useNavigate();
  return (
    <>
      <Flex w="100%" justifyContent="space-between" px="15px" py="4" h="45px">
        <HStack mr="10">
          <IconButton
            size="md"
            aria-label="Go back"
            name="return-button"
            variant="ghost"
            onClick={() => (returnUrl ? navigate(returnUrl) : navigate(-1))}
            icon={<Icon fontSize="14px" as={FaArrowLeft} />}
          />
          {isLoading ? (
            <Skeleton height="22px" width="150px" mr={10} m={3} />
          ) : heading ? (
            <>{heading}</>
          ) : (
            <Heading fontSize="18px" mr={10}>
              {title ?? ""}
            </Heading>
          )}
        </HStack>
        {children}
      </Flex>
    </>
  );
}
