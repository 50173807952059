import { IconType } from "react-icons";
import {
  FaAddressBook,
  FaPauseCircle,
  FaPlayCircle,
  FaUserFriends,
} from "react-icons/fa";
import { RiEditCircleFill } from "react-icons/ri";
import {
  CAMPAIGN_CONTEXT,
  CONNECTOR,
  DynamicListChild,
  DynamicListType,
  RUN_TYPE,
  TYPE,
  UpdateValueOperation,
} from "../types/campaign";
import { LOADING_STATES } from "./common";

export enum VARIABLE_TYPE {
  TOKEN = "token",
  COLUMN = "column",
}

export const CUSTOM_TABLE: Readonly<"Custom Table"> = "Custom Table";

export enum CAMPAIGN_QUALIFICATION_RULES {
  EVERY_TIME = "every_time",
  ONCE_EVERY = "once_every",
  ONCE = "once",
}

export enum DELAY_TYPE {
  DELAY_BY = "delay_by",
  DELAY_UNTIL = "delay_until",
}

export enum DAY_OF_WEEK {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
}

export enum DELAY_OPTION_VALUES {
  TIME_OF_DAY = "time",
  SPECIFIC_DATE_TIME = "date_time",
  TIME_IN_WEEK = "weekday",
  FIRST_DAY_OF_MONTH = "first_of_month",
  LAST_DAY_OF_MONTH = "last_of_month",
}

export enum DELAY_UNIT {
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
}

export const QUALIFICATION_RULES_OPTIONS = {
  [CAMPAIGN_QUALIFICATION_RULES.ONCE]: "Only once",
  [CAMPAIGN_QUALIFICATION_RULES.EVERY_TIME]: "Every time",
  [CAMPAIGN_QUALIFICATION_RULES.ONCE_EVERY]: "Once every",
};

export const PERSON_ENTITY: Readonly<"person"> = "person";

export enum FILTER_TABLE_NAME {
  PRODUCT_ACTIVITY = "internal_product_activity",
  PERSON = "internal_person",
}

export enum FILTER_TYPE {
  PRODUCT_ACTIVITY = "internal_product_activity",
  PERSON = "internal_person",
  MARKETING_ACTIVITY = "marketing_activity",
  LIST_MEMBERSHIP = "list_memberships",
  ORG_MEMBERSHIP = "org_membership",
  ACCOUNT_MEMBERSHIP = "account_membership",
  WEBSITE_ACTIVITY = "website_tracking_activities",
}

export enum PERIODIC_FREQUENCIES {
  MINUTES = "minutes",
  HOURS = "hours",
}

export enum RECURRING_FREQUENCIES {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export enum FLOW_ACTIONS {
  DELAY = "delay",
  SEND_EMAIL = "send_email",
  SEND_INTERNAL_EMAIL = "send_internal_email",
  ADD_TO_STATIC_LIST = "add_to_static_list",
  REMOVE_FROM_STATIC_LIST = "remove_from_static_list",
  WEBHOOK = "webhook",
  SALESFORCE = "salesforce_manual_sync",
  SALESFORCE_CAMPAIGN = "salesforce_campaign_sync",
  UPDATE_VALUE = "update_value",
  BRANCH_BY_FILTER = "branch_by_filter",
  BRANCH_BY_TOKEN = "branch_by_token",
  BRANCH_BY_VALUE = "branch_by_value",
  GOTO = "goto",
  GROUP = "flow_group",
}

export const DISABLED_EDIT_CLASSNAME = "disabled-edit";

export enum CAMPAIGN_EDITABLE_COMPONENTS {
  DYNAMIC_LIST = "dynamic-list-container",
  SCHEDULE = "campaign-schedule-container",
  QUAlIFICATION = "campaign-qualification-rules-container",
  EDIT_DL_BUTTON = "dynamiclist-redirect-button",
}

export enum RECIPIENT_EVENT_TYPE {
  ALL = "all",
  NOT_SENT = "notsent",
  OPEN = "open",
  CLICK = "click",
  BOUNCE = "bounce",
  UNSUB = "unsub",
  SPAM = "spam",
}

export enum TOP_REPORTS_EVENT {
  CLICK = "click",
  OPEN = "open",
  BOUNCE = "bounce",
}

export enum CAMPAIGN_RUN_STATE {
  FINISHED = "finished",
  PROCESSING = "processing",
  CANCELED = "cancelled",
  SCHEDULED = "scheduled",
  SKIPPED = "skipped",
}

export const CAMPAIGN_TYPE: Record<
  CAMPAIGN_CONTEXT,
  { label: string; color: string; icon: IconType }
> = {
  [CAMPAIGN_CONTEXT.PERSON]: {
    label: "Contact",
    color: "blue.600",
    icon: FaAddressBook,
  },
  [CAMPAIGN_CONTEXT.ORG]: {
    label: "User",
    color: "purple.600",
    icon: FaUserFriends,
  },
};

export const SCHEDULE_TYPES = [
  { name: "Now", value: RUN_TYPE.AT_ONCE, frequencies: [] },
  { name: "Only once", value: RUN_TYPE.ONCE, frequencies: [] },
  {
    name: "High frequency",
    value: RUN_TYPE.PERIOD,
    frequencies: ["minutes", "hours", "days", "weeks"],
  },
  {
    name: "Recurring",
    value: RUN_TYPE.RECURRING,
    frequencies: ["day", "week", "month", "year"],
  },
];

export enum SALESFORCE_SYNC_STATE {
  SCHEDULED = "run_scheduled",
  PROCESSING = "started_processing",
  CANCELLED = "run_cancelled",
  FINISHED = "run_finished",
}

export enum UPDATE_VALUE_TYPE {
  FORMULA = "formula",
  TOKEN = "token",
}

export enum UPDATE_VALUE_OBJECT_TYPE {
  PERSON = "person",
}

export const IS_EQUAL_OPERATOR: Readonly<"is_equal"> = "is_equal";
export const EVENT_NAME_FILTER = "event_name";

export const NEW_OUTER_GROUP: Readonly<DynamicListType> = {
  type: TYPE.GROUP,
  name: "",
  filter_type: null,
  filter: null,
  property: null,
  operator: null,
  value: null,
  connector: CONNECTOR.AND,
  children: [],
};

export const NEW_FILTER_GROUP: { [key: string]: Readonly<DynamicListChild> } = {
  [FILTER_TYPE.PERSON]: {
    ...NEW_OUTER_GROUP,
    children: [
      {
        type: TYPE.EXPRESSION,
        filter_type: FILTER_TYPE.PERSON,
        filter: "",
        property: null,
        operator: null,
        value: [""],
        connector: CONNECTOR.EMPTY,
      },
    ],
  },
  [FILTER_TYPE.PRODUCT_ACTIVITY]: {
    ...NEW_OUTER_GROUP,
    children: [
      {
        type: TYPE.EXPRESSION,
        filter_type: FILTER_TYPE.PRODUCT_ACTIVITY,
        filter: EVENT_NAME_FILTER,
        property: null,
        operator: IS_EQUAL_OPERATOR,
        value: [""],
        connector: CONNECTOR.EMPTY,
      },
    ],
  },
  [FILTER_TYPE.MARKETING_ACTIVITY]: {
    ...NEW_OUTER_GROUP,
    children: [
      {
        type: TYPE.EXPRESSION,
        filter_type: FILTER_TYPE.MARKETING_ACTIVITY,
        filter: EVENT_NAME_FILTER,
        property: null,
        operator: IS_EQUAL_OPERATOR,
        value: [""],
        connector: CONNECTOR.EMPTY,
      },
    ],
  },
  [FILTER_TYPE.WEBSITE_ACTIVITY]: {
    ...NEW_OUTER_GROUP,
    children: [
      {
        type: TYPE.EXPRESSION,
        filter_type: FILTER_TYPE.WEBSITE_ACTIVITY,
        filter: EVENT_NAME_FILTER,
        property: null,
        operator: IS_EQUAL_OPERATOR,
        value: ["browsed_session"],
        connector: CONNECTOR.EMPTY,
      },
    ],
  },
  [FILTER_TYPE.LIST_MEMBERSHIP]: {
    ...NEW_OUTER_GROUP,
    children: [
      {
        type: TYPE.EXPRESSION,
        filter_type: FILTER_TYPE.LIST_MEMBERSHIP,
        filter: null,
        property: null,
        operator: null,
        value: [""],
        connector: CONNECTOR.EMPTY,
      },
    ],
  },
};

export const FILTER_COLUMNS_DL_PREVIEW = ["email"];

export enum CAMPAIGN_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DRAFT = "draft",
}

export const CAMPAIGN_STATUS_BASIC = {
  [CAMPAIGN_STATUS.ACTIVE]: {
    color: "brand.green",
    icon: FaPlayCircle,
  },
  [CAMPAIGN_STATUS.INACTIVE]: {
    color: "yellow.400",
    icon: RiEditCircleFill,
  },
  [CAMPAIGN_STATUS.DRAFT]: {
    color: "gray.600",
    icon: FaPauseCircle,
  },
};

export const MANY_ARGUMENTS = "many";

export const CAMPAIGN_LIST_STATS_INIT = {
  count_processed: null,
  count_delivered: null,
  count_bounced: null,
  count_dropped: null,
  open_count: null,
  total_count: null,
  stats_loading: LOADING_STATES.INIT,
};

export const SF_CAMPAIGN_SYNC_DRAWER_VALIDITY = {
  campaignIdSource: true,
  salesforceCampaign: true,
  personField: true,
  salesforceCampaignStatus: true,
};

export const BOUNCE_GROUP_BY = "bounce_classification";

export const BOUNCE_EVENT = "bounce";

export enum BOUNCE_STATS_VIEW_TYPE {
  AGGREGATE = "aggregate",
  LIST = "list",
}

export const INIT_UPDATE_VALUE: UpdateValueOperation = {
  type: "",
  object_type: UPDATE_VALUE_OBJECT_TYPE.PERSON,
  filter_type: FILTER_TYPE.PERSON,
  filter: "",
  operator: null,
  value: [],
};

export const ADDITIONAL_STEP_ACTIONS = [
  FLOW_ACTIONS.BRANCH_BY_FILTER,
  FLOW_ACTIONS.BRANCH_BY_TOKEN,
  FLOW_ACTIONS.BRANCH_BY_VALUE,
  FLOW_ACTIONS.UPDATE_VALUE,
  FLOW_ACTIONS.DELAY,
  FLOW_ACTIONS.SALESFORCE_CAMPAIGN,
  FLOW_ACTIONS.SEND_EMAIL,
];

export const VALUE_ALL = "all";

export const TEMPLATE_LIST_INIT = [{ label: "All emails", value: VALUE_ALL }];
export const CAMPAIGN_LIST_INIT = [{ label: "All journeys", value: VALUE_ALL }];

export enum PROPERTY_FILTER_ID {
  TEMPLATE = "template_id",
  CAMPAIGN = "campaign_id",
  FORM = "form_id",
  FORM_FIELD = "submission_data",
  REASON = "reason",
  URL_FRAGMENT = "page_url_fragment",
  URL_PROTOCOL = "page_url_scheme",
}

export enum CHILD_FILTER_TYPE {
  TIME_FRAME = "event_date",
  FREQUENCY = "frequency",
  LINK_CLICKED = "url",
  VISITOR_SOURCE = "referrer_domain_path",
}

export enum WEBSITE_ACTIVITY_META {
  VISITOR_SOURCE = "referrer_domain_path",
  WEBPAGE_URL = "page_domain_path",
  CUSTOM_PARAM = "custom_parameter",
}

export const DL_LIST_ALL_ACTION = "campaign/dynamic-list/list-all";
export const CAMPAIGN_LIST_ALL_ACTION = "campaign/list-all";
