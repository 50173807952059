import { useContext, useMemo, Fragment } from "react";
import {
  CONNECTOR,
  DynamicListChild,
  DynamicListChildL2,
  OperatorType,
  TYPE,
  ValueTypes,
  WebsiteActivityFiltersArg,
} from "../../common/types/campaign";
import {
  createGroupId,
  getOperatorDetails,
  isLoading,
  isBlank,
  isArgumentMany,
} from "../../common/helper/commonHelper";
import { DynamicListContext } from "./DynamicList";
import { FilterGroupBox } from "./FilterGroupBox";
import { cloneDeep, isNull } from "lodash";
import {
  VStack,
  HStack,
  Box,
  Text,
  Menu,
  Button,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
  Stack,
  Icon,
} from "@chakra-ui/react";
import ActivitySelector from "./ActivitySelector";
import {
  CHILD_FILTER_TYPE,
  PROPERTY_FILTER_ID,
  FILTER_TYPE,
  WEBSITE_ACTIVITY_META,
} from "../../common/constants/campaign";
import { useSelector } from "react-redux";
import { selectDynamicList } from "./dynamicListSlice";
import { RiMailCheckLine } from "react-icons/ri";
import { RxPlus, RxChevronDown } from "react-icons/rx";
import AddPropertyButton from "./AddPropertyButton";
import {
  FrequencySelector,
  StringOperatorValueFilter,
  TimeFrameSelector,
} from "./CommonFilterRows";
import RemoveRowCloseButton from "../RemoveRowCloseButton";
import {
  getWebActivityLevelOneFilterIndices,
  initWebsiteActivity,
  getFilterGroupChild,
  getWebActivityFilters,
  getWebActivityArgumentList,
  isTypeGroup,
} from "../../common/helper/dynamicListHelper";
import DynamicListLogicGate, {
  ReadOnlyDlLogicGate,
} from "./DynamicListLogicGate";
import {
  DynamicListValueFields,
  ValueSelectFields,
  AsyncWebActivityMetaField,
} from "./DynamicListValueFields";
import { isEmpty } from "lodash";
import { LuAppWindow, LuFileCode } from "react-icons/lu";
import { findFirstGroupIndex } from "../../common/helper/dynamicListHelper";

// Website activity schema
// outer group
//   filter group - outerGroups's children , frequency filter included here
//      property group - filterGroup's children (type - expression -> time frame, source of visit and only one type-group child is allowed - that contains all property groups as it children)
//         property row - all properties/filters with operators and values (each row), except webpage url (has children)
//           property row children - (only webpage url, will have children) [first child is the property filter(=webpage url) itself, followed by other argument filters]

enum FREQUENCY_CONDITION {
  ADD = "add",
  OPERATOR = "operator",
  VALUE = "value",
  REMOVE = "remove",
}

enum FIELD {
  OPERATOR = "operator",
  VALUE = "value",
  FILTER = "filter",
}

enum ACTION {
  ADD = "add",
  CHANGE = "change",
  REMOVE = "remove",
}

const UTM_PARAM_FILTER = "utm_parameter";
const CUSTOM_PARAM_FILTER = "custom_parameter";
const STR_OPERATOR = "string_operator";

export default function WebsiteActivityFilterGroup({
  filterGroup,
  onChangeFilterGroup,
  onRemoveFilterGroup,
  id,
  label,
  isReadOnly,
}: {
  filterGroup: DynamicListChild;
  onChangeFilterGroup: (data: any) => void;
  onRemoveFilterGroup: () => void;
  id: string;
  label: string;
  isReadOnly?: boolean;
}) {
  const {
    operators: { data: operators },
    websiteActivity: {
      eventNameList: { data: eventNameList, loading: eventNameListLoading },
    },
  } = useSelector(selectDynamicList);
  const { activeErrorCheck } = useContext(DynamicListContext);

  //first child's value is the main filter - website activity
  const websiteActivity = (filterGroup.children[0].value?.[0] as string) ?? "";

  //possible filters included as a part of specific website activity
  const websiteActivityFilters = useMemo(
    () => getWebActivityArgumentList(eventNameList, websiteActivity),
    [eventNameList, websiteActivity]
  );

  const timeFrameInEventList = websiteActivityFilters?.includes(
    CHILD_FILTER_TYPE.TIME_FRAME
  );
  const pageSrcInEventList = websiteActivityFilters?.includes(
    CHILD_FILTER_TYPE.VISITOR_SOURCE
  );

  const { timeFrameIndex, srcOfVisitIndex } = useMemo(
    () => getWebActivityLevelOneFilterIndices(filterGroup),
    [filterGroup]
  );

  //all propertyGroups all put inside one property group with type group
  const propertyGroupsIndex = useMemo(
    () => findFirstGroupIndex(filterGroup.children),
    [filterGroup.children]
  );

  function onFilterGroupNameChange(name: string) {
    if (filterGroup.name !== name) {
      const dataCopy = cloneDeep(filterGroup);
      dataCopy.name = name;
      onChangeFilterGroup(dataCopy);
    }
  }

  function onFrequencyChange(
    operation: FREQUENCY_CONDITION,
    value?: string | ValueTypes
  ) {
    const dataCopy = cloneDeep(filterGroup);
    switch (operation) {
      case FREQUENCY_CONDITION.ADD:
        dataCopy.operator = "exactly";
        dataCopy.value = [0];
        break;
      case FREQUENCY_CONDITION.REMOVE:
        dataCopy.operator = null;
        dataCopy.value = null;
        break;
      case FREQUENCY_CONDITION.OPERATOR:
        if (dataCopy.operator !== value) {
          dataCopy.operator = value as string;
          dataCopy.value = [];
        }
        break;
      case FREQUENCY_CONDITION.VALUE:
        dataCopy.value = value as ValueTypes;
    }
    dataCopy.validation_error = "";
    onChangeFilterGroup(dataCopy);
  }

  function onAddTimeFrame() {
    const dataCopy = cloneDeep(filterGroup);
    dataCopy.children.splice(1, 0, {
      ...initWebsiteActivity({ filter: CHILD_FILTER_TYPE.TIME_FRAME }),
    });
    onChangeFilterGroup(dataCopy);
  }

  function onAddSourceOfVisit() {
    const dataCopy = cloneDeep(filterGroup);
    dataCopy.children.splice(1, 0, {
      ...initWebsiteActivity({ filter: CHILD_FILTER_TYPE.VISITOR_SOURCE }),
    });
    onChangeFilterGroup(dataCopy);
  }

  // property group change type-expression
  function onChangePropertyGroup(
    field: FIELD,
    index: number,
    value: string | ValueTypes
  ) {
    const dataCopy = cloneDeep(filterGroup);
    switch (field) {
      case FIELD.OPERATOR:
        if (dataCopy.children[index].operator !== value) {
          dataCopy.children[index].operator = value as string;
          dataCopy.children[index].value = [];
        }
        break;
      case FIELD.VALUE:
        dataCopy.children[index].value = value as ValueTypes;
        break;
    }

    dataCopy.children[index].validation_error = "";
    onChangeFilterGroup(dataCopy);
  }

  function onRemovePropertyGroup(index: number) {
    const dataCopy = cloneDeep(filterGroup);
    dataCopy.children.splice(index, 1);
    onChangeFilterGroup(dataCopy);
  }

  function onAddFirstPropertyGroup() {
    const dataCopy = cloneDeep(filterGroup);

    //filter group -> property group container -> property group -> property

    //property group container group
    const firstPropertyGroup = {
      ...initWebsiteActivity({ connector: CONNECTOR.AND }),
      filter_type: null,
      type: TYPE.GROUP,

      //property groups
      children: [
        {
          ...initWebsiteActivity({ connector: CONNECTOR.EMPTY }),
          type: TYPE.GROUP,
          filter_type: null,
          children: [initWebsiteActivity({ connector: CONNECTOR.EMPTY })],
        },
      ],
    };

    dataCopy.children.push(firstPropertyGroup as DynamicListChildL2);
    onChangeFilterGroup(dataCopy);
  }

  function onPropertyGroupsChange(
    propertyGroups: DynamicListChildL2[],
    propertyGroupsIndex: number
  ) {
    const dataCopy = cloneDeep(filterGroup);

    //remove property group if there are no nested property Groups
    if (isEmpty(propertyGroups)) {
      dataCopy.children.splice(propertyGroupsIndex, 1);
    } else {
      dataCopy.children[propertyGroupsIndex].children = propertyGroups;
    }

    onChangeFilterGroup(dataCopy);
  }

  const timeFrameFilter = getFilterGroupChild(filterGroup, timeFrameIndex);

  const srcOfVisitFilter = getFilterGroupChild(filterGroup, srcOfVisitIndex);

  const wrapperStyle = isReadOnly
    ? {
        p: 0,
        gridGap: 1,
      }
    : {
        px: 3,
        py: 2,
        gridGap: 2,
      };

  const hidePropertyButton = isReadOnly || !websiteActivity;

  return (
    <FilterGroupBox
      id={id}
      label={label}
      groupName={filterGroup.name}
      onGroupNameChange={onFilterGroupNameChange}
      onRemove={onRemoveFilterGroup}
      isReadOnly={isReadOnly}
    >
      <VStack alignItems="flex-start" {...wrapperStyle} w="100%">
        {filterGroup.children && (
          <>
            <HStack
              width="100%"
              wrap="wrap"
              alignItems="flex-start"
              gridGap={isReadOnly ? undefined : "2"}
            >
              <ActivitySelector
                value={websiteActivity}
                activityList={
                  eventNameList?.map(({ id, display }) => ({
                    label: display,
                    value: id,
                  })) ?? []
                }
                onChange={(activity) => {
                  //only one activity so no onChange needed
                }}
                loading={isLoading(eventNameListLoading)}
                displayText="...performed a web activity of..."
                isReadOnly={isReadOnly}
                icon={RiMailCheckLine}
              />
              <HStack alignItems="flex-start">
                {websiteActivityFilters?.includes(
                  CHILD_FILTER_TYPE.FREQUENCY
                ) &&
                  (filterGroup.operator && operators ? (
                    <FrequencySelector
                      numberOperators={operators[OperatorType.INTEGER]}
                      operator={filterGroup.operator}
                      onOperatorChange={(value: string) =>
                        onFrequencyChange(FREQUENCY_CONDITION.OPERATOR, value)
                      }
                      value={filterGroup.value as ValueTypes}
                      onValueChange={(value: ValueTypes) =>
                        onFrequencyChange(FREQUENCY_CONDITION.VALUE, value)
                      }
                      isReadOnly={isReadOnly}
                      validationError={
                        activeErrorCheck ? filterGroup.validation_error : ""
                      }
                    />
                  ) : (
                    <AddPropertyButton
                      onClick={() => onFrequencyChange(FREQUENCY_CONDITION.ADD)}
                      children="Add frequency"
                      hidden={hidePropertyButton}
                    />
                  ))}

                <RemoveRowCloseButton
                  onClick={() => onFrequencyChange(FREQUENCY_CONDITION.REMOVE)}
                  hidden={isReadOnly || !filterGroup.operator}
                />
              </HStack>
              {timeFrameInEventList && isNull(timeFrameIndex) && (
                <AddPropertyButton
                  onClick={onAddTimeFrame}
                  children="Add time frame"
                  hidden={hidePropertyButton}
                />
              )}
              {pageSrcInEventList && isNull(srcOfVisitIndex) && (
                <AddPropertyButton
                  onClick={onAddSourceOfVisit}
                  children="Add source of visit"
                  hidden={hidePropertyButton}
                />
              )}
            </HStack>
            {timeFrameInEventList && !isNull(timeFrameIndex) && operators && (
              <TimeFrameSelector
                dateOperators={operators[OperatorType.DATE]}
                setOperator={(value) =>
                  onChangePropertyGroup(FIELD.OPERATOR, timeFrameIndex, value)
                }
                operator={timeFrameFilter?.operator ?? ""}
                value={timeFrameFilter?.value as ValueTypes}
                setValue={(value) =>
                  onChangePropertyGroup(FIELD.VALUE, timeFrameIndex, value)
                }
                showRemoveButton={true}
                onRemoveRow={() => onRemovePropertyGroup(timeFrameIndex)}
                validationError={
                  activeErrorCheck ? timeFrameFilter?.validation_error : ""
                }
                isReadOnly={isReadOnly}
              />
            )}

            {pageSrcInEventList && !isNull(srcOfVisitIndex) && operators && (
              <StringOperatorValueFilter
                filter={CHILD_FILTER_TYPE.VISITOR_SOURCE}
                stringOperators={operators[OperatorType.STRING]}
                setOperator={(value) =>
                  onChangePropertyGroup(FIELD.OPERATOR, srcOfVisitIndex, value)
                }
                operator={srcOfVisitFilter?.operator ?? ""}
                value={srcOfVisitFilter?.value as ValueTypes}
                setValue={(value) =>
                  onChangePropertyGroup(FIELD.VALUE, srcOfVisitIndex, value)
                }
                onRemoveRow={() => onRemovePropertyGroup(srcOfVisitIndex)}
                validationError={
                  activeErrorCheck ? srcOfVisitFilter?.validation_error : ""
                }
                isReadOnly={isReadOnly}
              />
            )}
          </>
        )}

        <AddPropertyButton
          children="Click to filter with additional properties (URLs, UTM parameters)"
          capitalize={false}
          onClick={onAddFirstPropertyGroup}
          isDisabled={!websiteActivity}
          hidden={isReadOnly || propertyGroupsIndex >= 0}
          p={1}
        />
        {propertyGroupsIndex >= 0 && (
          <PropertyGroupsContainer
            propertyGroups={
              filterGroup.children[propertyGroupsIndex]?.children ?? []
            }
            onChangePropertyGroups={(data) =>
              onPropertyGroupsChange(data, propertyGroupsIndex)
            }
            mainFilter={websiteActivity || ""}
            parentId={id}
            isReadOnly={isReadOnly}
          />
        )}
      </VStack>
    </FilterGroupBox>
  );
}

function PropertyGroupsContainer({
  propertyGroups,
  parentId,
  mainFilter,
  onChangePropertyGroups,
  isReadOnly,
}: {
  propertyGroups: DynamicListChildL2[];
  parentId: string;
  mainFilter: string;
  onChangePropertyGroups: (data: DynamicListChildL2[]) => void;
  isReadOnly?: boolean;
}) {
  // taking second one as first one is always empty
  const propertyGroupsConnector = propertyGroups[1]?.connector ?? CONNECTOR.AND;

  function onChangePropertyGroup(
    action: ACTION,
    index?: number,
    data?: DynamicListChildL2
  ) {
    const dataCopy = cloneDeep(propertyGroups);
    switch (action) {
      case ACTION.ADD:
        const newPropertyGroup = {
          ...initWebsiteActivity({ connector: propertyGroupsConnector }),
          type: TYPE.GROUP,
          filter_type: null,
          children: [initWebsiteActivity({ connector: CONNECTOR.EMPTY })],
        };
        dataCopy.push(newPropertyGroup);
        break;

      case ACTION.CHANGE:
        dataCopy[index!] = data!;
        break;

      case ACTION.REMOVE:
        dataCopy.splice(index!, 1);
    }
    onChangePropertyGroups(dataCopy);
  }

  function onPropertyGroupsConnectorChange(connector: CONNECTOR) {
    if (propertyGroups.length > 1 && propertyGroupsConnector !== connector) {
      const dataCopy = cloneDeep(propertyGroups);
      dataCopy.forEach((propertyGroup, index) => {
        if (isTypeGroup(propertyGroup.type) && index > 0) {
          propertyGroup.connector = connector;
        }
      });
      onChangePropertyGroups(dataCopy);
    }
  }

  const totalPropertyGroups = propertyGroups.length;

  const wrapperStyle = isReadOnly ? { spacing: 2 } : { spacing: 3 };

  return (
    <VStack
      alignItems="flex-start"
      w="100%"
      fontSize="14px"
      bg="grayV2.300"
      rounded="md"
      hidden={totalPropertyGroups < 1}
      px={2}
      pb={2}
    >
      <PropertyGroupHeader
        connector={propertyGroupsConnector}
        onConnectorChange={onPropertyGroupsConnectorChange}
        displayConnector={totalPropertyGroups > 1}
        isReadOnly={isReadOnly}
      />

      <VStack alignItems="flex-start" w="100%" {...wrapperStyle}>
        {propertyGroups.map((propertyGroup, index) => {
          const propertyGroupId = createGroupId(
            parentId,
            "property",
            index + 1
          );
          return (
            <Stack
              key={propertyGroupId}
              alignItems="flex-start"
              w="100%"
              direction={isReadOnly ? "column" : "row"}
            >
              <Box w={totalPropertyGroups > 1 ? "25px" : undefined}>
                {index > 0 && (
                  <ReadOnlyDlLogicGate connector={propertyGroup.connector} />
                )}
              </Box>

              <PropertyGroup
                propertyGroup={propertyGroup}
                onChangePropertyGroup={(data) =>
                  onChangePropertyGroup(ACTION.CHANGE, index, data)
                }
                onRemovePropertyGroup={() =>
                  onChangePropertyGroup(ACTION.REMOVE, index)
                }
                label={propertyGroup.name || `Property Group ${index + 1}`}
                id={propertyGroupId}
                mainFilter={mainFilter}
                isReadOnly={isReadOnly}
              />
            </Stack>
          );
        })}

        <HStack px={1} alignItems="center" hidden={isReadOnly}>
          <ReadOnlyDlLogicGate connector={propertyGroupsConnector} mt={2} />
          <AddPropertyButton
            onClick={() => onChangePropertyGroup(ACTION.ADD)}
            children="Add property group"
          />
        </HStack>
      </VStack>
    </VStack>
  );
}

//Single propertyGroup
function PropertyGroup({
  propertyGroup,
  onChangePropertyGroup,
  onRemovePropertyGroup,
  mainFilter,
  id,
  label,
  isReadOnly,
}: {
  propertyGroup: DynamicListChildL2;
  onChangePropertyGroup: (data: DynamicListChildL2) => void;
  onRemovePropertyGroup: () => void;
  mainFilter: string;
  id: string;
  label: string;
  isReadOnly?: boolean;
}) {
  const propertyGroupRowsConnector =
    propertyGroup.children?.[1]?.connector ?? CONNECTOR.AND; //first connector [0].connector is always empty;

  function onPropertyGroupNameChange(name: string) {
    if (propertyGroup.name !== name) {
      const dataCopy = cloneDeep(propertyGroup);
      dataCopy.name = name;
      onChangePropertyGroup(dataCopy);
    }
  }

  function onAddRow() {
    const newPropertyGroupRow = initWebsiteActivity({
      connector: propertyGroupRowsConnector,
    });
    const dataCopy = cloneDeep(propertyGroup);
    dataCopy.children?.push(newPropertyGroupRow);
    onChangePropertyGroup(dataCopy);
  }

  function onRemoveRow(index: number) {
    const dataCopy = cloneDeep(propertyGroup);
    dataCopy.children?.splice(index, 1);
    onChangePropertyGroup(dataCopy);
  }

  function onChangeRow(row: DynamicListChildL2, index: number) {
    if (propertyGroup.children) {
      const dataCopy = cloneDeep(propertyGroup);
      (dataCopy.children as DynamicListChildL2[])[index] = row;
      onChangePropertyGroup(dataCopy);
    }
  }

  const totalProperties = propertyGroup.children?.length ?? 0;

  function onRowsConnectorChange(connector: CONNECTOR) {
    //if only one property then need not change the connector
    if (totalProperties > 1 && connector !== propertyGroupRowsConnector) {
      const dataCopy = cloneDeep(propertyGroup);
      dataCopy.children?.forEach((property, index) => {
        if (index > 0) property.connector = connector;
      });
      onChangePropertyGroup(dataCopy);
    }
  }

  return (
    <FilterGroupBox
      id={id}
      type="property"
      groupName={propertyGroup.name ?? ""}
      onGroupNameChange={onPropertyGroupNameChange}
      onRemove={onRemovePropertyGroup}
      label={label}
      isReadOnly={isReadOnly}
      additionalComponent={
        <PropertyGroupHeader
          connector={propertyGroupRowsConnector}
          onConnectorChange={onRowsConnectorChange}
          isReadOnly={isReadOnly}
          showText={false}
          displayConnector={totalProperties > 1}
        />
      }
      bg="grayV2.400"
      borderColor="grayV2.400"
      readOnlyProps={{ bg: "grayV2.400", px: 0 }}
    >
      <VStack
        alignItems="baseline"
        w="100%"
        px={2}
        divider={
          isReadOnly ? (
            <Divider orientation="horizontal" borderColor="grayV2.200" />
          ) : undefined
        }
      >
        {propertyGroup.children?.map((property, index) => {
          return (
            <HStack
              key={index}
              width="100%"
              alignItems="baseline"
              spacing={0}
              gap={1}
            >
              <Box w={totalProperties > 1 && !isReadOnly ? "25px" : undefined}>
                {index > 0 && (
                  <ReadOnlyDlLogicGate connector={property.connector} />
                )}
              </Box>

              <PropertyGroupRow
                propertyRow={property}
                onChangePropertyRow={(data) => onChangeRow(data, index)}
                onRemovePropertyRow={() => onRemoveRow(index)}
                mainFilter={mainFilter}
                isReadOnly={isReadOnly}
                showRemoveButton={totalProperties > 1}
              />
            </HStack>
          );
        })}
      </VStack>

      <AddPropertyButton
        children={
          totalProperties > 1
            ? "Add another property"
            : "Add multiple properties in the group"
        }
        onClick={onAddRow}
        hidden={isReadOnly || totalProperties <= 0}
        py={2}
        px={4}
      />
    </FilterGroupBox>
  );
}

function PropertyGroupRow({
  propertyRow,
  onChangePropertyRow,
  onRemovePropertyRow,
  mainFilter,
  showRemoveButton = false,
  isReadOnly,
}: {
  propertyRow: DynamicListChildL2;
  onChangePropertyRow: (data: DynamicListChildL2) => void;
  onRemovePropertyRow: () => void;
  mainFilter: string;
  showRemoveButton?: boolean;
  isReadOnly?: boolean;
}) {
  const {
    websiteActivity: {
      eventNameList: { data: eventNameList },
    },
  } = useSelector(selectDynamicList);

  const { activeErrorCheck } = useContext(DynamicListContext);

  //webpage url , custom and utm parameters
  const level2FiltersList = useMemo(
    () => getWebActivityFilters(eventNameList ?? [], mainFilter, 2) ?? [],
    [eventNameList, mainFilter]
  );

  const filter = useMemo(
    () =>
      (isTypeGroup(propertyRow.type)
        ? propertyRow.children?.[0].filter
        : propertyRow.filter) ?? null,
    [propertyRow]
  );

  const filterDetails = useMemo(
    () =>
      filter ? level2FiltersList.find(({ id }) => id === filter) ?? null : null,
    [level2FiltersList, filter]
  );

  function checkIfArgPresent(filter: string) {
    return !!level2FiltersList.find(({ id }) => id === filter)?.arguments;
  }

  function onPropertyFilterChange(value: string) {
    if (filter !== value) {
      const dataCopy = cloneDeep(propertyRow);

      if (checkIfArgPresent(value)) {
        dataCopy.type = TYPE.GROUP;
        dataCopy.children = [{ ...initWebsiteActivity({ filter: value }) }];
        dataCopy.filter_type = null;
        dataCopy.filter = "";
        dataCopy.operator = null;
      } else {
        dataCopy.filter = value;
        dataCopy.type = TYPE.EXPRESSION;
        dataCopy.filter_type = FILTER_TYPE.WEBSITE_ACTIVITY;
        dataCopy.children = [];

        switch (value) {
          case UTM_PARAM_FILTER:
            dataCopy.operator = "utm_parameter_string_compare";
            break;
          case CUSTOM_PARAM_FILTER:
            dataCopy.operator = "custom_parameter_string_compare";
        }
      }

      dataCopy.value = [];
      dataCopy.validation_error = "";

      onChangePropertyRow(dataCopy);
    }
  }

  function onChangePropertyRowGroup(data: DynamicListChildL2[]) {
    const dataCopy = cloneDeep(propertyRow);
    dataCopy.children = data;
    onChangePropertyRow(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        p: 0,
        spacing: 0,
        gap: 1,
        gridRowGap: 1,
      }
    : {
        spacing: 2,
        gridRowGap: 2,
        p: 1,
      };

  const validationError = activeErrorCheck
    ? (propertyRow.validation_error ||
        propertyRow.children?.[0]?.validation_error) ??
      ""
    : "";

  return (
    <VStack alignItems="flex-start" w="100%" spacing={0}>
      <HStack alignItems="flex-start" wrap="wrap" w="100%" {...wrapperStyle}>
        <HStack maxW="40px" hidden={isReadOnly} pl={1}>
          <Text pt={3}>where...</Text>
        </HStack>

        <HStack
          alignItems="flex-start"
          w="87%"
          flexWrap="wrap"
          {...wrapperStyle}
        >
          <ActivitySelector
            activityList={level2FiltersList?.map(({ display, id }) => ({
              label: display,
              value: id,
            }))}
            value={filter ?? ""}
            onChange={onPropertyFilterChange}
            isReadOnly={isReadOnly}
            validationError={validationError}
            icon={LuAppWindow}
          />
          {filter && (
            <>
              {isTypeGroup(propertyRow.type) ? (
                <PropertyGroupRowGroup
                  propertyRowGroup={propertyRow.children ?? []}
                  rowFilterDetails={filterDetails}
                  onChangePropertyRowGroup={onChangePropertyRowGroup}
                  isReadOnly={isReadOnly}
                />
              ) : (
                <PropertyGroupRowExp
                  propertyRowExp={propertyRow}
                  rowFilterDetails={filterDetails}
                  onChangePropertyRowExp={onChangePropertyRow}
                  isReadOnly={isReadOnly}
                />
              )}
            </>
          )}
        </HStack>

        <RemoveRowCloseButton
          onClick={onRemovePropertyRow}
          hidden={isReadOnly || !showRemoveButton}
        />
      </HStack>
    </VStack>
  );
}

//used for custom and utm parameter filters
function PropertyGroupRowExp({
  propertyRowExp,
  onChangePropertyRowExp,
  rowFilterDetails,
  isReadOnly,
}: {
  propertyRowExp: DynamicListChildL2;
  onChangePropertyRowExp: (data: DynamicListChildL2) => void;
  rowFilterDetails: WebsiteActivityFiltersArg | null;
  isReadOnly?: boolean;
}) {
  const {
    operators: { data: operators },
  } = useSelector(selectDynamicList);
  const { activeErrorCheck } = useContext(DynamicListContext);

  //custom and utm operator details
  const operatorDetails = useMemo(
    () =>
      propertyRowExp.operator && rowFilterDetails?.data_type && operators
        ? getOperatorDetails(
            propertyRowExp.operator,
            operators,
            rowFilterDetails.data_type
          )
        : null,
    [rowFilterDetails?.data_type, operators, propertyRowExp.operator]
  );

  function onChangeValue(
    value: string | number | string[],
    index: number,
    emptyNextValue?: boolean
  ) {
    const dataCopy = cloneDeep(propertyRowExp);

    dataCopy.value[index] = value;
    dataCopy.validation_error = "";
    if (emptyNextValue) {
      dataCopy.value.splice(index + 1, 1);
    }
    onChangePropertyRowExp(dataCopy);
  }

  //filter operator and value together put in the value field
  const [filter, stringOperator] = useMemo(
    () => propertyRowExp.value ?? [null, null, null],
    [propertyRowExp.value]
  );

  const validationError = activeErrorCheck
    ? propertyRowExp.validation_error
    : "";

  const stringOperatorDetails =
    stringOperator && operators
      ? getOperatorDetails(
          (stringOperator as string) ?? null,
          operators,
          OperatorType.STRING
        )
      : null;

  function OperatorValueFields(
    type: string | { display: string; id: string }[] | null,
    index: number
  ) {
    const val = propertyRowExp.value?.[index];
    const commonProps = {
      isReadOnly,
      validationError,
    };
    if (type && typeof type === "object") {
      return (
        <ValueSelectFields
          options={
            type?.map(({ display, id }) => ({
              label: display,
              value: id,
            })) ?? []
          }
          value={val as string}
          onChange={(val) => onChangeValue(val, index)}
          {...commonProps}
        />
      );
    } else if (type === STR_OPERATOR) {
      return (
        <>
          {filter && (
            <ValueSelectFields
              options={
                operators
                  ? Object.values(operators[OperatorType.STRING]).map(
                      ({ display, id }) => ({
                        label: display,
                        value: id,
                      })
                    )
                  : []
              }
              onChange={(val) => onChangeValue(val, index, true)}
              value={val as string}
              {...commonProps}
            />
          )}
        </>
      );
    } else if (type === null) {
      // type inferred from previous value in the array
      const isManyArg = isArgumentMany(stringOperatorDetails?.arguments);
      const v = isManyArg ? (val ? val : []) : [val];

      return (
        <>
          {stringOperator && (
            <DynamicListValueFields
              value={v as string[]}
              onChange={(value) =>
                onChangeValue(
                  (isManyArg ? (value as string[]) : (value[0] as string)) ??
                    "",
                  index
                )
              }
              argumentTypes={stringOperatorDetails?.arguments_types ?? null}
              helperText={stringOperatorDetails?.display_2}
              noOfArguments={stringOperatorDetails?.arguments}
              {...commonProps}
            />
          )}
        </>
      );
    }
    return (
      <AsyncWebActivityMetaField
        metaField={propertyRowExp.filter as WEBSITE_ACTIVITY_META}
        value={!isBlank(val) ? (val as string) : ""}
        onChange={(val) => onChangeValue(val, index, !val)}
        {...commonProps}
      />
    );
  }

  return (
    <>
      {propertyRowExp.filter &&
        propertyRowExp.operator &&
        operators &&
        operatorDetails?.arguments_types?.map((type, index) => {
          return (
            <Fragment key={index}>{OperatorValueFields(type, index)}</Fragment>
          );
        })}
    </>
  );
}

function PropertyGroupRowGroup({
  propertyRowGroup,
  onChangePropertyRowGroup,
  rowFilterDetails,
  isReadOnly,
}: {
  propertyRowGroup: DynamicListChildL2[];
  onChangePropertyRowGroup: (data: DynamicListChildL2[]) => void;
  rowFilterDetails: WebsiteActivityFiltersArg | null;
  isReadOnly?: boolean;
}) {
  const {
    operators: { data: operators },
  } = useSelector(selectDynamicList);
  const { activeErrorCheck } = useContext(DynamicListContext);

  //split the first row and the rest of the rows
  //first row has the main filter
  const firstPropertyRow = useMemo(
    () => propertyRowGroup[0],
    [propertyRowGroup]
  );

  const operatorDetails = useMemo(
    () =>
      rowFilterDetails && firstPropertyRow.operator
        ? getOperatorDetails(
            firstPropertyRow.operator,
            operators,
            rowFilterDetails.data_type
          )
        : null,
    [firstPropertyRow.operator, operators, rowFilterDetails]
  );

  function onChangeFirstPropertyRow(field: FIELD, value: string | ValueTypes) {
    const dataCopy = cloneDeep(propertyRowGroup);
    switch (field) {
      case FIELD.OPERATOR:
        if (dataCopy[0].operator !== value) {
          dataCopy[0].operator = value as string;
          dataCopy[0].value = [];
        }
        break;
      case FIELD.VALUE:
        dataCopy[0].value = value as ValueTypes;
    }
    dataCopy[0].validation_error = "";
    onChangePropertyRowGroup(dataCopy);
  }

  function onChangeRowFilter(
    action: ACTION,
    index?: number,
    data?: DynamicListChildL2 | PROPERTY_FILTER_ID
  ) {
    const dataCopy = cloneDeep(propertyRowGroup);

    switch (action) {
      case ACTION.ADD:
        const newRowGroupFilter = initWebsiteActivity({
          filter: data as PROPERTY_FILTER_ID,
        });
        dataCopy.push(newRowGroupFilter);
        break;
      case ACTION.CHANGE:
        dataCopy[index!] = data! as DynamicListChildL2;
        break;
      case ACTION.REMOVE:
        dataCopy.splice(index!, 1);
    }
    onChangePropertyRowGroup(dataCopy);
  }

  const validationError = activeErrorCheck
    ? firstPropertyRow.validation_error
    : "";

  return (
    <>
      {firstPropertyRow.filter && operators && (
        <ValueSelectFields
          options={
            operators && rowFilterDetails
              ? Object.values(operators[rowFilterDetails.data_type] ?? {})?.map(
                  (op) => ({
                    label: op.display,
                    value: op.id,
                  })
                )
              : []
          }
          value={firstPropertyRow.operator ?? ""}
          onChange={(op) => onChangeFirstPropertyRow(FIELD.OPERATOR, op)}
          validationError={validationError}
          isReadOnly={isReadOnly}
        />
      )}
      {firstPropertyRow.operator && operators && (
        <DynamicListValueFields
          value={firstPropertyRow.value ?? []}
          onChange={(value) => onChangeFirstPropertyRow(FIELD.VALUE, value)}
          argumentTypes={operatorDetails?.arguments_types ?? null}
          helperText={operatorDetails?.display_2}
          noOfArguments={operatorDetails?.arguments}
          validationError={validationError}
          isReadOnly={isReadOnly}
          filter={firstPropertyRow.filter ?? ""}
          operator={firstPropertyRow.operator}
        />
      )}

      <Stack
        direction="column"
        alignItems="flex-start"
        position="relative"
        spacing={1}
        w="100%"
      >
        <Divider
          orientation="vertical"
          position="absolute"
          borderColor="brandBlue.300"
          h="80%"
          ml="-30px"
          mt="16px"
          hidden={isReadOnly}
        />
        {/* remove first filter  */}
        {propertyRowGroup.slice(1).map((propertyRowGroupFilter, index) => {
          return (
            <PropertyGroupRowGroupFilter
              key={index}
              rowFilter={propertyRowGroupFilter}
              onChangeRowFilter={(data) =>
                onChangeRowFilter(ACTION.CHANGE, index + 1, data)
              }
              onRemoveRowFilter={() =>
                onChangeRowFilter(ACTION.REMOVE, index + 1)
              }
              rowFilterDetails={
                rowFilterDetails?.arguments?.find(
                  ({ id }) => id === propertyRowGroupFilter.filter
                ) ?? null
              }
              isReadOnly={isReadOnly}
            />
          );
        })}
      </Stack>
      {firstPropertyRow.operator && (
        <AdditionalParameterButton
          options={
            rowFilterDetails?.arguments?.filter(
              ({ id }) => !propertyRowGroup.find(({ filter }) => filter === id)
            ) ?? null
          }
          label="Add Additional URL parameters"
          onSelect={(val) => onChangeRowFilter(ACTION.ADD, undefined, val)}
          isReadOnly={isReadOnly}
        />
      )}
    </>
  );
}

//filters applied to each property row
function PropertyGroupRowGroupFilter({
  rowFilter,
  rowFilterDetails,
  onChangeRowFilter,
  onRemoveRowFilter,
  isReadOnly,
}: {
  rowFilter: DynamicListChildL2;
  onChangeRowFilter: (data: DynamicListChildL2) => void;
  onRemoveRowFilter: () => void;
  rowFilterDetails: WebsiteActivityFiltersArg | null;
  isReadOnly?: boolean;
}) {
  const {
    operators: { data: operators },
  } = useSelector(selectDynamicList);

  const { activeErrorCheck } = useContext(DynamicListContext);

  const operatorDetails = useMemo(
    () =>
      getOperatorDetails(
        rowFilter.operator,
        operators,
        rowFilterDetails?.data_type
      ),
    [rowFilter.operator, operators, rowFilterDetails?.data_type]
  );

  function onChangeFilter(field: FIELD, value: ValueTypes | string) {
    const dataCopy = cloneDeep(rowFilter);
    switch (field) {
      case FIELD.OPERATOR:
        if (dataCopy.operator !== value) {
          dataCopy.operator = value as string;
          dataCopy.value = [];
        }
        break;
      case FIELD.VALUE:
        dataCopy.value = value as ValueTypes;
    }
    dataCopy.validation_error = "";
    onChangeRowFilter(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? { spacing: 1, gridRowGap: 1 }
    : { spacing: 2, py: 1, gridRowGap: 2 };

  const validationError = activeErrorCheck ? rowFilter.validation_error : "";
  return (
    <HStack alignItems="flex-start" wrap="wrap" {...wrapperStyle} w="100%">
      {isReadOnly ? (
        <>
          <Icon as={LuFileCode} color="brand.blue" fontSize="14px" mt={1} />
          <Text>in which {rowFilterDetails?.display}</Text>
        </>
      ) : (
        <Text pt={2}>...in which {rowFilterDetails?.display}...</Text>
      )}
      <HStack alignItems="flex-start" maxW="65%" wrap="wrap" {...wrapperStyle}>
        {rowFilter.filter && rowFilterDetails && operators && (
          <ValueSelectFields
            options={Object.values(
              operators[rowFilterDetails.data_type] ?? {}
            )?.map((op) => ({
              label: op.display,
              value: op.id,
            }))}
            value={rowFilter.operator}
            onChange={(op) => onChangeFilter(FIELD.OPERATOR, op)}
            validationError={validationError}
            isReadOnly={isReadOnly}
          />
        )}

        {rowFilter.operator && operators && (
          <DynamicListValueFields
            value={rowFilter.value ?? []}
            onChange={(val) => onChangeFilter(FIELD.VALUE, val)}
            argumentTypes={operatorDetails?.arguments_types ?? null}
            helperText={operatorDetails?.display_2}
            noOfArguments={operatorDetails?.arguments}
            isReadOnly={isReadOnly}
            validationError={validationError}
          />
        )}
      </HStack>
      <RemoveRowCloseButton onClick={onRemoveRowFilter} hidden={isReadOnly} />
    </HStack>
  );
}

// additional components
function PropertyGroupHeader({
  connector,
  onConnectorChange,
  displayConnector = true,
  showText = true,
  isReadOnly,
}: {
  connector: CONNECTOR;
  onConnectorChange: (connector: CONNECTOR) => void;
  isReadOnly?: boolean;
  displayConnector?: boolean;
  showText?: boolean;
}) {
  const style = showText ? { spacing: 2, pt: 2 } : { spacing: 0 };

  return (
    <HStack {...style}>
      <Text fontWeight={700} hidden={!showText}>
        Additional properties
      </Text>
      {displayConnector && !isReadOnly && (
        <HStack justifyContent="flex-start">
          <Text borderLeft="1px solid" borderLeftColor="gray.400" pl="10px">
            Group properties by
          </Text>
          <DynamicListLogicGate
            operator={connector}
            handleChange={onConnectorChange}
            isReadOnly={isReadOnly}
            connectorProps={{ bg: "white" }}
          />
        </HStack>
      )}
    </HStack>
  );
}

function AdditionalParameterButton({
  label,
  options,
  onSelect,
  isReadOnly,
}: {
  label: string;
  options: WebsiteActivityFiltersArg[] | null;
  onSelect: (id: PROPERTY_FILTER_ID) => void;
  isReadOnly?: boolean;
}) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="link"
        color="brand.blue"
        fontWeight="400"
        name="additional-parameters"
        rightIcon={<RxChevronDown fontSize="12px" />}
        leftIcon={<RxPlus fontSize="12px" />}
        hidden={isReadOnly || !options?.length}
      >
        {label}
      </MenuButton>
      <MenuList>
        {options?.map((option) => {
          return (
            <MenuItem
              key={option.id}
              onClick={() => onSelect(option.id as PROPERTY_FILTER_ID)}
            >
              {option.display}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
