import { parseDomain, fromUrl, ParseResultType } from "parse-domain";

import {
  DOMAIN_VARIANTS,
  TRACKED_DOMAINS_STATUS,
  TRACK_ACCESS_STATES,
  TRACK_SUBDOMAIN_STATES,
  WILDCARD_SUBDOMAIN_REGEX,
} from "../constants/settings";

const PARSE_RESULT_LISTED = "LISTED";

export function subdomainWildcardTest(subdomain: string) {
  return WILDCARD_SUBDOMAIN_REGEX.test(subdomain);
}

export function isVariantDomain(variant: DOMAIN_VARIANTS) {
  return variant === DOMAIN_VARIANTS.DOMAIN;
}
export function isDomainActive(status: TRACKED_DOMAINS_STATUS) {
  return status === TRACKED_DOMAINS_STATUS.ACTIVE;
}

export function isTrackAllSubdomains(status: TRACK_SUBDOMAIN_STATES) {
  return status === TRACK_SUBDOMAIN_STATES.DEFAULT;
}

export function isAllowedSubdomain(access: TRACK_ACCESS_STATES) {
  return access === TRACK_ACCESS_STATES.ALLOWED;
}

export function toggleDomainControlStatus(status: TRACKED_DOMAINS_STATUS) {
  return isDomainActive(status)
    ? TRACKED_DOMAINS_STATUS.INACTIVE
    : TRACKED_DOMAINS_STATUS.ACTIVE;
}

export function toggleSubdomainStatus(status: TRACK_SUBDOMAIN_STATES) {
  return isTrackAllSubdomains(status)
    ? TRACK_SUBDOMAIN_STATES.CUSTOM
    : TRACK_SUBDOMAIN_STATES.DEFAULT;
}

export function getSortedSubdomainList(
  subdomainObj: { [key: string]: TRACK_ACCESS_STATES },
  currDomain: string
) {
  const subdomainList = Object.entries(subdomainObj);
  return subdomainList.sort(([keyA, valueA], [keyB, valueB]) => {
    if (keyA === currDomain) return -1;
    if (keyB === currDomain) return 1;
    return valueA === TRACK_ACCESS_STATES.ALLOWED ? -1 : 1;
  });
}

export function getBaseDomain(inputDomain: string) {
  // ab.cd.com will be retruned as cd.com
  // ab.cd.co.uk will be returned as cd.co.uk

  const parseResult = parseDomain(fromUrl(inputDomain));

  if (parseResult.type === ParseResultType.Listed) {
    const { domain, topLevelDomains } = parseResult;
    return (
      domain && topLevelDomains && `${domain}.${topLevelDomains.join(".")}`
    );
  }

  return "";
}

export function getSubdomain(inputDomain: string, currDomain: string): string {
  const parseResult = parseDomain(fromUrl(inputDomain));

  // user enters a complete url including domain and tld, - abc.def.co.in
  if (parseResult.type === PARSE_RESULT_LISTED) {
    const { subDomains, domain, topLevelDomains } = parseResult;
    const baseDomain = `${domain}.${topLevelDomains.join(".")}`;

    if (currDomain === baseDomain && subDomains.length) {
      return subDomains.join(".");
    } else {
      return inputDomain;
    }
  }
  // user enters only a subdomain, abc
  else {
    const url = `${inputDomain}.${currDomain}`;
    const parseResult2 = parseDomain(fromUrl(url));

    if (parseResult2.type === PARSE_RESULT_LISTED) {
      const { subDomains } = parseResult2;
      return subDomains.length ? inputDomain : "";
    } else {
      return "";
    }
  }
}
