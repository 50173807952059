// !!info: Most of the enums are uncertain or incomplete.

import {
  INCLUSION_LIST_TYPES,
  MEMBERSHIP_ASSET_TYPES,
  OperatorType,
} from "../types/campaign";
import { FaDesktop, FaEnvelope, FaRegIdCard } from "react-icons/fa";
import { IconType } from "react-icons";
import {
  OperatorDetailsV2,
  OperatorListV2,
  OperatorTypeV2,
  NodeGroup,
  NodeGroupWithComparison,
} from "../types/dynamicList";
import { TIME_FRAME_TYPE, TIME_FRAME_UNIT } from "./trigger";

export enum COMPARISON_OPERATORS {
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
  GTE = "GTE",
  LTE = "LTE",
  GT = "GT",
  LT = "LT",
  IN = "IN",
  NIN = "NIN",
  STARTS_WITH = "STARTS_WITH",
  NOT_STARTS_WITH = "NOT_STARTS_WITH",
  ENDS_WITH = "ENDS_WITH",
  NOT_ENDS_WITH = "NOT_ENDS_WITH",
  CONTAINS = "CONTAINS",
  NOT_CONTAINS = "NOT_CONTAINS",
  IS_NOT_BLANK = "IS_NOT_BLANK",
  IS_BLANK = "IS_BLANK",
  BETWEEN_INCLUSIVE = "BETWEEN_INCLUSIVE",
  NOT_BETWEEN_INCLUSIVE = "NOT_BETWEEN_INCLUSIVE",
  NOW = "NOW",

  CHANGES_TO = "CHANGES_TO",
  CHANGES_FROM_TO = "CHANGES_FROM_TO",
}

//not implemented in the BE yet
export enum FLINK_CEP_OPERARTORS {
  // Ordering
  NEXT = "next",
  NOT_NEXT = "notNext",
  FOLLOWED_BY = "FOLLOWED_BY",
  NOT_FOLLOWED_BY = "notfollowedBy",
  FOLLOWED_BY_ANY = "followedByAny",
  // Repetition
  TIMES = "times",
  ONE_OR_MORE = "oneOrMore",
  TIMES_OR_MORE = "timesOrMore",
  UNTIL = "until",
  // Pattern behaviours
  OPTIONAL = "optional",
  GREEDY = "greedy",
  CONSECUTIVE = "consecutive",
  ALLOW_COMBINATIONS = "allowCombinations",
  // Specify time window
  WITHIN = "WITHIN",
  // match skip strategy
  NO_SKIP = "noSkip",
  SKIP_TO_NEXT = "skipToNext",
  SKIP_PAST_LAST_EVENT = "skipPastLastEvent",
  SKIP_TO_FIRST = "skipToFirst",
  SKIP_TO_LAST = "skipToLast",
}

export enum MEMBERSHIP_OPERATOR {
  INCLUSION = "INCLUSION",
  EXCLUSION = "EXCLUSION",
}

export enum GROUP_OPERATORS {
  HAVING = "HAVING",
  NOT_HAVING = "NOT_HAVING",
}

export enum LOGICAL_OPERATORS {
  FIRST = "FIRST_NODE",
  AND = "AND",
  OR = "OR",
}

export enum TIME_FRAME_RELATIVE_OPERATOR {
  BETWEEN = "RELATIVE_BETWEEN", //ONLY IN FE,
  PAST = "PAST",
}

export enum DL_TARGET_VALUE_TYPES {
  INTEGER = "INTEGER",
  DOUBLE = "DOUBLE",
  BOOLEAN = "BOOLEAN",
  STRING = "STRING",
  DATE = "DATE",
  JSON = "JSON",
}

export enum NODE_GROUP_TYPES {
  INDIVIDUAL = "INDIVIDUAL_EVALUATION",
  ORGANISATION = "ORGANIZATION_LEVEL_EVALUATION",
  ACCOUNT = "ACCOUNT_LEVEL_EVALUATION",
}

export enum TRIGGER_DL_NODE_TYPES {
  PERSON = "PERSON_PROPERTY",
  COMPARISON = "COMPARISON",
  LIST_MEMBERSHIP = "LIST_MEMBERSHIP",
  SEQUENCE_PATTERN = "SEQUENCE_PATTERN",
}

export enum EVENT_CATEGORY {
  MARKETING_ACTIVITY = "MARKETING_ACTIVITY_EVENT",
  PRODUCT_ACTIVITY = "PRODUCT_ACTIVITY_EVENT",
}

export enum SEQUENCE_OPERATION {
  ADD = "ADD",
  REPLACE = "REPLACE",
  REMOVE = "REMOVE",
}

export enum ASSET_LIST_TYPE {
  STATIC_LIST = "STATIC_LIST",
  DYNAMIC_LIST = "DYNAMIC_LIST",
}
export const NEW_EXIT_GROUP: Readonly<NodeGroupWithComparison> = {
  nodeGroupType: NODE_GROUP_TYPES.INDIVIDUAL,
  nodeGroupId: "",
  logicalOperator: LOGICAL_OPERATORS.FIRST,
  nodes: [],
};

export const NEW_TRIGGER_GROUP: Readonly<NodeGroup> = {
  nodeGroupType: NODE_GROUP_TYPES.INDIVIDUAL,
  nodeGroupId: "",
  logicalOperator: LOGICAL_OPERATORS.FIRST,
  nodes: [],
};

export const TRIGGER_CRITERIA_HEADER: {
  [key: string]: { label: string; icon?: IconType };
} = {
  MARKETING_ACTIVITY_EVENT: {
    label: "Marketing Event Trigger",
    icon: FaEnvelope,
  },
  PRODUCT_ACTIVITY_EVENT: {
    label: "Product Event Trigger",
    icon: FaDesktop,
  },
  PERSON_PROPERTY: {
    label: "Contact Update Trigger",
    icon: FaRegIdCard,
  },
};

export const TRIGGER_SEQUENCE_HEADER: {
  [key: string]: { label: string; icon?: IconType };
} = {
  MARKETING_ACTIVITY_EVENT: {
    label: "Marketing Trigger Sequence",
    icon: FaEnvelope,
  },
  PRODUCT_ACTIVITY_EVENT: {
    label: "Product Trigger Sequence",
    icon: FaDesktop,
  },
};

export const ICON_MAPPINGS: Record<EVENT_CATEGORY, IconType> = {
  [EVENT_CATEGORY.PRODUCT_ACTIVITY]: FaDesktop,
  [EVENT_CATEGORY.MARKETING_ACTIVITY]: FaEnvelope,
};

export enum OPERATOR_ARG_COUNT {
  ZERO = "0",
  ONE = "1",
  TWO = "2",
  MANY = "many",
}

export const COMPARISON_OPERATORS_ARGUMENT_COUNT = {
  [COMPARISON_OPERATORS.EQUALS]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.NOT_EQUALS]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.GTE]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.LTE]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.GT]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.LT]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.IN]: OPERATOR_ARG_COUNT.MANY,
  [COMPARISON_OPERATORS.NIN]: OPERATOR_ARG_COUNT.MANY,
  [COMPARISON_OPERATORS.STARTS_WITH]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.NOT_STARTS_WITH]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.ENDS_WITH]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.NOT_ENDS_WITH]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.CONTAINS]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.NOT_CONTAINS]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.IS_NOT_BLANK]: OPERATOR_ARG_COUNT.ZERO,
  [COMPARISON_OPERATORS.IS_BLANK]: OPERATOR_ARG_COUNT.ZERO,
  [COMPARISON_OPERATORS.BETWEEN_INCLUSIVE]: OPERATOR_ARG_COUNT.TWO,
  [COMPARISON_OPERATORS.NOT_BETWEEN_INCLUSIVE]: OPERATOR_ARG_COUNT.TWO,
  [COMPARISON_OPERATORS.NOW]: OPERATOR_ARG_COUNT.ONE,
  [COMPARISON_OPERATORS.CHANGES_TO]: OPERATOR_ARG_COUNT.TWO,
  [COMPARISON_OPERATORS.CHANGES_FROM_TO]: OPERATOR_ARG_COUNT.MANY,
};

export const TIME_FRAME_RELATIVE_DATE_OPERATORS: {
  [key in OperatorTypeV2]?: OperatorDetailsV2;
} = {
  [TIME_FRAME_RELATIVE_OPERATOR.BETWEEN]: {
    id: TIME_FRAME_RELATIVE_OPERATOR.BETWEEN, // ONLY IN FE
    display: "in relative timeframe between (inclusive)",
    display_2: "",
    arguments: "3",
    arguments_types: [
      "int",
      "int",
      [
        { display: "day(s)", id: "day(s)" },
        { display: "week(s)", id: "week(s)" },
        { display: "month(s)", id: "month(s)" },
      ],
    ],
    timeFrameType: TIME_FRAME_TYPE.RELATIVE,
    valueType: DL_TARGET_VALUE_TYPES.DATE,
  },
  [TIME_FRAME_RELATIVE_OPERATOR.PAST]: {
    id: TIME_FRAME_RELATIVE_OPERATOR.PAST,
    display: "in past",
    display_2: ["1 day = today", "1 week = this week", "1 month = this month"],
    arguments: "2",
    arguments_types: [
      "int",
      [
        { display: "day(s)", id: "day(s)" },
        { display: "week(s)", id: "week(s)" },
        { display: "month(s)", id: "month(s)" },
      ],
    ],
    timeFrameType: TIME_FRAME_TYPE.RELATIVE,
    valueType: DL_TARGET_VALUE_TYPES.DATE,
  },
};

export const TARGET_VALUE_TYPE_MAP = {
  [OperatorType.STRING]: DL_TARGET_VALUE_TYPES.STRING,
  [OperatorType.INTEGER]: DL_TARGET_VALUE_TYPES.INTEGER,
  [OperatorType.FLOAT]: DL_TARGET_VALUE_TYPES.DOUBLE,
  [OperatorType.BOOLEAN]: DL_TARGET_VALUE_TYPES.BOOLEAN,
  [OperatorType.DATE]: DL_TARGET_VALUE_TYPES.DATE,
  [OperatorType.JSON]: DL_TARGET_VALUE_TYPES.JSON,
};

export const TRIGGER_CAMPAIGN_DL_OPERATORS: OperatorListV2 = {
  //NUMBER TYPES INT AND FLOAT
  [OperatorType.INTEGER]: {
    [COMPARISON_OPERATORS.GT]: {
      id: COMPARISON_OPERATORS.GT,
      display: "is greater than",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.GT,
      arguments_types: ["int"],
      valueType: DL_TARGET_VALUE_TYPES.INTEGER,
    },
    [COMPARISON_OPERATORS.NIN]: {
      id: COMPARISON_OPERATORS.NIN,
      display: "is none of",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NIN,
      arguments_types: ["int"],
      valueType: DL_TARGET_VALUE_TYPES.INTEGER,
    },
    [COMPARISON_OPERATORS.EQUALS]: {
      id: COMPARISON_OPERATORS.EQUALS,
      display: "exactly",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.EQUALS,
      arguments_types: ["int"],
      valueType: DL_TARGET_VALUE_TYPES.INTEGER,
    },
    [COMPARISON_OPERATORS.NOT_EQUALS]: {
      id: COMPARISON_OPERATORS.NOT_EQUALS,
      display: "any number except",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NOT_EQUALS,
      arguments_types: ["int"],
      valueType: DL_TARGET_VALUE_TYPES.INTEGER,
    },
    [COMPARISON_OPERATORS.IN]: {
      id: COMPARISON_OPERATORS.IN,
      display: "is any of",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.IN,
      arguments_types: ["int"],
      valueType: DL_TARGET_VALUE_TYPES.INTEGER,
    },
    [COMPARISON_OPERATORS.LTE]: {
      id: COMPARISON_OPERATORS.LTE,
      display: "is less than or equal to",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.LTE,
      arguments_types: ["int"],
      valueType: DL_TARGET_VALUE_TYPES.INTEGER,
    },
    [COMPARISON_OPERATORS.LT]: {
      id: COMPARISON_OPERATORS.LT,
      display: "is less than",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.LT,
      arguments_types: ["int"],
      valueType: DL_TARGET_VALUE_TYPES.INTEGER,
    },
    [COMPARISON_OPERATORS.GTE]: {
      id: COMPARISON_OPERATORS.GTE,
      display: "is greater than or equal to",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.GTE,
      arguments_types: ["int"],
      valueType: DL_TARGET_VALUE_TYPES.INTEGER,
    },
    [COMPARISON_OPERATORS.BETWEEN_INCLUSIVE]: {
      id: COMPARISON_OPERATORS.BETWEEN_INCLUSIVE,
      display: "is between (inclusive)",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.BETWEEN_INCLUSIVE,
      arguments_types: ["int", "int"],
      valueType: DL_TARGET_VALUE_TYPES.INTEGER,
    },

    [COMPARISON_OPERATORS.NOT_BETWEEN_INCLUSIVE]: {
      id: COMPARISON_OPERATORS.NOT_BETWEEN_INCLUSIVE,
      display: "is not between",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NOT_BETWEEN_INCLUSIVE,
      arguments_types: ["int", "int"],
      valueType: DL_TARGET_VALUE_TYPES.INTEGER,
    },
  },

  [OperatorType.FLOAT]: {
    [COMPARISON_OPERATORS.GT]: {
      id: COMPARISON_OPERATORS.GT,
      display: "is greater than",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.GT,
      arguments_types: ["float"],
      valueType: DL_TARGET_VALUE_TYPES.DOUBLE,
    },
    [COMPARISON_OPERATORS.NIN]: {
      id: COMPARISON_OPERATORS.NIN,
      display: "is none of",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NIN,
      arguments_types: ["float"],
      valueType: DL_TARGET_VALUE_TYPES.DOUBLE,
    },
    [COMPARISON_OPERATORS.BETWEEN_INCLUSIVE]: {
      id: COMPARISON_OPERATORS.BETWEEN_INCLUSIVE,
      display: "is between (inclusive)",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.BETWEEN_INCLUSIVE,
      arguments_types: ["float", "float"],
      valueType: DL_TARGET_VALUE_TYPES.DOUBLE,
    },
    [COMPARISON_OPERATORS.NOT_BETWEEN_INCLUSIVE]: {
      id: COMPARISON_OPERATORS.NOT_BETWEEN_INCLUSIVE,
      display: "is not between",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NOT_BETWEEN_INCLUSIVE,
      arguments_types: ["float", "float"],
      valueType: DL_TARGET_VALUE_TYPES.DOUBLE,
    },
    [COMPARISON_OPERATORS.IN]: {
      id: COMPARISON_OPERATORS.IN,
      display: "is any of",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.IN,
      arguments_types: ["float"],
      valueType: DL_TARGET_VALUE_TYPES.DOUBLE,
    },
    [COMPARISON_OPERATORS.LTE]: {
      id: COMPARISON_OPERATORS.LTE,
      display: "is less than or equal to",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.LTE,
      arguments_types: ["float"],
      valueType: DL_TARGET_VALUE_TYPES.DOUBLE,
    },
    [COMPARISON_OPERATORS.LT]: {
      id: COMPARISON_OPERATORS.LT,
      display: "is less than",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.LT,
      arguments_types: ["float"],
      valueType: DL_TARGET_VALUE_TYPES.DOUBLE,
    },
    [COMPARISON_OPERATORS.GTE]: {
      id: COMPARISON_OPERATORS.GTE,
      display: "is greater than or equal to",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.GTE,
      arguments_types: ["float"],
      valueType: DL_TARGET_VALUE_TYPES.DOUBLE,
    },
  },

  //STRING OPERATORS
  [OperatorType.STRING]: {
    [COMPARISON_OPERATORS.IS_NOT_BLANK]: {
      id: COMPARISON_OPERATORS.IS_NOT_BLANK,
      display: "is not blank",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.IS_NOT_BLANK,
      arguments_types: [],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
    [COMPARISON_OPERATORS.NOT_STARTS_WITH]: {
      id: COMPARISON_OPERATORS.NOT_STARTS_WITH,
      display: "does not begin with",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NOT_STARTS_WITH,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
    [COMPARISON_OPERATORS.NIN]: {
      id: COMPARISON_OPERATORS.NIN,
      display: "is none of",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NIN,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
    [COMPARISON_OPERATORS.ENDS_WITH]: {
      id: COMPARISON_OPERATORS.ENDS_WITH,
      display: "ends with",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.ENDS_WITH,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
    [COMPARISON_OPERATORS.CONTAINS]: {
      id: COMPARISON_OPERATORS.CONTAINS,
      display: "contains",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.CONTAINS,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
    [COMPARISON_OPERATORS.IS_BLANK]: {
      id: COMPARISON_OPERATORS.IS_BLANK,
      display: "is blank",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.IS_BLANK,
      valueType: DL_TARGET_VALUE_TYPES.STRING,
      arguments_types: [],
    },
    [COMPARISON_OPERATORS.STARTS_WITH]: {
      id: COMPARISON_OPERATORS.STARTS_WITH,
      display: "begins with",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.STARTS_WITH,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
    [COMPARISON_OPERATORS.NOT_EQUALS]: {
      id: COMPARISON_OPERATORS.NOT_EQUALS,
      display: "is not",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NOT_EQUALS,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
    [COMPARISON_OPERATORS.IN]: {
      id: COMPARISON_OPERATORS.IN,
      display: "is any of",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.IN,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
    [COMPARISON_OPERATORS.EQUALS]: {
      id: COMPARISON_OPERATORS.EQUALS,
      display: "is",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NOT_EQUALS,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
    [COMPARISON_OPERATORS.NOT_ENDS_WITH]: {
      id: COMPARISON_OPERATORS.NOT_ENDS_WITH,
      display: "does not end with",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NOT_ENDS_WITH,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
    [COMPARISON_OPERATORS.NOT_CONTAINS]: {
      id: COMPARISON_OPERATORS.NOT_CONTAINS,
      display: "does not contain",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NOT_CONTAINS,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
  },

  // ASSET OPERATORS
  [OperatorType.ASSET_SPECIFIC]: {
    [COMPARISON_OPERATORS.NOT_CONTAINS]: {
      id: COMPARISON_OPERATORS.NOT_CONTAINS,
      display: "Name does not contains",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NOT_CONTAINS,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
    [COMPARISON_OPERATORS.CONTAINS]: {
      id: COMPARISON_OPERATORS.CONTAINS,
      display: "Name contains",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.CONTAINS,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
    [COMPARISON_OPERATORS.EQUALS]: {
      id: COMPARISON_OPERATORS.EQUALS,
      display: "is",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.EQUALS,
      arguments_types: ["string"],
      valueType: DL_TARGET_VALUE_TYPES.STRING,
    },
  },

  //LIST MEMBERSHIP OPERATORS
  [OperatorType.LIST_SPECIFIC]: {
    [MEMBERSHIP_OPERATOR.INCLUSION]: {
      id: MEMBERSHIP_OPERATOR.INCLUSION,
      display: "in",
      display_2: "",
      arguments: "1",
      arguments_types: ["string"],
    },
    [MEMBERSHIP_OPERATOR.EXCLUSION]: {
      id: MEMBERSHIP_OPERATOR.EXCLUSION,
      display: "not in",
      display_2: "",
      arguments: "1",
      arguments_types: ["string"],
    },
  },

  //ORANISATION - GROUP OPERATORS
  [OperatorType.ORG_AGGREGATE_SPECIFIC]: {
    [GROUP_OPERATORS.HAVING]: {
      id: GROUP_OPERATORS.HAVING,
      display: "having",
      display_2: "",
      arguments: "0",
      arguments_types: [],
    },
    [GROUP_OPERATORS.NOT_HAVING]: {
      id: GROUP_OPERATORS.NOT_HAVING,
      display: "not having",
      display_2: "",
      arguments: "0",
      arguments_types: [],
    },
  },

  //ACCOUNT - GROUP OPERATORS
  [OperatorType.ACCOUNT_AGGREGATE_SPECIFIC]: {
    [GROUP_OPERATORS.HAVING]: {
      id: GROUP_OPERATORS.HAVING,
      display: "having",
      display_2: "",
      arguments: "0",
      arguments_types: [],
    },
    [GROUP_OPERATORS.NOT_HAVING]: {
      id: GROUP_OPERATORS.NOT_HAVING,
      display: "not having",
      display_2: "",
      arguments: "0",
      arguments_types: [],
    },
  },
  [OperatorType.JSON]: {},
  [OperatorType.FORM_FIELD_SPECIFIC]: {},
  [OperatorType.FORM_SPECIFIC]: {},
  [OperatorType.WT_UTM_PARAM_SPECIFIC]: {},
  [OperatorType.WT_CUSTOM_PARAM_SPECIFIC]: {},
  [OperatorType.WT_PROTOCOL]: {},

  //TODO: TIME FRAME OPERATORS
  [OperatorType.DATE]: {
    [COMPARISON_OPERATORS.NOT_EQUALS]: {
      id: COMPARISON_OPERATORS.NOT_EQUALS,
      display: "not on",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NOT_EQUALS,
      arguments_types: ["date"],
      valueType: DL_TARGET_VALUE_TYPES.DATE,
      timeFrameType: TIME_FRAME_TYPE.ABSOLUTE,
    },
    [COMPARISON_OPERATORS.BETWEEN_INCLUSIVE]: {
      id: COMPARISON_OPERATORS.BETWEEN_INCLUSIVE,
      display: "is between (inclusive)",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.BETWEEN_INCLUSIVE,
      arguments_types: ["date", "date"],
      valueType: DL_TARGET_VALUE_TYPES.DATE,
      timeFrameType: TIME_FRAME_TYPE.ABSOLUTE,
    },
    [COMPARISON_OPERATORS.LTE]: {
      id: COMPARISON_OPERATORS.LTE,
      display: "on or before",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.LTE,
      arguments_types: ["date"],
      valueType: DL_TARGET_VALUE_TYPES.DATE,
      timeFrameType: TIME_FRAME_TYPE.ABSOLUTE,
    },

    [COMPARISON_OPERATORS.LT]: {
      id: COMPARISON_OPERATORS.LT,
      display: "before",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.LT,
      arguments_types: ["date"],
      valueType: DL_TARGET_VALUE_TYPES.DATE,
      timeFrameType: TIME_FRAME_TYPE.ABSOLUTE,
    },
    [COMPARISON_OPERATORS.GTE]: {
      id: COMPARISON_OPERATORS.GTE,
      display: "on or after",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.GTE,
      arguments_types: ["date"],
      valueType: DL_TARGET_VALUE_TYPES.DATE,
      timeFrameType: TIME_FRAME_TYPE.ABSOLUTE,
    },
    [COMPARISON_OPERATORS.GT]: {
      id: COMPARISON_OPERATORS.GT,
      display: "after",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.GT,
      arguments_types: ["date"],
      valueType: DL_TARGET_VALUE_TYPES.DATE,
      timeFrameType: TIME_FRAME_TYPE.ABSOLUTE,
    },
    [COMPARISON_OPERATORS.NOT_BETWEEN_INCLUSIVE]: {
      id: COMPARISON_OPERATORS.NOT_BETWEEN_INCLUSIVE,
      display: "is not between",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.NOT_BETWEEN_INCLUSIVE,
      arguments_types: ["date", "date"],
      valueType: DL_TARGET_VALUE_TYPES.DATE,
      timeFrameType: TIME_FRAME_TYPE.ABSOLUTE,
    },
    [COMPARISON_OPERATORS.EQUALS]: {
      id: COMPARISON_OPERATORS.EQUALS,
      display: "on",
      display_2: "",
      arguments: COMPARISON_OPERATORS_ARGUMENT_COUNT.EQUALS,
      arguments_types: ["date"],
      valueType: DL_TARGET_VALUE_TYPES.DATE,
      timeFrameType: TIME_FRAME_TYPE.ABSOLUTE,
    },
  },
  // TODO: Handle this later with component and assign the arguments for this.
  [OperatorType.BOOLEAN]: {
    [COMPARISON_OPERATORS.EQUALS]: {
      id: COMPARISON_OPERATORS.EQUALS, //EQUALS FALSE
      display: "is false",
      display_2: "",
      arguments: "0",
      arguments_types: [],
      valueType: DL_TARGET_VALUE_TYPES.BOOLEAN,
    },
    [COMPARISON_OPERATORS.CONTAINS]: {
      id: COMPARISON_OPERATORS.CONTAINS, //CONTAINS TRUE;
      display: "is true",
      display_2: "",
      arguments: "0",
      arguments_types: [],
      valueType: DL_TARGET_VALUE_TYPES.BOOLEAN,
    },
  },
  [OperatorType.CONTACT_UPDATE_SPECIFIC]: {
    [COMPARISON_OPERATORS.CHANGES_TO]: {
      id: COMPARISON_OPERATORS.CHANGES_TO,
      display: "Is updated",
      display_2: "",
      arguments: OPERATOR_ARG_COUNT.TWO, // arguments is like a composite one
      arguments_types: [],
      valueType: DL_TARGET_VALUE_TYPES.STRING, // default one. will be overwritten in the component
    },
    [COMPARISON_OPERATORS.CHANGES_FROM_TO]: {
      id: COMPARISON_OPERATORS.CHANGES_FROM_TO,
      display: "Changed from",
      display_2: "",
      arguments: OPERATOR_ARG_COUNT.MANY,
      arguments_types: [],
      valueType: DL_TARGET_VALUE_TYPES.STRING, // default one. will be overwritten in the component
    },
  },
};

export const LIST_TYPE_FROM_MEMBERSHIP_TYPE = {
  [MEMBERSHIP_ASSET_TYPES.DYNAMIC]: ASSET_LIST_TYPE.DYNAMIC_LIST,
  [MEMBERSHIP_ASSET_TYPES.STATIC]: ASSET_LIST_TYPE.STATIC_LIST,
  [INCLUSION_LIST_TYPES.DYNAMIC]: ASSET_LIST_TYPE.DYNAMIC_LIST,
  [INCLUSION_LIST_TYPES.STATIC]: ASSET_LIST_TYPE.STATIC_LIST,
};

export const MAP_TIME_UNIT: { [k: string]: TIME_FRAME_UNIT } = {
  "day(s)": TIME_FRAME_UNIT.DAY,
  "week(s)": TIME_FRAME_UNIT.WEEK,
  "month(s)": TIME_FRAME_UNIT.MONTH,
};

export const TIME_UNIT_LABEL: { [key in TIME_FRAME_UNIT]: string } = {
  [TIME_FRAME_UNIT.DAY]: "day(s)",
  [TIME_FRAME_UNIT.WEEK]: "week(s)",
  [TIME_FRAME_UNIT.MONTH]: "month(s)",
  [TIME_FRAME_UNIT.YEAR]: "year(s)",
  [TIME_FRAME_UNIT.HOUR]: "hour(s)",
  [TIME_FRAME_UNIT.EPOCH]: "epoch",
  [TIME_FRAME_UNIT.SECOND]: "second(s)",
};

export const TRIGGER_DL_TIME_FRAME_OPERATORS = {
  ...TRIGGER_CAMPAIGN_DL_OPERATORS[OperatorType.DATE],
  ...TIME_FRAME_RELATIVE_DATE_OPERATORS,
};
