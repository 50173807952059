import { Input, InputProps, Textarea, TextareaProps } from "@chakra-ui/react";
import { CODEMIRROR_SIZE } from "../../common/constants/common";
import { CodemirrorType } from "../../common/types/common";
import CodemirrorInput from "./CodemirrorInput";

export default function OptionalCodemirrorInput({
  isInvalid = false,
  reinitCodemirror,
  onChange,
  type,
  isCodemirror = true,
  inputProps,
  isDisabled,
  ...props
}: {
  isInvalid?: boolean;
  reinitCodemirror?: boolean;
  onChange: (code: string) => void;
  type: CODEMIRROR_SIZE;
  isCodemirror?: boolean;
  inputProps?: InputProps | TextareaProps;
  isDisabled?: boolean;
} & CodemirrorType) {
  function renderNormalInput() {
    switch (type) {
      case CODEMIRROR_SIZE.SINGLE_LINE:
        return (
          <Input
            value={props.value || ""}
            onChange={(e) => onChange(e.target.value)}
            fontSize="14px"
            {...(inputProps as InputProps)}
            isDisabled={isDisabled}
          />
        );
      default:
        return (
          <Textarea
            value={props.value || ""}
            onChange={(e) => onChange(e.target.value)}
            fontSize="14px"
            {...(inputProps as TextareaProps)}
            isDisabled={isDisabled}
          />
        );
    }
  }

  if (isCodemirror)
    return (
      <CodemirrorInput
        type={type}
        reinitialize={reinitCodemirror}
        onChange={onChange}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        {...props}
      />
    );
  return renderNormalInput();
}
