import { Box, HStack, VStack, Text, Icon } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import {
  DynamicListChild,
  DynamicListChildL1,
  OperatorType,
} from "../../common/types/campaign";
import { FilterGroupBox } from "./FilterGroupBox";
import { ValueSelectFields } from "./DynamicListValueFields";
import { useContext, useMemo } from "react";
import { SingleValue } from "react-select";
import { getOperatorDetails } from "../../common/helper/commonHelper";
import { AssetField } from "../../common/types/connection";
import { DynamicListContext } from "./DynamicList";
import { FaRegListAlt } from "react-icons/fa";
import { selectDynamicList } from "./dynamicListSlice";
import ListSelectionDropdownWithPreview, {
  LIST_SELECTION_DROPDOWN_VARIANT,
} from "../ListSelectionDropdown";
import { StackProps } from "@chakra-ui/react";

export default function ListMembershipFilterGroup({
  data,
  label,
  id,
  onChange,
  onRemove,
  isReadOnly,
}: {
  data: DynamicListChild;
  label: string;
  id: string;
  onChange: (data: any) => void;
  onRemove: () => void;
  isReadOnly?: boolean;
}) {
  function onChildChange(child: DynamicListChildL1) {
    const dataCopy = cloneDeep(data);
    dataCopy.children[0] = child;
    onChange(dataCopy);
  }

  function onChangeFilterGroupName(value: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.name = value;
    onChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        py: "0",
        px: "0",
        spacing: "1",
      }
    : {
        py: "3",
        px: "2",
        spacing: "4",
        bg: "grayV2.200",
      };

  return (
    <FilterGroupBox
      id={id}
      label={label}
      onRemove={onRemove}
      groupName={data.name}
      onGroupNameChange={onChangeFilterGroupName}
      isReadOnly={isReadOnly}
    >
      <VStack alignItems="flex-start" {...wrapperStyle} w="100%">
        {data.children && (
          <HStack width="100%" wrap="wrap" gridGap="2">
            <ActivitySelector
              data={data.children[0]}
              onChange={onChildChange}
              isReadOnly={isReadOnly}
            />
          </HStack>
        )}
      </VStack>
    </FilterGroupBox>
  );
}

function ActivitySelector({
  data,
  onChange,
  isReadOnly,
}: {
  data: DynamicListChildL1;
  onChange: (data: DynamicListChildL1) => void;
  isReadOnly?: boolean;
}) {
  const {
    operators: { data: operators },
  } = useSelector(selectDynamicList);

  const { activeErrorCheck } = useContext(DynamicListContext);

  const operatorDetails = useMemo(
    () =>
      getOperatorDetails(data.operator, operators, OperatorType.LIST_SPECIFIC),
    [data.operator, operators]
  );

  function onOperatorChange(operator: string) {
    const dataCopy = cloneDeep(data);
    if (dataCopy.operator !== operator) {
      dataCopy.value = [""];
    }
    dataCopy.operator = operator;
    if (operator) {
      dataCopy.validation_error = "";
    }
    onChange(dataCopy);
  }

  function onValueChange(val: SingleValue<AssetField>) {
    const dataCopy = cloneDeep(data);
    if (val && val.id) {
      dataCopy.validation_error = "";
      dataCopy.value = [val.id];
      dataCopy.filter = val.type;
    } else {
      dataCopy.validation_error = "Invalid values";
      dataCopy.value = [];
      dataCopy.filter = null;
    }
    onChange(dataCopy);
  }

  const wrapperStyle: StackProps = isReadOnly
    ? {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        spacing: "1",
      }
    : {
        spacing: "3",
      };

  return (
    <HStack alignItems="center" {...wrapperStyle} w="90%">
      {isReadOnly ? (
        <>
          <Icon as={FaRegListAlt} color="brand.blue" />
          <Text>Membership</Text>
        </>
      ) : (
        <Text fontSize="sm">...membership property of...</Text>
      )}
      {operators && (
        <Box id="list-membership-activity-selector">
          <ValueSelectFields
            options={Object.values(operators[OperatorType.LIST_SPECIFIC]).map(
              (op) => ({
                label: op.display,
                value: op.id,
              })
            )}
            value={(data.operator as string) || ""}
            onChange={(value: string) => onOperatorChange(value)}
            validationError={activeErrorCheck ? data.validation_error : ""}
            isReadOnly={isReadOnly}
          />
        </Box>
      )}
      {data.operator && operators && operatorDetails && (
        <ListSelectionDropdownWithPreview
          variant={LIST_SELECTION_DROPDOWN_VARIANT.CAMPAIGN}
          value={(data.value?.[0] as string) ?? null}
          onChange={onValueChange}
          isInvalid={activeErrorCheck && !!data.validation_error}
          isReadOnly={isReadOnly}
        />
      )}
    </HStack>
  );
}
