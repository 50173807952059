import { Skeleton, Text, Tooltip, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function LabelValuePair({
  label,
  value,
  isLoadingData,
  maxWidth,
}: {
  label: string;
  value: string | ReactNode;
  isLoadingData?: boolean;
  maxWidth?: string;
}) {
  return (
    <VStack alignItems="flex-start" fontSize="14px" spacing={1.5}>
      <Tooltip label={maxWidth ? label : ""} fontSize="xs">
        <Text
          maxWidth={maxWidth}
          fontWeight={600}
          color="text.50"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {label}
        </Text>
      </Tooltip>
      {isLoadingData ? (
        <Skeleton h="20px" w="100px" isLoaded={!isLoadingData} />
      ) : (
        <Tooltip label={maxWidth ? value : ""} fontSize="xs">
          <Text maxWidth={maxWidth} wordBreak="break-all">
            {value ?? "-"}
          </Text>
        </Tooltip>
      )}
    </VStack>
  );
}
