import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAccountDetailsApi,
  getAccountMembersApi,
  listAccountsApi,
} from "../../../common/api/campaign/account";
import { getMappingDestinationApi } from "../../../common/api/integrations/connection";
import {
  INITIAL_PAGINATION_15_ITEMS,
  LOADING_STATES,
} from "../../../common/constants/common";
import {
  createAsyncThunkWrapper,
  initializeLoadingData,
} from "../../../common/helper/commonHelper";
import {
  Account,
  AccountDetails,
  AccountMember,
} from "../../../common/types/account";
import {
  LoadingWithData,
  PaginationType,
  SearchAssetsType,
} from "../../../common/types/common";
import {
  MappingDestination,
  MAPPING_FIELD,
  PersonDestinationFields,
} from "../../../common/types/person";
import { RootState } from "../../../store";

interface AccountState {
  accountList: PaginationType<Account>;
  accountDetails: LoadingWithData<AccountDetails[]>;
  accountMembersList: PaginationType<AccountMember>;
  accountMappingDetails: LoadingWithData<PersonDestinationFields>;
}

//TODO : Change LOADING_STATES to bool
const initialState: AccountState = {
  accountList: INITIAL_PAGINATION_15_ITEMS,
  accountDetails: initializeLoadingData([]),
  accountMembersList: INITIAL_PAGINATION_15_ITEMS,
  accountMappingDetails: initializeLoadingData([]),
};

export const listAccounts = createAsyncThunk(
  "account/list",
  async ({ searchKeyword, columnsToSearchIn }: SearchAssetsType, thunkApi) => {
    const {
      accountDirectory: {
        accountList: { pageSize, currentPageNo: pageNo },
      },
    } = thunkApi.getState() as RootState;
    return await listAccountsApi({
      pageSize,
      pageNo,
      searchKeyword,
      columnsToSearchIn,
    });
  }
);

export const getAccount = createAsyncThunk(
  "account/get",
  async (id: string) => {
    return await getAccountDetailsApi(id);
  }
);

export const getAccountMembers = createAsyncThunk(
  "account/account.person-list",
  async (accountId: string, thunkApi) => {
    const {
      accountDirectory: {
        accountMembersList: { pageSize, currentPageNo: pageNo },
      },
    } = thunkApi.getState() as RootState;
    return await getAccountMembersApi(accountId, pageNo, pageSize);
  }
);

export const getAccountMappingDetails = createAsyncThunkWrapper({
  actionName: "account/mapping-destination",
  dispatchFn: async () => {
    return await getMappingDestinationApi();
  },
  isCachable: true,
});

function getAccountDestinationFields(
  destination: MappingDestination[]
): PersonDestinationFields {
  const destinationFields = destination
    .find((mappingDetails) => {
      return mappingDetails.mapping_field === MAPPING_FIELD.ACCOUNT_MAPPING;
    })
    ?.columns.map((details) => {
      return { [details.name]: { ...details } };
    });
  return Object.assign({}, ...destinationFields!);
}

const accountDbSlice = createSlice({
  name: "accountDirectory",
  initialState,
  reducers: {
    setAccountPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.accountList.totalPageCount ?? 1)) {
        state.accountList.currentPageNo = pageNo;
        state.accountList.changingPage = true;
      } else {
        state.accountList.currentPageNo = 1;
      }
    },
    setAccountMembersPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.accountMembersList.totalPageCount ?? 1)) {
        state.accountMembersList.currentPageNo = pageNo;
        state.accountMembersList.changingPage = true;
      }
    },
    resetAccountList(state) {
      state.accountList = initialState.accountList;
    },
    resetAccountDetails(state) {
      state.accountDetails = initialState.accountDetails;
    },
    resetAccountMembersList(state) {
      state.accountMembersList = initialState.accountMembersList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAccounts.pending, (state) => {
        state.accountList.fetchingList = true;
      })
      .addCase(listAccounts.fulfilled, (state, action) => {
        state.accountList.list = action.payload.records;
        state.accountList.totalPageCount = action.payload.page_count;
        state.accountList.count = action.payload.record_count;
        state.accountList.fetchingList = false;
        state.accountList.changingPage = false;
      })
      .addCase(listAccounts.rejected, (state) => {
        state.accountList.fetchingList = false;
        state.accountList.changingPage = false;
      })

      .addCase(getAccount.pending, (state) => {
        state.accountDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getAccount.fulfilled, (state, action) => {
        state.accountDetails.data = action.payload.rows;
        state.accountDetails.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getAccount.rejected, (state) => {
        state.accountDetails.loading = LOADING_STATES.FAILED;
      })

      .addCase(getAccountMembers.pending, (state) => {
        state.accountMembersList.fetchingList = true;
      })
      .addCase(getAccountMembers.fulfilled, (state, action) => {
        state.accountMembersList.list = action.payload.records;
        state.accountMembersList.totalPageCount = action.payload.page_count;
        state.accountMembersList.count = action.payload.record_count;
        state.accountMembersList.fetchingList = false;
        state.accountMembersList.changingPage = false;
      })
      .addCase(getAccountMembers.rejected, (state) => {
        state.accountMembersList.fetchingList = false;
        state.accountMembersList.changingPage = false;
      })

      .addCase(getAccountMappingDetails.pending, (state) => {
        state.accountMappingDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getAccountMappingDetails.fulfilled, (state, action) => {
        state.accountMappingDetails.loading = LOADING_STATES.SUCCESS;
        state.accountMappingDetails.data = getAccountDestinationFields(
          action.payload.destination
        );
      })
      .addCase(getAccountMappingDetails.rejected, (state) => {
        state.accountMappingDetails.loading = LOADING_STATES.FAILED;
      });
  },
});

export const {
  setAccountPage,
  setAccountMembersPage,
  resetAccountList,
  resetAccountDetails,
  resetAccountMembersList,
} = accountDbSlice.actions;

export const selectDirectoryAccount = (state: RootState) =>
  state.accountDirectory;

export default accountDbSlice.reducer;
