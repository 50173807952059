import { VALUE_META_TYPE } from "../constants/template";
import { EmailConfigurationType } from "./campaign";
import { UserSummary } from "./common";

export interface TemplateBase {
  template_id: string;
  name?: string;
  template_type?: TEMPLATE_EDITOR_MODE;
  subject?: string | null;
  reply_to?: string | null;
  from_email?: {
    name: string;
    email: string;
  } | null;
  updated_at?: string;
  created_at?: string;
  is_empty?: boolean;
}

export type TemplateExhaustive = TemplateBase &
  TemplateAdditionalProperties &
  TemplateMeta &
  SenderMeta;

export interface TemplateAdditionalProperties {
  content: string;
  previews?: {
    img_thumb: string;
    img_large: string;
  };
  content_json?: string;
  type?: string;
  is_deleted?: boolean;
}

export interface TemplateMeta {
  created_at: string;
  created_by: UserSummary;
  updated_at: string;
}

export interface SenderMeta {
  sender_meta?: {
    from_email: VALUE_META_TYPE;
    from_name: VALUE_META_TYPE;
    reply_to: VALUE_META_TYPE;
  };
}

export type TemplateType = TemplateBase &
  SenderMeta &
  TemplateAdditionalProperties;

export enum TEMPLATE_EDITOR_MODE {
  CODE = "advanced",
  DRAG_DROP = "visual",
}

export const VisualTemplateType = "standard";

export interface VisualTemplateData {
  template_name: string;
  template_preview: string;
  template_type: typeof VisualTemplateType;
}

export interface BeeAuthType {
  ".expires": string;
  ".issued": string;
  access_token: string;
  "as:client_id": string;
  "as:region": string;
  expires_in: number;
  refresh_token: string;
  token_type: "bearer";
  userName: string;
}

export interface SavedRowBasic {
  row_json: string;
  row_html?: string;
  page_json?: string;
}

export interface SavedRow extends SavedRowBasic {
  bee_row_id: string;
}

export interface SavedRowResp extends SavedRow {
  created_at: string;
  updated_at: string;
  created_by: UserSummary;
  updated_by: UserSummary;
  type: "bee_row";
  is_deleted: boolean;
}

export type EmailConfigWithSubject = EmailConfigurationType &
  SenderMeta & {
    subject: string;
  };

export type EmailConfigChangeTracker = {
  fromEmailEmail: boolean;
  fromEmailName: boolean;
  replyTo: boolean;
  subject: boolean;
};
