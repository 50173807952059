import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  StackProps,
  VStack,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { CODEMIRROR_SIZE } from "../common/constants/common";
import { customOnChangeHandler } from "../common/helper/commonHelper";
import { SenderMeta } from "../common/types/template";
import RestrictedEmailInput from "./RestrictedEmailInput";
import OptionalCodemirrorInput from "./codemirror/OptionalCodemirrorInput";
import { EMAIL_CONFIG_FIELDS } from "../common/constants/template";

export type EmailConfigurationType = {
  from_email?: {
    name: string;
    email: string;
  };
  reply_to: string;
  subject?: string;
};

interface EmailConfigureInputProps extends StackProps {
  inputs: EmailConfigurationType & Partial<SenderMeta>;
  errors: EmailConfigurationType;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  inputPaddingX: string;
  subjectLess?: boolean;
  reinitCodemirror?: boolean;
  isCodemirror?: boolean;
  readonly?: boolean;
}

export default function EmailConfigureInput({
  inputs,
  errors,
  onChange,
  inputPaddingX,
  subjectLess = false,
  reinitCodemirror,
  isCodemirror = true,
  readonly,
  ...props
}: EmailConfigureInputProps) {
  return (
    <VStack {...props}>
      {!subjectLess && (
        <CodemirrorWithLabel
          px={inputPaddingX}
          reinitCodemirror={reinitCodemirror}
          input={inputs.subject}
          error={errors.subject}
          label="Subject"
          onChange={onChange}
          type={CODEMIRROR_SIZE.TEXT_AREA}
          isCodemirror={isCodemirror}
          name={EMAIL_CONFIG_FIELDS.SUBJECT}
          isDisabled={readonly}
        />
      )}
      <CodemirrorWithLabel
        px={inputPaddingX}
        reinitCodemirror={reinitCodemirror}
        input={inputs.from_email?.name}
        error={errors.from_email?.name}
        onChange={onChange}
        label="Sender name"
        isCodemirror={isCodemirror}
        type={CODEMIRROR_SIZE.SINGLE_LINE}
        name={EMAIL_CONFIG_FIELDS.FROM_EMAIL_NAME}
        isDisabled={readonly}
      />

      <RestrictedEmailInput
        labelText="Send from"
        value={inputs.from_email?.email ?? ""}
        tokenType={inputs.sender_meta?.from_email ?? null}
        onTokenTypeChange={(val) =>
          customOnChangeHandler(
            EMAIL_CONFIG_FIELDS.SENDER_META_FROM_EMAIL,
            val,
            onChange
          )
        }
        onChange={onChange}
        errorMsg={errors.from_email?.email}
        paddingX={inputPaddingX}
        isCodemirror={isCodemirror}
        name={EMAIL_CONFIG_FIELDS.FROM_EMAIL}
        formLabelProps={{ color: "gray.500", fontSize: "xs" }}
        isDisabled={readonly}
      />
      <RestrictedEmailInput
        labelText="Reply to"
        value={inputs.reply_to}
        tokenType={inputs.sender_meta?.reply_to ?? null}
        onTokenTypeChange={(val) =>
          customOnChangeHandler(
            EMAIL_CONFIG_FIELDS.SENDER_META_REPLY_TO,
            val,
            onChange
          )
        }
        onChange={onChange}
        errorMsg={errors.reply_to}
        paddingX={inputPaddingX}
        isCodemirror={isCodemirror}
        name={EMAIL_CONFIG_FIELDS.REPLY_TO}
        formLabelProps={{ color: "gray.500", fontSize: "xs" }}
        isDisabled={readonly}
      />
    </VStack>
  );
}

export function CodemirrorWithLabel({
  input,
  error,
  lang,
  reinitCodemirror,
  onChange,
  type,
  isCodemirror = true,
  name,
  label,
  isDisabled,
  ...props
}: {
  input: string | undefined;
  error: string | undefined;
  lang?: string;
  reinitCodemirror?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  type: CODEMIRROR_SIZE;
  isCodemirror?: boolean;
  label: string;
  name: string;
} & FormControlProps) {
  return (
    <FormControl isInvalid={!!error} py="2" {...props}>
      <FormLabel color={"gray.500"} fontSize={"xs"}>
        {label}
      </FormLabel>
      <OptionalCodemirrorInput
        isInvalid={!!error}
        isCodemirror={isCodemirror}
        type={type}
        lang={lang}
        reinitialize={reinitCodemirror}
        value={input || ""}
        onChange={(code: string) => customOnChangeHandler(name, code, onChange)}
        className="codemirror-wrapper input-cm"
        isDisabled={isDisabled}
      />
      <FormErrorMessage fontSize="12px">{error}</FormErrorMessage>
    </FormControl>
  );
}
