import {
  Heading,
  HStack,
  StackProps,
  Flex,
  IconButton,
  Icon,
  Skeleton,
  TextProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { HEADER_HEIGHT } from "../common/constants/common";
import { FaArrowLeft } from "react-icons/fa";

export type DefaultHeaderType = {
  heading: string;
  isLoading?: boolean;
  goBack?: () => void;
  children?: ReactNode;
};

export default function PrimaryHeader({
  heading,
  isLoading,
  goBack,
  children,
  addComponentToLeft,
  titleProps,
  ...stackProps
}: DefaultHeaderType & {
  addComponentToLeft?: ReactNode;
  titleProps?: TextProps;
} & StackProps) {
  return (
    <>
      <HStack
        justifyContent="space-between"
        px="20px"
        py="1"
        height={HEADER_HEIGHT}
        {...stackProps}
      >
        <HStack>
          {goBack && (
            <IconButton
              size="md"
              aria-label="Go back"
              variant="ghost"
              name="return-button"
              onClick={goBack}
              icon={<Icon fontSize="20px" as={FaArrowLeft} />}
            />
          )}
          <Flex alignItems="baseline">
            {isLoading ? (
              <Skeleton
                isLoaded={!isLoading}
                height="28px"
                minWidth="150px"
              ></Skeleton>
            ) : (
              <>
                <Heading as="h1" fontSize="lg" {...titleProps}>
                  {heading}
                </Heading>
                {addComponentToLeft}
              </>
            )}
          </Flex>
        </HStack>
        {children}
      </HStack>
    </>
  );
}
