import {
  Flex,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  HStack,
  Box,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaChevronDown, FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import urls from "../../../urls";
import {
  getPerson,
  getProductPerson,
  listPersonActivity,
  removePerson,
  resetMarketingActivityPage,
  resetPersonDetailsPage,
  selectPerson,
  updatePerson,
  getPersonMappingDetails,
  setActivityPage,
  listPersonMarketingActivity,
} from "./personDbSlice";
import SpinnerContainer from "../../../components/SpinnerContainer";
import SideBar from "../../../components/SideBar";
import PersonActivities from "./components/PersonActivities";
import {
  PersonUpdateFields,
  PersonDestinationFields,
} from "../../../common/types/person";
import { toast } from "react-toastify";
import {
  isLoading,
  isFinished,
  isFulfilled,
} from "../../../common/helper/commonHelper";
import PersonMarketingActivities from "./components/PersonMarketingActivities";
import IButton, { BUTTON } from "../../../components/IButton";
import { cloneDeep, debounce, get } from "lodash";
import { useAppDispatch } from "../../../store";
import { TableList } from "../../../common/types/campaign";
import PersonInfo from "./components/PersonInfo";
import ProductPerson from "./components/ProductPerson";
import SalesforceSyncTab from "./components/SalesforceSync";
import { SearchField } from "../../../components/SearchField";
import SecondarySidebar from "../../../components/v2/SecondarySidebar";
import ContentContainer from "../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import SidebarBackButton from "../../../components/v2/SidebarBackButton";
import {
  getStartDate,
  CUSTOM_DATE_RANGE_LABELS,
} from "../../../common/constants/common";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import { formatISO } from "date-fns";
import {
  removePersonApi,
  updatePersonApi,
} from "../../../common/api/campaign/person";
import {
  selectDynamicList,
  getTableDescription,
} from "../../../components/dynamic-list/dynamicListSlice";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";

const SIDEBAR_ITEMS = [
  "Contact Fields",
  "Product User",
  "Marketing Activity",
  "Product Activity",
  "Salesforce",
];

function DeleteConfirmationModalContent({ email }: { email: string }) {
  return (
    <>
      <Text fontSize="14px" color="blackAlpha.700">
        Are you sure you want to delete this contact?
      </Text>
      <HStack my={3} color="brand.black" wordBreak="break-word">
        <Icon as={FaUser} />
        <Text>{email}</Text>
      </HStack>
      <Box
        mt={3}
        p={2}
        bgColor="gray.100"
        fontSize="14px"
        color="blackAlpha.900"
      >
        <Text fontWeight="600" mr={1}>
          Note:{" "}
          <Text as="span" fontWeight="400">
            Deleting the contact will only remove the contact's details. This
            will not remove the product data assoicated with the email address.
          </Text>
        </Text>
      </Box>
    </>
  );
}

function ActionsMenu({
  onEdit,
  onDelete,
}: {
  onEdit: () => void;
  onDelete: () => void;
}) {
  return (
    <Menu>
      <MenuButton
        colorScheme="blue"
        size="sm"
        as={Button}
        name="actions"
        rightIcon={<FaChevronDown />}
      >
        Actions
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onEdit} name="edit-contact">
          Edit
        </MenuItem>
        <MenuItem onClick={onDelete} name="delete-contact">
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function SaveCancel({
  onSave,
  onCancel,
  onLoadingSaveAndCancel,
}: {
  onSave: () => void;
  onCancel: () => void;
  onLoadingSaveAndCancel: boolean;
}) {
  return (
    <>
      <IButton
        variant={BUTTON.SECONDARY}
        onClick={onCancel}
        name="cancel"
        isDisabled={onLoadingSaveAndCancel}
      >
        Cancel
      </IButton>
      <IButton
        variant={BUTTON.PRIMARY}
        onClick={onSave}
        isLoading={onLoadingSaveAndCancel}
        name="save"
      >
        Save
      </IButton>
    </>
  );
}

const FILTER_COLUMNS = ["event_name"];

export default function PersonDashboard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    personDetails: { data: personDetails, loading: fetchingPersonDetails },
    updatingPersonDetails,
    personMarketingActivity,
    personActivity,
    deletingPerson,
    personMappingDetails: {
      data: personMappingDetails,
      loading: fetchingPersonMappingDetails,
    },
  } = useSelector(selectPerson);

  const { tableList } = useSelector(selectDynamicList);

  const [details, setDetails] = useState(personMappingDetails);
  const [backup, setBackup] = useState(personMappingDetails);
  const [enableEdit, setEnableEdit] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [[startDate, endDate], setDateRange] = useState([
    getStartDate(CUSTOM_DATE_RANGE_LABELS.LAST_WEEK),
    new Date(),
  ]);

  let { id } = useParams<{ id: string }>();

  const goToPersonList = useCallback(() => {
    navigate(urls.person);
  }, [navigate]);

  useEffect(() => {
    dispatch(getPersonMappingDetails());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTableDescription());
  }, [dispatch]);

  useEffect(() => {
    if (isFinished(fetchingPersonMappingDetails)) {
      if (!personMappingDetails) {
        toast.error("Something went wrong");
        goToPersonList();
      } else {
        if (id) {
          dispatch(getPerson(id));
          dispatch(getProductPerson(id));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    dispatch,
    goToPersonList,
    personMappingDetails,
    fetchingPersonMappingDetails,
  ]);

  useEffect(() => {
    if (id)
      dispatch(
        listPersonActivity({
          personId: id,
          searchKeyword,
          columnsToSearchIn: FILTER_COLUMNS,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, personActivity.currentPageNo]);

  useEffect(() => {
    return () => {
      dispatch(resetMarketingActivityPage());
      dispatch(resetPersonDetailsPage());
    };
  }, [dispatch]);

  useEffect(() => {
    let detailsPerson: PersonDestinationFields = { ...personMappingDetails };
    if (personDetails && personDetails.length && personMappingDetails) {
      Object.entries(personDetails[0]).forEach(([key, details]) => {
        if (key in detailsPerson) {
          detailsPerson[key] = {
            ...detailsPerson[key],
            value: details,
          };
        }
      });
      setDetails(detailsPerson);
      setBackup(detailsPerson);
    }
  }, [personDetails, personMappingDetails]);

  useEffect(() => {
    if (id) {
      dispatch(
        listPersonMarketingActivity({
          personId: id,
          startDate: formatISO(startDate),
          endDate: formatISO(endDate),
        })
      );
    }
  }, [dispatch, endDate, id, startDate, personMarketingActivity.currentPageNo]);

  function onDateChange(startDate: Date, endDate: Date) {
    setDateRange([startDate, endDate]);
  }

  async function onSave() {
    let data: PersonUpdateFields = {
      default_fields: {},
      custom_fields: {},
      email: "",
    };
    Object.keys(details).forEach((key) => {
      if (details[key].updatable && !details[key].hidden) {
        if (details[key].custom) {
          data.custom_fields[key] = details[key].value;
        } else {
          data.default_fields[key] = details[key].value;
        }
      }
    });
    data.email = details.email?.value ?? "";

    const updatedPersonResponse = await dispatch(updatePerson(data));

    if (isFulfilled(updatedPersonResponse.meta.requestStatus)) {
      const { status } = updatedPersonResponse.payload as Awaited<
        ReturnType<typeof updatePersonApi>
      >;
      if (status && id) {
        dispatch(getPerson(id));
        onCancelEdit();
      }
    }
  }

  function onCancelEdit() {
    setDetails(backup);
    setEnableEdit(false);
  }

  const editDetails = useCallback(
    (key: string, value: any) => {
      let newDetails = cloneDeep(details);
      newDetails[key].value = value;
      setDetails(newDetails);
    },
    [details]
  );

  async function deletePerson(id: string, email: string) {
    const removePersonResponse = await dispatch(removePerson({ id, email }));
    if (isFulfilled(removePersonResponse.meta.requestStatus)) {
      const { success } = removePersonResponse.payload as Awaited<
        ReturnType<typeof removePersonApi>
      >;
      if (success) {
        goToPersonList();
        setDeleteModalOpen(false);
      }
    }
  }

  const searchIfValid = useCallback(
    (keyword: string) => {
      if (personActivity.currentPageNo !== 1) {
        dispatch(setActivityPage(1));
      } else {
        if (id)
          dispatch(
            listPersonActivity({
              personId: id,
              searchKeyword: keyword,
              columnsToSearchIn: FILTER_COLUMNS,
            })
          );
      }
    },
    [dispatch, id, personActivity.currentPageNo]
  );

  const debounceSearch = useMemo(
    () => debounce(searchIfValid, 1000),
    [searchIfValid]
  );

  function currentTabMenu(tab: number) {
    //displays side menu based on the current tab, add more cases if needed

    switch (tab) {
      case 0:
        // action, save and cancel menu for person_details tab
        return !enableEdit ? (
          <ActionsMenu
            onEdit={() => setEnableEdit(true)}
            onDelete={() => setDeleteModalOpen(true)}
          />
        ) : (
          <SaveCancel
            onSave={onSave}
            onCancel={onCancelEdit}
            onLoadingSaveAndCancel={isLoading(updatingPersonDetails)}
          />
        );
      case 2:
        return (
          <CustomDateRangePicker
            startDate={startDate}
            endDate={endDate}
            onDateRangeChange={onDateChange}
          />
        );
      case 3:
        return (
          <SearchField
            name="search-product-activities"
            placeholder="Search product events"
            value={searchKeyword}
            onSearch={(keyword) => {
              setSearchKeyword(keyword);
              debounceSearch(keyword);
            }}
            width="260px"
          />
        );
    }
  }

  const tabs = useMemo(() => {
    return {
      [SIDEBAR_ITEMS[0]]: (
        <ContentContainer flexDir="column">
          <PersonInfo
            details={details}
            editDetails={editDetails}
            enableEdit={enableEdit}
          />
        </ContentContainer>
      ),
      [SIDEBAR_ITEMS[1]]: (
        <ProductPerson allTableList={tableList.data as TableList} />
      ),
      [SIDEBAR_ITEMS[2]]: <PersonMarketingActivities personId={id ? id : ""} />,
      [SIDEBAR_ITEMS[3]]: <PersonActivities />,
      [SIDEBAR_ITEMS[4]]: details?.email?.value && (
        <ContentContainer flexDir="column">
          <SalesforceSyncTab email={details?.email?.value} />
        </ContentContainer>
      ),
    };
  }, [details, editDetails, enableEdit, id, tableList.data]);

  return (
    <Flex>
      <SecondarySidebar
        heading={personDetails?.[0]?.email}
        backButton={
          <SidebarBackButton
            onClick={() => navigate(urls.person)}
            text="Back to contact list"
          />
        }
      >
        <SideBar
          options={SIDEBAR_ITEMS}
          selected={selectedOption}
          select={setSelectedOption}
        />
      </SecondarySidebar>

      <ContentContainerWithHeader>
        <HStack minH="45px" maxH="45px" flex={1} justifyContent="flex-end">
          {currentTabMenu(selectedOption)}
        </HStack>
        <SpinnerContainer
          loading={
            isLoading(fetchingPersonDetails) ||
            isLoading(fetchingPersonMappingDetails)
          }
          height="80vh"
        >
          {get(tabs, SIDEBAR_ITEMS[selectedOption])}
        </SpinnerContainer>
      </ContentContainerWithHeader>
      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        submitHandler={() => {
          if (id) deletePerson(id, details.email.value);
          setDeleteModalOpen(false);
        }}
        isLoading={isLoading(deletingPerson)}
        customHeader="Delete contact"
        customContent={
          <DeleteConfirmationModalContent email={details?.email?.value ?? ""} />
        }
      />
    </Flex>
  );
}
