import { Box, Flex } from "@chakra-ui/react";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isLoading, isSuccess } from "../../../common/helper/commonHelper";
import { EmailConfigurationType } from "../../../common/types/campaign";
import { UnsubUrlForm } from "../../../common/types/settings";
import { CommonListHeader } from "../../../components/CommonListHeader";
import IButton, { BUTTON } from "../../../components/IButton";
import SpinnerContainer from "../../../components/SpinnerContainer";
import ContentContainer from "../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import { useAppDispatch } from "../../../store";
import GlobalEmailConfiguration from "./components/GlobalEmailConfiguration";
import SubHeader from "./components/SubHeader";
import UnsubscribeUrl from "./components/UnsubscribeEmailConfiguration";
import { resetLoadingStateEmailDefault, selectSettings } from "./settingsSlice";
import TwoStepUnsubscribe from "./components/TwoStepUnsubscribe";

export default function EmailSettings() {
  const { globalDefault, unsubUrl } = useSelector(selectSettings);
  const [activeErrorCheck, setActiveErrorCheck] = useState(false);
  const dispatch = useAppDispatch();

  const globalEmailRef = useRef<{
    formRef: React.RefObject<FormikProps<EmailConfigurationType>> | null;
  }>({ formRef: null });
  const unsubscribeEmailRef = useRef<{
    formRef: React.RefObject<FormikProps<UnsubUrlForm>> | null;
  }>({ formRef: null });

  useEffect(() => {
    if (isSuccess(unsubUrl.updating) || globalDefault.updating) {
      setActiveErrorCheck(false);
    }
  }, [globalDefault.updating, unsubUrl.updating]);

  useEffect(() => {
    return () => {
      dispatch(resetLoadingStateEmailDefault());
    };
  }, [dispatch]);

  async function submit() {
    setActiveErrorCheck(true);
    if (
      globalEmailRef.current.formRef?.current &&
      unsubscribeEmailRef.current.formRef?.current
    ) {
      const [globalEmailErrors, unsubscribeEmailErrors] = await Promise.all([
        globalEmailRef.current.formRef.current.validateForm(
          globalEmailRef.current.formRef.current.values
        ),
        unsubscribeEmailRef.current.formRef.current.validateForm(
          unsubscribeEmailRef.current.formRef.current.values
        ),
      ]);

      if (
        globalEmailRef.current.formRef?.current &&
        unsubscribeEmailRef.current.formRef?.current
      ) {
        if (isEmpty(globalEmailErrors) && isEmpty(unsubscribeEmailErrors)) {
          if (globalEmailRef.current.formRef.current.dirty) {
            globalEmailRef.current.formRef.current.submitForm();
          }
          if (unsubscribeEmailRef.current.formRef.current.dirty) {
            unsubscribeEmailRef.current.formRef.current.submitForm();
          }
        } else {
          // to set fields touched and show validation errors
          if (!isEmpty(globalEmailErrors)) {
            globalEmailRef.current.formRef.current.submitForm();
          }
          if (!isEmpty(unsubscribeEmailErrors)) {
            unsubscribeEmailRef.current.formRef.current.submitForm();
          }
        }
      }
    }
  }

  return (
    <>
      <SpinnerContainer
        loading={
          isLoading(globalDefault.fetching) || isLoading(unsubUrl.fetching)
        }
        width="100%"
      >
        <ContentContainerWithHeader>
          <CommonListHeader heading="" />
          <ContentContainer flexDir="column">
            <Box w="70%">
              <TwoStepUnsubscribe />
            </Box>
            <Box w="50%">
              <UnsubscribeUrl
                validate={activeErrorCheck}
                parentRef={unsubscribeEmailRef}
                showSave={false}
              />
            </Box>
            <SubHeader
              title="Global email configuration"
              desc="These configurations apply to all email templates under this organisation"
              boxProps={{ width: "50%", mt: "4" }}
            >
              <GlobalEmailConfiguration
                validate={activeErrorCheck}
                parentRef={globalEmailRef}
                showSave={false}
              />
            </SubHeader>
            <Flex width="50%" justifyContent="flex-end">
              <IButton
                variant={BUTTON.PRIMARY}
                onClick={submit}
                isLoading={
                  globalDefault.updating || isLoading(unsubUrl.updating)
                }
                fontSize={14}
                name="save-settings"
                mt="5"
              >
                Save
              </IButton>
            </Flex>
          </ContentContainer>
        </ContentContainerWithHeader>
      </SpinnerContainer>
    </>
  );
}
