import { toast } from "react-toastify";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createSamlConfigApi,
  getSamlConfigApi,
  updateSamlConfigApi,
} from "../../../common/api/auth/auth";
import { SamlConfigReq, SamlConfigResp } from "../../../common/types/auth";
import { EmailConfigurationType } from "../../../common/types/campaign";
import { PERSON_ORG_MAPPING, UnsubUrl } from "../../../common/types/person";
import { LOADING_STATES } from "../../../common/constants/common";
import { RootState } from "../../../store";
import {
  createAsyncThunkWrapper,
  initializeInfiniteList,
  initializeLoadingData,
  isFailed,
} from "../../../common/helper/commonHelper";
import {
  createUnsubUrlApi,
  deleteUnsubUrlApi,
  getUnsubUrlApi,
  updateUnsubUrlApi,
  getSettingsApi,
  createGlobalDefaultApi,
  updateGlobalDefaultApi,
  createFormSettingsApi,
  getFormSettingsApi,
  deleteFormSettingsApi,
  getUnsubscribeSettingsApi,
  toggleUnsubscribeSettingsApi,
  getTimezonePreferenceSettingsApi,
  updateTimezonePreferenceSettingsApi,
  getTenantTimezoneApi,
  createTimezonePreferenceSettingsApi,
} from "../../../common/api/campaign/settings";
import {
  DomainDetails,
  DomainReq,
  TenantDetails,
  TimezoneSettings,
  UnsubscribeSettings,
} from "../../../common/types/settings";
import {
  InfiniteListType,
  LoadingWithData,
} from "../../../common/types/common";
import {
  TIMEZONE_FIELDS,
  TRACKED_DOMAINS_STATUS,
} from "../../../common/constants/settings";
import {
  getDomainListApi,
  createDomainApi,
  updateDomainApi,
  deleteDomainApi,
  validateDomainApi,
  activateDomainApi,
  deactivateDomainApi,
  sendEmailToDevApi,
} from "../../../common/api/websiteTracking/domains";
import { isDomainActive } from "../../../common/helper/settingsHelper";

interface settingState {
  samlConfig: SamlConfigResp;
  globalDefault: {
    fetching: LOADING_STATES;
    updating: boolean;
    data: EmailConfigurationType | null;
  };
  unsubUrl: UnsubUrl & { fetching: LOADING_STATES; updating: LOADING_STATES };
  twoStepUnsub: LoadingWithData<UnsubscribeSettings>;
  timezonePreference: LoadingWithData<TimezoneSettings>;
  tenantDetails: LoadingWithData<TenantDetails>;
  personOrgMapping: PERSON_ORG_MAPPING;
  fetchingPersonOrgMapping: LOADING_STATES;
  domainList: InfiniteListType<DomainDetails>;
  domainChanged: boolean;
}

const initialState: settingState = {
  samlConfig: {
    id: 0,
    organisation_id: 0,
    created_by_id: 0,
    entity_id: "",
    sso_url: "",
    certificate: "",
    idp: null,
    time_created: "",
    time_updated: "",
  },
  globalDefault: {
    fetching: LOADING_STATES.INIT,
    updating: false,
    data: null,
  },
  unsubUrl: {
    url: "",
    created_at: "",
    updated_at: "",
    fetching: LOADING_STATES.INIT,
    updating: LOADING_STATES.INIT,
  },
  twoStepUnsub: initializeLoadingData({
    two_step_enabled: false,
    created_at: "",
    updated_at: "",
  }),
  timezonePreference: initializeLoadingData({
    preferred_timezone: "",
  }),
  tenantDetails: initializeLoadingData({
    timezone: "",
  }),
  personOrgMapping: PERSON_ORG_MAPPING.ONE_TO_NONE,
  fetchingPersonOrgMapping: LOADING_STATES.INIT,
  domainChanged: true,
  domainList: initializeInfiniteList({ startPageNo: 0 }),
};

export const getSamlConfig = createAsyncThunk("auth/saml-config", async () => {
  return await getSamlConfigApi();
});

export const updateSamlConfig = createAsyncThunk(
  "auth/update-saml-config",
  async (config: SamlConfigReq) => {
    return await updateSamlConfigApi(config);
  }
);

export const createSamlConfig = createAsyncThunk(
  "auth/create-saml-config",
  async (config: SamlConfigReq) => {
    return await createSamlConfigApi(config);
  }
);

export const fetchSettings = createAsyncThunk("settings/get", async () => {
  return await getSettingsApi();
});

export const createGlobalEmailConfig = createAsyncThunk(
  "settings/email/create",
  async (data: EmailConfigurationType) => {
    return await createGlobalDefaultApi(data);
  }
);

export const updateGlobalEmailConfig = createAsyncThunk(
  "settings/email/update",
  async (data: EmailConfigurationType) => {
    return await updateGlobalDefaultApi(data);
  }
);

export const updateUnsubUrl = createAsyncThunk(
  "settings/unsub-url/update",
  async (url: string) => {
    return await updateUnsubUrlApi(url);
  }
);

export const createUnsubUrl = createAsyncThunk(
  "settings/unsub-url/create",
  async (url: string) => {
    return await createUnsubUrlApi(url);
  }
);

export const deleteUnsubUrl = createAsyncThunk(
  "settings/unsub-url/delete",
  async () => {
    return await deleteUnsubUrlApi();
  }
);

export const getUnsubUrl = createAsyncThunk(
  "settings/unsub-url/get",
  async () => {
    return await getUnsubUrlApi();
  }
);

export const createFormSettings = createAsyncThunk(
  "settings/form.create",
  async (overwrite: boolean) => {
    return await createFormSettingsApi(overwrite);
  }
);

export const getFormSettings = createAsyncThunk(
  "settings/form.get",
  async () => {
    return await getFormSettingsApi();
  }
);

export const deleteFormSettings = createAsyncThunk(
  "settings/form.delete",
  async () => {
    return await deleteFormSettingsApi();
  }
);

export const getUnsubscribeSettings = createAsyncThunk(
  "settings/unsub.get",
  async () => {
    return await getUnsubscribeSettingsApi();
  }
);

export const toggleUnsubscribeSettings = createAsyncThunk(
  "settings/unsub.toggle",
  async () => {
    return await toggleUnsubscribeSettingsApi();
  }
);

export const getTimezonePreferenceSettings = createAsyncThunkWrapper({
  actionName: "settings/timezone-preference.get",
  dispatchFn: async () => {
    return await getTimezonePreferenceSettingsApi();
  },
  isCachable: true,
});
export const createTimezonePreferenceSettings = createAsyncThunk(
  "settings/timezone-preference.create",
  async ({ preferredTimezone }: { preferredTimezone: TIMEZONE_FIELDS }) => {
    return await createTimezonePreferenceSettingsApi(preferredTimezone);
  }
);

export const updateTimezonePreferenceSettings = createAsyncThunk(
  "settings/timezone-preference.update",
  async ({ preferredTimezone }: { preferredTimezone: TIMEZONE_FIELDS }) => {
    return await updateTimezonePreferenceSettingsApi(preferredTimezone);
  }
);

export const getTenantTimezone = createAsyncThunkWrapper({
  actionName: "settings/tenant-timezone.get",
  dispatchFn: async () => {
    return await getTenantTimezoneApi();
  },
  isCachable: true,
});

export const getDomainList = createAsyncThunk(
  "settings/get-domain-list",
  async (_, { getState }) => {
    const {
      settings: {
        domainList: { pageSize, currentPageNo: page },
      },
    } = getState() as RootState;

    return await getDomainListApi({
      pageSize,
      page,
    });
  }
);

export const createDomain = createAsyncThunk(
  "settings/create-domain",
  async (domainInfo: DomainReq) => {
    return await createDomainApi(domainInfo);
  }
);

export const updateDomain = createAsyncThunk(
  "settings/update-domain",
  async ({
    domainInfo,
    domainId,
  }: {
    domainInfo: DomainReq;
    domainId: string;
  }) => {
    return await updateDomainApi(domainInfo, domainId);
  }
);

export const deleteDomain = createAsyncThunk(
  "settings/delete-domain",
  async (domainId: string) => {
    return await deleteDomainApi(domainId);
  }
);

export const validateDomain = createAsyncThunk(
  "settings/validate-domain",
  async (domain: string) => {
    return await validateDomainApi(domain);
  }
);

export const sendEmailToDev = createAsyncThunk(
  "settings/send-to-dev",
  async (email: string) => {
    return await sendEmailToDevApi(email);
  }
);

export const controlDomainStatus = createAsyncThunk(
  "settings/control-domain-activity",
  async ({
    domainId,
    domainActivity,
  }: {
    domainId: string;
    domainActivity:
      | TRACKED_DOMAINS_STATUS.ACTIVE
      | TRACKED_DOMAINS_STATUS.INACTIVE;
  }) => {
    if (isDomainActive(domainActivity)) {
      return await activateDomainApi(domainId);
    } else {
      return await deactivateDomainApi(domainId);
    }
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetLoadingStateEmailDefault(state) {
      if (isFailed(state.globalDefault.fetching)) {
        state.globalDefault.fetching = LOADING_STATES.INIT;
      }
    },
    resetLoadingStateUnsubUrl(state) {
      state.unsubUrl.fetching = LOADING_STATES.INIT;
    },
    resetDomainList(state) {
      state.domainList = initializeInfiniteList({ startPageNo: 0 });
    },
    setDomainListPageNo(state, { payload: pageNo }: PayloadAction<number>) {
      if (pageNo && pageNo < (state.domainList.totalPageCount ?? 1)) {
        state.domainList.currentPageNo = pageNo;
      } else {
        state.domainList.currentPageNo = 0;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSamlConfig.fulfilled, (state, action) => {
        state.samlConfig = action.payload.config;
      })
      .addCase(updateSamlConfig.fulfilled, (state, action) => {
        state.samlConfig = action.payload.config;
        toast.success("SSO configuration updated successfully");
      })
      .addCase(createSamlConfig.fulfilled, (state, action) => {
        state.samlConfig = action.payload.config;
        toast.success("SSO configuration created successfully");
      })

      //fetch global default
      .addCase(fetchSettings.pending, (state) => {
        state.fetchingPersonOrgMapping = LOADING_STATES.LOADING;
        state.globalDefault.fetching = LOADING_STATES.LOADING;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.fetchingPersonOrgMapping = LOADING_STATES.SUCCESS;
        state.globalDefault.fetching = LOADING_STATES.SUCCESS;
        state.personOrgMapping = action.payload.settings.person_org_mapping;
        if (action.payload.settings.email) {
          const { from_email, reply_to } = action.payload.settings.email;
          state.globalDefault.data = { from_email, reply_to };
        } else {
          state.globalDefault.data = null;
        }
      })
      .addCase(fetchSettings.rejected, (state) => {
        state.globalDefault.fetching = LOADING_STATES.FAILED;
        state.fetchingPersonOrgMapping = LOADING_STATES.FAILED;
      })

      //create global default
      .addCase(createGlobalEmailConfig.fulfilled, (state, action) => {
        state.globalDefault.fetching = LOADING_STATES.SUCCESS;
        state.globalDefault.updating = false;
        if (action.payload) {
          const { from_email, reply_to } = action.payload;
          state.globalDefault.data = { from_email, reply_to };
        } else {
          state.globalDefault.data = null;
        }
        toast.success("Global email configuration created");
      })
      .addCase(createGlobalEmailConfig.pending, (state) => {
        state.globalDefault.updating = true;
      })
      .addCase(createGlobalEmailConfig.rejected, (state) => {
        state.globalDefault.updating = false;
      })
      //update global default
      .addCase(updateGlobalEmailConfig.fulfilled, (state, action) => {
        state.globalDefault.fetching = LOADING_STATES.SUCCESS;
        if (action.payload.email) {
          const { from_email, reply_to } = action.payload.email;
          state.globalDefault.data = { from_email, reply_to };
        } else {
          state.globalDefault.data = null;
        }
        state.globalDefault.updating = false;
        toast.success("Global email configuration updated");
      })
      .addCase(updateGlobalEmailConfig.pending, (state) => {
        state.globalDefault.updating = true;
      })
      .addCase(updateGlobalEmailConfig.rejected, (state) => {
        state.globalDefault.updating = false;
      })

      // create unsub url
      .addCase(createUnsubUrl.pending, (state) => {
        state.unsubUrl.updating = LOADING_STATES.LOADING;
      })
      .addCase(createUnsubUrl.fulfilled, (state, action) => {
        state.unsubUrl.updating = LOADING_STATES.SUCCESS;
        state.unsubUrl = { ...state.unsubUrl, ...action.payload };
        toast.success("Unsubscribe URL added");
      })
      .addCase(createUnsubUrl.rejected, (state) => {
        state.unsubUrl.updating = LOADING_STATES.FAILED;
      })

      // update unsub url
      .addCase(updateUnsubUrl.pending, (state) => {
        state.unsubUrl.updating = LOADING_STATES.LOADING;
      })
      .addCase(updateUnsubUrl.fulfilled, (state, action) => {
        state.unsubUrl.updating = LOADING_STATES.SUCCESS;
        state.unsubUrl = { ...state.unsubUrl, ...action.payload.unsub_url };
        toast.success("Unsubscribe URL updated");
      })
      .addCase(updateUnsubUrl.rejected, (state) => {
        state.unsubUrl.updating = LOADING_STATES.FAILED;
      })

      // delete unsub url
      .addCase(deleteUnsubUrl.pending, (state) => {
        state.unsubUrl.updating = LOADING_STATES.LOADING;
      })
      .addCase(deleteUnsubUrl.fulfilled, (state, action) => {
        if (action.payload.unsub_url) {
          state.unsubUrl.url = null;
          state.unsubUrl.updating = LOADING_STATES.SUCCESS;
          toast.success("Unsubscribe URL updated");
        }
      })
      .addCase(deleteUnsubUrl.rejected, (state) => {
        state.unsubUrl.updating = LOADING_STATES.FAILED;
      })

      // get unsub url
      .addCase(getUnsubUrl.pending, (state) => {
        state.unsubUrl.fetching = LOADING_STATES.LOADING;
      })
      .addCase(getUnsubUrl.fulfilled, (state, action) => {
        state.unsubUrl.fetching = LOADING_STATES.SUCCESS;
        state.unsubUrl = { ...state.unsubUrl, ...action.payload.unsub_url };
      })
      .addCase(getUnsubUrl.rejected, (state) => {
        state.unsubUrl.fetching = LOADING_STATES.FAILED;
      })

      // get unsubscribe settings
      .addCase(getUnsubscribeSettings.pending, (state) => {
        state.twoStepUnsub.loading = LOADING_STATES.LOADING;
      })
      .addCase(getUnsubscribeSettings.fulfilled, (state, { payload }) => {
        state.twoStepUnsub = {
          loading: LOADING_STATES.SUCCESS,
          data: payload.unsubscribe,
        };
      })
      .addCase(getUnsubscribeSettings.rejected, (state) => {
        state.twoStepUnsub.loading = LOADING_STATES.FAILED;
      })

      //update unsubscribe settings
      .addCase(toggleUnsubscribeSettings.pending, (state) => {
        state.twoStepUnsub.loading = LOADING_STATES.LOADING;
      })
      .addCase(toggleUnsubscribeSettings.fulfilled, (state, { payload }) => {
        state.twoStepUnsub = {
          loading: LOADING_STATES.SUCCESS,
          data: payload.unsubscribe,
        };
        toast.success("Unsubscribe settings updated successfully");
      })
      .addCase(toggleUnsubscribeSettings.rejected, (state) => {
        state.twoStepUnsub.loading = LOADING_STATES.FAILED;
        toast.error("Unsubscribe settings updation failed");
      })

      // get timezone preference settings
      .addCase(getTimezonePreferenceSettings.pending, (state) => {
        state.timezonePreference.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        getTimezonePreferenceSettings.fulfilled,
        (state, { payload }) => {
          state.timezonePreference = {
            loading: LOADING_STATES.SUCCESS,
            data: payload.timezone_preference,
          };
        }
      )
      .addCase(getTimezonePreferenceSettings.rejected, (state) => {
        state.timezonePreference.loading = LOADING_STATES.FAILED;
      })

      //update timezone preference settings
      .addCase(updateTimezonePreferenceSettings.pending, (state) => {
        state.timezonePreference.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        updateTimezonePreferenceSettings.fulfilled,
        (state, { payload }) => {
          state.timezonePreference = {
            loading: LOADING_STATES.SUCCESS,
            data: payload.timezone_preference,
          };
          toast.success("Timezone preference updated successfully");
        }
      )
      .addCase(updateTimezonePreferenceSettings.rejected, (state) => {
        state.timezonePreference.loading = LOADING_STATES.FAILED;
        toast.error("Timezone preference creation failed");
      })

      //create timezone preference settings
      .addCase(createTimezonePreferenceSettings.pending, (state) => {
        state.timezonePreference.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        createTimezonePreferenceSettings.fulfilled,
        (state, { payload }) => {
          state.timezonePreference = {
            loading: LOADING_STATES.SUCCESS,
            data: payload.timezone_preference,
          };
          toast.success("Timezone preference creation successfully");
        }
      )
      .addCase(createTimezonePreferenceSettings.rejected, (state) => {
        state.timezonePreference.loading = LOADING_STATES.FAILED;
        toast.error("Timezone preference creation failed");
      })

      //get tenant timezone
      .addCase(getTenantTimezone.pending, (state) => {
        state.tenantDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getTenantTimezone.fulfilled, (state, { payload }) => {
        state.tenantDetails = {
          loading: LOADING_STATES.SUCCESS,
          data: payload.tenant_timezone,
        };
      })
      //domain creation
      .addCase(createDomain.fulfilled, (state) => {
        state.domainChanged = true;
      })
      // domain update
      .addCase(updateDomain.fulfilled, (state) => {
        state.domainChanged = true;
      })
      //remove domain
      .addCase(deleteDomain.fulfilled, (state) => {
        state.domainChanged = true;
      })
      //get domain list
      .addCase(getDomainList.pending, (state) => {
        state.domainList.loadingList = LOADING_STATES.LOADING;
      })

      .addCase(
        getDomainList.fulfilled,
        (state, { payload: { data, pagination } }) => {
          // accessing data key may not be safe, test
          if (data) {
            state.domainList.list = state.domainList.list
              ? [...state.domainList.list, ...data]
              : data;
            state.domainList.count = pagination.totalRecords;
            state.domainList.totalPageCount = pagination.totalPages;
            state.domainList.loadingList = LOADING_STATES.SUCCESS;
          } else {
            state.domainList.loadingList = LOADING_STATES.FAILED;
          }
          state.domainChanged = false;
        }
      )
      .addCase(getDomainList.rejected, (state) => {
        state.domainList.loadingList = LOADING_STATES.FAILED;
      })

      // send to dev
      .addCase(sendEmailToDev.fulfilled, (state) => {
        toast.success("Email is sent!");
      })

      // control domain activity
      .addCase(controlDomainStatus.fulfilled, (state, { meta: { arg } }) => {
        if (!isDomainActive(arg.domainActivity)) {
          state.domainChanged = true;
        }
      });
  },
});

export const {
  resetLoadingStateEmailDefault,
  resetLoadingStateUnsubUrl,
  setDomainListPageNo,
  resetDomainList,
} = settingsSlice.actions;

export const selectSettings = (state: RootState) => state.settings;

export default settingsSlice.reducer;
