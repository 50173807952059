import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Text,
  Image,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  listPerson,
  setPersonPage,
  selectPerson,
  resetPersonList,
} from "./personDbSlice";
import { Person } from "../../../common/types/person";
import urls from "../../../urls";
import { FaChevronDown } from "react-icons/fa";
import CreatePersonModal from "./components/CreatePersonModal";
import { debounce } from "lodash";
import { SearchInputAndCreateButton } from "../../../components/CommonListHeader";
import { useAppDispatch } from "../../../store";
import { DataTable } from "../../../components/data-table/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import LayoutWithTable from "../../../layout/LayoutWithTable";
import { FormatDate } from "../../../components/DateTimeRangeFilter";
import NumberFormatHeader from "../../../components/NumberFormatHeader";
import { useSearch } from "../../../common/hooks/commonHooks";
import ContactUploaderCsv from "../contactUpload/ContactUploaderCsv";
import { UPLOAD_CONTACTS_TO } from "../../../common/types/contactUpload";
import { getTableRowLinkProps } from "../../../common/helper/commonHelper";
import InitialEmptyState from "../../../components/InitialEmptyState";
import ContactsEmptyState from "../../../common/img/emptyStateLogos/contacts.svg";

enum ACTIONS {
  CREATE = "create",
  IMPORT = "import",
}

function ActionsMenu({ onClick }: { onClick: (action: ACTIONS) => void }) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        size="sm"
        colorScheme="blue"
        rightIcon={<FaChevronDown />}
        px="16px"
        py="4px"
      >
        Actions
      </MenuButton>
      <MenuList zIndex="3">
        <MenuItem onClick={() => onClick(ACTIONS.CREATE)}>
          Create Contact
        </MenuItem>
        <MenuItem onClick={() => onClick(ACTIONS.IMPORT)}>
          Import Contacts
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default function Persons() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    isOpen: isCsvModalOpen,
    onClose: onCsvModalClose,
    onOpen: onCsvModalOpen,
  } = useDisclosure();

  const {
    isOpen: isCreateModalOpen,
    onClose: onCreateModalClose,
    onOpen: onCreateModalOpen,
  } = useDisclosure();

  const { person } = useSelector(selectPerson);

  const [searchKeyword, setSearchKeyword] = useSearch();
  const COLUMNS_TO_SEARCH_IN = ["email", "first_name", "last_name"];

  const columnHelper = createColumnHelper<Person>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("email", {
        header: "Email",
        size: 300,
      }),
      columnHelper.accessor("first_name", {
        header: "First Name",
        size: 250,
      }),
      columnHelper.accessor("last_name", {
        header: "Last Name",
        size: 250,
      }),
      columnHelper.accessor("title", {
        header: "Title",
        cell: (info) => (
          <Text whiteSpace="pre-line" wordBreak="break-word">
            {info.getValue()}
          </Text>
        ),
        size: 250,
      }),
      columnHelper.accessor("company_name", {
        header: "Company",
        size: 250,
      }),
      columnHelper.accessor("phone_number", {
        header: "Phone",
        size: 200,
      }),
      columnHelper.accessor("person_created_time", {
        header: "Created Date",
        cell: (info) => <FormatDate date={info.getValue()} />,
        size: 200,
      }),
    ],
    [columnHelper]
  );

  const searchIfValid = useCallback(
    (searchKeyword: string) => {
      if (person.currentPageNo !== 1) {
        dispatch(setPersonPage(0));
      } else {
        dispatch(
          listPerson({ searchKeyword, columnsToSearchIn: COLUMNS_TO_SEARCH_IN })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, person.currentPageNo]
  );

  const debouncedSearch = useMemo(
    () => debounce(searchIfValid, 1000),
    [searchIfValid]
  );

  function onSearchKeywordChange(text: string) {
    setSearchKeyword(text);
    debouncedSearch(text);
  }

  const openPerson = useCallback(
    (person: Person) => {
      navigate(`${urls.person}/${person.id}`);
    },
    [navigate]
  );

  useEffect(() => {
    dispatch(
      listPerson({ searchKeyword, columnsToSearchIn: COLUMNS_TO_SEARCH_IN })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, person.currentPageNo]);

  useEffect(() => {
    return () => {
      dispatch(resetPersonList());
    };
  }, [dispatch]);

  function actionsClickHandler(action: ACTIONS) {
    switch (action) {
      case ACTIONS.CREATE:
        onCreateModalOpen();
        break;
      case ACTIONS.IMPORT:
        onCsvModalOpen();
        break;
    }
  }

  function onImportContactsViaCsv() {
    setTimeout(() => {
      dispatch(
        listPerson({ searchKeyword, columnsToSearchIn: COLUMNS_TO_SEARCH_IN })
      );
    }, 500);
  }

  const displayContacts = person.totalPageCount !== 0 || searchKeyword;

  return (
    <>
      <NumberFormatHeader
        heading="Contacts"
        count={person.count ? person.count : null}
        countLabel="contacts"
      >
        <SearchInputAndCreateButton
          searchInputProps={{
            placeholder: "Search contacts ",
            name: "search-input",
            value: searchKeyword,
            onSearch: onSearchKeywordChange,
          }}
          ctaMenu={<ActionsMenu onClick={actionsClickHandler} />}
        />
      </NumberFormatHeader>
      {displayContacts ? (
        <LayoutWithTable>
          <DataTable
            fetchingList={person.fetchingList}
            changingPage={person.changingPage}
            list={person.list}
            totalPageSize={person.pageSize}
            totalPageCount={person.totalPageCount}
            currentPage={person.currentPageNo}
            onRowClick={openPerson}
            setPage={(pageNo) => dispatch(setPersonPage(pageNo))}
            columns={columns}
            emptyMsg={`No contacts found.${
              searchKeyword ? " Please change the search / filter values" : ""
            }`}
            getTableRowLinkProps={(data) =>
              getTableRowLinkProps({ to: urls.person, editParam: "id" }, data)
            }
          />
        </LayoutWithTable>
      ) : (
        <InitialEmptyState
          mainText="Add contacts to craft personalized and impactful campaigns"
          message="No contacts"
          additionalActions={<ActionsMenu onClick={actionsClickHandler} />}
        >
          <Image src={ContactsEmptyState} alt="Contacts" />
        </InitialEmptyState>
      )}

      <CreatePersonModal
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
      />
      <ContactUploaderCsv
        isOpen={isCsvModalOpen}
        onImport={onImportContactsViaCsv}
        onClose={onCsvModalClose}
        uploadTo={UPLOAD_CONTACTS_TO.PERSON_DB}
      />
    </>
  );
}
