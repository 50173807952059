import {
  DynamicListChildL2,
  CONNECTOR,
  TYPE,
  DynamicListChild,
  DynamicListChildL1,
  WebsiteActivityFilter,
} from "../types/campaign";
import {
  PROPERTY_FILTER_ID,
  FILTER_TYPE,
  CHILD_FILTER_TYPE,
} from "../constants/campaign";
import { isEmpty } from "lodash";

type PropertyGroupIndices = {
  timeFrameIndex: number | null;
  srcOfVisitIndex: number | null;
};

export function isTypeGroup(type: TYPE) {
  return type === TYPE.GROUP;
}

export function findFirstGroupIndex(data: DynamicListChildL1[]) {
  return data.findIndex((item) => isTypeGroup(item.type));
}

export function getFilterGroupChild(
  filterGroup: DynamicListChild,
  index: number | null
) {
  return index !== null ? filterGroup.children[index] ?? null : null;
}

//marketing activity

export function initMarketingActivityWithFilter(
  filter: PROPERTY_FILTER_ID | CHILD_FILTER_TYPE,
  connector?: CONNECTOR
): DynamicListChildL2 {
  return {
    type: TYPE.EXPRESSION,
    filter_type: FILTER_TYPE.MARKETING_ACTIVITY,
    filter,
    property: null,
    operator: null,
    value: [""],
    connector: connector ?? CONNECTOR.AND,
  };
}

// website tracking activity

export function initWebsiteActivity({
  filter,
  connector,
}: {
  filter?: string;
  connector?: CONNECTOR;
}): DynamicListChildL2 {
  return {
    type: TYPE.EXPRESSION,
    filter_type: FILTER_TYPE.WEBSITE_ACTIVITY,
    filter: filter ?? "",
    property: null,
    operator: null,
    value: [""],
    connector: connector ?? CONNECTOR.AND,
  };
}

export function getWebActivityLevelOneFilterIndices(
  data: DynamicListChild
): PropertyGroupIndices {
  let propertyGroup: PropertyGroupIndices = {
    timeFrameIndex: null,
    srcOfVisitIndex: null,
  };
  if (isEmpty(data.children)) return propertyGroup;

  for (let i = 0; i < data.children.length; i++) {
    switch (data.children[i].filter) {
      case CHILD_FILTER_TYPE.TIME_FRAME:
        propertyGroup.timeFrameIndex = i;
        break;
      case CHILD_FILTER_TYPE.VISITOR_SOURCE:
        propertyGroup.srcOfVisitIndex = i;
        break;
    }
    if (
      propertyGroup.timeFrameIndex !== null &&
      propertyGroup.srcOfVisitIndex !== null
    ) {
      break;
    }
  }
  return propertyGroup;
}

export function findWebsiteActivity(
  websiteActivityEventList: WebsiteActivityFilter[] | null,
  activitySelected: string | null
) {
  return websiteActivityEventList && activitySelected
    ? websiteActivityEventList?.find(({ id }) => id === activitySelected) ??
        null
    : null;
}

export function getWebActivityFilters(
  websiteActivityEventList: WebsiteActivityFilter[] | null,
  activitySelected: string,
  level: number
) {
  // filters that can be applied to the activity chosen , at particular level
  return findWebsiteActivity(
    websiteActivityEventList,
    activitySelected
  )?.arguments.filter(({ display_level }) => display_level === level);
}

export function getWebActivityArgumentList(
  websiteActivityEventList: WebsiteActivityFilter[] | null,
  activitySelected: string | null
) {
  return (
    findWebsiteActivity(
      websiteActivityEventList,
      activitySelected
    )?.arguments.map((arg) => arg.id) ?? null
  );
}
