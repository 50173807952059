import {
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Divider,
  Stack,
  useBoolean,
} from "@chakra-ui/react";
import { cloneDeep, isArray } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RxPlus } from "react-icons/rx";
import { useSelector } from "react-redux";
import { FilterGroupBox } from "./FilterGroupBox";
import { RiMailCheckLine } from "react-icons/ri";
import {
  CAMPAIGN_LIST_INIT,
  FILTER_TYPE,
  PROPERTY_FILTER_ID,
  TEMPLATE_LIST_INIT,
  VALUE_ALL,
  CHILD_FILTER_TYPE,
} from "../../../../../common/constants/campaign";
import {
  getOperatorDetails,
  isFulfilled,
  isInit,
  isLoading,
} from "../../../../../common/helper/commonHelper";
import {
  DynamicListChild,
  DynamicListChildL1,
  CONNECTOR,
  ValueTypes,
  DynamicListChildL2,
  OperatorType,
  TYPE,
} from "../../../../../common/types/campaign";
import {
  MarketingActivityArgument,
  MarketingActivity,
} from "../../../../../common/types/person";
import { StringOperatorValueFilter } from "../../../../../components/dynamic-list/CommonFilterRows";
import { DynamicListContext } from "../../../../../components/dynamic-list/DynamicList";
import {
  ValueSelectFields,
  DynamicListValueFields,
} from "../../../../../components/dynamic-list/DynamicListValueFields";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";
import IButton from "../../../../../components/IButton";
import { useAppDispatch } from "../../../../../store";
import { validateFilterGroup } from "../../helper/validationHelper";
import { selectCampaign } from "../../campaignSlice";
import {
  selectDynamicList,
  getMarketingActivities,
  getTemplatesOfCampaign,
  getFormDetails,
  listAllCampaigns,
} from "../../../../../components/dynamic-list/dynamicListSlice";
import { listAllForms, selectForm } from "../../../form/formSlice";
import { getFormDetailsApi } from "../../../../../common/api/integrations/form";
import { AllFields } from "../../../../../common/types/form";
import {
  FORM_FIELD_PROPERTY_GROUP_CHILD,
  FORM_IS_NOT_OPERATOR,
  FORM_IS_OPERATOR,
} from "../../../../../common/constants/form";

const IS_OPERATOR = "asset_is_equal";

const templateRow: DynamicListChildL2 = {
  type: TYPE.EXPRESSION,
  filter_type: FILTER_TYPE.MARKETING_ACTIVITY,
  filter: PROPERTY_FILTER_ID.TEMPLATE,
  property: null,
  operator: null,
  value: [""],
  connector: CONNECTOR.AND,
};

function PropertyGroupRow({
  data,
  onChange,
  argumentData,
  argumentSelectList,
  isReadOnly,
  isListLoading,
}: {
  data: DynamicListChildL2;
  onChange: (data: DynamicListChildL2) => void;
  argumentData?: MarketingActivityArgument;
  argumentSelectList?: { label: string; value: string }[];
  isReadOnly?: boolean;
  isListLoading?: boolean;
}) {
  const { activeErrorCheck } = useSelector(selectCampaign);

  const {
    operators: { data: operators },
  } = useSelector(selectDynamicList);

  const operatorDetails = useMemo(
    () => getOperatorDetails(data.operator, operators, argumentData?.type),
    [data.operator, operators, argumentData]
  );

  function setOperator(operator: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.operator = operator;
    dataCopy.value = [];
    dataCopy.validation_error = "";
    onChange(dataCopy);
  }

  function handleValueChange(val: ValueTypes) {
    const dataCopy = cloneDeep(data);
    if (
      operatorDetails?.arguments_types?.length &&
      val.length &&
      val.some((x) => x === "")
    ) {
      dataCopy.validation_error = "Invalid values";
    } else {
      dataCopy.validation_error = "";
    }
    dataCopy.value = val;
    onChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        p: "0",
        spacing: "1",
        color: "text.50",
        fontWeight: "600",
        fontSize: "12px",
      }
    : {
        spacing: "3",
        py: "2",
        px: "3",
        gridGap: "2",
      };

  const passArgumentSelectList = [
    IS_OPERATOR,
    FORM_IS_NOT_OPERATOR,
    FORM_IS_OPERATOR,
  ].includes(data.operator ?? "");

  if (argumentData) {
    return (
      <HStack alignItems="center" wrap="wrap" {...wrapperStyle}>
        <Text fontSize={isReadOnly ? "12px" : "sm"} textAlign="right">
          {argumentData.display}
        </Text>

        {operators && (
          <Box>
            <ValueSelectFields
              options={Object.values(operators[argumentData.type]).map(
                (op) => ({
                  label: op.display,
                  value: op.id,
                })
              )}
              value={(data.operator as string) || ""}
              onChange={setOperator}
              validationError={activeErrorCheck ? data.validation_error : ""}
              isReadOnly={isReadOnly}
            />
          </Box>
        )}
        {data.operator && operatorDetails && (
          <HStack>
            <DynamicListValueFields
              value={data.value}
              onChange={handleValueChange}
              argumentTypes={operatorDetails.arguments_types}
              argumentSelectList={
                passArgumentSelectList ? argumentSelectList : undefined
              }
              filter={data.filter as PROPERTY_FILTER_ID}
              noOfArguments={operatorDetails.arguments}
              helperText={operatorDetails.display_2}
              validationError={activeErrorCheck ? data.validation_error : ""}
              isReadOnly={isReadOnly}
              isLoading={isListLoading}
            />
          </HStack>
        )}
      </HStack>
    );
  }

  return <></>;
}

function convertToLabelValue(list: AllFields) {
  return Object.values(list).map((x) => {
    return { label: x.source.display, value: x.source.name };
  });
}

function PropertyGroup({
  mainFilter,
  data,
  onRemove,
  onChange,
  isReadOnly,
}: {
  mainFilter: ValueTypes | null;
  data: DynamicListChildL1;
  onRemove: () => void;
  onChange: (data: DynamicListChildL1) => void;
  isReadOnly?: boolean;
}) {
  const dispatch = useAppDispatch();

  const { campaignDetails } = useSelector(selectCampaign);
  const { allFormsSummary } = useSelector(selectForm);
  const { formDetailsList, totalCampaignList } = useSelector(selectDynamicList);

  const {
    marketingEventNamesList: { data: marketingEventNamesList },
    templateListOfCampaign,
  } = useSelector(selectDynamicList);

  const [templateList, setTemplateList] =
    useState<{ label: string; value: string }[]>(TEMPLATE_LIST_INIT);
  const [formFieldsList, setFormsFieldsList] = useState<
    { label: string; value: string }[]
  >([]);
  const [fetchingFormFields, setFetchingFormFields] = useBoolean(false);

  const campaignFilter = useMemo(
    () =>
      data.children?.find(
        (child) => child.filter === PROPERTY_FILTER_ID.CAMPAIGN
      ),
    [data.children]
  );
  const templateFilter = useMemo(
    () =>
      data.children?.find(
        (child) => child.filter === PROPERTY_FILTER_ID.TEMPLATE
      ),
    [data.children]
  );
  const reasonFilter = useMemo(
    () =>
      data.children?.find(
        (child) => child.filter === PROPERTY_FILTER_ID.REASON
      ),
    [data.children]
  );
  const formFilter = useMemo(
    () =>
      data.children?.find((child) => child.filter === PROPERTY_FILTER_ID.FORM),
    [data.children]
  );
  const formFieldFilter = useMemo(
    () =>
      data.children?.find(
        (child) => child.filter === PROPERTY_FILTER_ID.FORM_FIELD
      ),
    [data.children]
  );

  const getFormFields = useCallback(
    async (formId: string) => {
      setFetchingFormFields.on();
      const res = await dispatch(getFormDetails(formId));
      setFetchingFormFields.off();
      if (isFulfilled(res.meta.requestStatus)) {
        const payload = res.payload as Awaited<
          ReturnType<typeof getFormDetailsApi>
        >;
        const fields = convertToLabelValue(payload.form.all_fields);
        setFormsFieldsList(fields);
      }
    },
    [dispatch, setFetchingFormFields]
  );

  const findFormFields = useCallback(
    (formId: string) => {
      if (formDetailsList[formId]?.data?.all_fields) {
        setFormsFieldsList(
          convertToLabelValue(
            formDetailsList[formId].data?.all_fields as AllFields
          )
        );
      }
    },
    [formDetailsList]
  );

  useEffect(() => {
    if (formDetailsList) {
      const formFilterProperty = data.children?.find(
        (x) => x.operator === FORM_IS_OPERATOR
      );
      if (formFilterProperty?.value?.length) {
        findFormFields(formFilterProperty?.value?.[0] as string);
      }
    }
  }, [data.children, findFormFields, formDetailsList]);

  useEffect(() => {
    const campaignId = campaignFilter?.value[0] as string;
    if (
      campaignFilter?.operator === IS_OPERATOR &&
      campaignId &&
      campaignId !== VALUE_ALL
    ) {
      const templates =
        templateListOfCampaign[campaignId]?.data.map((template) => {
          return {
            value: template.template_id,
            label: template.name,
          };
        }) ?? [];
      setTemplateList([...TEMPLATE_LIST_INIT, ...templates]);
    }
  }, [templateListOfCampaign, campaignFilter?.value, campaignFilter?.operator]);

  const campaignList: { label: string; value: string }[] = useMemo(() => {
    const data = totalCampaignList.data
      .filter(
        (item) =>
          campaignDetails.data.campaign_id !== item.campaign_id &&
          (item.activated_at || item.deactivated_at)
      )
      .map((item) => {
        return {
          label: item.name,
          value: item.campaign_id,
        };
      });
    return [...CAMPAIGN_LIST_INIT, ...data];
  }, [campaignDetails.data.campaign_id, totalCampaignList]);

  useEffect(() => {
    if (isInit(allFormsSummary.loading)) {
      dispatch(listAllForms());
    }
  }, [dispatch, allFormsSummary.loading]);

  const formsList = useMemo(
    () =>
      allFormsSummary.data.map((form) => {
        return { label: form.name, value: form.form_id };
      }),
    [allFormsSummary]
  );

  useEffect(() => {
    dispatch(listAllCampaigns());
  }, [dispatch]);

  useEffect(() => {
    const campaignId = campaignFilter?.value[0] as string;
    if (
      campaignFilter?.operator === IS_OPERATOR &&
      campaignId &&
      campaignId !== VALUE_ALL
    )
      dispatch(getTemplatesOfCampaign(campaignId as string));
  }, [dispatch, campaignFilter?.value, campaignFilter?.operator]);

  function onChangeHandler(
    newData: DynamicListChildL2,
    type: PROPERTY_FILTER_ID
  ) {
    const dataCopy = cloneDeep(data);
    if (dataCopy.children) {
      const index = dataCopy.children.findIndex(
        (child) => child.filter === type
      );
      if (index !== undefined) {
        dataCopy.children[index] = newData;
        if (type === PROPERTY_FILTER_ID.CAMPAIGN) {
          const templateFilterIndex = dataCopy.children.findIndex(
            (child) => child.filter === PROPERTY_FILTER_ID.TEMPLATE
          );
          if (templateFilterIndex !== undefined) {
            dataCopy.children[templateFilterIndex] = templateRow;
          }
        }

        if (type === PROPERTY_FILTER_ID.FORM) {
          const formFieldFilterIndex = dataCopy.children.findIndex(
            (child) => child.filter === PROPERTY_FILTER_ID.FORM_FIELD
          );

          if (
            dataCopy.children[index].operator === FORM_IS_NOT_OPERATOR ||
            formFieldFilterIndex > -1
          ) {
            // remove form field filter if form filter is updated
            dataCopy.children = [dataCopy.children[index]];
          }
        }
      }
      onChange(dataCopy);
    }
  }

  useEffect(() => {
    const formId = data.children?.[0].value?.[0] as string;
    const isFormOperator = data.children?.[0].operator === FORM_IS_OPERATOR;
    const hasFormFieldRow = data.children?.[1];
    if (
      isFormOperator &&
      formId &&
      hasFormFieldRow &&
      !formDetailsList[formId]
    ) {
      getFormFields(formId as string);
    }
  }, [data.children, formDetailsList, getFormFields]);

  function addFormFieldFilter() {
    const dataCopy = cloneDeep(data);

    if (dataCopy.children) {
      // if form id filter and form is operator is selected fetch form fields
      if (dataCopy.children[0].value?.[0]) {
        getFormFields(dataCopy.children?.[0].value?.[0] as string);
      }
      dataCopy.children.push(FORM_FIELD_PROPERTY_GROUP_CHILD);
    }
    onChange(dataCopy);
  }

  function onRemoveFormFieldFilterGroup() {
    const dataCopy = cloneDeep(data);
    const formFieldFilterIndex =
      dataCopy.children?.findIndex(
        (child) => child.filter === PROPERTY_FILTER_ID.FORM_FIELD
      ) ?? -1;
    if (formFieldFilterIndex > -1) {
      dataCopy.children?.splice(formFieldFilterIndex, 1);
    }
    onChange(dataCopy);
  }

  function getArgumentDetails(type: string) {
    return mainFilter
      ? marketingEventNamesList
          ?.find((x) => x.id === mainFilter[0])
          ?.arguments.find((x) => x.id === type)
      : undefined;
  }

  return (
    <Flex w="100%" flexWrap="nowrap" justifyContent="space-between">
      <VStack spacing="0" w="100%" alignItems="flex-start">
        {campaignFilter && (
          <PropertyGroupRow
            onChange={(data) =>
              onChangeHandler(data, PROPERTY_FILTER_ID.CAMPAIGN)
            }
            data={campaignFilter}
            argumentData={getArgumentDetails(PROPERTY_FILTER_ID.CAMPAIGN)}
            argumentSelectList={campaignList}
            isReadOnly={isReadOnly}
          />
        )}
        {templateFilter && (
          <PropertyGroupRow
            onChange={(data) =>
              onChangeHandler(data, PROPERTY_FILTER_ID.TEMPLATE)
            }
            data={templateFilter}
            argumentData={getArgumentDetails(PROPERTY_FILTER_ID.TEMPLATE)}
            argumentSelectList={templateList}
            isReadOnly={isReadOnly}
          />
        )}
        {reasonFilter && (
          <PropertyGroupRow
            onChange={(data) =>
              onChangeHandler(data, PROPERTY_FILTER_ID.REASON)
            }
            data={reasonFilter}
            argumentData={getArgumentDetails(PROPERTY_FILTER_ID.REASON)}
            isReadOnly={isReadOnly}
          />
        )}
        {formFilter && (
          <HStack>
            <PropertyGroupRow
              onChange={(data) =>
                onChangeHandler(data, PROPERTY_FILTER_ID.FORM)
              }
              data={formFilter}
              argumentData={getArgumentDetails(PROPERTY_FILTER_ID.FORM)}
              argumentSelectList={formsList}
              isListLoading={isLoading(allFormsSummary.loading)}
              isReadOnly={isReadOnly}
            />
            {formFilter.operator === FORM_IS_OPERATOR &&
              formFilter.value?.length &&
              !formFieldFilter &&
              !isReadOnly && (
                <IButton
                  size="sm"
                  variant="link"
                  leftIcon={<RxPlus fontSize="12px" />}
                  onClick={addFormFieldFilter}
                  color="brand.blue"
                  fontWeight="normal"
                >
                  Add form field
                </IButton>
              )}
          </HStack>
        )}
        {formFieldFilter && (
          <FormFieldPropertyGroupRow
            onChange={(data) =>
              onChangeHandler(data, PROPERTY_FILTER_ID.FORM_FIELD)
            }
            data={formFieldFilter}
            argumentData={getArgumentDetails(PROPERTY_FILTER_ID.FORM_FIELD)}
            argumentSelectList={formFieldsList}
            isListLoading={fetchingFormFields}
            isReadOnly={isReadOnly}
            onRemove={onRemoveFormFieldFilterGroup}
          />
        )}
      </VStack>
      {!isReadOnly && <RemoveRowCloseButton mt={2} onClick={onRemove} />}
    </Flex>
  );
}

const OPERATOR_KEY = "operator";
const PROPERTY_KEY = "property";

function FormFieldPropertyGroupRow({
  data,
  onChange,
  argumentData,
  argumentSelectList,
  isReadOnly,
  isListLoading = false,
  onRemove,
}: {
  data: DynamicListChildL2;
  onChange: (data: DynamicListChildL2) => void;
  argumentData?: MarketingActivityArgument;
  argumentSelectList?: { label: string; value: string }[];
  isReadOnly?: boolean;
  isListLoading?: boolean;
  onRemove: () => void;
}) {
  const {
    operators: { data: operators },
  } = useSelector(selectDynamicList);

  const { activeErrorCheck } = useContext(DynamicListContext);

  const operatorDetails = useMemo(
    () => getOperatorDetails(data.operator, operators, argumentData?.type),
    [data.operator, operators, argumentData]
  );

  function setPropertyValue(
    key: typeof OPERATOR_KEY | typeof PROPERTY_KEY,
    val: string
  ) {
    const dataCopy = cloneDeep(data);
    dataCopy[key] = val;
    dataCopy.value = [];
    dataCopy.validation_error = "";
    onChange(dataCopy);
  }

  function handleValueChange(val: ValueTypes) {
    const dataCopy = cloneDeep(data);
    if (
      operatorDetails?.arguments_types?.length &&
      val.length &&
      val.some((x) => x === "")
    ) {
      dataCopy.validation_error = "Invalid values";
    } else {
      dataCopy.validation_error = "";
    }
    dataCopy.value = val;
    onChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        p: "0",
        spacing: "1",
      }
    : {
        spacing: "3",
        py: "2",
        px: "3",
        gridGap: "2",
      };

  if (argumentData) {
    return (
      <HStack alignItems="flex-start" wrap="wrap" {...wrapperStyle}>
        {isReadOnly ? (
          <Text fontSize="sm" textAlign="right">
            {argumentData.display}
          </Text>
        ) : (
          <HStack h="33px">
            <Text fontSize="sm" textAlign="right">
              {`...${argumentData.display}...`}
            </Text>
          </HStack>
        )}

        <Box>
          <ValueSelectFields
            options={argumentSelectList ?? []}
            value={data.property || ""}
            onChange={(val) => setPropertyValue(PROPERTY_KEY, val)}
            validationError={activeErrorCheck ? data.validation_error : ""}
            isReadOnly={isReadOnly}
          />
        </Box>

        {operators && data.property && (
          <HStack>
            <ValueSelectFields
              options={Object.values(operators[OperatorType.STRING]).map(
                (op) => ({
                  label: op.display,
                  value: op.id,
                })
              )}
              value={(data.operator as string) || ""}
              onChange={(val) => setPropertyValue(OPERATOR_KEY, val)}
              validationError={activeErrorCheck ? data.validation_error : ""}
              isReadOnly={isReadOnly}
            />
          </HStack>
        )}
        <HStack alignItems="flex-start" wrap="wrap">
          {data.operator && operatorDetails && (
            <HStack>
              <DynamicListValueFields
                value={data.value}
                onChange={handleValueChange}
                argumentTypes={operatorDetails.arguments_types}
                argumentSelectList={undefined}
                filter={data.filter as PROPERTY_FILTER_ID}
                noOfArguments={operatorDetails.arguments}
                helperText={operatorDetails.display_2}
                validationError={activeErrorCheck ? data.validation_error : ""}
                isReadOnly={isReadOnly}
                isLoading={isListLoading}
              />
            </HStack>
          )}

          {!isReadOnly && <RemoveRowCloseButton onClick={onRemove} />}
        </HStack>
      </HStack>
    );
  }

  return <></>;
}

function ActivitySelector({
  marketingActivityList,
  onChange,
  value,
  validationError,
  isReadOnly,
}: {
  marketingActivityList: MarketingActivity[];
  onChange: (value: string) => void;
  value: string | null;
  validationError?: string;
  isReadOnly?: boolean;
}) {
  return (
    <HStack alignItems="center" spacing="3">
      {!isReadOnly && <Text fontSize="sm">Marketing event</Text>}
      <Box id="marketing-activity-selector">
        <ValueSelectFields
          options={marketingActivityList?.map((x) => ({
            label: x.display,
            value: x.id,
          }))}
          value={value || ""}
          onChange={onChange}
          validationError={validationError}
          isReadOnly={isReadOnly}
        />
      </Box>
    </HStack>
  );
}

function PropertyGroupContainer({
  data,
  onChange,
  isReadOnly,
}: {
  data: DynamicListChildL1[];
  onChange: (data: DynamicListChildL1[]) => void;
  isReadOnly?: boolean;
}) {
  function onRemovePropertyGroup(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.splice(index, 1);
    onChange(dataCopy);
  }

  function onChangePropertyGroup(
    updateData: DynamicListChildL1,
    index: number
  ) {
    const dataCopy = cloneDeep(data);
    dataCopy[index] = updateData;
    onChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        px: "0",
        py: "0",
        spacing: "1",
        ml: "0px !important",
      }
    : {
        px: "12px",
        ml: "12px !important",
        borderLeftWidth: "1px",
        borderLeftColor: "grayV2.500",
      };

  return (
    <VStack
      alignItems="left"
      {...wrapperStyle}
      mt={!isReadOnly && data.length ? "12px !important" : "0px !important"}
    >
      {data.map(
        (item, index) =>
          item.type === TYPE.GROUP && (
            <>
              {index === 1 && !isReadOnly && (
                <Text fontSize="12px" color="brand.blue">
                  where all the properties apply
                </Text>
              )}
              <PropertyGroup
                mainFilter={data[0].value}
                data={item}
                onRemove={() => onRemovePropertyGroup(index)}
                onChange={(data) => onChangePropertyGroup(data, index)}
                isReadOnly={isReadOnly}
              />
            </>
          )
      )}
    </VStack>
  );
}

export default function MarketingEventFilterGroup({
  data,
  id,
  onChange,
  onRemove,
  isReadOnly,
}: {
  data: DynamicListChild;
  id: string;
  onChange: (data: any) => void;
  onRemove: () => void;
  isReadOnly?: boolean;
}) {
  const {
    marketingEventNamesList,
    operators: { data: operators },
    operatorsArgCount,
  } = useSelector(selectDynamicList);
  const { activeErrorCheck } = useContext(DynamicListContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMarketingActivities());
  }, [dispatch]);

  function onActivityChange(value: string) {
    let dataCopy = cloneDeep(data);
    // reset data in property groups when filter group activity changes
    if (dataCopy.children[0].value?.[0] !== value) {
      dataCopy.children = dataCopy.children.filter(
        (child: DynamicListChildL1) => child.type !== TYPE.GROUP
      );
    }
    dataCopy.children[0].value = [value];
    if (value) {
      dataCopy.children[0].validation_error = "";
    }
    onChange(dataCopy);
  }

  function onRemoveRow(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.children = dataCopy.children.filter((_, i) => i !== index);
    onChange(dataCopy);
  }

  function onAddLinkFilter() {
    const dataCopy = cloneDeep(data);
    dataCopy.children.splice(1, 0, {
      type: TYPE.EXPRESSION,
      filter_type: FILTER_TYPE.MARKETING_ACTIVITY,
      filter: CHILD_FILTER_TYPE.LINK_CLICKED,
      property: null,
      operator: null,
      value: [""],
      connector: CONNECTOR.AND,
    });
    onChange(dataCopy);
  }

  function onFilterDataChange(
    field: string,
    index: number,
    value: string | number | CONNECTOR | ValueTypes
  ) {
    const dataCopy = cloneDeep(data);
    switch (field) {
      case "operator":
        dataCopy.children[index].operator = value as string;
        dataCopy.children[index].value = [];
        break;
      case "value":
        dataCopy.children[index].value = value as ValueTypes;
        break;
    }

    const { filterGroup } = validateFilterGroup(
      dataCopy,
      false,
      operatorsArgCount
    );
    onChange(filterGroup);
  }

  const linkFilterRowIndex: number | null = useMemo(() => {
    const hasLinkFilter = isArray(data.children)
      ? data.children.findIndex(
          (child) => child.filter === CHILD_FILTER_TYPE.LINK_CLICKED
        )
      : null;
    return hasLinkFilter && hasLinkFilter >= 0 ? hasLinkFilter : null;
  }, [data]);

  function onPropertyGroupChange(newData: DynamicListChildL1[]) {
    const dataCopy = cloneDeep(data);
    dataCopy.children = newData;
    onChange(dataCopy);
  }

  function onAddPropertyGroup() {
    const dataCopy = cloneDeep(data);
    const newPropertyGroup: DynamicListChildL1 = {
      type: TYPE.GROUP,
      name: "",
      filter_type: null,
      filter: null,
      property: null,
      operator: null,
      value: null,
      children: [
        {
          type: TYPE.EXPRESSION,
          filter_type: FILTER_TYPE.MARKETING_ACTIVITY,
          filter: PROPERTY_FILTER_ID.CAMPAIGN,
          property: null,
          operator: null,
          value: [""],
          connector: CONNECTOR.AND,
        },
      ],
      connector: CONNECTOR.AND,
    };
    const templatePropertyGroupChild: DynamicListChildL2 = {
      type: TYPE.EXPRESSION,
      filter_type: FILTER_TYPE.MARKETING_ACTIVITY,
      filter: PROPERTY_FILTER_ID.TEMPLATE,
      property: null,
      operator: null,
      value: [""],
      connector: CONNECTOR.AND,
    };
    const reasonPropertyGroupChild: DynamicListChildL2 = {
      type: TYPE.EXPRESSION,
      filter_type: FILTER_TYPE.MARKETING_ACTIVITY,
      filter: PROPERTY_FILTER_ID.REASON,
      property: null,
      operator: null,
      value: [""],
      connector: CONNECTOR.AND,
    };
    const formPropertyGroupChild: DynamicListChildL2 = {
      type: TYPE.EXPRESSION,
      filter_type: FILTER_TYPE.MARKETING_ACTIVITY,
      filter: PROPERTY_FILTER_ID.FORM,
      property: null,
      operator: null,
      value: [""],
      connector: CONNECTOR.EMPTY,
    };
    if (getEventList()?.includes(PROPERTY_FILTER_ID.TEMPLATE)) {
      newPropertyGroup?.children?.push(templatePropertyGroupChild);
    }
    if (getEventList()?.includes(PROPERTY_FILTER_ID.REASON)) {
      newPropertyGroup?.children?.push(reasonPropertyGroupChild);
    }
    if (getEventList()?.includes(PROPERTY_FILTER_ID.FORM)) {
      newPropertyGroup.children = [formPropertyGroupChild];
    }

    dataCopy.children.push(newPropertyGroup);
    onChange(dataCopy);
  }

  function getEventList() {
    return data.children[0].value?.[0]
      ? marketingEventNamesList.data
          ?.find((x) => x.id === data.children[0].value?.[0])
          ?.arguments.map((x) => x.id)
      : [];
  }

  const wrapperStyle = isReadOnly
    ? {
        px: "0",
        py: "0",
        spacing: "1",
      }
    : {
        px: "3",
        py: "3",
        spacing: "0",
      };

  const isGroupAvailable = (data: DynamicListChildL1[]) => {
    return data.filter((item) => item.type === TYPE.GROUP).length > 0;
  };

  // Hiding add property group button only for forms
  const FORM_SUBMITTED_EVENT = "form_submitted";
  const isFormActivityFilter =
    data.children?.[0]?.value?.[0] === FORM_SUBMITTED_EVENT;
  const isFormPropertyExist = isFormActivityFilter && data.children?.length > 1;

  return (
    <FilterGroupBox
      id={id}
      onRemove={onRemove}
      icon={RiMailCheckLine}
      isReadOnly={isReadOnly}
      rounded="lg"
      borderColor="grayV2.200"
      borderWidth="1px"
      bg="grayV2.200"
      hideRemove={true}
    >
      <VStack alignItems="flex-start" {...wrapperStyle}>
        {data.children && (
          <>
            <HStack
              width="100%"
              wrap="wrap"
              gridGap={isReadOnly ? undefined : "2"}
            >
              <ActivitySelector
                marketingActivityList={marketingEventNamesList.data || []}
                value={data.children[0].value?.[0] as string}
                onChange={(value) => onActivityChange(value)}
                validationError={
                  activeErrorCheck ? data.children[0].validation_error : ""
                }
                isReadOnly={isReadOnly}
              />
              {!isReadOnly && !isGroupAvailable(data.children) && (
                <IButton
                  size="sm"
                  variant="link"
                  leftIcon={<RxPlus fontSize="12px" />}
                  onClick={onAddPropertyGroup}
                  isDisabled={!data.children?.[0]?.value?.[0]}
                  color="brand.blue"
                  fontWeight="normal"
                >
                  Add property
                </IButton>
              )}
              {!isReadOnly && (
                <RemoveRowCloseButton onClick={onRemove}></RemoveRowCloseButton>
              )}

              {!isReadOnly &&
                getEventList()?.includes(CHILD_FILTER_TYPE.LINK_CLICKED) &&
                !linkFilterRowIndex && (
                  <IButton
                    hidden={!data.children?.[0]?.value?.[0]}
                    size="sm"
                    leftIcon={<RxPlus fontSize="12px" />}
                    onClick={onAddLinkFilter}
                    variant="link"
                    fontWeight="normal"
                  >
                    link
                  </IButton>
                )}
            </HStack>

            {getEventList()?.includes(CHILD_FILTER_TYPE.LINK_CLICKED) &&
              linkFilterRowIndex &&
              operators && (
                <>
                  {!isReadOnly && <Divider />}
                  <StringOperatorValueFilter
                    filter={CHILD_FILTER_TYPE.LINK_CLICKED}
                    stringOperators={operators[OperatorType.STRING]}
                    setOperator={(value) =>
                      onFilterDataChange("operator", linkFilterRowIndex, value)
                    }
                    operator={data.children[linkFilterRowIndex]?.operator}
                    value={
                      data.children[linkFilterRowIndex]?.value as ValueTypes
                    }
                    setValue={(value) =>
                      onFilterDataChange("value", linkFilterRowIndex, value)
                    }
                    onRemoveRow={() => onRemoveRow(linkFilterRowIndex)}
                    validationError={
                      activeErrorCheck
                        ? data.children[linkFilterRowIndex]?.validation_error
                        : ""
                    }
                    isReadOnly={isReadOnly}
                  />
                </>
              )}
            {isReadOnly ? (
              <Stack direction="row" position="relative">
                <Divider
                  orientation="vertical"
                  h="100%"
                  ml="-16px"
                  position="absolute"
                  borderColor="brandBlue.500"
                ></Divider>
                <PropertyGroupContainer
                  data={data.children}
                  onChange={onPropertyGroupChange}
                  isReadOnly={isReadOnly}
                />
              </Stack>
            ) : (
              <PropertyGroupContainer
                data={data.children}
                onChange={onPropertyGroupChange}
                isReadOnly={isReadOnly}
              />
            )}
            {!isReadOnly && isGroupAvailable(data.children) && (
              <IButton
                size="sm"
                variant="link"
                leftIcon={<RxPlus fontSize="12px" />}
                onClick={onAddPropertyGroup}
                isDisabled={!data.children?.[0]?.value?.[0]}
                color="brand.blue"
                ml="24px !important"
                mt="8px !important"
                fontWeight="normal"
                hidden={isFormPropertyExist}
              >
                Add additional property
              </IButton>
            )}
          </>
        )}
      </VStack>
    </FilterGroupBox>
  );
}
