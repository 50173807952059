import { Box } from "@chakra-ui/react";
import { Flex, VStack } from "@chakra-ui/layout";
import { createContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  DynamicListType,
  CONNECTOR,
  TYPE,
  CAMPAIGN_CONTEXT,
} from "../../common/types/campaign";
import DynamicListLogicGate from "./DynamicListLogicGate";
import { cloneDeep, isEmpty } from "lodash";
import {
  FILTER_TYPE,
  NEW_FILTER_GROUP,
  NEW_OUTER_GROUP,
} from "../../common/constants/campaign";
import { createGroupId, isInit } from "../../common/helper/commonHelper";
import DynamicListSideBar from "./DynamicListSideBar";
import { useAppDispatch } from "../../store";
import OuterGroup from "./OuterGroup";
import AddOuterGroupButton from "./AddOuterGroupButton";
import SpinnerContainer from "../SpinnerContainer";
import ISkeleton, { SKELETON_VARIANT } from "../ISkeleton";
import {
  getEventKeys,
  getEventNames,
  getFilterList,
  getMarketingActivities,
  getWebsiteTrackingActivities,
  getOperators,
  selectDynamicList,
} from "./dynamicListSlice";

export const DynamicListContext = createContext({
  activeErrorCheck: false,
  campaignContext: CAMPAIGN_CONTEXT.PERSON,
});

export default function DynamicList({
  dynamicListData,
  onChange,
  sidebar = true,
  activeErrorCheck = false,
  readOnlyMode = false,
  campaignContext = CAMPAIGN_CONTEXT.PERSON,
}: {
  dynamicListData: DynamicListType[];
  onChange: (data: DynamicListType[]) => void;
  sidebar?: boolean;
  activeErrorCheck?: boolean;
  readOnlyMode?: boolean;
  campaignContext?: CAMPAIGN_CONTEXT;
}) {
  const dispatch = useAppDispatch();

  const productEventsFetched = useRef(false);

  const {
    productEventKeysList,
    productEventNamesList,
    filterList,
    filterListLoading,
  } = useSelector(selectDynamicList);

  useEffect(() => {
    if (dynamicListData && !productEventsFetched.current) {
      // Fetch product_event_properties list for all product_event_names in the campaign dynamiclist get
      // create an array of event names in the product activity filter group
      const eventNamesArray: string[][] = dynamicListData.map((condition) => {
        if (condition.type === TYPE.GROUP) {
          const eventNames: (string | undefined)[] = condition.children.map(
            (child) => {
              if (child.type === TYPE.GROUP) {
                const filterGroupRow = child.children.find(
                  (ch) => ch.filter_type === FILTER_TYPE.PRODUCT_ACTIVITY
                );
                return filterGroupRow?.value?.[0] as string | undefined;
              }
              return "";
            }
          );
          return eventNames.filter((x) => x) as string[];
        }
        return [];
      });
      const eventKeysAlreadyFetched = Object.keys(
        productEventKeysList.data || {}
      );
      const eventKeysToBeFetched = eventNamesArray
        .flat()
        .filter((event) => !eventKeysAlreadyFetched.includes(event));

      if (!isEmpty(eventKeysToBeFetched)) {
        dispatch(
          getEventKeys({
            max_result: 1000,
            entity: FILTER_TYPE.PERSON,
            event_names: eventKeysToBeFetched,
          })
        );
      }
      productEventsFetched.current = true;
    }
  }, [dynamicListData, dispatch, productEventKeysList.data]);

  useEffect(() => {
    if (!productEventNamesList.data && isInit(productEventNamesList.loading)) {
      const data = {
        max_result: 10000,
        entity: FILTER_TYPE.PERSON,
      };
      dispatch(getEventNames(data));
    }
  }, [dispatch, productEventNamesList]);

  useEffect(() => {
    const personFilterList = filterList[campaignContext]?.[FILTER_TYPE.PERSON];
    const productActivitiesFilterList =
      filterList[campaignContext]?.[FILTER_TYPE.PRODUCT_ACTIVITY];
    if (!personFilterList || isInit(personFilterList.loading)) {
      dispatch(
        getFilterList({
          filterType: FILTER_TYPE.PERSON,
          campaignContext: campaignContext ?? CAMPAIGN_CONTEXT.PERSON,
        })
      );
    }
    if (
      !productActivitiesFilterList ||
      isInit(productActivitiesFilterList.loading)
    ) {
      dispatch(
        getFilterList({
          filterType: FILTER_TYPE.PRODUCT_ACTIVITY,
          campaignContext: campaignContext ?? CAMPAIGN_CONTEXT.PERSON,
        })
      );
    }
  }, [dispatch, filterList, campaignContext]);

  useEffect(() => {
    dispatch(getOperators());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMarketingActivities());
    dispatch(getWebsiteTrackingActivities());
  }, [dispatch]);

  function onAddOuterGroup(type: FILTER_TYPE) {
    const dataCopy = cloneDeep(dynamicListData);
    const outerGroup = cloneDeep(NEW_OUTER_GROUP);
    dataCopy.push({
      ...outerGroup,
      // operator is not null for org aggregate supported outer group
      operator: type === FILTER_TYPE.ORG_MEMBERSHIP ? "" : null,
      children: NEW_FILTER_GROUP[type] ? [NEW_FILTER_GROUP[type]] : [],
    });
    onChange(dataCopy);
  }

  function onRemoveOuterGroup(index: number) {
    let dataCopy = cloneDeep(dynamicListData);
    dataCopy = dataCopy.filter((_, i) => i !== index);
    onChange(dataCopy);
  }

  function setConnector(connector: CONNECTOR, index: number) {
    const dataCopy = cloneDeep(dynamicListData);
    dataCopy[index].connector = connector;
    onChange(dataCopy);
  }

  function onOuterGroupChange(index: number, newData: DynamicListType) {
    const dataCopy = cloneDeep(dynamicListData);
    dataCopy[index] = newData;
    onChange(dataCopy);
  }

  return (
    <>
      {readOnlyMode ? (
        <VStack alignItems="flex-start" width="100%">
          <ISkeleton
            variant={SKELETON_VARIANT.TEXT}
            isLoaded={!filterListLoading}
          >
            {dynamicListData.length ? (
              <>
                {dynamicListData.map((group, index) => {
                  return (
                    <Box key={index} w="100%" mt="0px !important">
                      {group.connector && index !== 0 && (
                        <DynamicListLogicGate
                          operator={group.connector}
                          handleChange={(connector) =>
                            setConnector(connector, index)
                          }
                          isReadOnly={readOnlyMode}
                        />
                      )}
                      <DynamicListContext.Provider
                        value={{
                          activeErrorCheck: activeErrorCheck,
                          campaignContext,
                        }}
                      >
                        <OuterGroup
                          onChange={(newData) =>
                            onOuterGroupChange(index, newData)
                          }
                          data={group}
                          label={
                            group.name ? group.name : `Outer group ${index + 1}`
                          }
                          id={createGroupId("", "outer", index + 1)}
                          onRemove={() => onRemoveOuterGroup(index)}
                          isReadOnly={readOnlyMode}
                          campaignContext={campaignContext}
                          group={group}
                        />
                      </DynamicListContext.Provider>
                    </Box>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </ISkeleton>
        </VStack>
      ) : (
        <SpinnerContainer loading={!!filterListLoading}>
          <Flex
            width="100%"
            flexDir={{ base: "column", md: "row" }}
            height="100%"
          >
            {sidebar && (
              <DynamicListSideBar
                dynamicListData={dynamicListData}
                campaignContext={campaignContext}
              />
            )}
            <VStack
              spacing="3"
              alignItems="flex-start"
              width="100%"
              overflowY="auto"
              pr={0}
              pl={sidebar ? 3 : 0}
              fontSize="16px"
              css={{ scrollBehavior: "smooth" }}
            >
              {dynamicListData.length ? (
                <>
                  {dynamicListData.map((group, index) => {
                    return (
                      <Box key={index} w="100%">
                        {group.connector && index !== 0 && (
                          <DynamicListLogicGate
                            operator={group.connector}
                            handleChange={(connector) =>
                              setConnector(connector, index)
                            }
                          />
                        )}
                        <DynamicListContext.Provider
                          value={{
                            activeErrorCheck: activeErrorCheck,
                            campaignContext,
                          }}
                        >
                          <OuterGroup
                            onChange={(newData) =>
                              onOuterGroupChange(index, newData)
                            }
                            data={group}
                            label={
                              group.name
                                ? group.name
                                : `Outer group ${index + 1}`
                            }
                            id={createGroupId("", "outer", index + 1)}
                            onRemove={() => onRemoveOuterGroup(index)}
                            campaignContext={campaignContext}
                            group={group}
                          />
                        </DynamicListContext.Provider>
                      </Box>
                    );
                  })}
                  <Box pt="5">
                    <AddOuterGroupButton
                      onClick={onAddOuterGroup}
                      campaignContext={campaignContext}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <AddOuterGroupButton
                    onClick={onAddOuterGroup}
                    campaignContext={campaignContext}
                  />
                </>
              )}
            </VStack>
          </Flex>
        </SpinnerContainer>
      )}
    </>
  );
}
