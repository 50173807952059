import { chakra, VisuallyHidden } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Formik, FormikProps } from "formik";
import EmailConfigureInput from "../../../../components/EmailConfigureInput";
import {
  emailValidation,
  requiredString,
} from "../../../../common/helper/validationHelper";
import { object } from "yup";
import {
  selectSettings,
  createGlobalEmailConfig,
  updateGlobalEmailConfig,
} from "../settingsSlice";
import { EmailConfigurationType } from "../../../../common/types/campaign";
import IButton, { BUTTON } from "../../../../components/IButton";
import { GLOBAL_DEFAULT_INIT } from "../../../../common/constants/template";

const GlobalEmailConfiguration = forwardRef(
  ({
    parentRef,
    validate,
    showSave,
  }: {
    parentRef?: React.MutableRefObject<any>;
    validate: boolean;
    showSave?: boolean;
  }) => {
    const dispatch = useDispatch();
    const { globalDefault } = useSelector(selectSettings);
    const formRef = useRef<FormikProps<EmailConfigurationType>>(null);
    const [activeErrorCheck, setActiveErrorCheck] = useState(false);
    const submitButton = useRef(null);

    const submit = useCallback(() => {
      const values = formRef.current?.values;

      if (values) {
        if (globalDefault.data) {
          dispatch(updateGlobalEmailConfig(values));
        } else {
          dispatch(createGlobalEmailConfig(values));
        }
      }
    }, [dispatch, globalDefault.data]);

    useImperativeHandle(
      parentRef,
      () => ({
        formRef,
      }),
      [formRef]
    );

    const globalDefaultFormSchema = object().shape({
      from_email: object().shape({
        name: requiredString("Sender name"),
        email: emailValidation,
      }),
      reply_to: emailValidation,
    });

    return (
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={globalDefault.data ?? GLOBAL_DEFAULT_INIT}
        onSubmit={submit}
        validateOnChange={validate || activeErrorCheck}
        validateOnBlur={validate || activeErrorCheck}
        validationSchema={globalDefaultFormSchema}
      >
        {(form) => (
          <chakra.form
            id="settings-form"
            onSubmit={form.handleSubmit}
            width="full"
          >
            <EmailConfigureInput
              inputs={form.values}
              onChange={form.handleChange}
              errors={form.errors as EmailConfigurationType}
              inputPaddingX="0"
              flexDirection="column"
              width="100%"
              subjectLess={true}
            />
            <VisuallyHidden
              width="full"
              justifyContent="flex-start"
              hidden={!showSave}
            >
              <IButton
                variant={BUTTON.PRIMARY}
                ref={submitButton}
                type="submit"
                isDisabled={!form.isValid}
                onClick={() => setActiveErrorCheck(true)}
                isLoading={globalDefault.updating}
                fontSize={14}
                name="save-configuration"
              >
                Save configurations
              </IButton>
            </VisuallyHidden>
          </chakra.form>
        )}
      </Formik>
    );
  }
);

export default GlobalEmailConfiguration;
