import { HStack, Text, ButtonProps, Flex, StackProps } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import React, { useMemo } from "react";
import {
  isBlank,
  isArgumentMany,
} from "../../../../../common/helper/commonHelper";
import { OperatorDetails } from "../../../../../common/types/campaign";
import { ValueSelectFields } from "../../../../../components/dynamic-list/DynamicListValueFields";
import InputFormControl from "../../../../../components/dynamic-list/InputFormControl";
import {
  COMPARISON_OPERATORS,
  MAP_TIME_UNIT,
  TIME_FRAME_RELATIVE_OPERATOR,
  TIME_UNIT_LABEL,
  TRIGGER_DL_TIME_FRAME_OPERATORS,
} from "../../../../../common/constants/dynamicList";
import {
  DlTimeFrameOperators,
  FrequencyCondition,
  OperatorDetailsV2,
  TargetValueTypes,
  TimeframeCondition,
} from "../../../../../common/types/dynamicList";
import AudienceCriteriaValueFields from "./AudienceCriteriaValueFields";
import IButton from "../../../../../components/IButton";
import { RxPlus } from "react-icons/rx";
import {
  TIME_FRAME_TYPE,
  TIME_FRAME_UNIT,
} from "../../../../../common/constants/trigger";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";

const END_DAY = " 11:59:59";
const START_DAY = " 00:00:00";

function getDateString(epoch: number | null) {
  //0 should be passed as null as well
  return epoch ? new Date(epoch).toLocaleDateString("en-CA") : "";
}

export const FrequencySelectorV2 = React.memo(
  ({
    frequencyCondition,
    onFrequencyChange,
    numberOperators,
    activeErrorCheck,
    isReadOnly,
    buttonProps,
  }: {
    frequencyCondition: FrequencyCondition | null;
    onFrequencyChange: (value: FrequencyCondition | null) => void;
    numberOperators: { [operator: string]: OperatorDetails };
    activeErrorCheck: boolean;
    isReadOnly?: boolean;
    buttonProps?: ButtonProps;
  }) => {
    const operatorDetails: OperatorDetails | null = useMemo(
      () =>
        frequencyCondition?.comparisonOperator
          ? numberOperators[frequencyCondition?.comparisonOperator] ?? null
          : null,
      [frequencyCondition?.comparisonOperator, numberOperators]
    );

    function onChangeComparisonOperator(operator: COMPARISON_OPERATORS | null) {
      if (frequencyCondition?.comparisonOperator !== operator) {
        onFrequencyChange({
          comparisonOperator: operator,
          targetValue: [],
        });
      }
    }
    function onAddFrequency() {
      const frequency: FrequencyCondition = {
        comparisonOperator: COMPARISON_OPERATORS.EQUALS,
        targetValue: [0],
      };
      onFrequencyChange(frequency);
    }
    function onTargetValueChange(value: string | number, index: number) {
      let dataCopy = cloneDeep(frequencyCondition);

      const updatedValue = isBlank(value) ? null : Number(value);
      if (dataCopy) {
        if (dataCopy.targetValue) {
          dataCopy.targetValue[index] = updatedValue;
        } else {
          dataCopy.targetValue = [];
          dataCopy.targetValue[index] = updatedValue;
        }
        onFrequencyChange(dataCopy);
      }
    }

    function onManyArgChange(value: TargetValueTypes) {
      const dataCopy = cloneDeep(frequencyCondition);

      if (dataCopy) {
        dataCopy.targetValue =
          value?.map((val) => (isBlank(val) ? null : Number(val))) ?? null;
        onFrequencyChange(dataCopy);
      }
    }

    const wrapperStyle = isReadOnly
      ? {
          spacing: "1",
        }
      : {
          flex: "1",
          spacing: "3",
        };

    const targetValue = frequencyCondition?.targetValue ?? [];
    const isInvalidOperator =
      activeErrorCheck && !frequencyCondition?.comparisonOperator;

    function onRemoveFrequency() {
      onFrequencyChange(null);
    }

    return (
      <HStack alignItems="flex-start">
        {frequencyCondition ? (
          <HStack alignItems="flex-start" {...wrapperStyle}>
            <ValueSelectFields
              options={Object.values(numberOperators).map((op) => ({
                value: op.id,
                label: op.display,
              }))}
              value={frequencyCondition?.comparisonOperator || ""}
              onChange={(value) =>
                onChangeComparisonOperator(value as COMPARISON_OPERATORS)
              }
              isReadOnly={isReadOnly}
              validationError={isInvalidOperator ? "Invalid operator" : ""}
            />
            {(operatorDetails?.arguments === "1" ||
              operatorDetails?.arguments === "2") && (
              <InputFormControl
                width="100px"
                type="number"
                name="first-argument"
                value={targetValue[0] ?? ""}
                onChange={(event) => onTargetValueChange(event.target.value, 0)}
                isReadOnly={isReadOnly}
                validationMessage={
                  activeErrorCheck && isBlank(targetValue[0])
                    ? "Invalid value"
                    : ""
                }
              />
            )}

            {operatorDetails?.arguments === "2" && (
              <HStack>
                <Text>and</Text>
                <InputFormControl
                  width="100px"
                  name="second-argument"
                  type="number"
                  value={targetValue[1] ?? ""}
                  onChange={(event) =>
                    onTargetValueChange(event.target.value, 1)
                  }
                  isReadOnly={isReadOnly}
                  validationMessage={
                    activeErrorCheck && isBlank(targetValue[1])
                      ? "Invalid value"
                      : ""
                  }
                />
              </HStack>
            )}

            {operatorDetails?.arguments &&
              !isArgumentMany(operatorDetails?.arguments) && (
                <Text fontSize="14px" alignSelf="center">
                  times
                </Text>
              )}

            {operatorDetails && isArgumentMany(operatorDetails?.arguments) && (
              <AudienceCriteriaValueFields
                value={
                  frequencyCondition?.targetValue?.map(
                    (val) => val?.toString() ?? ""
                  ) ?? []
                }
                onChange={onManyArgChange}
                argumentTypes={operatorDetails.arguments_types}
                helperText={operatorDetails.display_2}
                noOfArguments={operatorDetails.arguments}
                isReadOnly={isReadOnly}
                activeErrorCheck={activeErrorCheck}
              />
            )}
          </HStack>
        ) : (
          !isReadOnly && (
            <IButton
              {...buttonProps}
              leftIcon={<RxPlus fontSize="12px" />}
              onClick={onAddFrequency}
              variant="link"
              color="brand.blue"
              mt="5px !important"
              fontWeight="normal"
            >
              Add frequency
            </IButton>
          )
        )}
        {!isReadOnly && frequencyCondition && (
          <RemoveRowCloseButton onClick={onRemoveFrequency} />
        )}
      </HStack>
    );
  }
);

export function TimeFrameSelectorV2({
  timeFrame,
  onChangeTimeFrame,
  dateOperators = TRIGGER_DL_TIME_FRAME_OPERATORS,
  activeErrorCheck,
  showRemoveButton,
  validationError,
  isReadOnly,
  isDisabled,
}: {
  timeFrame: TimeframeCondition;
  onChangeTimeFrame: (timeFrame: TimeframeCondition | null) => void;
  dateOperators?: {
    [operator: string]: OperatorDetailsV2;
  };
  activeErrorCheck: boolean;
  showRemoveButton?: boolean;
  validationError?: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}) {
  const timeFrameOperator = useMemo(
    () =>
      timeFrame.type === TIME_FRAME_TYPE.RELATIVE &&
      timeFrame.operator === COMPARISON_OPERATORS.BETWEEN_INCLUSIVE
        ? TIME_FRAME_RELATIVE_OPERATOR.BETWEEN
        : timeFrame.operator,
    [timeFrame.operator, timeFrame.type]
  );

  const operatorDetails: OperatorDetailsV2 | null = useMemo(
    () => (timeFrameOperator ? dateOperators[timeFrameOperator] ?? null : null),
    [timeFrameOperator, dateOperators]
  );

  function onChangeOperator(op: DlTimeFrameOperators) {
    const dataCopy = cloneDeep(timeFrame);
    dataCopy.operator =
      op === TIME_FRAME_RELATIVE_OPERATOR.BETWEEN
        ? COMPARISON_OPERATORS.BETWEEN_INCLUSIVE
        : op;
    dataCopy.type = dateOperators[op].timeFrameType ?? null;
    dataCopy.start = null;
    dataCopy.end = null;
    dataCopy.unit = null;

    onChangeTimeFrame(dataCopy);
  }

  function onChangeDateValue(value: TargetValueTypes) {
    const dataCopy = cloneDeep(timeFrame);
    const startDateEpoch = value?.[0]
      ? new Date(value[0] + START_DAY).valueOf()
      : null;
    const endDateEpoch = value?.[0]
      ? new Date(value[0] + END_DAY).valueOf()
      : null;

    if (dataCopy.type === TIME_FRAME_TYPE.ABSOLUTE) {
      // for absolute the unit is always epoch
      dataCopy.unit = TIME_FRAME_UNIT.EPOCH;

      switch (dataCopy.operator) {
        case COMPARISON_OPERATORS.EQUALS:
        case COMPARISON_OPERATORS.NOT_EQUALS:
          dataCopy.start = startDateEpoch;
          dataCopy.end = endDateEpoch;
          break;

        case COMPARISON_OPERATORS.GT:
          dataCopy.start = endDateEpoch;
          dataCopy.end = null;
          break;

        case COMPARISON_OPERATORS.GTE:
          dataCopy.start = startDateEpoch;
          dataCopy.end = null;
          break;

        case COMPARISON_OPERATORS.LT:
          dataCopy.end = startDateEpoch;
          dataCopy.start = null;
          break;

        case COMPARISON_OPERATORS.LTE:
          dataCopy.end = endDateEpoch;
          dataCopy.start = null;
          break;

        case COMPARISON_OPERATORS.BETWEEN_INCLUSIVE:
        case COMPARISON_OPERATORS.NOT_BETWEEN_INCLUSIVE:
          dataCopy.start = value?.[0] ? new Date(value[0]).valueOf() : null;
          dataCopy.end = value?.[1] ? new Date(value[1]).valueOf() : null;
          break;
      }
    } else if (timeFrame?.type === TIME_FRAME_TYPE.RELATIVE) {
      switch (dataCopy.operator) {
        case COMPARISON_OPERATORS.BETWEEN_INCLUSIVE:
          dataCopy.start = value?.[0] ? Number(value[0]) : null;
          dataCopy.end = value?.[1] ? Number(value[1]) : null;
          dataCopy.unit = value?.[2] ? MAP_TIME_UNIT[value[2] as string] : null;
          break;
        case TIME_FRAME_RELATIVE_OPERATOR.PAST:
          dataCopy.start = value?.[0] ? Number(value[0]) : null;
          dataCopy.end = null;
          dataCopy.unit = value?.[1] ? MAP_TIME_UNIT[value[1] as string] : null;
          break;
      }
    }

    onChangeTimeFrame(dataCopy);
  }

  function onRemoveTimeFrame() {
    onChangeTimeFrame(null);
  }

  function getTargetValueFromTimeFrame(): string[] {
    if (timeFrame.type === TIME_FRAME_TYPE.ABSOLUTE) {
      switch (timeFrame.operator) {
        case COMPARISON_OPERATORS.EQUALS:
        case COMPARISON_OPERATORS.NOT_EQUALS:
        case COMPARISON_OPERATORS.GT:
        case COMPARISON_OPERATORS.GTE:
          return [getDateString(timeFrame.start)];
        case COMPARISON_OPERATORS.LT:
        case COMPARISON_OPERATORS.LTE:
          return [getDateString(timeFrame.end)];
        case COMPARISON_OPERATORS.BETWEEN_INCLUSIVE:
        case COMPARISON_OPERATORS.NOT_BETWEEN_INCLUSIVE:
          return [getDateString(timeFrame.start), getDateString(timeFrame.end)];
        default:
          return [];
      }
    } else if (timeFrame.type === TIME_FRAME_TYPE.RELATIVE) {
      const unit = timeFrame.unit ? TIME_UNIT_LABEL[timeFrame.unit] : "";
      switch (timeFrame.operator) {
        case COMPARISON_OPERATORS.BETWEEN_INCLUSIVE:
          return [
            timeFrame.start?.toString() ?? "",
            timeFrame.end?.toString() ?? "",
            unit,
          ];

        case TIME_FRAME_RELATIVE_OPERATOR.PAST:
          return [timeFrame.start?.toString() ?? "", unit];
        default:
          return [];
      }
    }
    return [];
  }

  const wrapperStyle: StackProps = isReadOnly
    ? {
        spacing: "1",
      }
    : {
        spacing: "3",
        gridGap: "1",
      };

  return (
    <HStack alignItems="flex-start" width="100%" wrap="wrap" {...wrapperStyle}>
      {isReadOnly ? (
        <Text fontSize="sm">during the time frame</Text>
      ) : (
        <Text fontSize="sm">...during the time frame...</Text>
      )}

      <ValueSelectFields
        options={Object.values(dateOperators).map((op) => ({
          value: op.id,
          label: op.display,
        }))}
        validationError={validationError}
        value={timeFrameOperator || ""}
        onChange={(val) => onChangeOperator(val as DlTimeFrameOperators)}
        isReadOnly={isReadOnly}
      />

      {operatorDetails && (
        <AudienceCriteriaValueFields
          value={getTargetValueFromTimeFrame()}
          onChange={onChangeDateValue}
          noOfArguments={operatorDetails.arguments}
          argumentTypes={operatorDetails.arguments_types}
          helperText={operatorDetails.display_2}
          activeErrorCheck={activeErrorCheck}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
        />
      )}
      {!isReadOnly && showRemoveButton && (
        <Flex flex={1} alignSelf="end">
          <RemoveRowCloseButton onClick={onRemoveTimeFrame} />
        </Flex>
      )}
    </HStack>
  );
}
