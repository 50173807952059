import {
  Input,
  FormHelperText,
  Box,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";

export default function InputFormControl({
  type,
  value,
  onChange,
  helperText,
  width,
  name,
  validationMessage,
  isReadOnly,
  isDisabled,
}: {
  type: "text" | "number" | "date";
  value: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  helperText?: string;
  width?: string;
  validationMessage?: string;
  name?: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}) {
  if (isReadOnly) {
    return <Text>{value}</Text>;
  } else {
    return (
      <Box>
        <Input
          width={width}
          bg="white"
          rounded="md"
          size="sm"
          type={type}
          value={value}
          onChange={onChange}
          isInvalid={!!validationMessage}
          name={name}
          isDisabled={isDisabled}
        />
        {helperText && !validationMessage && (
          <FormHelperText pos="fixed" fontSize="xs">
            {helperText}
          </FormHelperText>
        )}
        <FormErrorMessage pos="fixed" fontSize="xs">
          {validationMessage}
        </FormErrorMessage>
      </Box>
    );
  }
}
