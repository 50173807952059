import {
  FormControl,
  FormLabel,
  Switch,
  VisuallyHidden,
} from "@chakra-ui/react";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Formik, FormikProps } from "formik";
import { urlValidation } from "../../../../common/helper/validationHelper";
import { boolean, object, string } from "yup";
import {
  selectSettings,
  getUnsubUrl,
  resetLoadingStateUnsubUrl,
  updateUnsubUrl,
  createUnsubUrl,
  deleteUnsubUrl,
} from "../settingsSlice";
import { isInit, isLoading } from "../../../../common/helper/commonHelper";
import SubHeader from "./SubHeader";
import IButton, { BUTTON } from "../../../../components/IButton";
import InputFieldWithError from "../../../../components/InputFieldWithError";
import { UnsubUrlForm } from "../../../../common/types/settings";

let InitialState: UnsubUrlForm = {
  url: "",
  customUrl: false,
};

const UnsubscribeUrl = forwardRef(
  ({
    parentRef,
    validate,
    showSave,
  }: {
    parentRef?: React.MutableRefObject<any>;
    validate: boolean;
    showSave?: boolean;
  }) => {
    const dispatch = useDispatch();
    const { unsubUrl } = useSelector(selectSettings);
    const formRef = useRef<FormikProps<UnsubUrlForm>>(null);
    const [activeErrorCheck, setActiveErrorCheck] = useState(false);
    const submitButton = useRef(null);

    const submit = useCallback(() => {
      const values = formRef.current?.values;
      if (values) {
        if (values.customUrl) {
          if (unsubUrl.url) {
            dispatch(updateUnsubUrl(values.url));
          } else {
            dispatch(createUnsubUrl(values.url));
          }
        } else {
          dispatch(deleteUnsubUrl());
        }
      }
    }, [dispatch, unsubUrl.url]);

    useImperativeHandle(
      parentRef,
      () => ({
        formRef,
      }),
      [formRef]
    );

    useEffect(() => {
      if (isInit(unsubUrl.fetching)) {
        dispatch(getUnsubUrl());
      }
      return () => {
        dispatch(resetLoadingStateUnsubUrl);
      };
    }, [dispatch, unsubUrl.fetching]);

    const unsubscribeUrlSchema = object().shape({
      url: string().when("customUrl", {
        is: true,
        then: urlValidation(),
        otherwise: string(),
      }),
      customUrl: boolean().required(),
    });

    return (
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={
          unsubUrl?.url
            ? { url: unsubUrl.url, customUrl: !!unsubUrl.url }
            : InitialState
        }
        onSubmit={submit}
        validateOnChange={validate || activeErrorCheck}
        validateOnBlur={validate || activeErrorCheck}
        validationSchema={unsubscribeUrlSchema}
      >
        {(form) => (
          <form id="unsub-url-form" onSubmit={form.handleSubmit}>
            <SubHeader
              title="Email opt-out configuration page"
              desc="Give a link to where unsubscribed contacts should be directed to"
            >
              <FormControl id="customUrl" display="flex" alignItems="center">
                <Switch
                  size="sm"
                  name="customUrl"
                  onChange={form.handleChange}
                  isChecked={form.values?.customUrl}
                />
                <FormLabel
                  htmlFor="customUrl"
                  mb="0"
                  ml="2"
                  fontSize="12px"
                  fontWeight="semibold"
                >
                  Use custom configuration page URL
                </FormLabel>
              </FormControl>
              {form.values.customUrl && (
                <InputFieldWithError
                  name="url"
                  labelText="URL"
                  value={form.values.url}
                  errorMsg={form.errors.url}
                  touched={!!form.touched.url}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  fontSize={14}
                  capitalize={false}
                  formLabelProps={{ color: "gray.500", fontSize: "xs" }}
                />
              )}
              <VisuallyHidden
                width="full"
                justifyContent="flex-start"
                hidden={!showSave}
              >
                <IButton
                  variant={BUTTON.PRIMARY}
                  ref={submitButton}
                  type="submit"
                  isDisabled={!form.isValid || !form.dirty}
                  onClick={() => setActiveErrorCheck(true)}
                  isLoading={isLoading(unsubUrl.updating)}
                  name="save-unsub-url"
                  fontSize="16px"
                >
                  Save URL
                </IButton>
              </VisuallyHidden>
              )
            </SubHeader>
          </form>
        )}
      </Formik>
    );
  }
);

export default UnsubscribeUrl;
