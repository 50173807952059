import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { debounce, isEmpty } from "lodash";
import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  memo,
  useContext,
} from "react";
import { FaCog, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { NodeProps } from "reactflow";
import { FLOW_ACTIONS } from "../../../../../../common/constants/campaign";
import { isValidToken } from "../../../../../../common/helper/codemirrorHelper";
import {
  isLoading,
  openAssetInNewTab,
  validateEmail,
} from "../../../../../../common/helper/commonHelper";
import { SendInternalEmailActionOptions } from "../../../../../../common/types/campaign";
import { ActionNodeArgs } from "../../../../../../common/types/flow";
import DropdownWithSearch from "../../../../../../components/DropdownWithSearch";
import IButton from "../../../../../../components/IButton";
import IModal from "../../../../../../components/IModal";
import MultiEmailInput from "../../../../../../components/MultiEmailInput";
import { selectSettings } from "../../../../settings/settingsSlice";
import {
  listAllTemplates,
  selectTemplate,
} from "../../../../templates/templateSlice";
import {
  listAllEmailTokens,
  selectEmailToken,
} from "../../../../emailtoken/emailTokenSlice";
import { WIDGET_OPTIONS_DETAILS } from "../constants";
import { isActionDataSame } from "../helpers";
import WidgetContainer from "../WidgetContainer";
import { useAppDispatch } from "../../../../../../store";
import {
  CampaignBuilderContext,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";
import { AssetPreviewComponent } from "../../../../../../components/OptionHelper";
import { ReactSelectDefaultOptionsWithClick } from "../../../../../../common/types/common";
import { ASSET_TYPES } from "../../../../../../common/constants/common";

const optionDetails = WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.SEND_INTERNAL_EMAIL];

function EmailAlertWidget({
  data: { action, groupId, isCandidate, props, selectedExit, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const { saveDraft, setActions, readonly } = props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);
  const actionOptions = useMemo(
    () => action.action_options as SendInternalEmailActionOptions,
    [action]
  );
  const {
    globalDefault: { data: globalDefault },
  } = useSelector(selectSettings);
  const {
    fullTemplateList: { data: templateList, loading: fetchingTemplateList },
  } = useSelector(selectTemplate);

  const {
    allColumnsList: { data: columnsList },
    emailTokenList: {
      listAll: { data: tokensList },
    },
  } = useSelector(selectEmailToken);

  const { flowValidity } = useSelector(selectFlow);

  const dispatch = useAppDispatch();
  const { activeErrorCheck } = useContext(CampaignBuilderContext);

  const [internalEmails, setInternalEmails] = useState<string[]>(
    actionOptions.internal_emails ?? []
  );
  const [value, setValue] = useState("");
  const [errors, setErrors] = useState({
    template_id: "",
    internal_emails: "",
  });

  const { isOpen, onClose, onOpen } = useDisclosure();

  const setValidityCallback = useCallback(
    (valid: boolean) => {
      dispatch(setFlowValidity({ [identities.actionId]: valid }));
    },
    [dispatch, identities.actionId]
  );

  const debouncedSave = useMemo(() => debounce(saveDraft, 2000), [saveDraft]);

  useEffect(() => {
    const validity = !!(
      actionOptions.template_id && actionOptions.internal_emails.length
    );
    setValidityCallback(validity);
  }, [actionOptions, setValidityCallback]);

  useEffect(() => {
    dispatch(listAllTemplates());
    dispatch(listAllEmailTokens());
  }, [dispatch]);

  function setOptions(options: SendInternalEmailActionOptions) {
    setActions(options, identities.actionId, groupId);
    debouncedSave(options, { actionId: identities.actionId, groupId });
  }

  function handleSelectChange(id: string | null) {
    setOptions({ ...actionOptions, template_id: id });
    if (!id) {
      setErrors({ ...errors, template_id: "Select an email asset" });
    } else {
      setErrors({ ...errors, template_id: "" });
    }
    setValidityCallback(!!id);
  }

  const handleEmailChangeInternal = useCallback(
    (
      emails: string[],
      updateAction: (
        options: SendInternalEmailActionOptions,
        validity: boolean
      ) => void
    ) => {
      if (!isEmpty(emails)) {
        updateAction({ ...actionOptions, internal_emails: [...emails] }, true);
        setErrors({ ...errors, internal_emails: "" });
        setValidityCallback(true);
      } else {
        updateAction({ ...actionOptions, internal_emails: [] }, false);
        setErrors({ ...errors, internal_emails: "Email should not be empty" });
        setValidityCallback(false);
      }
    },
    [actionOptions, errors, setValidityCallback]
  );

  useEffect(() => {
    setErrors((prev) => {
      return {
        ...prev,
        internal_emails: isEmpty(internalEmails)
          ? "Email should not be empty"
          : "",
      };
    });
  }, [internalEmails]);

  useEffect(() => {
    handleEmailChangeInternal(internalEmails, (value) =>
      setActions(value, identities.actionId, identities.groupId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      setInternalEmails(actionOptions.internal_emails);
    }
  }, [actionOptions.internal_emails, isOpen]);

  const templateListOptions: ReactSelectDefaultOptionsWithClick[] = useMemo(
    () =>
      templateList
        ? templateList
            .filter(
              (template) =>
                !template.is_empty &&
                (globalDefault ||
                  (template.subject &&
                    template.from_email &&
                    template.reply_to))
            )
            .map((template) => {
              return {
                label: template.name ?? "",
                value: template.template_id,
                onPreview: () =>
                  openAssetInNewTab(ASSET_TYPES.TEMPLATE, template.template_id),
              };
            })
        : [],
    [templateList, globalDefault]
  );

  function onEmailsSave() {
    let emails =
      value &&
      (isValidToken(value, tokensList, columnsList) || validateEmail(value))
        ? [...internalEmails, value]
        : internalEmails;

    handleEmailChangeInternal(emails, (value) =>
      setActions(value, identities.actionId, identities.groupId)
    );
    setOptions({ ...actionOptions, internal_emails: emails });
    onClose();
  }

  return (
    <WidgetContainer
      invalidMessage={
        flowValidity[identities.actionId] ? "" : "Fields are invalid"
      }
      identities={identities}
      title={optionDetails.label}
      icon={optionDetails.icon}
      color={optionDetails.color}
      isCandidate={isCandidate}
      selectedExit={selectedExit}
      selectedGoto={selectedGoto}
      isDisabled={readonly}
    >
      <VStack width="100%" px="5" py="6" spacing="4">
        <FormControl
          isInvalid={activeErrorCheck && actionOptions.template_id === ""}
        >
          <FormLabel color={"gray.500"} fontSize={"xs"}>
            Email template
          </FormLabel>
          <DropdownWithSearch
            options={templateListOptions}
            value={templateListOptions.find(
              (option) => option.value === actionOptions.template_id
            )}
            onChange={(option) => handleSelectChange(option?.value ?? null)}
            isInvalid={activeErrorCheck && !actionOptions.template_id}
            maxMenuHeight={300}
            isSearchable
            isDisabled={readonly}
            menuPortalTarget={null}
            isLoading={isLoading(fetchingTemplateList)}
            components={{
              Option: AssetPreviewComponent<ReactSelectDefaultOptionsWithClick>,
            }}
          />
          <FormErrorMessage>Select an email template</FormErrorMessage>
        </FormControl>
        {actionOptions.template_id && (
          <IButton
            variant="link"
            color={
              activeErrorCheck && isEmpty(actionOptions.internal_emails)
                ? "red.500"
                : "brand.blue"
            }
            alignSelf="start"
            leftIcon={
              actionOptions.internal_emails.length ? <FaCog /> : <FaPlus />
            }
            size="sm"
            onClick={onOpen}
          >
            {actionOptions.internal_emails.length
              ? `${readonly ? "View" : "Edit"} recipient list`
              : "Add recipients"}
          </IButton>
        )}
        <IModal
          header={{
            title: "Send email alerts",
          }}
          isOpen={isOpen}
          onClose={onClose}
          primaryButton={{
            label: "Save",
            props: { onClick: onEmailsSave, hidden: readonly },
          }}
          secondaryButton={{
            label: "Cancel",
            props: { onClick: onClose, hidden: readonly },
          }}
        >
          <FormControl isInvalid={activeErrorCheck && !!errors.internal_emails}>
            <FormLabel color={"gray.500"} fontSize={"xs"}>
              Send to
            </FormLabel>
            <MultiEmailInput
              emailList={internalEmails}
              setEmailList={setInternalEmails}
              setValue={setValue}
              errorMsg={
                activeErrorCheck && errors.internal_emails
                  ? errors.internal_emails
                  : undefined
              }
              allowTokens={true}
              isDisabled={readonly}
            />
          </FormControl>
        </IModal>
      </VStack>
    </WidgetContainer>
  );
}

export default memo(EmailAlertWidget, isActionDataSame);
