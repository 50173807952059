import {
  AudienceList,
  EventNamesReq,
  EventNamesResp,
  EventKeysResp,
  EventKeysReq,
  OperatorList,
  DynamicListType,
  DynamicListSummary,
  TableList,
  CAMPAIGN_CONTEXT,
  WebsiteActivityFilter,
} from "../../types/campaign";
import { PaginationDataV2, ListFilterParams } from "../../types/common";
import { ComputeOperatorList } from "../../types/flow";
import { MarketingActivity } from "../../types/person";
import { campaignRequest } from "./api";

type PaginatedStringSearch = PaginationDataV2<string>;

function getTrackedDomainSessionUrl(append: string = "") {
  return `/assets/trackedDomains/sessions${append ? "/" + append : ""}`;
}

export async function listAllDynamicListsApi(): Promise<{
  records: DynamicListSummary[];
}> {
  return campaignRequest({
    url: "/assets/dynamic-list/list.all",
    data: null,
  });
}

export async function getTableDescriptionApi(): Promise<TableList> {
  return campaignRequest({
    url: "/assets/dynamic-list/table.description",
    data: null,
    shouldRetryApiCall: true,
  });
}

export async function getFilterListApi(
  filterType: string,
  campaignContext: CAMPAIGN_CONTEXT
): Promise<TableList> {
  return campaignRequest({
    url: "/assets/dynamic-list/filter-type/filters.list",
    data: { filter_type: filterType, campaign_context: campaignContext },
    shouldRetryApiCall: true,
  });
}

export async function getDynamicListPreviewApi(
  dynamicList: DynamicListType[],
  searchKeyword: string,
  columnsToSearchIn: string[],
  campaignContext?: CAMPAIGN_CONTEXT
): Promise<{ audience_list: AudienceList }> {
  return campaignRequest({
    url: "/campaigns/dynamic-list/preview",
    data: {
      query: { conditions: dynamicList },
      campaign_context: campaignContext,
      search: {
        keyword: searchKeyword,
        fields: columnsToSearchIn,
      },
    },
  });
}

export async function getDynamicListCountApi(
  campaign_id: string
): Promise<{ count: number }> {
  return campaignRequest({
    url: "/campaigns/dynamic-list/count",
    data: { campaign_id },
  });
}

export async function getEventNamesApi(
  data: EventNamesReq
): Promise<EventNamesResp> {
  return campaignRequest({
    url: "/assets/dynamic-list/entity.events",
    data,
    shouldRetryApiCall: true,
  });
}

export async function getEventKeysApi(
  data: EventKeysReq
): Promise<EventKeysResp> {
  return campaignRequest({
    url: "/assets/dynamic-list/entity.event-properties",
    data,
  });
}

export async function getOperatorsApi(): Promise<OperatorList> {
  return campaignRequest({
    method: "GET",
    url: "/assets/dynamic-list/operators",
  });
}

export async function getMarketingActivitiesApi(): Promise<{
  marketing_activity: MarketingActivity[];
}> {
  return campaignRequest({
    url: "/assets/dynamic-list/marketing-activities",
    method: "GET",
    shouldRetryApiCall: true,
  });
}

//website tracking apis

export async function getWebsiteTrackingActivitiesApi(): Promise<{
  website_tracking_activities: WebsiteActivityFilter[];
}> {
  return campaignRequest({
    url: "/assets/dynamic-list/website-tracking-activities",
    method: "GET",
    shouldRetryApiCall: true,
  });
}

//custom parameters in page visit at tenant level
export async function getCustomParamsInPageVisitsApi({
  search,
  pageSize,
  page,
}: ListFilterParams): Promise<PaginatedStringSearch> {
  return campaignRequest({
    url: getTrackedDomainSessionUrl("custom_parameters"),
    params: {
      page_number: page,
      page_size: pageSize,
      search,
    },
    method: "GET",
  });
}

export async function getPageVisitUrlListApi({
  search,
  pageSize,
  page,
}: ListFilterParams): Promise<PaginatedStringSearch> {
  return campaignRequest({
    url: getTrackedDomainSessionUrl("page_visits/page_urls"),
    params: {
      page_number: page,
      page_size: pageSize,
      search,
    },
    method: "GET",
  });
}

export async function getReferrerListApi({
  search,
  pageSize,
  page,
}: ListFilterParams): Promise<PaginatedStringSearch> {
  return campaignRequest({
    url: getTrackedDomainSessionUrl("referrer_urls"),
    params: {
      page_number: page,
      page_size: pageSize,
      search,
    },
    method: "GET",
  });
}

export async function getComputeOperatorsApi(): Promise<ComputeOperatorList> {
  return campaignRequest({
    method: "GET",
    url: "/assets/dynamic-list/operators.compute",
  });
}

export async function getDlPreviewByDlIdApi(
  dynamicListId: string
): Promise<{ audience_list: AudienceList }> {
  return campaignRequest({
    method: "GET",
    url: `/campaigns/dynamic-list/${dynamicListId}/preview`,
  });
}

export async function getDlPreviewByCampaignIdApi(
  campaignId: string
): Promise<{ audience_list: AudienceList }> {
  return campaignRequest({
    method: "GET",
    url: `/campaigns/${campaignId}/dynamic-list/preview`,
  });
}
