import { defineStyleConfig } from "@chakra-ui/react";

const drawerStyle = defineStyleConfig({
  sizes: {
    "2xl": {
      dialog: {
        maxWidth: "1008px",
      },
    },
    "3xl": {
      dialog: {
        maxW: "1152px",
      },
    },
    "4xl": {
      dialog: {
        maxW: "1300px",
      },
    },
  },
});

export default drawerStyle;
