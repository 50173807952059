import { createColumnHelper } from "@tanstack/react-table";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Account } from "../../../common/types/account";
import { SearchInputAndCreateButton } from "../../../components/CommonListHeader";
import { DataTable } from "../../../components/data-table/DataTable";
import LayoutWithTable from "../../../layout/LayoutWithTable";
import { useAppDispatch } from "../../../store";
import urls from "../../../urls";
import NumberFormatHeader from "../../../components/NumberFormatHeader";
import {
  listAccounts,
  selectDirectoryAccount,
  setAccountPage,
} from "./accountDbSlice";
import { useSearch } from "../../../common/hooks/commonHooks";
import { getTableRowLinkProps } from "../../../common/helper/commonHelper";

const COLUMNS_TO_SEARCH_IN = ["id", "name"];

export default function Accounts() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accountList } = useSelector(selectDirectoryAccount);
  const [searchKeyword, setSearchKeyword] = useSearch();

  const columnHelper = createColumnHelper<Account>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "Account Id",
        size: 400,
      }),
      columnHelper.accessor("name", {
        header: "Account Name",
        size: 400,
      }),
      columnHelper.accessor("account_owner", {
        header: "Account Owner",
        size: 250,
      }),
      columnHelper.accessor("website", {
        header: "Website",
        size: 250,
      }),
    ],
    [columnHelper]
  );

  useEffect(() => {
    dispatch(
      listAccounts({ searchKeyword, columnsToSearchIn: COLUMNS_TO_SEARCH_IN })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accountList.currentPageNo]);

  const searchIfValid = useCallback(
    (searchKeyword: string) => {
      if (accountList.currentPageNo !== 1) {
        dispatch(setAccountPage(0));
      } else {
        dispatch(
          listAccounts({
            searchKeyword,
            columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
          })
        );
      }
    },
    [accountList.currentPageNo, dispatch]
  );

  const debouncedSearch = useMemo(
    () => debounce(searchIfValid, 1000),
    [searchIfValid]
  );

  function onSearchKeywordChange(text: string) {
    setSearchKeyword(text);
    debouncedSearch(text);
  }

  const openAccount = useCallback(
    (account: Account) => {
      navigate(`${urls.account}/${account.id}`);
    },
    [navigate]
  );

  return (
    <>
      <NumberFormatHeader
        count={accountList.count}
        heading="Accounts"
        countLabel="accounts"
      >
        <SearchInputAndCreateButton
          searchInputProps={{
            placeholder: "Search accounts",
            name: "search-input",
            value: searchKeyword,
            onSearch: onSearchKeywordChange,
          }}
        />
      </NumberFormatHeader>
      <LayoutWithTable>
        <DataTable
          fetchingList={accountList.fetchingList}
          changingPage={accountList.changingPage}
          list={accountList.list}
          totalPageCount={accountList.totalPageCount}
          currentPage={accountList.currentPageNo}
          totalPageSize={accountList.pageSize}
          onRowClick={openAccount}
          setPage={(pageNo) => dispatch(setAccountPage(pageNo))}
          columns={columns}
          emptyMsg={`No accounts found.${
            searchKeyword ? " Please change the search / filter values" : ""
          }`}
          getTableRowLinkProps={(data) =>
            getTableRowLinkProps({ to: urls.account, editParam: "id" }, data)
          }
        />
      </LayoutWithTable>
    </>
  );
}
