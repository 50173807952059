import {
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Divider,
  useMediaQuery,
  Stack,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import { FaDesktop } from "react-icons/fa";
import { useSelector } from "react-redux";
import { FILTER_TYPE } from "../../../../../common/constants/campaign";
import {
  createGroupId,
  getOperatorDetails,
  isInit,
  isLoading,
} from "../../../../../common/helper/commonHelper";
import {
  DynamicListChildL1,
  CONNECTOR,
  ValueTypes,
  DynamicListChildL2,
  OperatorType,
  DynamicListChild,
  TYPE,
} from "../../../../../common/types/campaign";
import AddPropertyButton from "../../../../../components/dynamic-list/AddPropertyButton";
import { DynamicListContext } from "../../../../../components/dynamic-list/DynamicList";
import {
  selectDynamicList,
  getEventKeys,
  getEventNames,
} from "../../../../../components/dynamic-list/dynamicListSlice";
import {
  ValueSelectFields,
  DynamicListValueFields,
} from "../../../../../components/dynamic-list/DynamicListValueFields";
import IButton from "../../../../../components/IButton";
import { useAppDispatch } from "../../../../../store";
import { selectCampaign } from "../../campaignSlice";
import { FilterGroupBox } from "./FilterGroupBox";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";
import { RxPlus } from "react-icons/rx";

function PropertyGroupRow({
  data,
  mainFilter,
  onChange,
  showRemoveButton,
  onRemove,
  showAddButton,
  onAddRow,
  isReadOnly,
}: {
  data: DynamicListChildL2;
  mainFilter: ValueTypes | null;
  onChange: (data: DynamicListChildL2) => void;
  showRemoveButton: boolean;
  onRemove: () => void;
  showAddButton: boolean;
  onAddRow: () => void;
  isReadOnly?: boolean;
}) {
  const { activeErrorCheck } = useSelector(selectCampaign);
  const {
    productEventKeysList: {
      data: productEventKeysList,
      loading: fetchingProductEventKeysList,
    },
    operators: { data: operators },
  } = useSelector(selectDynamicList);
  const [isSmallerThan1024] = useMediaQuery("(max-width: 1024px)");

  const operatorDetails = useMemo(
    () => getOperatorDetails(data.operator, operators, OperatorType.STRING),
    [data.operator, operators]
  );

  function setProperty(newProperty: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.property = newProperty;
    dataCopy.operator = null;
    dataCopy.value = [];
    dataCopy.validation_error = "";
    onChange(dataCopy);
  }

  function setOperator(operator: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.operator = operator;
    dataCopy.value = [];
    dataCopy.validation_error = "";
    onChange(dataCopy);
  }

  function handleValueChange(val: ValueTypes) {
    const dataCopy = cloneDeep(data);
    if (
      operatorDetails?.arguments_types?.length &&
      val.length &&
      val.some((x) => x === "")
    ) {
      dataCopy.validation_error = "Invalid values";
    } else {
      dataCopy.validation_error = "";
    }
    dataCopy.value = val;
    onChange(dataCopy);
  }

  function getEventList() {
    return mainFilter
      ? productEventKeysList[mainFilter[0] as string]?.map((x) => ({
          label: x,
          value: x,
        }))
      : [];
  }

  const wrapperStyle = isReadOnly
    ? {
        p: "0",
        spacing: "1",
      }
    : {
        spacing: "3",
        py: "2",
        px: "3",
        gridGap: "2",
      };

  return (
    <HStack alignItems="flex-start" width="100%" wrap="wrap" {...wrapperStyle}>
      <Box>
        <ValueSelectFields
          options={getEventList() ?? []}
          value={data.property || ""}
          onChange={setProperty}
          validationError={activeErrorCheck ? data.validation_error : ""}
          loading={isLoading(fetchingProductEventKeysList)}
          isReadOnly={isReadOnly}
        />
      </Box>

      {data.property && operators && (
        <Box>
          <ValueSelectFields
            options={Object.values(operators["string"]).map((op) => ({
              label: op.display,
              value: op.id,
            }))}
            value={(data.operator as string) || ""}
            onChange={setOperator}
            validationError={activeErrorCheck ? data.validation_error : ""}
            isReadOnly={isReadOnly}
          />
        </Box>
      )}

      {data.property && data.operator && operatorDetails && (
        <HStack>
          <DynamicListValueFields
            value={data.value}
            onChange={handleValueChange}
            argumentTypes={operatorDetails.arguments_types}
            helperText={operatorDetails.display_2}
            noOfArguments={operatorDetails.arguments}
            validationError={activeErrorCheck ? data.validation_error : ""}
            isReadOnly={isReadOnly}
          />
        </HStack>
      )}

      {!isReadOnly && showAddButton && data.operator && (
        <AddPropertyButton iconOnly={isSmallerThan1024} onClick={onAddRow} />
      )}

      {!isReadOnly && showRemoveButton && (
        <Flex flex="1" justifyContent="flex-end">
          <RemoveRowCloseButton onClick={onRemove} />
        </Flex>
      )}
    </HStack>
  );
}

function PropertyGroup({
  mainFilter,
  data,
  id,
  onRemove,
  onChange,
  isReadOnly,
}: {
  mainFilter: ValueTypes | null;
  data: DynamicListChildL1;
  label: string;
  id: string;
  onRemove: () => void;
  onChange: (data: DynamicListChildL1) => void;
  isReadOnly?: boolean;
}) {
  function addRow() {
    const newRow: DynamicListChildL2 = {
      type: TYPE.EXPRESSION,
      filter_type: FILTER_TYPE.PRODUCT_ACTIVITY,
      filter: "event_properties",
      property: "",
      operator: null,
      value: [""],
      connector: CONNECTOR.AND,
    };
    const dataCopy = cloneDeep(data);
    if (dataCopy.children) {
      dataCopy.children.push(newRow);
    } else {
      dataCopy.children = [newRow];
    }
    onChange(dataCopy);
  }

  function removeRow(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.children?.splice(index, 1);
    onChange(dataCopy);
  }

  function onChangeHandler(newData: DynamicListChildL2, index: number) {
    if (data.children) {
      const dataCopy = cloneDeep(data);
      (dataCopy.children as DynamicListChildL2[])[index] = newData;
      onChange(dataCopy);
    }
  }

  if (isReadOnly) {
    return (
      <VStack color="text.50" fontWeight="600" fontSize="12px" spacing="0">
        {data.children?.map((child: DynamicListChildL2, index: number) => {
          return (
            <PropertyGroupRow
              mainFilter={mainFilter}
              onRemove={() => removeRow(index)}
              onChange={(data) => onChangeHandler(data, index)}
              data={child}
              onAddRow={addRow}
              showAddButton={false}
              showRemoveButton={false}
              isReadOnly={isReadOnly}
            />
          );
        })}
      </VStack>
    );
  } else {
    return (
      <FilterGroupBox onRemove={onRemove} id={id} icon={FaDesktop}>
        <VStack divider={<Divider />} spacing="0">
          {data.children?.map((child: DynamicListChildL2, index: number) => {
            return (
              <PropertyGroupRow
                mainFilter={mainFilter}
                onRemove={() => removeRow(index)}
                onChange={(data) => onChangeHandler(data, index)}
                data={child}
                onAddRow={addRow}
                showAddButton={false}
                showRemoveButton={false}
              />
            );
          })}
        </VStack>
      </FilterGroupBox>
    );
  }
}

function PropertyGroupContainer({
  data,
  parentId,
  onChange,
  isReadOnly,
}: {
  data: DynamicListChildL1[];
  parentId: string;
  onChange: (data: DynamicListChildL1[]) => void;
  isReadOnly?: boolean;
}) {
  function onRemovePropertyGroup(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.splice(index, 1);
    onChange(dataCopy);
  }

  function onChangePropertyGroup(
    updateData: DynamicListChildL1,
    index: number
  ) {
    const dataCopy = cloneDeep(data);
    dataCopy[index] = updateData;
    onChange(dataCopy);
  }

  function findFirstGroupIndex(data: DynamicListChildL1[]) {
    return data.findIndex((x) => x.type === TYPE.GROUP) || 0;
  }

  const wrapperStyle = isReadOnly
    ? {
        px: "0",
        py: "0",
        spacing: "1",
        ml: "0px !important",
      }
    : {
        px: "12px",
        ml: "12px !important",
        borderLeftWidth: "1px",
        borderLeftColor: "grayV2.500",
      };

  return (
    <VStack
      alignItems="left"
      {...wrapperStyle}
      mt={!isReadOnly && data.length ? "12px !important" : "0px !important"}
    >
      {data.map(
        (item, index) =>
          item.type === TYPE.GROUP && (
            <>
              {index === 1 && !isReadOnly && (
                <Text fontSize="12px" color="brand.blue">
                  where all the properties apply
                </Text>
              )}
              <PropertyGroup
                mainFilter={data[0].value}
                data={item}
                id={createGroupId(
                  parentId,
                  "filter",
                  index - findFirstGroupIndex(data) + 1
                )}
                label={
                  item.name
                    ? item.name
                    : `Property group ${index - findFirstGroupIndex(data) + 1}`
                }
                onRemove={() => onRemovePropertyGroup(index)}
                onChange={(data) => onChangePropertyGroup(data, index)}
                isReadOnly={isReadOnly}
              />
            </>
          )
      )}
    </VStack>
  );
}

function ActivitySelector({
  productActivityList,
  onChange,
  value,
  validationError,
  isReadOnly,
}: {
  productActivityList: string[];
  onChange: (value: string) => void;
  value: string | null;
  validationError?: string;
  isReadOnly?: boolean;
}) {
  return (
    <HStack alignItems="center" spacing="3">
      {!isReadOnly && <Text fontSize="sm">Product event</Text>}
      <Box id="product-activity-selector">
        <ValueSelectFields
          options={productActivityList?.map((x) => ({ label: x, value: x }))}
          value={value || ""}
          onChange={onChange}
          validationError={validationError}
          isReadOnly={isReadOnly}
        />
      </Box>
    </HStack>
  );
}

export default function ProductEventFilterGroup({
  data,
  id,
  onChange,
  onRemove,
  isReadOnly,
}: {
  data: DynamicListChild;
  id: string;
  onChange: (data: any) => void;
  onRemove: () => void;
  isReadOnly?: boolean;
}) {
  const dispatch = useAppDispatch();

  const {
    productEventKeysList: {
      data: productEventKeysList,
      loading: fetchingProductEventKeysList,
    },
    productEventNamesList: {
      data: productEventNamesList,
      loading: fetchingProductEventNamesList,
    },
  } = useSelector(selectDynamicList);

  const { activeErrorCheck } = useContext(DynamicListContext);

  useEffect(() => {
    if (!productEventNamesList && isInit(fetchingProductEventNamesList)) {
      const data = {
        max_result: 10000,
        entity: FILTER_TYPE.PERSON,
      };
      dispatch(getEventNames(data));
    }
  }, [dispatch, productEventNamesList, fetchingProductEventNamesList]);

  function fetchEventKeys(event: string) {
    if (
      event &&
      !productEventKeysList[event] &&
      !isLoading(fetchingProductEventKeysList)
    ) {
      const data = {
        entity: FILTER_TYPE.PERSON,
        event_names: [event],
        max_result: 10000,
      };
      dispatch(getEventKeys(data));
    }
  }

  function onActivityChange(value: string) {
    fetchEventKeys(value);
    const dataCopy = cloneDeep(data);
    // reset data in property groups when filter group activity changes
    if (dataCopy.children[0].value?.[0] !== value) {
      dataCopy.children.forEach((child: DynamicListChildL1) => {
        if (child.type === TYPE.GROUP) {
          child.children?.forEach((prop) => {
            prop.property = "";
            prop.value = [""];
            prop.operator = null;
          });
        }
      });
    }
    dataCopy.children[0].value = [value];
    if (value) {
      dataCopy.children[0].validation_error = "";
    }
    onChange(dataCopy);
  }

  function onPropertyGroupChange(newData: DynamicListChildL1[]) {
    const dataCopy = cloneDeep(data);
    dataCopy.children = newData;
    onChange(dataCopy);
  }

  function onAddPropertyGroup() {
    const dataCopy = cloneDeep(data);
    const newPropertyGroup: DynamicListChildL1 = {
      type: TYPE.GROUP,
      name: "",
      filter_type: null,
      filter: null,
      property: null,
      operator: null,
      value: null,
      children: [
        {
          // Fluffy Child
          type: TYPE.EXPRESSION,
          filter_type: FILTER_TYPE.PRODUCT_ACTIVITY,
          filter: "event_properties",
          property: "",
          operator: null,
          value: [""],
          connector: CONNECTOR.AND,
        },
      ],
      connector: CONNECTOR.AND,
    };
    dataCopy.children.push(newPropertyGroup);
    onChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        px: "0",
        py: "0",
        spacing: "1",
      }
    : {
        px: "3",
        py: "3",
        spacing: "0",
      };

  const isGroupAvailable = (data: DynamicListChildL1[]) => {
    return data.filter((item) => item.type === TYPE.GROUP).length > 0;
  };

  return (
    <FilterGroupBox
      id={id}
      onRemove={onRemove}
      icon={FaDesktop}
      isReadOnly={isReadOnly}
      rounded="lg"
      borderColor="grayV2.200"
      borderWidth="1px"
      bg="grayV2.200"
      hideRemove={true}
    >
      <VStack alignItems="flex-start" {...wrapperStyle}>
        {data.children && (
          <>
            <HStack
              width="100%"
              wrap="wrap"
              gridGap={isReadOnly ? undefined : "2"}
              spacing="1"
            >
              <ActivitySelector
                productActivityList={productEventNamesList || []}
                value={data.children[0].value?.[0] as string}
                onChange={(value) => onActivityChange(value)}
                validationError={
                  activeErrorCheck ? data.children[0].validation_error : ""
                }
                isReadOnly={isReadOnly}
              />
              {!isReadOnly && !isGroupAvailable(data.children) && (
                <IButton
                  size="sm"
                  variant="link"
                  leftIcon={<RxPlus fontSize="12px" />}
                  onClick={onAddPropertyGroup}
                  isDisabled={!data.children?.[0]?.value?.[0]}
                  name="add-property-group"
                  color="brand.blue"
                  fontWeight="normal"
                >
                  Add property
                </IButton>
              )}
              {!isReadOnly && (
                <RemoveRowCloseButton onClick={onRemove}></RemoveRowCloseButton>
              )}
            </HStack>
            {isReadOnly ? (
              <Stack direction="row" position="relative">
                <Divider
                  orientation="vertical"
                  h="100%"
                  ml="-16px"
                  position="absolute"
                  borderColor="brandBlue.500"
                ></Divider>
                <PropertyGroupContainer
                  data={data.children}
                  parentId={id}
                  onChange={onPropertyGroupChange}
                  isReadOnly={isReadOnly}
                />
              </Stack>
            ) : (
              <PropertyGroupContainer
                data={data.children}
                parentId={id}
                onChange={onPropertyGroupChange}
                isReadOnly={isReadOnly}
              />
            )}

            {!isReadOnly && isGroupAvailable(data.children) && (
              <IButton
                size="sm"
                variant="link"
                leftIcon={<RxPlus fontSize="12px" />}
                onClick={onAddPropertyGroup}
                isDisabled={!data.children?.[0]?.value?.[0]}
                name="add-property-group"
                color="brand.blue"
                ml="24px !important"
                mt="8px !important"
                fontWeight="normal"
              >
                Add additional property
              </IButton>
            )}
          </>
        )}
      </VStack>
    </FilterGroupBox>
  );
}
