import { UserSummary } from "./common";
import { LOADING_STATES } from "../constants/common";
import { Destination, STATUS as VENDOR_STATUS } from "./unifiedMapping";
import { MAPPING_RULES } from "../constants/form";

export enum FORM_VENDORS {
  WEBFLOW = "webflow",
  FORMSTACK = "formstack",
}

export enum FORM_STATUS {
  NEW_FIELDS_IDENTIFIED = "NEW_FIELDS_IDENTIFIED",
  MAPPING_PENDING = "MAPPING_PENDING",
  READY = "READY",
  DISCONNECTED = "DISCONNECTED", //STATUS ONLY IN FE
}

export type FormSummary = {
  form_id: string;
  name: string;
  vendor: FORM_VENDORS;
  form_status: FORM_STATUS;
  created_at: string;
  updated_at: string;
};

type DestinationWithMappingRule = Destination & {
  mapping_rule?: MAPPING_RULES | null;
};

export type MappingItems = {
  destination: DestinationWithMappingRule;
  source: Destination;
};

export type AllFields = {
  [key: string]: {
    source: DestinationWithMappingRule;
  };
};

export type MappedFields = {
  [key: string]: MappingItems;
};

export type FormDetailsSummary = FormSummary & {
  form_key: string;
  form_metadata: { [key: string]: any };
  mapped_fields: MappedFields;
  all_fields: AllFields;
  email_source_field: string;
  organisation_id: number;
  created_by: UserSummary;
  is_deleted: boolean;
  updated_by: UserSummary;
  submissions: null | number;
  submissionStatsLoading: LOADING_STATES;
  description: string;
};

export type FormSubmissionDetails = {
  id: string;
  submission_id: string;
  submission_data: string; // This is a stringified json
  submission_date: string;
  received_date: string;
  integrations: FORM_VENDORS;
  mapped_data: string; // This is a stringified json with email and person_id
};

export type GetFormSubmissions = GetFormErrors & {
  start_time?: string;
  end_time?: string;
};

export type GetFormSubmissionStats = {
  source: FORM_VENDORS | "";
  form_ids: string[];
};

export type FormSubmissionStatData = {
  count: number;
  last_sync_time: string;
};

export type FormSubmissionStats = {
  [id: string]: {
    count: number;
    last_sync_time: string;
  };
};

export type FormIntegrationDetails = {
  vendor: FORM_VENDORS;
  prerequisites: { [key: string]: any };
};

export type FormsAddIntegration = {
  vendor: FORM_VENDORS;
  vendor_data: { [key: string]: any };
};

export type FormWebhookUrl = { webhook_url: string };

export type FormMappingUpdate = {
  form_id: string;
  email_source_field: string;
  webform_mapping: {
    mapping: MappingItems[];
  };
};

export type GetListForms = {
  page_number: number;
  page_size: number;
  search_keyword: string;
};

export type FormProviderDetails = {
  webhook_url: string;
};

export type ProviderDetails = Partial<FormProviderDetails> & {
  api_key: string;
  label: FORM_VENDORS;
  time_updated: string;
  key_type: FORM_VENDORS;
  time_created: string;
  organisation_id: number;
  created_by_account_id: number;
  state: VENDOR_STATUS;
  connected_table: string;
  sync_interval: string;
  name: string;
  last_sync_time: string;
};

// Form Error
export type GetFormErrors = {
  source: FORM_VENDORS;
  form_id: string;
  page_size?: number;
  page_number?: number;
};

export interface FormError {
  form_id: string;
  vendor: FORM_VENDORS;
  submission: string;
  id: string;
  error: string;
  received_date: string;
}
