import { HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";
import { TagSummary } from "../../../common/types/tag";
import ISkeleton, { SKELETON_VARIANT } from "../../../components/ISkeleton";

const initStyle = {
  _hover: {
    bg: "blackAlpha.100",
    "&>svg": { visibility: "visible" },
  },
};
const selectedStyle = {
  bg: "brandV2.gray",
  color: "brandBlue.500",
  _hover: {
    "&>svg": { visibility: "visible" },
  },
};

export default function TagList({
  tags,
  searchKeyword = "",
  onTagClick,
  isLoading,
  onRemoveRow,
}: {
  tags: (TagSummary & { selected: boolean })[];
  searchKeyword?: string;
  onTagClick: (tagId: string) => void;
  isLoading: boolean;
  onRemoveRow: (tagId: string) => void;
}) {
  return (
    <VStack
      alignItems="flex-start"
      height="90%"
      overflow="hidden"
      overflowY="auto"
      pt={1}
      spacing={isLoading ? 6 : 1}
      w="100%"
      fontSize="14px"
    >
      <ISkeleton
        isLoaded={!isLoading}
        variant={SKELETON_VARIANT.LIST}
        rowCount={5}
        height="3"
        w="80%"
      >
        {tags.length ? (
          tags
            .filter(({ name }) =>
              name.toLowerCase().includes(searchKeyword.toLowerCase())
            )
            .map(({ name, tag_id, selected }) => {
              const style = selected ? selectedStyle : initStyle;
              return (
                <HStack
                  w="100%"
                  cursor="pointer"
                  px={2}
                  py="6px"
                  rounded="md"
                  {...style}
                  onClick={() => onTagClick(tag_id)}
                  justifyContent="space-between"
                >
                  <Text
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    #{name}
                  </Text>
                  <Icon
                    as={FaTimes}
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveRow(tag_id);
                    }}
                    visibility="hidden"
                  />
                </HStack>
              );
            })
        ) : (
          <Text fontSize="12px" color="text.50" w="100%" textAlign="center">
            Add tags here to filter
          </Text>
        )}
      </ISkeleton>
    </VStack>
  );
}
