import { ButtonProps, HStack, StackProps, TextProps } from "@chakra-ui/react";
import { ReactNode } from "react";
import IButton, { BUTTON } from "./IButton";
import PrimaryHeader, { DefaultHeaderType } from "./PrimaryHeader";
import { SearchField, SearchFieldType } from "./SearchField";

export type CreateButtonProps = ButtonProps & {
  onClick: () => void;
  text: string;
  name: string;
};

export function SearchInputAndCreateButton({
  //separate component for search and create button , can be used in future
  searchInputProps,
  createButtonProps,
  ctaMenu,
  children,
}: {
  searchInputProps?: SearchFieldType;
  createButtonProps?: CreateButtonProps;
  ctaMenu?: ReactNode;
  children?: ReactNode;
}) {
  return (
    <HStack>
      {children}
      {searchInputProps && (
        <SearchField size="sm" w="210px" {...searchInputProps} />
      )}
      {createButtonProps && (
        <IButton variant={BUTTON.PRIMARY} {...createButtonProps}>
          {createButtonProps.text}
        </IButton>
      )}
      {ctaMenu}
    </HStack>
  );
}

// common header with search input field for listing Assets page
export function CommonListHeader({
  heading,
  goBack,
  children,
  headerStackProps,
  titleProps,
  createButtonProps,
  searchInputProps,
  addComponentToLeft,
}: DefaultHeaderType & {
  headerStackProps?: StackProps;
  titleProps?: TextProps;
  searchInputProps?: SearchFieldType;
  createButtonProps?: CreateButtonProps;
  addComponentToLeft?: ReactNode;
}) {
  return (
    <PrimaryHeader
      heading={heading}
      goBack={goBack}
      addComponentToLeft={addComponentToLeft}
      titleProps={titleProps}
      {...headerStackProps}
    >
      <SearchInputAndCreateButton
        createButtonProps={createButtonProps}
        searchInputProps={searchInputProps}
      >
        {children}
      </SearchInputAndCreateButton>
    </PrimaryHeader>
  );
}
