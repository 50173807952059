import { Box, TextProps, HStack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TIMEZONE_FIELDS } from "../../../common/constants/settings";
import DropdownWithSearch from "../../../components/DropdownWithSearch";
import IButton, { BUTTON } from "../../../components/IButton";
import ITitle from "../../../components/ITitle";
import ContentContainer from "../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import { useAppDispatch } from "../../../store";
import {
  createTimezonePreferenceSettings,
  getTimezonePreferenceSettings,
  selectSettings,
  updateTimezonePreferenceSettings,
} from "./settingsSlice";
import { IoIosCloseCircle } from "react-icons/io";
import { FaSave } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { EMPTY_CONTEXT } from "../../../common/constants/common";
import { useUpdateWithInitValue } from "../../../common/hooks/commonHooks";

const PREFERNCE_OPTIONS = [
  { label: "System inferred field", value: TIMEZONE_FIELDS.SYSTEM_INFERRED },
  { label: "Custom timezone field", value: TIMEZONE_FIELDS.CUSTOM_TIMEZONE },
];

enum TIME_ZONE_SETTING_MODES {
  READ = "read",
  EDIT = "edit",
}

enum TEXT_TYPE {
  HEADER = "header",
  DESCRIPTION = "description",
}

function DescriptionText({
  text,
  type = TEXT_TYPE.DESCRIPTION,
  ...props
}: { text: string; type?: TEXT_TYPE } & TextProps) {
  const isTypeDescription = type === TEXT_TYPE.DESCRIPTION;
  return (
    <Text
      fontSize={isTypeDescription ? "12px" : "14px"}
      color={isTypeDescription ? "brandBlue.500" : "blackAlpha.700"}
      {...props}
    >
      {text}
    </Text>
  );
}

export default function TimeZoneSettings() {
  const dispatch = useAppDispatch();
  const {
    timezonePreference: { data: timezonePreferenceDetails },
  } = useSelector(selectSettings);

  const [mode, setMode] = useState<TIME_ZONE_SETTING_MODES>(
    TIME_ZONE_SETTING_MODES.READ
  );
  const [selectedOption, setSelectedOption] = useUpdateWithInitValue(
    timezonePreferenceDetails?.preferred_timezone_field
  );
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    dispatch(getTimezonePreferenceSettings());
  }, [dispatch]);

  function onSaveClick() {
    if (selectedOption) {
      timezonePreferenceDetails
        ? dispatch(
            updateTimezonePreferenceSettings({
              preferredTimezone: selectedOption,
            })
          )
        : dispatch(
            createTimezonePreferenceSettings({
              preferredTimezone: selectedOption,
            })
          );
      setMode(TIME_ZONE_SETTING_MODES.READ);
    } else {
      setIsError(true);
    }
  }

  function DisplayButtonsBasedOnMode({
    mode = TIME_ZONE_SETTING_MODES.READ,
  }: {
    mode?: string;
  }) {
    return mode === TIME_ZONE_SETTING_MODES.READ ? (
      <IButton
        variant={BUTTON.PRIMARY}
        leftIcon={<MdEdit size="16px" />}
        p={4}
        onClick={() => setMode(TIME_ZONE_SETTING_MODES.EDIT)}
      >
        Edit
      </IButton>
    ) : (
      <HStack>
        <IButton
          variant={BUTTON.SECONDARY}
          leftIcon={<IoIosCloseCircle size="16px" />}
          p={4}
          onClick={() => {
            setSelectedOption(
              timezonePreferenceDetails?.preferred_timezone_field
            );
            setMode(TIME_ZONE_SETTING_MODES.READ);
          }}
        >
          Cancel
        </IButton>
        <IButton
          leftIcon={<FaSave size="16px" />}
          isDisabled={!!isError}
          p={4}
          onClick={onSaveClick}
        >
          Save
        </IButton>
      </HStack>
    );
  }
  return (
    <ContentContainerWithHeader mr="20px">
      <Box w="100%" display="flex" justifyContent="flex-end" mb={2} mt={4}>
        <DisplayButtonsBasedOnMode mode={mode} />
      </Box>
      <ContentContainer flexDir="column" h="80vh">
        <Box w="90%">
          <ITitle
            title="Timezone settings"
            fontWeight="600"
            color="blackAlpha.700"
            pb={8}
          />
          <DescriptionText
            text="Inflection provides two different options to set the timezone for
              a contact"
            type={TEXT_TYPE.HEADER}
            mb={4}
          />
          <DescriptionText
            text="1. System inferred timezone"
            type={TEXT_TYPE.HEADER}
            mb={1}
          />
          <DescriptionText
            text="This allows the system to automatically update timezone
              information for the contact based on the contact's address."
            mb={8}
          />
          <DescriptionText
            text="2. Custom timezone field"
            type={TEXT_TYPE.HEADER}
            mb={1}
          />

          <DescriptionText
            text="This enables you to manually update a contact’s timezone field.
              The field can also be synced to a data connection."
            mb={12}
          />

          <DescriptionText
            text="Timezone preference"
            type={TEXT_TYPE.HEADER}
            fontWeight={600}
            mb={5}
          />

          <DescriptionText
            text="Select preference between system inferred and custom timezone when
              both values are present for a contact."
            type={TEXT_TYPE.HEADER}
            mb={6}
          />

          <DescriptionText
            text="Field preference"
            type={TEXT_TYPE.DESCRIPTION}
            mb={2}
            color="blackAlpha.700"
          />

          {mode === TIME_ZONE_SETTING_MODES.EDIT ? (
            <>
              <DropdownWithSearch
                options={PREFERNCE_OPTIONS}
                isSearchable
                value={PREFERNCE_OPTIONS.find(
                  (option) =>
                    option.value ===
                    (selectedOption ??
                      timezonePreferenceDetails?.preferred_timezone_field)
                )}
                onChange={(option) => {
                  setIsError(false);
                  setSelectedOption(option?.value as TIMEZONE_FIELDS);
                }}
                controlStyle={{
                  width: "430px",
                }}
              />
              {isError && (
                <Text fontSize="12px" color="red.500">
                  Please select a timezone preference
                </Text>
              )}
            </>
          ) : (
            <Text
              fontSize="14px"
              color="blackAlpha.900"
              fontWeight="400"
              mb={2}
            >
              {PREFERNCE_OPTIONS.find(
                (option) => option.value === selectedOption
              )?.label ?? EMPTY_CONTEXT}
            </Text>
          )}
        </Box>
      </ContentContainer>
    </ContentContainerWithHeader>
  );
}
