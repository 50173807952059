import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputProps,
  FormLabelProps,
  Flex,
  HStack,
} from "@chakra-ui/react";
import DropdownWithSearch from "./DropdownWithSearch";
import { useSelector } from "react-redux";
import { selectConstants } from "../common/slices/constantsSlice";
import { createSyntheticEvent } from "../common/helper/commonHelper";
import { useEffect, useState, useCallback } from "react";
import IButton from "./IButton";
import { DomainType } from "../common/types/common";
import { CODEMIRROR_SIZE } from "../common/constants/common";
import OptionalCodemirrorInput from "./codemirror/OptionalCodemirrorInput";
import { VALUE_META_TYPE } from "../common/constants/template";

function ToggleButton({
  val,
  onChange,
  isDisabled,
}: {
  val: VALUE_META_TYPE;
  onChange: (val: VALUE_META_TYPE) => void;
  isDisabled?: boolean;
}) {
  return (
    <Flex h="35px" alignItems="center" justifyContent="flex-start">
      <Flex rounded="2xl" border="1px" borderColor="gray.300">
        <IButton
          fontWeight="600"
          size="xs"
          rounded="2xl"
          width="45px"
          name="text-type"
          bg={val === "text" ? "green.400" : undefined}
          colorScheme={val === "text" ? "green" : undefined}
          variant={val !== "text" ? "ghost" : undefined}
          onClick={() => onChange(VALUE_META_TYPE.TEXT)}
          capitalize={false}
          isDisabled={isDisabled}
        >
          Text
        </IButton>
        <IButton
          fontWeight="600"
          size="xs"
          rounded="2xl"
          width="50px"
          name="token-type"
          bg={val === "token" ? "green.400" : undefined}
          colorScheme={val === "token" ? "green" : undefined}
          variant={val !== "token" ? "ghost" : undefined}
          onClick={() => onChange(VALUE_META_TYPE.TOKEN)}
          capitalize={false}
          isDisabled={isDisabled}
        >
          Token
        </IButton>
      </Flex>
    </Flex>
  );
}

function InputWithDropdown({
  value,
  onChangeHandler,
  inputProps,
  domainList,
  domain,
  errorMsg,
  isDisabled,
}: {
  value: string;
  onChangeHandler: (
    newValue: string,
    isInput: boolean,
    isToken?: boolean
  ) => void;
  inputProps: InputProps;
  domainList: DomainType[];
  domain: string;
  errorMsg: string | undefined;
  isDisabled?: boolean;
}) {
  return (
    <>
      <Input
        type="text"
        fontSize={14}
        value={value}
        onChange={(e) => onChangeHandler(e.target.value.trim(), true)}
        {...inputProps}
        w="100%"
        borderBottomRightRadius="0"
        borderTopRightRadius="0"
        isDisabled={isDisabled}
      />
      <DropdownWithSearch
        name={`email-select-${inputProps.name}`}
        options={domainList.filter((domain) => domain.active)}
        getOptionLabel={(option) => `@${option.domain}`}
        getOptionValue={(option) => `@${option.domain}`}
        placeholder="Select"
        value={domainList
          .filter((domain) => domain.active)
          .find((item) => `@${item.domain}` === domain)}
        onChange={(option) =>
          onChangeHandler(option ? `@${option.domain}` : "", false)
        }
        isInvalid={!!errorMsg && !domain}
        controlStyle={{
          height: "32px",
          minHeight: "28px",
          width: "160px",
          borderBottomLeftRadius: "0",
          borderTopLeftRadius: "0",
        }}
        isSearchable
        maxMenuHeight={300}
        isDisabled={isDisabled}
      />
    </>
  );
}

interface InputWithErrorProps extends InputProps {
  labelText?: string;
  errorMsg?: string;
  paddingX?: string;
  touched?: boolean;
  formLabelProps?: FormLabelProps;
  value: string;
  isCodemirror: boolean;
  tokenType: VALUE_META_TYPE | null;
  onTokenTypeChange: (tokenType: VALUE_META_TYPE) => void;
}

export default function RestrictedEmailInput({
  labelText,
  errorMsg,
  paddingX,
  touched = true,
  formLabelProps,
  name,
  value,
  onChange,
  isCodemirror,
  tokenType,
  onTokenTypeChange,
  isDisabled,
  ...props
}: InputWithErrorProps) {
  const { domainList } = useSelector(selectConstants);
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");

  const onChangeHandler = useCallback(
    (newValue: string, isInput: boolean, isToken: boolean = false) => {
      const target = document.createElement("input");
      if (isToken) {
        target.value = newValue;
      } else {
        if (isInput) {
          const values = newValue.split("@");
          target.value = values[0] + domain;
        } else {
          target.value = email + newValue;
        }
      }
      target.name = name ?? "";
      const event = new Event("change", { bubbles: true });
      Object.defineProperty(event, "target", {
        writable: false,
        value: target,
      });
      const syntheticEvent = createSyntheticEvent(event) as React.ChangeEvent<
        typeof target
      >;
      if (onChange) {
        onChange(syntheticEvent);
      }
      target.remove();
    },
    [name, email, domain, onChange]
  );

  useEffect(() => {
    const [name, domainName] = value.split("@");
    setEmail(name ?? "");
    if (domainName) {
      setDomain(`@${domainName}`);
    } else {
      const activeDomains = domainList.filter((domain) => domain.active);
      activeDomains.length === 1 && setDomain(`@${activeDomains[0].domain}`);
    }
  }, [value, onChangeHandler, domainList]);

  return (
    <FormControl px={paddingX} py="2" isInvalid={!!errorMsg && touched}>
      <HStack justifyContent="space-between">
        {labelText && <FormLabel {...formLabelProps}>{labelText}</FormLabel>}
        {tokenType && (
          <ToggleButton
            val={tokenType}
            onChange={onTokenTypeChange}
            isDisabled={isDisabled}
          />
        )}
      </HStack>
      <Flex flex="1">
        {tokenType === VALUE_META_TYPE.TOKEN ? (
          <OptionalCodemirrorInput
            isInvalid={!!errorMsg}
            isCodemirror={isCodemirror}
            type={CODEMIRROR_SIZE.SINGLE_LINE}
            value={value}
            onChange={(code) => onChangeHandler(code, false, true)}
            isDisabled={isDisabled}
          />
        ) : (
          <InputWithDropdown
            value={email}
            onChangeHandler={onChangeHandler}
            inputProps={{ ...props, name }}
            domainList={domainList}
            domain={domain}
            errorMsg={errorMsg}
            isDisabled={isDisabled}
          />
        )}
      </Flex>
      <FormErrorMessage fontSize={12}>{errorMsg}</FormErrorMessage>
    </FormControl>
  );
}
