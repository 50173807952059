import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  LoadingWithData,
  PaginationType,
  SearchAssetsType,
} from "../../../common/types/common";
import {
  INITIAL_PAGINATION,
  LOADING_STATES,
} from "../../../common/constants/common";
import {
  PreviewWebhookType,
  UpdateWebhookType,
  WebhookDetails,
  WebhookListItem,
  WebhookPreviewRequest,
  WebhookSummary,
} from "../../../common/types/webhook";
import {
  createAsyncThunkWrapper,
  initializeLoadingData,
} from "../../../common/helper/commonHelper";
import {
  createWebhookApi,
  deleteWebhookApi,
  getWebhookApi,
  listAllWebhooksApi,
  listWebhooksApi,
  previewWebhookApi,
  updateWebhookApi,
  updateWebhookNameApi,
} from "../../../common/api/campaign/webhook";
import { toast } from "react-toastify";
import { PERSON_ORG_MAPPING } from "../../../common/types/person";
import { personLookupApi } from "../../../common/api/campaign/person";
import { CAMPAIGN_CONTEXT } from "../../../common/types/campaign";
import { PreviewPerson } from "../../../common/types/token";
import {
  CONTENT_TYPE,
  APPLICATION_JSON,
  WEBHOOK_REQUEST_METHODS,
} from "../../../common/constants/webhook";
import { USER_ACCOUNT_STATUS } from "../../../common/types/auth";
import { expireApiStatus } from "../../../common/slices/apiStatusSlice";

const WEBHOOK_LIST_ALL_ACTION = "webhooks/list-all";

interface WebhookState {
  allWebhooksList: LoadingWithData<WebhookSummary[]>;
  webhooksList: PaginationType<WebhookListItem>;
  webhookDetails: LoadingWithData<WebhookDetails>;
  webhookName: string;
  deletingWebhook: LOADING_STATES;
  creatingWebhook: LOADING_STATES;
  updatingWebhook: LOADING_STATES;
  createdWebhookId: string;
  isWebhookDirty: boolean;
  webhookPreview: LoadingWithData<PreviewWebhookType>;
  personLookup: LoadingWithData<{ [email: string]: PreviewPerson[] }>;
}

export const initWebhookDetails: WebhookDetails = {
  webhook_id: "",
  headers: { [CONTENT_TYPE]: APPLICATION_JSON },
  name: "",
  description: "",
  method: WEBHOOK_REQUEST_METHODS.POST,
  url: "",
  payload: "",
  created_at: "",
  updated_at: "",
  created_by: {
    id: 0,
    name: "",
  },
  updated_by: {
    id: 0,
    name: "",
  },
  type: "webhook",
  account_status: USER_ACCOUNT_STATUS.INACTIVE,
};

const initialState: WebhookState = {
  allWebhooksList: initializeLoadingData([]),
  webhooksList: INITIAL_PAGINATION,
  webhookDetails: initializeLoadingData(initWebhookDetails),
  webhookName: "",
  deletingWebhook: LOADING_STATES.INIT,
  creatingWebhook: LOADING_STATES.INIT,
  updatingWebhook: LOADING_STATES.INIT,
  createdWebhookId: "",
  isWebhookDirty: false,
  webhookPreview: initializeLoadingData({}),
  personLookup: initializeLoadingData({}),
};

export const listAllWebhooks = createAsyncThunkWrapper({
  actionName: WEBHOOK_LIST_ALL_ACTION,
  dispatchFn: async () => {
    return await listAllWebhooksApi();
  },
  isCachable: true,
});

export const listWebhooks = createAsyncThunk(
  "webhooks/list",
  async ({ searchKeyword, columnsToSearchIn }: SearchAssetsType, thunkApi) => {
    const { webhook } = thunkApi.getState() as RootState;
    return await listWebhooksApi({
      pageNo: webhook.webhooksList.currentPageNo,
      pageSize: webhook.webhooksList.pageSize,
      searchKeyword,
      columnsToSearchIn,
    });
  }
);

export const createWebhook = createAsyncThunk(
  "webhooks/create",
  async (webhookName: string, { dispatch }) => {
    const response = await createWebhookApi(webhookName);
    dispatch(expireApiStatus([{ actionName: WEBHOOK_LIST_ALL_ACTION }]));
    return response;
  }
);

export const getWebhook = createAsyncThunk(
  "webhooks/get",
  async (webhookId: string) => {
    return await getWebhookApi(webhookId);
  }
);

export const deleteWebhook = createAsyncThunk(
  "webhooks/delete",
  async (webhookId: string, { dispatch }) => {
    const response = await deleteWebhookApi(webhookId);
    dispatch(expireApiStatus([{ actionName: WEBHOOK_LIST_ALL_ACTION }]));
    return response;
  }
);

export const updateWebhookName = createAsyncThunk(
  "webhooks/rename",
  async (
    { name, webhookId }: { name: string; webhookId: string },
    { dispatch }
  ) => {
    const response = await updateWebhookNameApi(name, webhookId);
    dispatch(expireApiStatus([{ actionName: WEBHOOK_LIST_ALL_ACTION }]));
    return response;
  }
);

export const updateWebhook = createAsyncThunk(
  "webhooks/update",
  async (webhookData: UpdateWebhookType, { dispatch }) => {
    const response = await updateWebhookApi(webhookData);
    dispatch(expireApiStatus([{ actionName: WEBHOOK_LIST_ALL_ACTION }]));
    return response;
  }
);

export const previewWebhook = createAsyncThunk(
  "webhooks/preview",
  async (previewData: WebhookPreviewRequest) => {
    return await previewWebhookApi(previewData);
  }
);

export const lookupPersonForWebhooks = createAsyncThunk(
  "webhooks/person-lookup",
  async (email: string, { getState }) => {
    const { settings } = getState() as RootState;
    const campaignContext =
      settings.personOrgMapping === PERSON_ORG_MAPPING.ONE_TO_NONE
        ? CAMPAIGN_CONTEXT.PERSON
        : CAMPAIGN_CONTEXT.ORG;
    return await personLookupApi(email, campaignContext);
  }
);

const webhookSlice = createSlice({
  name: "webhook",
  initialState,
  reducers: {
    setWebhookPage(state, { payload: pageNo }: { payload: number }) {
      if (pageNo && pageNo <= (state.webhooksList.totalPageCount ?? 1)) {
        state.webhooksList.currentPageNo = pageNo;
        state.webhooksList.changingPage = true;
      }
      // Call due to filter change will have pageNo as 0
      else {
        state.webhooksList.currentPageNo = 1;
      }
    },
    clearCreatedWebhookId(state) {
      state.createdWebhookId = "";
    },
    setIsWebhookDirty(state, { payload: isDirty }: { payload: boolean }) {
      state.isWebhookDirty = isDirty;
    },
    resetWebhookPreviewData(state) {
      state.webhookPreview = initializeLoadingData({});
    },
    resetWebhookDetails(state) {
      state.webhookDetails = initialState.webhookDetails;
      state.webhookName = "";
    },
    resetWebhookList(state) {
      state.webhooksList = initialState.webhooksList;
    },
  },
  extraReducers: (builder) => {
    builder
      //webhook summary list all
      .addCase(listAllWebhooks.pending, (state) => {
        state.allWebhooksList.loading = LOADING_STATES.LOADING;
      })
      .addCase(listAllWebhooks.fulfilled, (state, action) => {
        state.allWebhooksList.loading = LOADING_STATES.SUCCESS;
        state.allWebhooksList.data = action.payload.records;
      })
      .addCase(listAllWebhooks.rejected, (state) => {
        state.allWebhooksList.loading = LOADING_STATES.FAILED;
      })

      //webhook list
      .addCase(listWebhooks.pending, (state) => {
        state.webhooksList.fetchingList = true;
      })
      .addCase(listWebhooks.fulfilled, (state, action) => {
        state.webhooksList.list = action.payload.records;
        state.webhooksList.fetchingList = false;
        state.webhooksList.changingPage = false;
        state.webhooksList.count = action.payload.record_count;
        state.webhooksList.totalPageCount = action.payload.page_count;
      })
      .addCase(listWebhooks.rejected, (state) => {
        state.webhooksList.fetchingList = false;
        state.webhooksList.changingPage = false;
      })

      //create  a new webhook
      .addCase(createWebhook.pending, (state) => {
        state.creatingWebhook = LOADING_STATES.LOADING;
      })
      .addCase(createWebhook.fulfilled, (state, { payload }) => {
        if (payload.webhook.webhook_id) {
          state.createdWebhookId = payload.webhook.webhook_id;
          toast.success("Webhook created successfully");
          state.creatingWebhook = LOADING_STATES.SUCCESS;
        } else {
          state.creatingWebhook = LOADING_STATES.FAILED;
          toast.error("Webhook creation failed");
        }
      })
      .addCase(createWebhook.rejected, (state) => {
        state.creatingWebhook = LOADING_STATES.FAILED;
      })

      // get details of a single webhook
      .addCase(getWebhook.pending, (state) => {
        state.webhookDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getWebhook.fulfilled, (state, { payload }) => {
        state.webhookDetails.data = payload.webhook;
        state.webhookName = payload.webhook.name;
        state.webhookDetails.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getWebhook.rejected, (state) => {
        state.webhookDetails.loading = LOADING_STATES.FAILED;
      })

      //delete a webhook
      .addCase(deleteWebhook.pending, (state) => {
        state.deletingWebhook = LOADING_STATES.LOADING;
      })
      .addCase(deleteWebhook.fulfilled, (state, { payload }) => {
        if (payload.webhook) {
          state.deletingWebhook = LOADING_STATES.SUCCESS;
          toast.success("Webhook deleted successfully");
        } else {
          toast.error("Webhook deletion failed");
          state.deletingWebhook = LOADING_STATES.FAILED;
        }
      })
      .addCase(deleteWebhook.rejected, (state) => {
        state.deletingWebhook = LOADING_STATES.FAILED;
      })

      //update the name
      .addCase(updateWebhookName.pending, (state) => {
        state.updatingWebhook = LOADING_STATES.LOADING;
      })
      .addCase(updateWebhookName.fulfilled, (state, action) => {
        if (action.payload.webhook) {
          state.webhookName = action.meta.arg.name;
          state.updatingWebhook = LOADING_STATES.SUCCESS;
          toast.success("Webhook name updated successfully");
        } else {
          state.updatingWebhook = LOADING_STATES.FAILED;
          toast.error("Webhook updation failed");
        }
      })
      .addCase(updateWebhookName.rejected, (state) => {
        state.updatingWebhook = LOADING_STATES.FAILED;
      })

      //update webhook details
      .addCase(updateWebhook.pending, (state) => {
        state.updatingWebhook = LOADING_STATES.LOADING;
      })
      .addCase(updateWebhook.fulfilled, (state, { payload }) => {
        if (payload.webhook) {
          state.webhookDetails.data = { ...payload.webhook };
          state.webhookName = payload.webhook.name;
          state.isWebhookDirty = false;
          state.updatingWebhook = LOADING_STATES.SUCCESS;
          toast.success("Webhook updated successfully");
        } else {
          state.isWebhookDirty = true;
          state.updatingWebhook = LOADING_STATES.FAILED;
          toast.error("Webhook updation failed");
        }
      })
      .addCase(updateWebhook.rejected, (state) => {
        state.updatingWebhook = LOADING_STATES.FAILED;
      })

      //preview webhook
      .addCase(previewWebhook.pending, (state) => {
        resetWebhookPreviewData();
        state.webhookPreview.loading = LOADING_STATES.LOADING;
      })
      .addCase(previewWebhook.fulfilled, (state, { payload }) => {
        state.webhookPreview.data = payload;
        state.webhookPreview.loading = LOADING_STATES.SUCCESS;
        if (payload.status_code === 200) {
          toast.success("Preview successful");
        }
      })
      .addCase(previewWebhook.rejected, (state) => {
        resetWebhookPreviewData();
        state.webhookPreview.loading = LOADING_STATES.FAILED;
      })

      //lookup person for webhooks
      .addCase(lookupPersonForWebhooks.pending, (state) => {
        state.personLookup.loading = LOADING_STATES.LOADING;
      })
      .addCase(lookupPersonForWebhooks.fulfilled, (state, actions) => {
        state.personLookup.loading = LOADING_STATES.SUCCESS;
        if (actions.payload.rows.length) {
          const data = {
            [actions.meta.arg]: actions.payload.rows.map((row) => {
              return { ...row, campaign_context: CAMPAIGN_CONTEXT.PERSON };
            }),
          };
          state.personLookup.data = data;
        }
      })
      .addCase(lookupPersonForWebhooks.rejected, (state) => {
        state.personLookup.loading = LOADING_STATES.FAILED;
      });
  },
});
export const {
  setWebhookPage,
  clearCreatedWebhookId,
  setIsWebhookDirty,
  resetWebhookPreviewData,
  resetWebhookDetails,
  resetWebhookList,
} = webhookSlice.actions;

export const selectWebhook = (state: RootState) => state.webhook;

export default webhookSlice.reducer;
