import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DynamicListType,
  StaticListContactsData,
  StaticListDetails,
  StaticListSummary,
} from "../../../common/types/campaign";
import {
  createStaticListApi,
  deleteStaticListApi,
  getStaticListApi,
  getStaticListDlInsertApi,
  getStaticListDlInsertPreviewApi,
  listAllStaticListsApi,
  listStaticListContactsApi,
  listStaticListsApi,
  removeAllStaticListContactsApi,
  removeStaticListContactsApi,
  renameStaticListApi,
} from "../../../common/api/campaign/staticList";
import { RootState } from "../../../store";
import {
  INITIAL_PAGINATION,
  INITIAL_PAGINATION_15_ITEMS,
  LOADING_STATES,
} from "../../../common/constants/common";
import { toast } from "react-toastify";
import { LoadingWithData, PaginationType } from "../../../common/types/common";
import {
  createAsyncThunkWrapper,
  initializeLoadingData,
} from "../../../common/helper/commonHelper";
import { expireApiStatus } from "../../../common/slices/apiStatusSlice";

const STATIC_LIST_ALL_ACTION = "static-list/list-all";

interface StaticListState {
  staticLists: PaginationType<StaticListDetails>;
  staticListSummaryList: LoadingWithData<StaticListSummary[]>;
  updatingStaticList: LOADING_STATES;
  createdStaticListId: string;

  staticListDetails: LoadingWithData<StaticListDetails | null>;
  staticListContacts: PaginationType<StaticListContactsData>;
  staticListDeleting: LOADING_STATES;

  staticListDl: {
    count: number;
    previewing: LOADING_STATES;
    inserting: LOADING_STATES;
  };
}

const initialState: StaticListState = {
  staticLists: INITIAL_PAGINATION,
  staticListSummaryList: initializeLoadingData([]),
  updatingStaticList: LOADING_STATES.INIT,
  createdStaticListId: "",

  staticListDetails: initializeLoadingData(null),
  staticListContacts: INITIAL_PAGINATION_15_ITEMS,
  staticListDeleting: LOADING_STATES.INIT,

  staticListDl: {
    count: 0,
    previewing: LOADING_STATES.INIT,
    inserting: LOADING_STATES.INIT,
  },
};

export const listStaticLists = createAsyncThunk(
  "static-list/list",
  async (searchText: string, thunkApi) => {
    const { staticlist } = thunkApi.getState() as RootState;
    return await listStaticListsApi(
      searchText,
      staticlist.staticLists.pageSize,
      staticlist.staticLists.currentPageNo
    );
  }
);

export const createStaticList = createAsyncThunk(
  "static-list/create",
  async (name: string, { dispatch }) => {
    const response = await createStaticListApi(name);
    dispatch(expireApiStatus([{ actionName: STATIC_LIST_ALL_ACTION }]));
    return response;
  }
);

export const renameStaticList = createAsyncThunk(
  "static-list/rename",
  async (
    { name, staticListId }: { name: string; staticListId: string },
    { dispatch }
  ) => {
    const response = await renameStaticListApi(name, staticListId);
    dispatch(expireApiStatus([{ actionName: STATIC_LIST_ALL_ACTION }]));
    return response;
  }
);

export const deleteStaticList = createAsyncThunk(
  "static-list/delete",
  async (staticListId: string, { dispatch }) => {
    const response = await deleteStaticListApi(staticListId);
    dispatch(expireApiStatus([{ actionName: STATIC_LIST_ALL_ACTION }]));
    return response;
  }
);

export const getStaticList = createAsyncThunk(
  "static-list/get",
  async (staticListId: string) => {
    return await getStaticListApi(staticListId);
  }
);

export const listStaticListContacts = createAsyncThunk(
  "static-list/contacts/list",
  async (
    {
      id,
      searchText,
      end_date,
      start_date,
    }: { id: string; searchText: string; end_date: string; start_date: string },
    thunkApi
  ) => {
    const { staticlist } = thunkApi.getState() as RootState;
    return await listStaticListContactsApi({
      search_keyword: searchText,
      page_size: staticlist.staticListContacts.pageSize,
      page_number: staticlist.staticListContacts.currentPageNo,
      end_date,
      start_date,
      static_list_id: id,
    });
  }
);

export const removeStaticListContacts = createAsyncThunk(
  "static-list/contacts/remove",
  async ({
    staticListId,
    emails,
  }: {
    staticListId: string;
    emails: string[];
  }) => {
    return await removeStaticListContactsApi(staticListId, emails);
  }
);

export const removeAllStaticListContacts = createAsyncThunk(
  "static-list/contacts/remove-all",
  async (staticListId: string) => {
    return await removeAllStaticListContactsApi(staticListId);
  }
);

export const previewStaticListDl = createAsyncThunk(
  "static-list/contacts/dl-preview",
  async (dynamicList: DynamicListType[]) => {
    return await getStaticListDlInsertPreviewApi(dynamicList);
  }
);

export const insertStaticListContactsDl = createAsyncThunk(
  "static-list/contacts/Dl-insert",
  async ({
    id,
    dynamicList,
  }: {
    id: string;
    dynamicList: DynamicListType[];
  }) => {
    return await getStaticListDlInsertApi(id, dynamicList);
  }
);

export const listStaticListSummaryList = createAsyncThunkWrapper({
  actionName: STATIC_LIST_ALL_ACTION,
  dispatchFn: async () => {
    return await listAllStaticListsApi();
  },
  isCachable: true,
});

const staticlistSlice = createSlice({
  name: "static-list",
  initialState,
  reducers: {
    setStaticListsPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.staticLists.totalPageCount ?? 1)) {
        state.staticLists.currentPageNo = pageNo;
        state.staticLists.changingPage = true;
      }
      // Call due to filter change will have pageNo as 0
      else {
        state.staticLists.currentPageNo = 1;
      }
    },
    resetData(state) {
      state.updatingStaticList = LOADING_STATES.INIT;
      state.createdStaticListId = "";
      state.staticListDeleting = LOADING_STATES.INIT;
    },
    setStaticListContactsPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.staticListContacts.totalPageCount ?? 1)) {
        state.staticListContacts.currentPageNo = pageNo;
        state.staticListContacts.changingPage = true;
      }
      // Call due to filter change will have pageNo as 0
      else {
        state.staticListContacts.currentPageNo = 1;
      }
    },
    resetDlUploadState(state) {
      state.staticListDl = initialState.staticListDl;
    },
    resetStaticDetailsPage(state) {
      state.staticListContacts = initialState.staticListContacts;
      state.staticListDl = initialState.staticListDl;
      state.staticListDetails = initialState.staticListDetails;
    },
    resetStaticListsList(state) {
      state.staticLists = initialState.staticLists;
    },
  },
  extraReducers: (builder) => {
    builder
      // static-list
      .addCase(listStaticLists.pending, (state) => {
        state.staticLists.fetchingList = true;
      })
      .addCase(listStaticLists.fulfilled, (state, action) => {
        state.staticLists.list = action.payload.records;
        state.staticLists.totalPageCount = action.payload.page_count;
        state.staticLists.count = action.payload.record_count;
        state.staticLists.fetchingList = false;
        state.staticLists.changingPage = false;
        state.staticListDeleting = LOADING_STATES.INIT;
      })
      .addCase(listStaticLists.rejected, (state) => {
        state.staticLists.fetchingList = false;
        state.staticLists.changingPage = false;
      })
      .addCase(createStaticList.pending, (state) => {
        state.updatingStaticList = LOADING_STATES.LOADING;
      })
      .addCase(createStaticList.fulfilled, (state, action) => {
        if (action.payload.static_list_id) {
          state.updatingStaticList = LOADING_STATES.SUCCESS;
          state.createdStaticListId = action.payload.static_list_id;
        } else state.updatingStaticList = LOADING_STATES.FAILED;
      })
      .addCase(createStaticList.rejected, (state) => {
        state.updatingStaticList = LOADING_STATES.FAILED;
      })
      .addCase(renameStaticList.pending, (state) => {
        state.updatingStaticList = LOADING_STATES.LOADING;
      })
      .addCase(renameStaticList.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.updatingStaticList = LOADING_STATES.SUCCESS;
        } else state.updatingStaticList = LOADING_STATES.FAILED;
      })
      .addCase(renameStaticList.rejected, (state) => {
        state.updatingStaticList = LOADING_STATES.FAILED;
      })
      .addCase(deleteStaticList.pending, (state) => {
        state.updatingStaticList = LOADING_STATES.LOADING;
      })
      .addCase(deleteStaticList.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.updatingStaticList = LOADING_STATES.SUCCESS;
        } else state.updatingStaticList = LOADING_STATES.FAILED;
      })
      .addCase(deleteStaticList.rejected, (state) => {
        state.updatingStaticList = LOADING_STATES.FAILED;
      })

      .addCase(getStaticList.pending, (state) => {
        state.staticListDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getStaticList.fulfilled, (state, action) => {
        state.staticListDetails.loading = LOADING_STATES.SUCCESS;
        state.staticListDetails.data = action.payload;
      })
      .addCase(getStaticList.rejected, (state) => {
        state.staticListDetails.loading = LOADING_STATES.FAILED;
      })

      .addCase(listStaticListContacts.pending, (state) => {
        state.staticListContacts.fetchingList = true;
      })
      .addCase(listStaticListContacts.fulfilled, (state, action) => {
        state.staticListContacts.list = action.payload.records;
        state.staticListContacts.totalPageCount = action.payload.page_count;
        state.staticListContacts.count = action.payload.record_count;
        state.staticListContacts.fetchingList = false;
        state.staticListContacts.changingPage = false;
      })
      .addCase(listStaticListContacts.rejected, (state) => {
        state.staticListContacts.fetchingList = false;
        state.staticListContacts.changingPage = false;
      })
      .addCase(removeStaticListContacts.pending, (state) => {
        state.staticListDeleting = LOADING_STATES.LOADING;
      })
      .addCase(removeStaticListContacts.fulfilled, (state, action) => {
        if (action.payload.deleted_rowcount)
          state.staticListDeleting = LOADING_STATES.SUCCESS;
        else state.staticListDeleting = LOADING_STATES.FAILED;
      })
      .addCase(removeStaticListContacts.rejected, (state) => {
        state.staticListDeleting = LOADING_STATES.FAILED;
      })
      .addCase(removeAllStaticListContacts.pending, (state) => {
        state.staticListDeleting = LOADING_STATES.LOADING;
      })
      .addCase(removeAllStaticListContacts.fulfilled, (state, action) => {
        if (action.payload.deleted_rowcount)
          state.staticListDeleting = LOADING_STATES.SUCCESS;
        else state.staticListDeleting = LOADING_STATES.FAILED;
      })
      .addCase(removeAllStaticListContacts.rejected, (state) => {
        state.staticListDeleting = LOADING_STATES.FAILED;
      })
      .addCase(previewStaticListDl.pending, (state) => {
        state.staticListDl.previewing = LOADING_STATES.LOADING;
      })
      .addCase(previewStaticListDl.fulfilled, (state, action) => {
        state.staticListDl.previewing = LOADING_STATES.SUCCESS;
        state.staticListDl.count = action.payload.count;
      })
      .addCase(previewStaticListDl.rejected, (state) => {
        state.staticListDl.previewing = LOADING_STATES.FAILED;
      })
      .addCase(insertStaticListContactsDl.pending, (state) => {
        state.staticListDl.inserting = LOADING_STATES.LOADING;
      })
      .addCase(insertStaticListContactsDl.fulfilled, (state, action) => {
        state.staticListDl.inserting = LOADING_STATES.SUCCESS;
        state.staticListDl.count = action.payload.copied_rowcount;
        toast.success(`${action.payload.copied_rowcount} contacts added`);
      })
      .addCase(insertStaticListContactsDl.rejected, (state) => {
        state.staticListDl.inserting = LOADING_STATES.FAILED;
      })

      .addCase(listStaticListSummaryList.pending, (state) => {
        state.staticListSummaryList.loading = LOADING_STATES.LOADING;
      })
      .addCase(listStaticListSummaryList.fulfilled, (state, action) => {
        state.staticListSummaryList.loading = LOADING_STATES.SUCCESS;
        state.staticListSummaryList.data = action.payload.records;
      })
      .addCase(listStaticListSummaryList.rejected, (state) => {
        state.staticListSummaryList.loading = LOADING_STATES.FAILED;
      });
  },
});

export const {
  setStaticListsPage,
  setStaticListContactsPage,
  resetData,
  resetDlUploadState,
  resetStaticDetailsPage,
  resetStaticListsList,
} = staticlistSlice.actions;

export const selectStaticList = (state: RootState) => state.staticlist;

export default staticlistSlice.reducer;
