import {
  EmailTokenDetails,
  EmailTokenUpdate,
  EmailTokenFunctions,
  EmailTokenColumn,
  EmailTokenPreviewReq,
  TokenValidity,
  EmailTokenPreviewData,
} from "../../types/campaign";
import { PERSON_ORG_MAPPING } from "../../types/person";
import { PaginatedList, SearchAssetsRequestType } from "../../types/common";
import { campaignRequest } from "./api";

export async function getEmailTokenListApi({
  pageSize,
  pageNo,
  searchKeyword,
  columnsToSearchIn,
}: SearchAssetsRequestType): Promise<PaginatedList<EmailTokenDetails>> {
  return campaignRequest({
    url: "/email-tokens/tokens.list",
    data: {
      page_size: pageSize,
      page_number: pageNo,
      query: {
        search: {
          keyword: searchKeyword,
          fields: columnsToSearchIn,
        },
      },
    },
  });
}

export async function getEmailTokenListAllApi(): Promise<{
  tokens: EmailTokenDetails[];
}> {
  return campaignRequest({
    url: "/email-tokens/tokens.list-all",
    data: null,
    shouldRetryApiCall: true,
  });
}

export async function getEmailTokenApi(
  tokenId: string
): Promise<{ token: EmailTokenDetails }> {
  return campaignRequest({
    url: "/email-tokens/tokens.get",
    data: { token_id: tokenId },
    shouldRetryApiCall: true,
  });
}

export async function createEmailTokenApi(
  name: string
): Promise<{ token: EmailTokenDetails }> {
  return campaignRequest({
    url: "/email-tokens/tokens.create",
    data: { name },
  });
}

export async function updateEmailTokenApi(
  data: EmailTokenUpdate
): Promise<{ token: EmailTokenDetails }> {
  return campaignRequest({
    url: "/email-tokens/tokens.update",
    data,
  });
}

export async function removeEmailTokenApi(
  tokenId: string
): Promise<{ token: boolean }> {
  return campaignRequest({
    url: "/email-tokens/tokens.delete",
    data: { token_id: tokenId },
  });
}

export async function getEmailTokenFunctionsApi(
  personOrgMapping: PERSON_ORG_MAPPING
): Promise<{
  functions: EmailTokenFunctions;
}> {
  return campaignRequest({
    url: "/email-tokens/functions.list",
    data: { person_org_mapping: personOrgMapping },
  });
}

export async function getEmailTokenColumnsApi(
  personOrgMapping: PERSON_ORG_MAPPING
): Promise<{
  columns: EmailTokenColumn[];
}> {
  return campaignRequest({
    url: "/email-tokens/columns.list",
    data: { person_org_mapping: personOrgMapping },
    shouldRetryApiCall: true,
  });
}

export async function getEmailTokenPreviewApi(
  data: EmailTokenPreviewReq
): Promise<{ result: EmailTokenPreviewData[] }> {
  return campaignRequest({
    url: "/email-tokens/tokens.preview",
    data,
  });
}

export async function verifyTokenApi(token: string): Promise<TokenValidity> {
  return campaignRequest({
    url: "/email-tokens/tokens.verify",
    data: { token },
    shouldRetryApiCall: true,
  });
}
