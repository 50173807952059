import { HStack, StackProps, Text, Icon } from "@chakra-ui/react";
import { ValueSelectFields } from "./DynamicListValueFields";
import { ReactSelectDefaultOptionsType } from "../../common/types/common";
import { IconType } from "react-icons/lib";

export default function ActivitySelector({
  activityList,
  onChange,
  value,
  displayText,
  validationError,
  loading,
  isDisabled,
  isReadOnly,
  icon,
  containerProps,
}: {
  activityList: ReactSelectDefaultOptionsType[];
  onChange: (value: string) => void;
  value: string | null;
  loading?: boolean;
  displayText?: string;
  validationError?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  icon?: IconType;
  containerProps?: StackProps;
}) {
  return (
    <HStack
      alignItems="center"
      {...containerProps}
      spacing={isReadOnly ? 1 : 2}
    >
      {isReadOnly ? (
        icon && <Icon as={icon} color="brand.blue" fontSize="sm" />
      ) : (
        <Text fontSize="sm">{displayText}</Text>
      )}

      <ValueSelectFields
        options={activityList}
        value={value || ""}
        onChange={onChange}
        loading={loading}
        validationError={validationError}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
      />
    </HStack>
  );
}
