import { Alert, AlertIcon, ModalProps } from "@chakra-ui/react";
import { FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { EmailConfigurationType } from "../common/types/campaign";
import GlobalEmailConfiguration from "../pages/dashboard/settings/components/GlobalEmailConfiguration";
import { selectSettings } from "../pages/dashboard/settings/settingsSlice";
import IModal from "./IModal";

export default function GlobalDefaultsModal({
  isOpen,
  onClose,
  ...props
}: {
  isOpen: boolean;
  onClose: () => void;
} & Partial<ModalProps>) {
  const [activeErrorCheck, setActiveErrorCheck] = useState(false);
  const { globalDefault } = useSelector(selectSettings);

  const globalEmailRef = useRef<{
    formRef: React.RefObject<FormikProps<EmailConfigurationType>> | null;
  }>({ formRef: null });

  useEffect(() => {
    if (!globalDefault.updating) {
      setActiveErrorCheck(false);
    }
  }, [globalDefault.updating]);

  function submit() {
    setActiveErrorCheck(true);
    if (globalEmailRef.current.formRef?.current) {
      globalEmailRef.current.formRef.current.submitForm();
    }
    onClose();
  }

  return (
    <IModal
      isOpen={isOpen}
      modalContentProps={{ minW: "xl" }}
      onClose={onClose}
      header={{ title: "Global email configuration" }}
      primaryButton={{
        label: "Save configurations",
        props: {
          onClick: submit,
          isDisabled: false,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: { onClick: onClose, isLoading: globalDefault.updating },
      }}
      {...props}
    >
      <>
        <Alert status="warning" fontSize="14px">
          <AlertIcon />
          Configuring the global email settings is mandatory before you start
          creating emails or journeys. These settings apply to all email
          templates under this organisation.
        </Alert>
        <GlobalEmailConfiguration
          validate={activeErrorCheck}
          parentRef={globalEmailRef}
          showSave={false}
        />
      </>
    </IModal>
  );
}
