import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function LayoutWithTable({
  height = "calc(100vh - 45px)",
  children,
}: {
  height?: string;
  children: ReactNode;
}) {
  return (
    <Box px="20px" height={height}>
      {children}
    </Box>
  );
}
