import {
  VStack,
  FormControl,
  HStack,
  Text,
  Icon,
  Box,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Tooltip,
} from "@chakra-ui/react";
import { capitalize, isInteger } from "lodash";
import {
  RECURRING_FREQUENCIES,
  PERIODIC_FREQUENCIES,
} from "../../../../common/constants/campaign";
import InputFieldWithError from "../../../../components/InputFieldWithError";
import DropdownWithSearch from "../../../../components/DropdownWithSearch";
import { BsThreeDotsVertical } from "react-icons/bs";
import NumberField, {
  NUMBER_FIELD_TYPES,
} from "../../../../components/NumberField";
import { useMemo, FocusEvent, memo } from "react";
import {
  SCHEDULE_ERROR_TYPES,
  SCHEDULE_VALUE_TYPES,
} from "../CampaignSchedule";
import { IoTrash, IoWarning } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import {
  getAbbreviatedTimezone,
  getFormattedTenantTimezone,
} from "../../../../common/helper/commonHelper";
import { useSelector } from "react-redux";
import { selectSettings } from "../../settings/settingsSlice";

type SET_FIELD_VALUE_TYPE = (
  field: string,
  value: any,
  shouldValidate?: boolean | undefined
) => void;

type ON_FORM_BLUR_TYPE = {
  (e: FocusEvent<any, Element>): void;
  <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
};

type ON_FORM_CHANGE_TYPE = {
  (e: React.ChangeEvent<any>): void;
  <T = string | React.ChangeEvent<any>>(
    field: T
  ): T extends React.ChangeEvent<any>
    ? void
    : (e: string | React.ChangeEvent<any>) => void;
};

type FORM_DATA_TYPE = {
  values: SCHEDULE_VALUE_TYPES;
  errors: SCHEDULE_ERROR_TYPES;
  setValue: SET_FIELD_VALUE_TYPE;
  onBlur: ON_FORM_BLUR_TYPE;
  onChange?: ON_FORM_CHANGE_TYPE;
};

const ShowUtcTimezoneOnHover = memo(() => {
  const {
    tenantDetails: { data: tenantDetailsData },
  } = useSelector(selectSettings);

  return (
    <Tooltip label={getFormattedTenantTimezone(tenantDetailsData)}>
      <InputRightAddon
        borderRightRadius="6px"
        bg="gray.50"
        fontSize="12px"
        color="blackAlpha.700"
      >
        {getAbbreviatedTimezone(tenantDetailsData.timezone)}
      </InputRightAddon>
    </Tooltip>
  );
});

function ShowValidationText({ error }: { error: string }) {
  return (
    <HStack hidden={!error} mt={1}>
      <Icon as={IoWarning} color="red.500" />
      <Text fontSize="12px" color="red.500">
        {error}
      </Text>
    </HStack>
  );
}

function AddEndDateText({
  onClick,

  isHidden,
}: {
  onClick: () => void;

  isHidden: boolean;
}) {
  return (
    <HStack
      _hover={{
        textDecoration: "underline",
        cursor: "pointer",
      }}
      color="brandBlue.500"
      pt={1}
      pl={1}
      onClick={onClick}
      hidden={isHidden}
    >
      <Icon as={FaPlus} fontSize="10px" />
      <Text fontSize="14px">Add end date</Text>
    </HStack>
  );
}

function StartAndEndDateTimeInput({
  startDate,
  endDate,
  invalidStartDate,
  invalidEndDate,
  setValue,
  onBlur,
}: {
  startDate: string | undefined;
  invalidStartDate: string | undefined;
  endDate: string | undefined;
  invalidEndDate: string | undefined;
  setValue: SET_FIELD_VALUE_TYPE;
  onBlur: ON_FORM_BLUR_TYPE;
}) {
  const isAddEndDate = endDate !== undefined;

  return (
    <>
      <StartDateTimeInput
        startDate={startDate}
        invalidStartDate={invalidStartDate}
        endDate={endDate}
        setValue={setValue}
        onBlur={onBlur}
      />
      <Box hidden={isAddEndDate} pb={2} pt={2}>
        <ShowValidationText error={invalidStartDate ?? ""} />
      </Box>
      <AddEndDateText
        onClick={() => {
          setValue("run_config.end_date", "");
        }}
        isHidden={isAddEndDate}
      />

      {isAddEndDate && (
        <>
          <Box pt={1}>
            <Icon as={BsThreeDotsVertical} color="brandBlue.500" ml={4} />
          </Box>
          <HStack w="100%">
            <EndDateTimeInput
              startDate={startDate}
              endDate={endDate}
              invalidEndDate={invalidEndDate || invalidStartDate}
              setValue={setValue}
              onBlur={onBlur}
            />
            <IconButton
              aria-label="trash-remove"
              icon={<Icon as={IoTrash} fontSize="12px" />}
              color="brandBlue.500"
              bg="gray.200"
              size="xs"
              _hover={{
                bg: "gray.100",
                cursor: "pointer",
              }}
              onClick={() => {
                setValue("run_config.end_date", undefined);
              }}
            />
          </HStack>

          <Box pt={2}>
            <ShowValidationText
              error={invalidStartDate ?? invalidEndDate ?? ""}
            />
          </Box>
        </>
      )}
    </>
  );
}

function StartDateTimeInput({
  startDate,
  invalidStartDate,
  endDate,
  setValue,
  onBlur,
}: {
  startDate: string | undefined;
  invalidStartDate: string | undefined;
  endDate: string | undefined;
  setValue: SET_FIELD_VALUE_TYPE;
  onBlur: ON_FORM_BLUR_TYPE;
}) {
  return (
    <InputGroup w="90%">
      <Input
        value={startDate}
        name="run_config.start_date"
        onChange={(e) => setValue("run_config.start_date", e.target.value)}
        onBlur={onBlur}
        width="90%"
        type="datetime-local"
        isInvalid={!!invalidStartDate}
        min={new Date().toISOString().slice(0, 16)}
        max={endDate ? new Date(endDate).toISOString().slice(0, 16) : undefined}
        borderRightRadius="0px"
      />
      <ShowUtcTimezoneOnHover />
    </InputGroup>
  );
}

function EndDateTimeInput({
  startDate,
  endDate,
  invalidEndDate,
  setValue,
  onBlur,
}: {
  startDate: string | undefined;
  endDate: string | undefined;
  invalidEndDate: string | undefined;
  setValue: SET_FIELD_VALUE_TYPE;
  onBlur: ON_FORM_BLUR_TYPE;
}) {
  return (
    <InputGroup w="90%">
      <Input
        value={endDate}
        name="run_config.end_date"
        onBlur={onBlur}
        onChange={(e) => {
          setValue("run_config.end_date", e.target.value);
        }}
        isInvalid={!!invalidEndDate}
        width="90%"
        type="datetime-local"
        borderRightRadius="0px"
        min={startDate ? startDate : new Date().toISOString().slice(0, 16)}
      />
      <ShowUtcTimezoneOnHover />
    </InputGroup>
  );
}

function WeekPicker({
  day,
  invalidDay,
  setValue,
}: {
  day: string | undefined;
  invalidDay: string | undefined;
  setValue: SET_FIELD_VALUE_TYPE;
}) {
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  return (
    <FormControl isInvalid={!!invalidDay} mt={4}>
      <Text fontSize="14px" color="blackAlpha.700" pb={2}>
        Run every
      </Text>
      <HStack>
        <>
          {days.map((value) => {
            const isSelected = day === value;
            return (
              <Box
                key={value}
                w="45px"
                h="40px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bg="white"
                color="brandBlue.500"
                borderRadius="6px"
                fontSize="14px"
                _hover={{
                  cursor: "pointer",
                  border: isSelected ? "2px solid" : "1px solid",

                  borderColor: "brandBlue.500",
                }}
                onClick={() => {
                  setValue("run_config.freq_options.day", value);
                }}
                border={isSelected ? "2px solid" : undefined}
                borderColor={isSelected ? "brandBlue.500" : undefined}
              >
                {capitalize(value[0])}
              </Box>
            );
          })}
        </>
      </HStack>
      <ShowValidationText error={invalidDay ? "Select day" : ""} />
    </FormControl>
  );
}

function MonthPicker({
  day,
  invalidDay,
  dateString,
  invalidDateString,
  setValue,
}: {
  day: string | undefined;
  invalidDay: string | undefined;
  dateString: string | undefined;
  invalidDateString: string | undefined;
  setValue: SET_FIELD_VALUE_TYPE;
}) {
  const options = [
    { value: "first_of_the_month", label: "First day of month" },
    { value: "last_of_month", label: "Last day of month" },
    { value: "custom", label: "Custom day" },
  ];

  const initValueForCustom = useMemo(() => {
    if (day && isInteger(day)) {
      return day;
    }
    return "";
  }, [day]);

  return (
    <>
      <HStack width="300px" align="flex-start">
        <Text fontSize="14px" color="blackAlpha.700" width="43%" pt={2}>
          Run every
        </Text>
        <FormControl isInvalid={!!invalidDateString}>
          <DropdownWithSearch
            options={options}
            value={options.find((item) => item.value === dateString)}
            onChange={(option) =>
              setValue(
                "run_config.freq_options.date_string",
                option?.value ?? ""
              )
            }
            isInvalid={!!invalidDateString}
            controlStyle={{
              width: dateString === "custom" ? "200px" : "100%",
            }}
          />
        </FormControl>
      </HStack>
      <ShowValidationText error={invalidDateString ?? ""} />
      {dateString === "custom" && (
        <HStack align="start" pt={3} width="300px">
          <Text fontSize="14px" color="blackAlpha.700" width="200px" pt="6px">
            Day of the month
          </Text>

          <FormControl isInvalid={!!invalidDay}>
            <NumberField
              type={NUMBER_FIELD_TYPES.INTEGER}
              min={1}
              max={31}
              width="100px"
              name="run_config.freq_options.day"
              bg="white"
              borderRadius="6px"
              onValueChange={(value) => {
                setValue("run_config.freq_options.day", value);
              }}
              value={initValueForCustom}
            />
            <ShowValidationText error={invalidDay ?? ""} />
          </FormControl>
        </HStack>
      )}
    </>
  );
}

function FormWrapper({ children }: { children: any }) {
  return (
    <>
      <VStack alignItems="left" spacing="2">
        {children}
      </VStack>
    </>
  );
}

export function ScheduleOnce({
  values,
  errors,
  setValue,
  onBlur,
}: FORM_DATA_TYPE) {
  return (
    <FormWrapper>
      <VStack align="flex-start" spacing="0px" width="430px">
        <Text fontSize="12px" color="blackAlpha.700" mt={1} mb={1}>
          You can schedule the journey to run only once
        </Text>
        <StartDateTimeInput
          startDate={values.startDate}
          invalidStartDate={errors.startDate}
          endDate={values.endDate}
          setValue={setValue}
          onBlur={onBlur}
        />
        <Box mt={2}>
          <ShowValidationText error={errors.startDate ?? ""} />
        </Box>
      </VStack>
    </FormWrapper>
  );
}

export function SchedulePeriodic({
  values,
  errors,
  setValue,
  onBlur,
}: FORM_DATA_TYPE) {
  const options = Object.values(PERIODIC_FREQUENCIES).map(
    (freq: PERIODIC_FREQUENCIES) => {
      return { label: capitalize(freq), value: freq };
    }
  );

  return (
    <FormWrapper>
      <Text fontSize="12px" color="blackAlpha.700" mt={1}>
        1. Schedule the start and stop date
      </Text>
      <VStack align="flex-start" spacing={-2} pl={3}>
        <StartAndEndDateTimeInput
          startDate={values.startDate}
          invalidStartDate={errors.startDate}
          endDate={values.endDate}
          invalidEndDate={errors.endDate}
          setValue={setValue}
          onBlur={onBlur}
        />
      </VStack>
      <Text fontSize="12px" color="blackAlpha.700">
        2. You can schedule the journey to reoccur accordingly
      </Text>
      <VStack align="flex-start" pl={5}>
        <HStack align="center" width="100%">
          <Text fontSize="14px" color="blackAlpha.700" pr={6}>
            Frequency
          </Text>
          <NumberField
            type={NUMBER_FIELD_TYPES.INTEGER}
            name="run_config.unit"
            onValueChange={(value) => {
              setValue("run_config.unit", value);
            }}
            value={values.unit ?? ""}
            isInvalid={!!errors.unit}
          />
          <FormControl isInvalid={!!errors.frequency}>
            <DropdownWithSearch
              options={options}
              value={options.find((item) => item.value === values.frequency)}
              onChange={(option) =>
                setValue("run_config.frequency", option?.value ?? "")
              }
              isInvalid={!!errors.frequency}
              controlStyle={{
                width: "120px",
              }}
            />
          </FormControl>
        </HStack>
        <ShowValidationText error={errors.unit ?? errors.frequency ?? ""} />
      </VStack>
    </FormWrapper>
  );
}

export function ScheduleRecurring({
  values,
  errors,
  setValue,
  onBlur,
  onChange,
}: FORM_DATA_TYPE) {
  function frequencyFormSwitch(frequency: RECURRING_FREQUENCIES) {
    switch (frequency) {
      case RECURRING_FREQUENCIES.WEEK:
        return (
          <WeekPicker
            day={values.day}
            invalidDay={errors.day}
            setValue={setValue}
          />
        );
      case RECURRING_FREQUENCIES.MONTH:
        return (
          <MonthPicker
            day={values.day}
            invalidDay={errors.day}
            dateString={values.dateString}
            invalidDateString={errors.dateString}
            setValue={setValue}
          />
        );
      case RECURRING_FREQUENCIES.YEAR:
        return (
          <>
            <HStack align="flex-start">
              <Text fontSize="12px" color="blackAlpha.700" pt={3}>
                Run every
              </Text>
              <Box pl={5}>
                <InputFieldWithError
                  labelText=""
                  value={values.date || ""}
                  errorMsg={""}
                  name="run_config.freq_options.date"
                  onChange={onChange}
                  onBlur={onBlur}
                  type="date"
                  width="97%"
                  formLabelProps={{ color: "gray.500", fontSize: "xs" }}
                />
              </Box>
            </HStack>

            <ShowValidationText error={errors.date ?? ""} />
          </>
        );
      case RECURRING_FREQUENCIES.DAY:
        return (
          <>
            <HStack align="flex-start">
              <Text
                fontSize="12px"
                color="blackAlpha.700"
                width="120px"
                pt="6px"
              >
                Every day(s)
              </Text>
              <FormControl isInvalid={!!errors.unit}>
                <Box>
                  <NumberField
                    type={NUMBER_FIELD_TYPES.INTEGER}
                    min={1}
                    value={values.unit ?? ""}
                    width="43%"
                    name="run_config.freq_options.unit"
                    bg="white"
                    borderRadius="6px"
                    onValueChange={(value) => {
                      setValue("run_config.freq_options.unit", value);
                    }}
                    onBlur={onBlur}
                    isInvalid={!!errors.unit}
                  />
                </Box>
              </FormControl>
            </HStack>
            <ShowValidationText error={errors.unit ?? ""} />
          </>
        );
    }
  }

  const options = Object.values(RECURRING_FREQUENCIES).map((freq) => {
    return { label: capitalize(freq), value: freq };
  });

  return (
    <FormWrapper>
      <Text fontSize="12px" color="blackAlpha.700" mt={1}>
        1. Schedule the start and stop date
      </Text>
      <VStack spacing={-2} align="start" pl={3} w="420px">
        <StartAndEndDateTimeInput
          startDate={values.startDate}
          invalidStartDate={errors.startDate}
          endDate={values.endDate}
          invalidEndDate={errors.endDate}
          setValue={setValue}
          onBlur={onBlur}
        />
      </VStack>
      <Text fontSize="12px" color="blackAlpha.700" py="8px">
        2. You can schedule the journey to reoccur accordingly
      </Text>
      <VStack align="flex-start" pl={5}>
        <HStack align="center" spacing="0px">
          <Text fontSize="12px" color="blackAlpha.700" width="150px">
            Frequency
          </Text>
          <FormControl isInvalid={!!errors.frequency}>
            <DropdownWithSearch
              options={options}
              value={options.find((item) => item.value === values.frequency)}
              onChange={(option) =>
                setValue("run_config.frequency", option?.value ?? "")
              }
              isInvalid={!!errors.frequency}
              controlStyle={{
                width: "110px",
              }}
            />
          </FormControl>
        </HStack>

        <ShowValidationText error={errors.frequency ?? ""} />

        {values.frequency &&
          frequencyFormSwitch(values.frequency as RECURRING_FREQUENCIES)}
      </VStack>
    </FormWrapper>
  );
}
