import { IconButton, Icon, IconButtonProps } from "@chakra-ui/react";
import { IoClose } from "react-icons/io5";

export default function RemoveRowCloseButton({
  onClick,
  ...props
}: { onClick: () => void } & Partial<IconButtonProps>) {
  return (
    <IconButton
      rounded="full"
      name="remove-button"
      variant="ghost"
      size="sm"
      icon={<Icon fontSize="18px" as={IoClose} />}
      onClick={onClick}
      color="red.600"
      {...props}
      aria-label="Remove"
    />
  );
}
