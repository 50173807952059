import { Image } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StaticListDetails } from "../../../common/types/campaign";
import {
  findUserFromMapping,
  getTableRowLinkProps,
  getUserStatusFromMapping,
  isLoading,
  isSuccess,
} from "../../../common/helper/commonHelper";
import {
  ASSET_TYPES,
  DELETION_MODAL_TYPES_INFO,
  LOADING_STATES,
} from "../../../common/constants/common";
import { useNavigate } from "react-router-dom";
import urls from "../../../urls";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import EditNameModal, {
  ASSET_NAME_ACTIONS,
} from "../../../components/EditNameModal";
import {
  createStaticList,
  deleteStaticList,
  listStaticLists,
  renameStaticList,
  resetData,
  resetStaticListsList,
  selectStaticList,
  setStaticListsPage,
} from "./staticlistSlice";
import { staticListColumnDefs } from "./components/tableDefinitions";
import { debounce } from "lodash";
import { getUserList, selectAccount } from "../../account/accountSlice";
import { CommonListHeader } from "../../../components/CommonListHeader";
import { DataTable } from "../../../components/data-table/DataTable";
import LayoutWithTable from "../../../layout/LayoutWithTable";
import { useSearch } from "../../../common/hooks/commonHooks";
import InitialEmptyState from "../../../components/InitialEmptyState";
import StaticListEmptyState from "../../../common/img/emptyStateLogos/staticList.svg";

function StaticListHeader({
  clickHandler,
  loading,
  searchKeyword,
  onSearch,
}: {
  clickHandler: () => void;
  loading: LOADING_STATES;
  searchKeyword: string;
  onSearch: (value: string) => void;
}) {
  return (
    <CommonListHeader
      heading="Static lists"
      searchInputProps={{
        placeholder: "Search static lists",
        name: "search-input",
        onSearch: onSearch,
        value: searchKeyword,
      }}
      createButtonProps={{
        name: "add-static-list",
        onClick: clickHandler,
        isLoading: isLoading(loading),
        text: "New static list",
      }}
    />
  );
}

export default function StaticLists() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isEditModalOpen, setIsEditModalOpen] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<StaticListDetails | null>(
    null
  );
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState("");
  const [searchKeyword, setSearchKeyword] = useSearch();

  const {
    staticLists,
    updatingStaticList,
    createdStaticListId,
    staticListDeleting,
  } = useSelector(selectStaticList);
  const { usersList, fetchingUsersList } = useSelector(selectAccount);

  useEffect(() => {
    dispatch(listStaticLists(searchKeyword));

    return () => {
      dispatch(resetData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, staticLists.currentPageNo]);

  useEffect(() => {
    return () => {
      dispatch(resetStaticListsList());
    };
  }, [dispatch]);

  const searchIfValid = useCallback(
    (searchText: string) => {
      if (staticLists.currentPageNo !== 1) {
        dispatch(setStaticListsPage(0));
      } else {
        dispatch(listStaticLists(searchText));
      }
    },
    [dispatch, staticLists.currentPageNo]
  );

  const debouncedSearch = useMemo(
    () => debounce(searchIfValid, 1000),
    [searchIfValid]
  );

  useEffect(() => {
    if (isSuccess(updatingStaticList)) {
      dispatch(listStaticLists(searchKeyword));
      dispatch(resetData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updatingStaticList]);

  useEffect(() => {
    if (createdStaticListId) {
      dispatch(resetData());
      navigate(`${urls.staticList}/${createdStaticListId}`);
    }
  }, [navigate, dispatch, createdStaticListId]);

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  function onDeleteClick(row: StaticListDetails) {
    setSelectedRow(row);
    setIsOpenDeleteModal(row.static_list_id);
  }

  function deleteStaticListHandler() {
    if (isOpenDeleteModal) {
      dispatch(deleteStaticList(isOpenDeleteModal));
      setIsOpenDeleteModal("");
      setSelectedRow(null);
    }
  }

  function onRenameClick(row: StaticListDetails) {
    setSelectedRow(row);
    setIsEditModalOpen(row.name);
  }

  function renameStaticListHandler(name: string) {
    if (selectedRow) {
      dispatch(
        renameStaticList({ name, staticListId: selectedRow.static_list_id })
      );
      setIsEditModalOpen(null);
      setSelectedRow(null);
    }
  }

  function createStaticListHandler(name: string) {
    setIsEditModalOpen(null);
    dispatch(createStaticList(name));
  }

  function goToStaticListDetails(list: StaticListDetails) {
    navigate(`${urls.staticList}/${list.static_list_id}`);
  }

  const getColumns = useCallback(
    () =>
      staticListColumnDefs(
        usersList,
        onDeleteClick,
        onRenameClick,
        findUserFromMapping,
        getUserStatusFromMapping
      ),
    [usersList]
  );

  return (
    <>
      <StaticListHeader
        clickHandler={() => setIsEditModalOpen("")}
        loading={updatingStaticList}
        searchKeyword={searchKeyword}
        onSearch={(value) => {
          setSearchKeyword(value);
          debouncedSearch(value);
        }}
      />
      {staticLists.totalPageCount !== 0 || searchKeyword ? (
        <LayoutWithTable>
          <DataTable
            fetchingList={staticLists.fetchingList || fetchingUsersList}
            changingPage={staticLists.changingPage}
            list={staticLists.list}
            totalPageSize={staticLists.pageSize}
            totalPageCount={staticLists.totalPageCount}
            currentPage={staticLists.currentPageNo}
            onRowClick={goToStaticListDetails}
            setPage={(pageNo) => dispatch(setStaticListsPage(pageNo))}
            columns={getColumns()}
            emptyMsg={`No static list found.${
              searchKeyword ? " Please change the search / filter values" : ""
            }`}
            getTableRowLinkProps={(data) =>
              getTableRowLinkProps(
                { to: urls.staticList, editParam: "static_list_id" },
                data
              )
            }
          />
        </LayoutWithTable>
      ) : (
        <InitialEmptyState
          mainText="Create lists of your Contacts for quick access"
          message="No Static lists"
          ctaProps={{
            children: "Create static list",
            name: "new-static-list",
            onClick: () => setIsEditModalOpen(""),
            isLoading: isLoading(updatingStaticList),
          }}
        >
          <Image src={StaticListEmptyState} alt="Static list" />
        </InitialEmptyState>
      )}
      <EditNameModal
        value={isEditModalOpen ?? ""}
        action={
          isEditModalOpen
            ? ASSET_NAME_ACTIONS.RENAME
            : ASSET_NAME_ACTIONS.CREATE
        }
        isOpen={isEditModalOpen !== null}
        onClose={() => setIsEditModalOpen(null)}
        onSubmit={
          isEditModalOpen ? renameStaticListHandler : createStaticListHandler
        }
        asset="static list"
      />
      <DeleteConfirmationModal
        isOpen={!!isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal("")}
        submitHandler={deleteStaticListHandler}
        itemSpecificName={selectedRow?.name ?? ""}
        deleteItemType={
          DELETION_MODAL_TYPES_INFO[ASSET_TYPES.STATIC_LIST].display
        }
        isLoading={isLoading(staticListDeleting)}
      />
    </>
  );
}
