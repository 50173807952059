import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { SEARCH_QUERY_PARAM } from "../constants/common";
import { formatLastSaved } from "../helper/commonHelper";
import { useTheme } from "@chakra-ui/react";

export function useSearch(): [string, (searchKeyword: string) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParam = searchParams.get(SEARCH_QUERY_PARAM) ?? "";

  function setSearchParam(searchKeyword: string) {
    setSearchParams(
      searchKeyword ? { [SEARCH_QUERY_PARAM]: searchKeyword } : {}
    );
  }

  return [searchParam, setSearchParam];
}

const TIME_UNIT = 20000;

export function useLastSavedStatus(updatedAt: string | number) {
  const [lastSaved, setLastSaved] = useState("");

  const updateLastSavedStatus = useCallback((updatedAt: string | number) => {
    if (updatedAt) {
      setLastSaved(formatLastSaved({ updatedAt, includeSeconds: true }));
    }
  }, []);

  useEffect(() => {
    updateLastSavedStatus(updatedAt);
    const intervalId = setInterval(
      () => updateLastSavedStatus(updatedAt),
      TIME_UNIT
    );
    return () => clearInterval(intervalId);
  }, [updatedAt, updateLastSavedStatus]);

  return [lastSaved];
}

export function useDebouncedSearch({
  searchIfValid,
  delay = 1000,
}: {
  searchIfValid: (searchKeyword: string) => void;
  delay?: number;
}) {
  return useMemo(() => debounce(searchIfValid, delay), [searchIfValid, delay]);
}

export function useUpdateWithInitValue<T>(data: T) {
  const [currentState, setCurrentState] = useState(data);

  useEffect(() => {
    setCurrentState(data);
  }, [data]);

  return [currentState, setCurrentState] as [
    T,
    React.Dispatch<React.SetStateAction<T>>
  ];
}

export function useHover(): [
  boolean,
  { onMouseEnter(): void; onMouseLeave(): void }
] {
  const [hovered, setHovered] = useState(false);

  const eventHandlers = useMemo(
    () => ({
      onMouseEnter() {
        setHovered(true);
      },
      onMouseLeave() {
        setHovered(false);
      },
    }),
    []
  );

  return [hovered, eventHandlers];
}

export function usePreventRefresh({
  preventRefresh,
  warningMessage,
}: {
  preventRefresh: boolean;
  warningMessage?: string;
}) {
  useEffect(() => {
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      const warningMsg = warningMessage ? warningMessage : "Discard changes?";
      event.returnValue = warningMsg;
      return warningMsg;
    }

    if (preventRefresh) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [preventRefresh, warningMessage]);
}

export function useExponentialPolling({
  initialWaitTime = 2,
  exponent = 2,
  maxPollingSeconds = 16,
  shouldPoll,
  onPolling,
}: {
  initialWaitTime?: number;
  onPolling: () => void;
  shouldPoll: boolean;
  exponent?: number;
  maxPollingSeconds?: number;
}) {
  const timeoutIds = useRef<NodeJS.Timeout[]>([]);

  const exponentialPolling = useCallback(
    (waitTime: number) => {
      if (!shouldPoll) {
        return;
      }

      const timeoutId = setTimeout(() => {
        onPolling();

        const nextWaitTime = waitTime * exponent;
        if (nextWaitTime === 0) {
          exponentialPolling(exponent);
        } else if (nextWaitTime <= maxPollingSeconds) {
          exponentialPolling(nextWaitTime);
        } else {
          exponentialPolling(maxPollingSeconds);
        }
      }, waitTime * 1000);
      timeoutIds.current.push(timeoutId);
    },

    [exponent, maxPollingSeconds, onPolling, shouldPoll]
  );

  useEffect(() => {
    if (shouldPoll) {
      exponentialPolling(initialWaitTime);
    }
    return () => {
      timeoutIds.current.forEach(clearTimeout);
      timeoutIds.current = [];
    };

    // should run only once , to start polling when initial time changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialWaitTime, shouldPoll]);
}

export function useChakraColors() {
  const theme = useTheme();
  return {
    //red
    red500: theme.__cssVars["--chakra-colors-red-500"],
    //blue
    blue500: theme.__cssVars["--chakra-colors-blue-500"],
    //gray
    gray100: theme.__cssVars["--chakra-colors-gray-100"],
    gray200: theme.__cssVars["--chakra-colors-gray-200"],
    gray300: theme.__cssVars["--chakra-colors-gray-300"],
    gray400: theme.__cssVars["--chakra-colors-gray-400"],
    gray800: theme.__cssVars["--chakra-colors-gray-800"],

    //text
    text200: theme.__cssVars["--chakra-colors-text-200"],
  };
}
