import { Box, HStack, VStack } from "@chakra-ui/layout";
import { Icon, Text, useMediaQuery } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import React, { useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { FaRegIdCard } from "react-icons/fa";
import { AudienceCriteriaContext } from "../AudienceCriteria";
import NodeGroupBox from "../components/NodeGroupBox";
import {
  ComparisonNode,
  Condition,
  TargetValueTypes,
} from "../../../../../common/types/dynamicList";
import {
  COMPARISON_OPERATORS,
  LOGICAL_OPERATORS,
  DL_TARGET_VALUE_TYPES,
} from "../../../../../common/constants/dynamicList";
import {
  FILTER_TYPE,
  FILTER_TABLE_NAME,
} from "../../../../../common/constants/campaign";
import { TableList, OperatorType } from "../../../../../common/types/campaign";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";
import AddPropertyButton from "../../../../../components/dynamic-list/AddPropertyButton";
import { ValueSelectFields } from "../../../../../components/dynamic-list/DynamicListValueFields";
import { selectDynamicList } from "../../../../../components/dynamic-list/dynamicListSlice";
import LogicGate from "../components/LogicGate";
import AudienceCriteriaValueFields from "../components/AudienceCriteriaValueFields";

enum Fields {
  LOGICAL_OPERATOR = "logical_operator",
  PROPERTY_NAME = "property_name",
  OPERATOR = "operator",
  VALUE = "value",
}

function getFirstConditionGroup(nodeData: ComparisonNode) {
  return nodeData.conditionGroups.length ? nodeData.conditionGroups[0] : null;
}

type onConditionChangeType = (
  field: Fields,
  value: TargetValueTypes | COMPARISON_OPERATORS | LOGICAL_OPERATORS | string,
  valueType?: DL_TARGET_VALUE_TYPES
) => void;

function PersonFilterCondition({
  onConditionChange,
  condition,
  showRemoveButton,
  onRemoveCondition,
  showAddButton,
  onAddCondition,
  isReadOnly,
}: {
  onConditionChange: onConditionChangeType;
  condition: Condition;
  showRemoveButton: boolean;
  onRemoveCondition: () => void;
  showAddButton: boolean;
  onAddCondition: () => void;
  isReadOnly?: boolean;
}) {
  const [isSmallerThan1024] = useMediaQuery("(max-width: 1024px)");

  const { filterList } = useSelector(selectDynamicList);

  const { operators, activeErrorCheck, campaignContext } = useContext(
    AudienceCriteriaContext
  );

  const tableList = useMemo(
    () => filterList[campaignContext]?.[FILTER_TYPE.PERSON],
    [campaignContext, filterList]
  );

  const { propertyName, comparisonOperator, targetValue, logicalOperator } =
    condition;

  const formatTableList = useCallback((list: TableList) => {
    return Object.entries((list as TableList)[FILTER_TABLE_NAME.PERSON] ?? {})
      .filter(([key, value]) => !value.ui_hidden)
      .map(([key, value]) => {
        return { label: value.display_name || key, value: key };
      });
  }, []);

  const getOperatorList = useCallback(
    (colType: string) => {
      return operators?.[colType as OperatorType];
    },
    [operators]
  );

  const tableArray: { label: string; value: string }[] = useMemo(
    () => formatTableList(tableList?.data ?? {}),
    [formatTableList, tableList?.data]
  );

  const colType = useMemo(
    () => (propertyName ? findColType(propertyName, tableList?.data) : null),
    [propertyName, tableList]
  );

  const operatorList = useMemo(
    () => (colType ? getOperatorList(colType) : null),
    [getOperatorList, colType]
  );

  const operatorDetails = useMemo(
    () =>
      comparisonOperator && operatorList
        ? operatorList[comparisonOperator]
        : null,
    [comparisonOperator, operatorList]
  );

  function setPropertyName(value: string) {
    onConditionChange(Fields.PROPERTY_NAME, value);
  }

  function setOperator(value: COMPARISON_OPERATORS) {
    onConditionChange(Fields.OPERATOR, value, operatorList?.[value]?.valueType);
  }

  function setValue(value: TargetValueTypes) {
    onConditionChange(Fields.VALUE, value);
  }

  function setLogicalOperator(value: LOGICAL_OPERATORS) {
    onConditionChange(Fields.LOGICAL_OPERATOR, value);
  }

  function findColType(filter: string, filterList?: TableList): string {
    return (
      (filterList as TableList)?.[FILTER_TYPE.PERSON]?.[filter]?.type ?? null
    );
  }

  const wrapperStyle = isReadOnly
    ? {
        spacing: "1",
      }
    : {
        spacing: "2",
        gridGap: "1",
      };

  const isInvalidPropertyName = activeErrorCheck && !propertyName;
  const isInvalidOperator = activeErrorCheck && !comparisonOperator;

  function renderPersonFilterCondition() {
    return (
      <>
        <HStack id="person-activity-selector">
          <ValueSelectFields
            options={tableArray}
            value={propertyName || null}
            onChange={setPropertyName}
            validationError={isInvalidPropertyName ? "Invalid property" : ""}
            isReadOnly={isReadOnly}
          />
        </HStack>

        {propertyName && colType && operatorList && (
          <HStack>
            <ValueSelectFields
              options={Object.values(operatorList).map((op) => ({
                label: op.display,
                value: op.id,
              }))}
              value={comparisonOperator || ""}
              onChange={(op) => setOperator(op as COMPARISON_OPERATORS)}
              validationError={isInvalidOperator ? "Invalid operator" : ""}
              isReadOnly={isReadOnly}
            />
          </HStack>
        )}

        {comparisonOperator && colType && operatorDetails && (
          <HStack>
            <AudienceCriteriaValueFields
              value={targetValue}
              onChange={(val) => setValue(val)}
              argumentTypes={operatorDetails.arguments_types}
              helperText={operatorDetails.display_2}
              isReadOnly={isReadOnly}
              filterValue={propertyName ?? null}
              noOfArguments={operatorDetails.arguments}
              activeErrorCheck={activeErrorCheck}
            />
          </HStack>
        )}
      </>
    );
  }

  if (isReadOnly) {
    return (
      <VStack alignItems="flex-start" spacing="1">
        <LogicGate
          operator={logicalOperator}
          handleChange={setLogicalOperator}
          isReadOnly={isReadOnly}
        />

        <HStack alignItems="center" width="100%" wrap="wrap" {...wrapperStyle}>
          <Icon as={FaRegIdCard} color="brand.blue" mr={1} />
          {renderPersonFilterCondition()}
        </HStack>
      </VStack>
    );
  } else {
    return (
      <HStack alignItems="flex-start" wrap="wrap" {...wrapperStyle}>
        {logicalOperator && logicalOperator !== LOGICAL_OPERATORS.FIRST ? (
          <LogicGate
            operator={logicalOperator}
            handleChange={setLogicalOperator}
          />
        ) : (
          <HStack h="33px">
            <Text fontSize="sm">...a person property of...</Text>
          </HStack>
        )}

        {renderPersonFilterCondition()}

        {showAddButton && comparisonOperator && (
          <AddPropertyButton
            iconOnly={isSmallerThan1024}
            onClick={onAddCondition}
          />
        )}

        {showRemoveButton && (
          <RemoveRowCloseButton onClick={onRemoveCondition} />
        )}
      </HStack>
    );
  }
}

const PersonFilterNodeCriteria = React.memo(
  ({
    nodeData,
    label,
    id,
    onChange,
    onRemove,
    isReadOnly,
  }: {
    nodeData: ComparisonNode;
    label: string;
    id: string;
    onChange: (data: ComparisonNode) => void;
    onRemove: () => void;
    isReadOnly?: boolean;
  }) => {
    function onConditionChange(
      field: Fields,
      index: number,
      value:
        | COMPARISON_OPERATORS
        | TargetValueTypes
        | LOGICAL_OPERATORS
        | string,

      valueType?: DL_TARGET_VALUE_TYPES
    ) {
      const dataCopy = cloneDeep(nodeData);

      // all filters/conditions are added to first condition group
      const firstConditionGroup = getFirstConditionGroup(dataCopy);

      if (firstConditionGroup) {
        switch (field) {
          case Fields.PROPERTY_NAME:
            if (firstConditionGroup.conditions[index].propertyName !== value) {
              firstConditionGroup.conditions[index].propertyName =
                value as string;
              firstConditionGroup.conditions[index].comparisonOperator = null;
              firstConditionGroup.conditions[index].targetValue = [];
              firstConditionGroup.conditions[index].targetValueType = null;
            }
            break;
          case Fields.OPERATOR:
            if (
              firstConditionGroup.conditions[index].comparisonOperator !== value
            ) {
              firstConditionGroup.conditions[index].comparisonOperator =
                value as COMPARISON_OPERATORS;

              firstConditionGroup.conditions[index].targetValueType =
                valueType as DL_TARGET_VALUE_TYPES;
              //todo: separate component for boolean type
              if (valueType && valueType === DL_TARGET_VALUE_TYPES.BOOLEAN) {
                switch (value) {
                  case COMPARISON_OPERATORS.CONTAINS:
                    firstConditionGroup.conditions[index].targetValue = [
                      "true",
                    ];
                    break;
                  case COMPARISON_OPERATORS.EQUALS:
                    firstConditionGroup.conditions[index].targetValue = [
                      "false",
                    ];
                    break;
                }
              } else {
                firstConditionGroup.conditions[index].targetValue = [];
              }
            }
            break;
          case Fields.VALUE:
            firstConditionGroup.conditions[index].targetValue =
              value as TargetValueTypes;
            break;
          case Fields.LOGICAL_OPERATOR:
            firstConditionGroup.conditions[index].logicalOperator =
              value as LOGICAL_OPERATORS;
            break;
        }
        dataCopy.conditionGroups = [firstConditionGroup];
      }

      onChange(dataCopy);
    }

    function onRemoveCondition(index: number) {
      const dataCopy = cloneDeep(nodeData);
      const firstConditionGroup = getFirstConditionGroup(dataCopy);
      if (firstConditionGroup) {
        firstConditionGroup.conditions = firstConditionGroup.conditions.filter(
          (_, i) => i !== index
        );
        if (firstConditionGroup.conditions.length) {
          firstConditionGroup.conditions[0].logicalOperator =
            LOGICAL_OPERATORS.FIRST;
        }
        dataCopy.conditionGroups = [firstConditionGroup];
      }
      onChange(dataCopy);
    }

    function onAddConditionToConditionGroup() {
      const dataCopy = cloneDeep(nodeData);
      const firsConditionGroup = getFirstConditionGroup(dataCopy);
      const newCondition: Condition = {
        logicalOperator: LOGICAL_OPERATORS.AND,
        propertyName: null,
        comparisonOperator: null,
        targetValueType: null,
        targetValue: [],
      };
      if (firsConditionGroup) {
        firsConditionGroup.conditions.push(newCondition);
        dataCopy.conditionGroups = [firsConditionGroup];
      } else {
        dataCopy.conditionGroups = [
          {
            logicalOperator: LOGICAL_OPERATORS.FIRST,
            conditions: [
              { ...newCondition, logicalOperator: LOGICAL_OPERATORS.FIRST },
            ],
          },
        ];
      }

      onChange(dataCopy);
    }

    const wrapperStyle = isReadOnly
      ? {
          py: "0",
          px: "0",
          spacing: "1",
        }
      : {
          py: "2",
          px: "3",
          spacing: "4",
        };

    const totalConditions =
      getFirstConditionGroup(nodeData)?.conditions.length ?? 0;
    return (
      <NodeGroupBox
        id={id}
        nodeName={label}
        onRemove={onRemove}
        isReadOnly={isReadOnly}
        bg={!isReadOnly ? "grayV2.200" : ""}
      >
        <VStack
          bg={!isReadOnly ? "grayV2.200" : ""}
          alignItems="flex-start"
          {...wrapperStyle}
          w="100%"
        >
          {nodeData.conditionGroups[0].conditions.length &&
            nodeData.conditionGroups[0].conditions.map((condition, index) => {
              return (
                <Box key={index} width="100%">
                  <PersonFilterCondition
                    condition={condition}
                    onAddCondition={onAddConditionToConditionGroup}
                    onConditionChange={(field, value, valueType) =>
                      onConditionChange(field, index, value, valueType)
                    }
                    onRemoveCondition={() => onRemoveCondition(index)}
                    showAddButton={index === totalConditions - 1}
                    showRemoveButton={!!totalConditions && totalConditions > 1}
                    isReadOnly={isReadOnly}
                  />
                </Box>
              );
            })}
        </VStack>
      </NodeGroupBox>
    );
  }
);

export default PersonFilterNodeCriteria;
