import IModal from "../../../../../components/IModal";
import {
  HStack,
  Text,
  VStack,
  Link,
  Divider,
  Icon,
  Box,
} from "@chakra-ui/react";
import {
  SalesforceSyncError,
  SalesforceSyncErrorQuery,
  SALESFORCE_SYNC_ERROR_TYPE,
} from "../../../../../common/types/connection";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../../../store";
import {
  getSalesforceErrorQuery,
  selectConnection,
  setSalesforceErrorModalPage,
} from "../../connectionSlice";
import { useSelector } from "react-redux";
import {
  openSalesforceInstance,
  validateUrl,
} from "../../../../../common/helper/commonHelper";
import { FaExternalLinkAlt } from "react-icons/fa";
import SubHeader from "../../components/SubHeader";
import { debounce } from "lodash";
import { SearchField } from "../../../../../components/SearchField";
import TableEntryText from "../../../../../components/TableEntryText";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../../../../components/data-table/DataTable";
import { FormatDate } from "../../../../../components/DateTimeRangeFilter";
import urls from "../../../../../urls";

const ERROR_PAGE_SIZE = 5;

const errorTypeMessage: {
  [key in SALESFORCE_SYNC_ERROR_TYPE]: { errorTypeDetails: string };
} = {
  [SALESFORCE_SYNC_ERROR_TYPE.OTHERS]: {
    errorTypeDetails:
      "The records listed below aren't syncing because of an unknown error. Please review the error details to debug the issue",
  },
  [SALESFORCE_SYNC_ERROR_TYPE.PERMISSION]: {
    errorTypeDetails:
      "These Inflection records couldn't sync to Salesforce because the integration user doesn't have the required Salesforce field permissions",
  },
  [SALESFORCE_SYNC_ERROR_TYPE.NONE]: {
    errorTypeDetails: "",
  },
};

function ErrorModalHeader({
  searchKeyword,
  onSearch,
  totalRecords,
}: {
  searchKeyword: string;
  onSearch: (keyword: string) => void;
  totalRecords: number;
}) {
  return (
    <SubHeader title={`Affected Records (${totalRecords})`}>
      <Box pl="480px">
        <SearchField
          value={searchKeyword}
          name="search-input"
          onSearch={onSearch}
        />
      </Box>
    </SubHeader>
  );
}

export default function SalesforceAffectedRecordsModal({
  isOpen,
  onClose,
  errorType,
  data,
}: {
  isOpen: boolean;
  onClose: () => void;
  errorType: SALESFORCE_SYNC_ERROR_TYPE;
  data: SalesforceSyncError | null;
}) {
  const dispatch = useAppDispatch();
  const {
    salesforce: { sfAffectedRecords, connection },
  } = useSelector(selectConnection);
  const [searchKeyword, setSearchKeyword] = useState("");

  const redirectSalesforceWindow = useCallback(
    (row: SalesforceSyncErrorQuery) => {
      openSalesforceInstance({
        url: connection?.instance_url as string,
        crmObject: "users",
        salesforceId: row.salesforce_id,
      });
    },
    [connection?.instance_url]
  );

  function onModalClose() {
    dispatch(setSalesforceErrorModalPage(1));
    setSearchKeyword("");
    onClose();
  }

  const isInstanceUrlValid: boolean = useMemo(
    () =>
      !!connection &&
      (validateUrl(connection.instance_url) ||
        validateUrl("https://" + connection.instance_url)),
    [connection]
  );

  useEffect(() => {
    if (data) {
      dispatch(
        getSalesforceErrorQuery({
          errorClass: data?.error_class as string,
          pageSize: ERROR_PAGE_SIZE,
          pageNo: sfAffectedRecords.currentPageNo,
        })
      );
    }
  }, [dispatch, data, sfAffectedRecords.currentPageNo]);

  const columnHelper = createColumnHelper<SalesforceSyncErrorQuery>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        size: 300,
        cell: (info) => <Text>{info.getValue() ?? "Not Available"}</Text>,
      }),
      columnHelper.accessor("error_text", {
        header: "Error details",
        size: 440,
        cell: (info) => (
          <TableEntryText>{info.getValue() ?? "Not Available"}</TableEntryText>
        ),
      }),
      columnHelper.accessor("inflection_id", {
        header: "View record on",
        cell: (info) => {
          return (
            <HStack>
              {info.getValue() ? (
                <Link
                  href={`${urls.person}/${info.getValue()}`}
                  color="teal.300"
                >
                  Inflection Link
                  <Icon fontSize="17px" pt="2px" ml="2px">
                    <FaExternalLinkAlt />
                  </Icon>
                </Link>
              ) : (
                <Text>Not Available</Text>
              )}
              <Divider
                orientation="vertical"
                borderColor="blackAlpha.400"
                height="20px"
                mx="2px"
              />
              {isInstanceUrlValid && info.row.original.salesforce_id ? (
                <Link
                  onClick={() => redirectSalesforceWindow(info.row.original)}
                  color="blue.600"
                >
                  Salesforce Link
                  <Icon fontSize="17px" pt="2px" ml="2px">
                    <FaExternalLinkAlt />
                  </Icon>
                </Link>
              ) : (
                <Text>Not Available</Text>
              )}
            </HStack>
          );
        },
        size: 400,
      }),
      columnHelper.accessor("_created_time", {
        header: "Error date",
        cell: (info) => <FormatDate date={info.getValue()} />,
        size: 250,
      }),
    ],
    [columnHelper, isInstanceUrlValid, redirectSalesforceWindow]
  );

  const searchIfValid = useCallback(
    (searchKeyword: string) => {
      if (sfAffectedRecords.currentPageNo !== 1) {
        dispatch(setSalesforceErrorModalPage(0));
      } else {
        dispatch(
          getSalesforceErrorQuery({
            errorClass: data?.error_class as string,
            keyword: searchKeyword,
            pageNo: sfAffectedRecords.currentPageNo,
            pageSize: ERROR_PAGE_SIZE,
          })
        );
      }
    },
    [dispatch, data, sfAffectedRecords.currentPageNo]
  );

  const debouncedSearch = useMemo(
    () => debounce(searchIfValid, 1000),
    [searchIfValid]
  );

  return (
    <IModal
      isOpen={isOpen}
      onClose={onModalClose}
      size="6xl"
      header={{
        title: errorType + " error",
      }}
    >
      <VStack alignItems="flex-start">
        <Text>{errorTypeMessage[errorType]?.errorTypeDetails}</Text>
        <Box height="450px" width="100%">
          <ErrorModalHeader
            searchKeyword={searchKeyword}
            onSearch={(keyword: string) => {
              setSearchKeyword(keyword);
              debouncedSearch(keyword);
            }}
            totalRecords={sfAffectedRecords.count}
          />
          <DataTable
            fetchingList={sfAffectedRecords.fetchingList}
            changingPage={sfAffectedRecords.changingPage}
            list={(sfAffectedRecords.list as SalesforceSyncErrorQuery[]) ?? []}
            setPage={(pageNo: number) =>
              dispatch(setSalesforceErrorModalPage(pageNo))
            }
            totalPageCount={sfAffectedRecords.totalPageCount}
            currentPage={sfAffectedRecords.currentPageNo}
            totalPageSize={sfAffectedRecords.pageSize}
            columns={columns}
            emptyMsg="No entries exist"
            scrollProps={{
              overflowY: "scroll",
              height: "350px",
            }}
            mt="2"
            border="1px"
            borderColor="grayV2.100"
          />
        </Box>
      </VStack>
    </IModal>
  );
}
