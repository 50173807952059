import {
  VStack,
  Circle,
  Icon,
  Box,
  HStack,
  Link,
  Text,
} from "@chakra-ui/react";
import { BsUpload } from "react-icons/bs";
import { FaArrowUp, FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { LOADING_STATES } from "../../../../common/constants/common";
import {
  isFailed,
  isLoading,
  isInit,
} from "../../../../common/helper/commonHelper";
import IButton from "../../../../components/IButton";
import IDropZone from "../../../../components/IDropZone";
import { useAppDispatch } from "../../../../store";
import {
  selectContactUpload,
  uploadAndValidateFile,
  resetFileUploadDetails,
  cancelUploadFile,
  getSmartMapping,
} from "../contactUploadSlice";
import { useCallback, useEffect, useRef } from "react";

const ANTI_SPAM_POLICY_LINK =
  "https://www.inflection.io/terms#:~:text=Prohibition%20against%20Unsolicited%20Email%2FSpam";

function FileUploader({ onUpload }: { onUpload: (file: File[]) => void }) {
  return (
    <IDropZone onSelectFile={onUpload}>
      <VStack h="100%" py="20%">
        <Circle bg="brand.blueAlpha.700" size="68px">
          <Icon as={BsUpload} color="brand.blue" fontSize="26px" />
        </Circle>

        <Text>
          <IButton variant="link" color="brand.blue" px={1}>
            Click to upload
          </IButton>
          or drag and drop a file
        </Text>

        <Text fontSize="12px" color="gray.500">
          We support .CSV files and must contain a{" "}
          <Text as="span" color="gray.800">
            valid email
          </Text>
        </Text>
      </VStack>
    </IDropZone>
  );
}
function UploadAwait({
  fileName,
  onCancel,
}: {
  fileName: string;
  onCancel: () => void;
}) {
  return (
    <VStack py="20%">
      <Circle bg="white" size="68px" overflow="hidden">
        <Icon
          as={FaArrowUp}
          color="brand.blue"
          fontSize="34px"
          animation="moveUp 1.3s ease-in-out infinite"
        />
      </Circle>

      <Text
        fontSize="14px"
        color="brand.blue"
        maxW="75%"
        wordBreak="break-word"
        textAlign="center"
      >
        Uploading {fileName}
      </Text>
      <Text fontSize="12px" color="gray.500">
        We only support files up to 500MB
      </Text>
      <IButton variant="ghost" onClick={onCancel}>
        Cancel
      </IButton>
    </VStack>
  );
}

function UploadFailure({
  fileName,
  errorMsg,
  onReUpload,
}: {
  fileName: string;
  errorMsg: string;
  onReUpload: () => void;
}) {
  return (
    <VStack py="20%">
      <Circle bg="brand.redAlpha.100" size="68px">
        <Icon as={MdClose} color="red.400" fontSize="28px" />
      </Circle>

      <Text
        color="brand.blue"
        fontSize="14px"
        maxW="75%"
        wordBreak="break-word"
        textAlign="center"
      >
        {`"${fileName}" upload failed`}
      </Text>
      <Text color="red.400" fontSize="12px">
        {errorMsg}
      </Text>
      <IButton variant="ghost" onClick={onReUpload}>
        Re-upload
      </IButton>
    </VStack>
  );
}

function UploadComplete({
  fileName,
  onReUpload,
}: {
  fileName: string;
  onReUpload: () => void;
}) {
  return (
    <VStack py="20%">
      <Circle bg="brand.blueAlpha.700" size="68px">
        <Icon as={FaCheck} color="brand.blue" fontSize="26px" />
      </Circle>

      <Text
        fontSize="14px"
        color="brand.blue"
        maxW="75%"
        wordBreak="break-word"
      >
        {`“${fileName}” is uploaded and ready for import`}
      </Text>

      <IButton variant="ghost" onClick={onReUpload}>
        Re-upload
      </IButton>
    </VStack>
  );
}

export default function CsvFileUploader({
  goToNext,
}: {
  goToNext: () => void;
}) {
  const {
    fileUpload: { isLoading: uploadingFile, errorMsg, fileName, uploadId },
  } = useSelector(selectContactUpload);

  //Todo: need to update type of ref
  const cancelUploading = useRef<any>(null);
  const dispatch = useAppDispatch();

  const fileProps = { fileName };

  function onUploadFile(files: File[]) {
    if (files.length) {
      cancelUploading.current = dispatch(uploadAndValidateFile(files[0]));
    }
  }

  function onReUploadFile() {
    dispatch(resetFileUploadDetails());
  }

  const onCancelUpload = useCallback(() => {
    dispatch(cancelUploadFile());
    // cancel the upload , the promise goes to rejected state.
    // The apis would not be cancelled but the resp would be ignored.
    cancelUploading.current?.abort();
    cancelUploading.current = null;
  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (isLoading(uploadingFile)) {
        onCancelUpload();
      }
    };
  }, [uploadingFile, onCancelUpload]);

  function getContent() {
    switch (uploadingFile) {
      case LOADING_STATES.LOADING:
        return <UploadAwait {...fileProps} onCancel={onCancelUpload} />;
      case LOADING_STATES.SUCCESS:
        return <UploadComplete {...fileProps} onReUpload={onReUploadFile} />;
      case LOADING_STATES.FAILED:
        return (
          <UploadFailure
            {...fileProps}
            errorMsg={errorMsg}
            onReUpload={onReUploadFile}
          />
        );
      default:
        return <FileUploader onUpload={onUploadFile} />;
    }
  }

  function completeUpload() {
    dispatch(getSmartMapping(uploadId));
    goToNext();
  }

  return (
    <>
      <Text fontWeight="semibold">Select and upload your file</Text>
      <Text my={2}>
        Make sure your file contains email addresses before uploading it, as
        these are necessary to create or update Contacts.
      </Text>

      <Box
        my={3}
        bg="grayV2.100"
        borderRadius="md"
        border="1px solid"
        borderColor="gray.200"
        w="100%"
        h="calc(100% - 140px)"
      >
        {getContent()}
      </Box>

      <HStack justifyContent="space-between" w="100%" pt={1}>
        <Text>
          By importing contacts, you agree to our{" "}
          <Link href={ANTI_SPAM_POLICY_LINK} fontWeight="semibold" isExternal>
            anti-spam policy
          </Link>
        </Text>
        <IButton
          isLoading={isLoading(uploadingFile)}
          isDisabled={isFailed(uploadingFile) || isInit(uploadingFile)}
          onClick={completeUpload}
        >
          Next
        </IButton>
      </HStack>
    </>
  );
}
