import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectAccount } from "../pages/account/accountSlice";
import urls from "../urls";

type ProtectedRouteProps = {
  component: any;
  title: string;
};

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { component: Component, title } = props;
  const { isAuthenticated } = useSelector(selectAccount);

  useEffect(() => {
    document.title = `Inflection | ${title}`;
  }, [title]);

  return isAuthenticated ? <Component /> : <Navigate replace to={urls.login} />;
}
