import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  FILTER_TABLE_NAME,
  FILTER_TYPE,
  FLOW_ACTIONS,
} from "../../../../../common/constants/campaign";
import { isLoading } from "../../../../../common/helper/commonHelper";
import {
  ActionOptions,
  AddFlow,
  BranchingConditionDef,
  CAMPAIGN_CONTEXT,
  DynamicListType,
  EmailTokenDetails,
  FlowSfCampaignSyncActionOptions,
  OperatorDetails,
  OperatorType,
  SalesforceCampaignSyncDrawerData,
  SF_CAMPAIGN_CONTEXT_TYPE,
  TableDescWithId,
  TableList,
  UpdateValueOperation,
} from "../../../../../common/types/campaign";
import { ComputeOperatorDetails } from "../../../../../common/types/flow";
import { DESTINATION_TYPES } from "../../../../../common/types/unifiedMapping";
import DropdownWithSearch from "../../../../../components/DropdownWithSearch";
import CommonDynamicListDrawer from "../../../../../components/dynamic-list/CommonDynamicListDrawer";
import { useAppDispatch } from "../../../../../store";
import {
  listAllEmailTokens,
  selectEmailToken,
} from "../../../emailtoken/emailTokenSlice";
import {
  FILTER_TEXT,
  FILTER_TYPE_TEXT,
  TYPE_TO_DESTINATION_TYPE,
  VALID_BRANCH_TOKEN_DATA_TYPES,
  ValidTokenDatatypes,
  EMAIL_PREVIEW_VARIANTS,
} from "./constants";
import { BranchingConditionEditOverlay } from "./widgets/BranchByDataWidget";
import { TYPE, DelayActionOptions } from "../../../../../common/types/campaign";
import {
  selectDynamicList,
  getComputeOperators,
  getOperators,
} from "../../../../../components/dynamic-list/dynamicListSlice";
import UpdateActionDrawer from "./UpdateActionDrawer";
import {
  getPersonMappingDetails,
  selectPerson,
} from "../../../persondb/personDbSlice";
import { getUpdateValueFields } from "../../helper/formatHelper";
import { addFlowAction, CampaignBuilderContext } from "../flowSlice";
import AddDelayDrawer from "./AddDelayDrawer";
import SalesforceCampaignSyncDrawer from "./widgets/SalesforceCampaignSyncDrawer";
import {
  getSalesforceConnectionList,
  selectConnection,
} from "../../../connection/connectionSlice";
import {
  isResolveContextType,
  isResolveDirectType,
} from "../../../../../common/helper/salesforceHelper";
import TemplatesDrawer from "./TemplatesDrawer";
import EmailTemplatePreview from "./TemplatePreviewDrawer";
import { TemplateType } from "../../../../../common/types/template";
import { TokenDropdownOption } from "../../../../../components/OptionHelper";

const INIT_SALESFORCE_CAMPAIGN_SYNC_FLOW_STEP: SalesforceCampaignSyncDrawerData =
  {
    campaignIdSource: null,
    salesforceCampaign: null,
    personField: null,
    salesforceCampaignStatus: null,
  };
const ADD_FLOW_STEP_TXT = "Add flow step";

function AddBranchByFilterConditionWarning({
  branchType,
  selectedBranch,
  hidden = true,
}: {
  branchType: string;
  selectedBranch: string;
  hidden?: boolean;
}) {
  return (
    <HStack
      alignItems="flex-start"
      p="8px"
      bg="oneOffs.warning.bg"
      mb={2}
      color="oneOffs.warning.text"
      rounded="sm"
      fontSize="sm"
      hidden={hidden}
    >
      <Icon as={FaExclamationCircle} color="oneOffs.warning.text" mt={1} />
      <Text>
        Adding{" "}
        <Box as="span" fontWeight="semibold">
          {branchType}
        </Box>{" "}
        flow step in the middle will add the succeeding flow steps to{" "}
        <Box as="span" fontWeight="semibold">
          {selectedBranch}
        </Box>{" "}
        branch
      </Text>
    </HStack>
  );
}

function AddBranchByFilterCondition({
  onClose,
  flowStepDataForAdd,
  showWarning,
}: {
  onClose: () => void;
  flowStepDataForAdd: AddFlow;
  showWarning?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { campaignContext } = useContext(CampaignBuilderContext);

  const [isLoading, setIsLoading] = useState(false);

  async function addWidget(dynamicList: DynamicListType[]) {
    const data: AddFlow = {
      ...flowStepDataForAdd,
      action_options: {
        condition: {
          conditions: dynamicList,
        },
      },
    };
    setIsLoading(true);
    await dispatch(addFlowAction(data));
    setIsLoading(false);
    onClose();
  }

  return (
    <CommonDynamicListDrawer
      isOpen={true}
      onClose={onClose}
      dynamicListData={[]}
      submitButtonProps={{
        label: ADD_FLOW_STEP_TXT,
        onSubmit: (dynamicList: DynamicListType[]) => addWidget(dynamicList),
        props: {
          isLoading: isLoading,
        },
      }}
      campaignContext={campaignContext}
      title="Branch by filter"
      extraMessage={
        <AddBranchByFilterConditionWarning
          branchType="Branch by filter"
          selectedBranch="True"
          hidden={!showWarning}
        />
      }
    />
  );
}

function AddBranchByTokenCondition({
  onClose,
  flowStepDataForAdd,
  showWarning,
}: {
  onClose: () => void;
  flowStepDataForAdd: AddFlow;
  showWarning?: boolean;
}) {
  const dispatch = useAppDispatch();
  const [selectedOption, setSelectedOption] =
    useState<EmailTokenDetails | null>(null);
  const [isAddingWidget, setIsAddingWidget] = useState(false);

  const {
    emailTokenList: { listAll: allEmailTokenList },
  } = useSelector(selectEmailToken);

  const { computeBooleanOperators } = useSelector(selectDynamicList);

  useEffect(() => {
    dispatch(getComputeOperators());
    dispatch(listAllEmailTokens());
  }, [dispatch]);

  const tokenOptions = allEmailTokenList.data
    .filter((token) =>
      VALID_BRANCH_TOKEN_DATA_TYPES.includes(
        token.return_type as ValidTokenDatatypes
      )
    )
    .map((token) => {
      return {
        ...token,
        return_type:
          TYPE_TO_DESTINATION_TYPE[token.return_type as ValidTokenDatatypes],
      };
    });

  let filteredBooleanOperators: ComputeOperatorDetails[] = [];
  let operandType = DESTINATION_TYPES.STRING;
  if (selectedOption && computeBooleanOperators) {
    operandType = (selectedOption as EmailTokenDetails)
      .return_type as DESTINATION_TYPES;
    filteredBooleanOperators = Object.values(computeBooleanOperators).filter(
      (operatorDetails) => {
        return (
          operatorDetails.operand_data_type.includes(
            operandType as DESTINATION_TYPES
          ) && operatorDetails.id !== "set_value"
        );
      }
    );
  }

  async function addWidget(condition: BranchingConditionDef) {
    const data: AddFlow = {
      ...flowStepDataForAdd,
      action_options: {
        token: selectedOption?.display,
        conditions: [
          {
            sequence_number: 1,
            condition,
          },
        ],
      },
    };
    setIsAddingWidget(true);
    await dispatch(addFlowAction(data));
    setIsAddingWidget(false);
    onClose();
  }

  return (
    <AddBranchByDataCondition
      selectedOption={selectedOption?.name ?? ""}
      filterType={FILTER_TYPE_TEXT}
      filter={FILTER_TEXT}
      operators={filteredBooleanOperators}
      operandType={operandType}
      addCondition={addWidget}
      onClose={onClose}
      isSubmitLoading={isAddingWidget}
      title="Branch by token"
    >
      <>
        <AddBranchByFilterConditionWarning
          branchType="Branch by token"
          selectedBranch="defined condition"
          hidden={!showWarning}
        />
        <DropdownWithSearch<EmailTokenDetails>
          options={tokenOptions}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.display}
          placeholder="Select token"
          value={selectedOption}
          onChange={(option) => setSelectedOption(option)}
          isLoading={isLoading(allEmailTokenList.loading)}
          maxMenuHeight={300}
          isSearchable
          controlStyle={{ minWidth: "200px" }}
          id="branch-by-token"
          menuPortalTarget={null}
          components={{ Option: TokenDropdownOption }}
        />
      </>
    </AddBranchByDataCondition>
  );
}

function AddBranchByValueCondition({
  onClose,
  flowStepDataForAdd,
  showWarning,
}: {
  onClose: () => void;
  flowStepDataForAdd: AddFlow;
  showWarning?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { campaignContext } = useContext(CampaignBuilderContext);

  const { operators, filterList } = useSelector(selectDynamicList);

  const [selectedOption, setSelectedOption] = useState<TableDescWithId | null>(
    null
  );
  const [isAddingWidget, setIsAddingWidget] = useState(false);

  const tableList = useMemo(
    () =>
      filterList[campaignContext ?? CAMPAIGN_CONTEXT.PERSON]?.[
        FILTER_TYPE.PERSON
      ],
    [campaignContext, filterList]
  );

  function transformTableObjectToList(list?: TableList) {
    if (list && (list as TableList)[FILTER_TABLE_NAME.PERSON]) {
      return Object.entries((list as TableList)[FILTER_TABLE_NAME.PERSON])
        .filter(([_, value]) => !value.ui_hidden)
        .map(([key, value]) => {
          return { ...value, id: key };
        });
    } else {
      return [];
    }
  }

  const columnOptions = transformTableObjectToList(tableList?.data);
  let filteredOperators: OperatorDetails[] = [];
  let operandType = OperatorType.STRING;
  if (selectedOption && operators.data) {
    operandType = (selectedOption as TableDescWithId).type as OperatorType;
    filteredOperators = Object.values(operators.data[operandType]);
  }

  useEffect(() => {
    dispatch(getOperators());
  }, [dispatch]);

  async function addWidget(condition: BranchingConditionDef) {
    const data: AddFlow = {
      ...flowStepDataForAdd,
      action_options: {
        filter_type: TYPE.EXPRESSION,
        filter: selectedOption?.id,
        conditions: [
          {
            sequence_number: 1,
            condition,
          },
        ],
      },
    };
    setIsAddingWidget(true);
    await dispatch(addFlowAction(data));
    setIsAddingWidget(false);
    onClose();
  }

  return (
    <AddBranchByDataCondition
      selectedOption={
        (selectedOption?.display_name || selectedOption?.id) ?? ""
      }
      filterType={FILTER_TYPE.PERSON}
      filter={selectedOption?.id || ""}
      operators={filteredOperators}
      operandType={operandType}
      onClose={onClose}
      addCondition={addWidget}
      isSubmitLoading={isAddingWidget}
      title="Branch by value"
      isOldOperator
    >
      <>
        <AddBranchByFilterConditionWarning
          branchType="Branch by value"
          selectedBranch="defined condition"
          hidden={!showWarning}
        />
        <DropdownWithSearch<TableDescWithId>
          options={columnOptions}
          getOptionLabel={(option) => option.display_name || option.id}
          getOptionValue={(option) => option.id}
          placeholder="Select contact field"
          value={selectedOption}
          onChange={(option) => setSelectedOption(option)}
          isLoading={isLoading(tableList?.loading)}
          maxMenuHeight={300}
          isSearchable
          id="branch-by-value"
          menuPortalTarget={null}
        />
      </>
    </AddBranchByDataCondition>
  );
}

function AddBranchByDataCondition({
  selectedOption,
  filterType,
  filter,
  operators,
  isOldOperator,
  operandType,
  addCondition,
  onClose,
  children,
  isSubmitLoading,
  title,
}: {
  selectedOption: string;
  filterType: string;
  filter: string;
  operators: (ComputeOperatorDetails | OperatorDetails)[];
  isOldOperator?: boolean;
  operandType: DESTINATION_TYPES | OperatorType;
  addCondition: (condition: BranchingConditionDef) => void;
  onClose: () => void;
  children?: ReactNode;
  isSubmitLoading?: boolean;
  title?: string;
}) {
  return (
    <BranchingConditionEditOverlay
      optionDisplay={selectedOption}
      condition={{
        type: TYPE.EXPRESSION,
        filter_type: filterType,
        filter: filter,
        operator: null,
        value: [],
      }}
      operators={operators}
      setCondition={addCondition}
      type={operandType}
      isOpen={true}
      onClose={onClose}
      isOldOperator={isOldOperator}
      extraChildren={children}
      isSubmitLoading={isSubmitLoading}
      title={title}
      submitButtonText={ADD_FLOW_STEP_TXT}
      isModal
    />
  );
}

function AddUpdateByValue({
  onClose,
  flowStepDataForAdd,
}: {
  onClose: () => void;
  flowStepDataForAdd: AddFlow;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const {
    personMappingDetails: { data: personMapping },
  } = useSelector(selectPerson);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPersonMappingDetails());
    dispatch(listAllEmailTokens());
    dispatch(getComputeOperators());
  }, [dispatch]);

  const updatableFields = useMemo(
    () => getUpdateValueFields(personMapping),
    [personMapping]
  );

  async function saveUpdateAction(operation: UpdateValueOperation) {
    const updateValueData: AddFlow = {
      ...flowStepDataForAdd,
      action_options: { operations: [{ sequence_number: 1, operation }] },
    };
    setIsLoading(true);
    await dispatch(addFlowAction(updateValueData));
    setIsLoading(false);
    onClose();
  }

  return (
    <UpdateActionDrawer
      isOpen={true}
      onClose={onClose}
      onSave={saveUpdateAction}
      updateFieldOptions={updatableFields}
      primaryButtonProps={{ children: ADD_FLOW_STEP_TXT, isLoading }}
    />
  );
}

function AddDelayFlowStep({
  flowStepDataForAdd,
  onClose,
}: {
  flowStepDataForAdd: AddFlow;
  onClose: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  async function onAddFlowStep(options: DelayActionOptions) {
    setIsLoading(true);
    const addDelay: AddFlow = {
      ...flowStepDataForAdd,
      action_options: options,
    };
    await dispatch(addFlowAction(addDelay));
    setIsLoading(false);
    onClose();
  }

  return (
    <AddDelayDrawer
      isOpen={true}
      onClose={onClose}
      isInvalid={false}
      actionOptions={
        (flowStepDataForAdd.action_options as DelayActionOptions) ?? {
          type: undefined,
        }
      }
      setOptions={onAddFlowStep}
      primaryButtonProps={{ children: ADD_FLOW_STEP_TXT, isLoading }}
    />
  );
}

function AddSalesforceCampaignFlowStep({
  flowStepDataForAdd,
  onClose,
}: {
  flowStepDataForAdd: AddFlow;
  onClose: () => void;
}) {
  const {
    salesforce: { connectionList },
  } = useSelector(selectConnection);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSalesforceConnectionList());
  }, [dispatch]);

  async function onSave(
    data: { formData: SalesforceCampaignSyncDrawerData } | null
  ) {
    if (data) {
      setIsLoading(true);
      const { formData } = data;

      let sfCampaignActionOptions: Partial<FlowSfCampaignSyncActionOptions> = {
        campaign_selection_type: formData.campaignIdSource!,
        campaign_member_status: formData.salesforceCampaignStatus,
        connection_id: connectionList.data?.[0]?.connection_id,
      };

      if (isResolveContextType(formData.campaignIdSource)) {
        sfCampaignActionOptions.context = {
          context_type: SF_CAMPAIGN_CONTEXT_TYPE.PERSON,
          person_context: {
            field: formData.personField!,
          },
        };
        sfCampaignActionOptions.campaign_id = undefined;
      } else if (isResolveDirectType(formData.campaignIdSource)) {
        sfCampaignActionOptions.campaign_id = formData.salesforceCampaign!;
        sfCampaignActionOptions.context = undefined;
      }

      const salesforceCampaign: AddFlow = {
        ...flowStepDataForAdd,
        action_options: sfCampaignActionOptions as ActionOptions,
      };

      await dispatch(addFlowAction(salesforceCampaign));

      setIsLoading(false);
    }
    onClose();
  }

  return (
    <SalesforceCampaignSyncDrawer
      data={INIT_SALESFORCE_CAMPAIGN_SYNC_FLOW_STEP}
      isOpen={true}
      onClose={onSave}
      primaryButtonProps={{ children: ADD_FLOW_STEP_TXT, isLoading }}
    />
  );
}

function AddEmailFlowStep({
  flowStepDataForAdd,
  onClose,
}: {
  flowStepDataForAdd: AddFlow;
  onClose: () => void;
}) {
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  async function onTemplateSelect(options: TemplateType) {
    setIsLoading(true);
    const addEmailTemplate: AddFlow = {
      ...flowStepDataForAdd,
      action_options: options,
    };
    await dispatch(addFlowAction(addEmailTemplate));
    setIsLoading(false);
    onClose();
  }

  return (
    <>
      <TemplatesDrawer
        isOpen={true}
        onClose={onClose}
        handleSelectChange={onTemplateSelect}
        openTemplateSelectionPreview={() => setIsOpenPreview(true)}
        loading={isLoading}
      />
      <EmailTemplatePreview
        isOpen={!!isOpenPreview}
        variant={EMAIL_PREVIEW_VARIANTS.SELECTION}
        closeAllModal={onClose}
        handleSelectChange={onTemplateSelect}
        goBack={() => setIsOpenPreview(false)}
        onTemplateDrawerOpen={() => {}}
        primaryButtonProps={{ children: ADD_FLOW_STEP_TXT, isLoading }}
      />
    </>
  );
}

export default function AdditionalActionOptionSteps({
  flowStepDataForAdd,
  onClose,
  isBetweenFlowSteps,
}: {
  flowStepDataForAdd: AddFlow | null;
  onClose: () => void;
  isBetweenFlowSteps?: boolean;
}) {
  function returnActionOptionSteps() {
    switch (flowStepDataForAdd?.action_type) {
      case FLOW_ACTIONS.BRANCH_BY_FILTER:
        return (
          <AddBranchByFilterCondition
            onClose={onClose}
            flowStepDataForAdd={flowStepDataForAdd}
            showWarning={isBetweenFlowSteps}
          />
        );
      case FLOW_ACTIONS.BRANCH_BY_TOKEN:
        return (
          <AddBranchByTokenCondition
            onClose={onClose}
            flowStepDataForAdd={flowStepDataForAdd}
            showWarning={isBetweenFlowSteps}
          />
        );
      case FLOW_ACTIONS.BRANCH_BY_VALUE:
        return (
          <AddBranchByValueCondition
            onClose={onClose}
            flowStepDataForAdd={flowStepDataForAdd}
            showWarning={isBetweenFlowSteps}
          />
        );
      case FLOW_ACTIONS.UPDATE_VALUE:
        return (
          <AddUpdateByValue
            onClose={onClose}
            flowStepDataForAdd={flowStepDataForAdd}
          />
        );
      case FLOW_ACTIONS.DELAY:
        return (
          <AddDelayFlowStep
            flowStepDataForAdd={flowStepDataForAdd}
            onClose={onClose}
          />
        );
      case FLOW_ACTIONS.SALESFORCE_CAMPAIGN:
        return (
          <AddSalesforceCampaignFlowStep
            flowStepDataForAdd={flowStepDataForAdd}
            onClose={onClose}
          />
        );
      case FLOW_ACTIONS.SEND_EMAIL:
        return (
          <AddEmailFlowStep
            flowStepDataForAdd={flowStepDataForAdd}
            onClose={onClose}
          />
        );
      default:
        return <></>;
    }
  }
  return <>{returnActionOptionSteps()}</>;
}
