import { Box, HStack, Spinner, Switch, Text } from "@chakra-ui/react";
import SubHeader from "./SubHeader";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getUnsubscribeSettings,
  selectSettings,
  toggleUnsubscribeSettings,
} from "../settingsSlice";
import { useAppDispatch } from "../../../../store";
import { isLoading } from "../../../../common/helper/commonHelper";

export default function TwoStepUnsubscribe() {
  const {
    twoStepUnsub: { loading: unsubLoading, data: unsubSettings },
  } = useSelector(selectSettings);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUnsubscribeSettings());
  }, [dispatch]);

  function toggle() {
    dispatch(toggleUnsubscribeSettings());
  }

  return (
    <Box mb={4}>
      <SubHeader
        title="Two Step Opt-out"
        desc="With two click opt-out, recipients will be asked to confirm their opt-out when they click the unsubscribe link in your email."
        stackProps={{ alignItems: "flex-start" }}
      >
        <HStack alignItems="center">
          {isLoading(unsubLoading) ? (
            <Spinner size="xs" />
          ) : (
            <Switch
              size="sm"
              name="two-step-opt-out"
              onChange={(e) => toggle()}
              isChecked={unsubSettings.two_step_enabled}
            />
          )}

          <Text fontSize="12px" fontWeight="semibold">
            Use two click opt-out
          </Text>
        </HStack>
      </SubHeader>
    </Box>
  );
}
