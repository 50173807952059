import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import {
  initialisePaginationWithPageNo,
  initializeLoadingData,
  isLoading,
} from "../../../../common/helper/commonHelper";
import {
  LoadingWithData,
  PaginationType,
  SearchAssetsType,
} from "../../../../common/types/common";
import {
  FullTriggerCampaign,
  JourneyListRow,
  TriggerCampaign,
  TriggerCampaignUpdatableData,
} from "../../../../common/types/trigger";
import {
  CAMPAIGN_TARGET,
  INIT_TRIGGER_CAMPAIGN,
  TRIGGER_CAMPAIGN_STATES,
} from "../../../../common/constants/trigger";
import {
  archiveTriggerCampaignApi,
  cloneTriggerCampaignApi,
  createTriggerCampaignApi,
  deleteTriggerCampaignApi,
  getTriggerCampaignApi,
  listTriggerCampaignApi,
  pauseTriggerCampaignApi,
  renameTriggerCampaignApi,
  resumeTriggerCampaignApi,
  updateTriggerCampaignApi,
} from "../../../../common/api/realtime-campaign/trigger";
import {
  API_RESPONSE_STATUS,
  LOADING_STATES,
} from "../../../../common/constants/common";
import { toast } from "react-toastify";
import {
  getCampaignListStatsApi,
  listCampaignsApi,
} from "../../../../common/api/campaign/campaign";
import {
  transformCampaignToJourneyRow,
  transformStatsToJourneyRowStats,
} from "../helper/formatHelper";
import { CAMPAIGN_LIST_STATS_INIT } from "../../../../common/constants/campaign";

interface TriggerCampaignState {
  triggerCampaignList: PaginationType<TriggerCampaign>;
  triggerCampaignDetails: LoadingWithData<FullTriggerCampaign>;
  createdTriggerCampaign: LoadingWithData<string>;
  savingDraft: boolean;
  publishingTriggerCampaign: boolean;
  changingCampaignState: boolean;
  allJourneyList: PaginationType<JourneyListRow>;
  activeErrorCheck: boolean;
  triggerCampaignChanged: boolean;
}

const initialState: TriggerCampaignState = {
  triggerCampaignList: initialisePaginationWithPageNo(30),
  triggerCampaignDetails: initializeLoadingData(INIT_TRIGGER_CAMPAIGN),
  createdTriggerCampaign: initializeLoadingData(""),
  savingDraft: false,
  publishingTriggerCampaign: false,
  changingCampaignState: false,
  allJourneyList: initialisePaginationWithPageNo(30),
  activeErrorCheck: false,
  triggerCampaignChanged: false,
};

export const listAllTypeJourney = createAsyncThunk(
  "campaign/list/every",
  async (
    { searchKeyword, columnsToSearchIn, tagsFilter }: SearchAssetsType,
    { getState }
  ) => {
    const {
      triggerCampaign: {
        allJourneyList: { pageSize, currentPageNo: pageNo },
      },
    } = getState() as RootState;
    return listCampaignsApi({
      pageSize: pageSize,
      pageNo: pageNo,
      searchKeyword,
      columnsToSearchIn,
      tagsFilter,
    });
  }
);

export const getTriggerCampaignList = createAsyncThunk(
  "campaign/trigger/list",
  async (
    { searchKeyword, columnsToSearchIn, tagsFilter }: SearchAssetsType,
    thunkApi
  ) => {
    const {
      triggerCampaign: {
        triggerCampaignList: { pageSize, currentPageNo: pageNo },
      },
    } = thunkApi.getState() as RootState;
    return listTriggerCampaignApi({
      pageSize,
      page: pageNo,
    });
  }
);

export const createTriggerCampaign = createAsyncThunk(
  "campaign/trigger/create",
  async ({
    name,
    campaignContext = CAMPAIGN_TARGET.PERSON,
  }: {
    name: string;
    campaignContext?: CAMPAIGN_TARGET;
  }) => {
    return createTriggerCampaignApi(name, campaignContext);
  },
  {
    condition: (_, { getState }) => {
      const { triggerCampaign } = getState() as RootState;
      if (isLoading(triggerCampaign.createdTriggerCampaign.loading)) {
        return false;
      }
    },
  }
);

export const getTriggerCampaign = createAsyncThunk(
  "campaign/trigger/get",
  async (id: string) => {
    return await getTriggerCampaignApi(id);
  }
);

export const updateTriggerCampaign = createAsyncThunk(
  "campaign/trigger/update",
  async ({
    campaignId,
    data,
  }: {
    campaignId: string;
    data: TriggerCampaignUpdatableData;
  }) => {
    return updateTriggerCampaignApi(campaignId, {
      ...data,
      currentState: TRIGGER_CAMPAIGN_STATES.DRAFT,
    });
  }
);

export const publishTriggerCampaign = createAsyncThunk(
  "campaign/trigger/publish",
  async ({
    campaignId,
    data,
  }: {
    campaignId: string;
    data: TriggerCampaignUpdatableData;
  }) => {
    return updateTriggerCampaignApi(campaignId, {
      ...data,
      currentState: TRIGGER_CAMPAIGN_STATES.PUBLISHED,
    });
  }
);

export const deleteTriggerCampaign = createAsyncThunk(
  "campaign/trigger/delete",
  async (id: string) => {
    return deleteTriggerCampaignApi(id);
  }
);

export const pauseTriggerCampaign = createAsyncThunk(
  "campaign/trigger/pause",
  async (campaignId: string) => {
    return pauseTriggerCampaignApi(campaignId);
  }
);

export const resumeTriggerCampaign = createAsyncThunk(
  "campaign/trigger/resume",
  async (campaignId: string) => {
    return resumeTriggerCampaignApi(campaignId);
  }
);

export const archiveTriggerCampaign = createAsyncThunk(
  "campaign/trigger/archive",
  async (campaignId: string) => {
    return archiveTriggerCampaignApi(campaignId);
  }
);

export const getCampaignListStats = createAsyncThunk(
  "campaign/list-item-stats",
  async (campaignIds: string[]) => {
    return await getCampaignListStatsApi(campaignIds);
  }
);

export const cloneTriggerCampaign = createAsyncThunk(
  "campaign/trigger/clone",
  async ({ name, campaignId }: { name: string; campaignId: string }) => {
    return cloneTriggerCampaignApi(campaignId, name);
  }
);

export const renameTriggerCampaign = createAsyncThunk(
  "campaign/trigger/rename",
  async ({ name, campaignId }: { name: string; campaignId: string }) => {
    return renameTriggerCampaignApi(campaignId, name);
  }
);

const triggerCampaignSlice = createSlice({
  name: "triggerCampaign",
  initialState,
  reducers: {
    setJourneyListPagination(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.allJourneyList.totalPageCount ?? 1)) {
        state.allJourneyList.currentPageNo = pageNo;
        state.allJourneyList.changingPage = true;
      } else {
        state.allJourneyList.currentPageNo = 1;
      }
    },
    resetCampaignCreatedId(state) {
      state.createdTriggerCampaign = initialState.createdTriggerCampaign;
    },
    setActiveErrorCheck(state, action) {
      state.activeErrorCheck = action.payload;
    },
    clearCampaignDetails(state) {
      state.triggerCampaignDetails = initialState.triggerCampaignDetails;
      state.activeErrorCheck = false;
    },
    setTriggerCampaignTags(state, action: PayloadAction<string[]>) {
      state.triggerCampaignDetails.data.campaignTags = action.payload;
    },
    setTriggerCampaignStatusToDraft(
      state,
      action: PayloadAction<{
        updatedAt: string;
        updatedBy: string;
      }>
    ) {
      state.triggerCampaignDetails.data.currentState =
        TRIGGER_CAMPAIGN_STATES.DRAFT;
      state.triggerCampaignDetails.data.updatedAt = new Date(
        action.payload.updatedAt
      ).valueOf();
      state.triggerCampaignDetails.data.updatedBy = action.payload.updatedBy;
    },
  },
  extraReducers: (builder) => {
    builder
      // list all type of campaign
      .addCase(listAllTypeJourney.pending, (state) => {
        state.allJourneyList.fetchingList = true;
      })
      .addCase(listAllTypeJourney.fulfilled, (state, action) => {
        state.allJourneyList.list = transformCampaignToJourneyRow(
          action.payload.records.map((campaign) => {
            return {
              ...campaign,
              ...CAMPAIGN_LIST_STATS_INIT,
            };
          })
        );
        state.allJourneyList.totalPageCount = action.payload.page_count;
        state.allJourneyList.fetchingList = false;
        state.allJourneyList.changingPage = false;
      })
      .addCase(listAllTypeJourney.rejected, (state) => {
        state.allJourneyList.fetchingList = false;
        state.allJourneyList.changingPage = false;
      })

      // Create campaign
      .addCase(createTriggerCampaign.pending, (state) => {
        state.createdTriggerCampaign.loading = LOADING_STATES.LOADING;
      })
      .addCase(createTriggerCampaign.fulfilled, (state, action) => {
        state.createdTriggerCampaign.loading = LOADING_STATES.SUCCESS;
        state.createdTriggerCampaign.data = action.payload.data.campaignId;
        toast.success("New journey added");
      })
      .addCase(createTriggerCampaign.rejected, (state) => {
        state.createdTriggerCampaign.loading = LOADING_STATES.FAILED;
        toast.error("Journey creation failed");
        // Add support for new error codes and description.
      })

      //delete campaign
      .addCase(deleteTriggerCampaign.fulfilled, (state) => {
        state.triggerCampaignChanged = !state.triggerCampaignChanged;
        toast.success("Journey removed");
      })

      //clone campaign
      .addCase(cloneTriggerCampaign.fulfilled, (state) => {
        state.triggerCampaignChanged = !state.triggerCampaignChanged;
        toast.success("Journey cloned successfully");
      })

      //rename campaign
      .addCase(renameTriggerCampaign.fulfilled, (state) => {
        state.triggerCampaignChanged = !state.triggerCampaignChanged;
        toast.success("Journey renamed");
      })

      // Get campaign details
      .addCase(getTriggerCampaign.pending, (state) => {
        state.triggerCampaignDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getTriggerCampaign.fulfilled, (state, action) => {
        state.triggerCampaignDetails.loading = LOADING_STATES.SUCCESS;
        state.triggerCampaignDetails.data = {
          flow: state.triggerCampaignDetails.data.flow,
          ...action.payload.data,
        };
      })
      .addCase(getTriggerCampaign.rejected, (state) => {
        state.triggerCampaignDetails.data = INIT_TRIGGER_CAMPAIGN;
        state.triggerCampaignDetails.loading = LOADING_STATES.FAILED;
      })

      // Get campaign details
      .addCase(updateTriggerCampaign.pending, (state) => {
        state.savingDraft = true;
      })
      .addCase(updateTriggerCampaign.fulfilled, (state, action) => {
        state.savingDraft = false;
        state.triggerCampaignDetails.data.updatedAt =
          action.payload.data.updatedAt;
        state.triggerCampaignDetails.data.updatedBy =
          action.payload.data.updatedBy;
        state.triggerCampaignDetails.data.campaignName =
          action.payload.data.campaignName;
        state.triggerCampaignDetails.data.currentState =
          TRIGGER_CAMPAIGN_STATES.DRAFT;
      })
      .addCase(updateTriggerCampaign.rejected, (state) => {
        state.savingDraft = false;
      })

      //publish campaign
      .addCase(publishTriggerCampaign.pending, (state) => {
        state.publishingTriggerCampaign = true;
      })
      .addCase(publishTriggerCampaign.fulfilled, (state, action) => {
        state.publishingTriggerCampaign = false;
        if (action.payload.meta.status === API_RESPONSE_STATUS.SUCCESS) {
          toast.success("Journey published as active");
          state.triggerCampaignDetails.data.currentState =
            TRIGGER_CAMPAIGN_STATES.PUBLISHED;
        }
      })
      .addCase(publishTriggerCampaign.rejected, (state) => {
        state.publishingTriggerCampaign = false;
      })

      //resume campaign
      .addCase(resumeTriggerCampaign.pending, (state) => {
        state.changingCampaignState = true;
      })
      .addCase(resumeTriggerCampaign.fulfilled, (state, action) => {
        state.changingCampaignState = false;
        state.triggerCampaignDetails.data.currentState =
          TRIGGER_CAMPAIGN_STATES.PUBLISHED;
        toast.success("Journey published as active");
      })
      .addCase(resumeTriggerCampaign.rejected, (state) => {
        state.changingCampaignState = false;
      })

      //pause campaign
      .addCase(pauseTriggerCampaign.pending, (state) => {
        state.changingCampaignState = true;
      })
      .addCase(pauseTriggerCampaign.fulfilled, (state, action) => {
        state.changingCampaignState = false;
        state.triggerCampaignDetails.data.currentState =
          TRIGGER_CAMPAIGN_STATES.PAUSED;
        toast.success("Journey published as inactive");
      })
      .addCase(pauseTriggerCampaign.rejected, (state) => {
        state.changingCampaignState = false;
      })

      //archive campaign
      .addCase(archiveTriggerCampaign.pending, (state) => {
        state.changingCampaignState = true;
      })
      .addCase(archiveTriggerCampaign.fulfilled, (state, action) => {
        state.changingCampaignState = false;
        state.triggerCampaignDetails.data.currentState =
          TRIGGER_CAMPAIGN_STATES.ARCHIVED;
      })
      .addCase(archiveTriggerCampaign.rejected, (state) => {
        state.changingCampaignState = false;
      })

      // campaign list stats
      .addCase(getCampaignListStats.pending, (state) => {
        state.allJourneyList.list =
          state.allJourneyList.list?.map((campaign) => {
            campaign.statsLoading = LOADING_STATES.LOADING;
            return campaign;
          }) ?? null;
      })
      .addCase(getCampaignListStats.fulfilled, (state, action) => {
        const mappedStats = transformStatsToJourneyRowStats(
          action.payload.records
        );
        state.allJourneyList.list =
          state.allJourneyList.list?.map((campaign) => {
            const stats =
              mappedStats.find(
                (cStats) => cStats.campaignId === campaign.campaignId
              ) ?? {};
            return {
              ...campaign,
              ...stats,
              statsLoading: LOADING_STATES.SUCCESS,
            };
          }) ?? null;
      })
      .addCase(getCampaignListStats.rejected, (state) => {
        state.allJourneyList.list =
          state.allJourneyList.list?.map((campaign) => {
            campaign.statsLoading = LOADING_STATES.FAILED;
            return campaign;
          }) ?? null;
      });
  },
});

export const {
  resetCampaignCreatedId,
  setJourneyListPagination,
  setActiveErrorCheck,
  clearCampaignDetails,
  setTriggerCampaignTags,
  setTriggerCampaignStatusToDraft,
} = triggerCampaignSlice.actions;

export const selectTrigger = (state: RootState) => state.triggerCampaign;

export default triggerCampaignSlice.reducer;
