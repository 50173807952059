import { PaginatedList } from "../../types/common";
import { integrationsRequest } from "./api";
import {
  FormDetailsSummary,
  FORM_VENDORS,
  FormSummary,
  FormSubmissionStats,
  GetFormSubmissionStats,
  GetFormSubmissions,
  FormSubmissionDetails,
  FormMappingUpdate,
  FormsAddIntegration,
  GetFormErrors,
  FormError,
  GetListForms,
  FormProviderDetails,
  ProviderDetails,
} from "../../types/form";

export async function listFormsApi(
  data: GetListForms
): Promise<{ forms: PaginatedList<FormDetailsSummary> }> {
  return integrationsRequest({
    url: "/forms/forms.list",
    data,
  });
}

export async function listAllFormsApi(vendor?: FORM_VENDORS): Promise<{
  forms: {
    records: FormSummary[];
  };
}> {
  return integrationsRequest({
    url: "/forms/forms.list.all",
    data: {
      vendor,
    },
  });
}

export async function getFormDetailsApi(
  formId: string
): Promise<{ form: FormDetailsSummary }> {
  return integrationsRequest({
    url: "/forms/forms.get",
    data: {
      form_id: formId,
    },
  });
}

export async function updateFormDescriptionApi(
  formId: string,
  description: string
): Promise<{ success: boolean }> {
  return integrationsRequest({
    url: "/forms/forms.update.description",
    data: {
      form_id: formId,
      description,
    },
  });
}

export async function updateFormDetailsApi(
  formId: string,
  name: string
): Promise<{ success: boolean }> {
  return integrationsRequest({
    url: "/forms/forms.update",
    data: {
      form_id: formId,
      name,
    },
  });
}

export async function getFormSubmissionStatsApi(
  data: GetFormSubmissionStats
): Promise<{ submission_stats: FormSubmissionStats }> {
  return integrationsRequest({
    url: "/forms/forms.submissions.stats",
    data,
  });
}

export async function getFormSubmissionDetailsApi(
  submissionId: string
): Promise<{ submission: FormSubmissionDetails }> {
  return integrationsRequest({
    url: "/forms/forms.submissions.get-details",
    data: { submission_log_id: submissionId },
  });
}

export async function getFormSubmissionsApi(
  data: GetFormSubmissions
): Promise<{ submissions: PaginatedList<FormSubmissionDetails> }> {
  return integrationsRequest({
    url: "/forms/forms.submissions.get",
    data,
  });
}

export async function formsUpdateMappingApi(
  data: FormMappingUpdate
): Promise<{ status: boolean }> {
  return integrationsRequest({
    url: "/forms/forms.mapping.update",
    data,
  });
}

export async function formsDeleteMappingApi(
  formId: string
): Promise<{ status: boolean }> {
  return integrationsRequest({
    url: "/forms/forms.mapping.delete",
    data: {
      form_id: formId,
    },
  });
}

export async function formsAddIntegrationApi(
  data: FormsAddIntegration
): Promise<FormProviderDetails> {
  return integrationsRequest({
    url: "/forms/forms.add-integration",
    data,
  });
}

export async function getFormErrorsApi(
  data: GetFormErrors
): Promise<{ submission_stats: PaginatedList<FormError> }> {
  return integrationsRequest({
    url: "/forms/forms.errors.get",
    data,
  });
}

export async function listFormProvidersApi(
  vendor?: FORM_VENDORS
): Promise<{ integrations: ProviderDetails[] }> {
  return integrationsRequest({
    url: "/forms/forms.get-integrations",
    data: vendor ? { key_type: vendor } : {},
  });
}

export async function getFormConnectionErrorsApi(
  source: FORM_VENDORS,
  pageSize: number,
  pageNumber: number
): Promise<{ submission_stats: PaginatedList<FormError> }> {
  return integrationsRequest({
    url: "/forms/forms.connection.errors",
    data: { source, page_size: pageSize, page_number: pageNumber },
  });
}
