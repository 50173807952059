import { EmailConfigWithSubject } from "../types/template";

export const UNSUBSCRIBED_URL = "unsubscribe_url";

export enum EMAIL_CONFIG_FIELDS {
  SUBJECT = "subject",
  FROM_EMAIL_NAME = "from_email.name",
  FROM_EMAIL = "from_email.email",
  REPLY_TO = "reply_to",
  SENDER_META_FROM_EMAIL = "sender_meta.from_email",
  SENDER_META_REPLY_TO = "sender_meta.reply_to",
}

export enum VALUE_META_TYPE {
  TOKEN = "token",
  TEXT = "text",
}

export const GLOBAL_DEFAULT_INIT = {
  from_email: {
    name: "",
    email: "",
  },
  reply_to: "",
};

export const SENDER_META_DEFAULT_INIT = {
  sender_meta: {
    from_email: VALUE_META_TYPE.TEXT,
    from_name: VALUE_META_TYPE.TOKEN,
    reply_to: VALUE_META_TYPE.TEXT,
  },
};

export const EMAIL_CONFIG_DATA_INIT: EmailConfigWithSubject = {
  subject: "",
  ...GLOBAL_DEFAULT_INIT,
  ...SENDER_META_DEFAULT_INIT,
};

export enum PREVIEW_MODES {
  LIST = "list",
  PREVIEW = "preview",
}

export const EMAIL_CONFIG_TEXT_MAP = {
  fromEmailEmail: "From",
  fromEmailName: "Sender name",
  replyTo: "Reply-to",
  subject: "Subject",
};

export const INIT_EMAIL_CONFIG_CHANGE_TRACKER = {
  fromEmailEmail: false,
  fromEmailName: false,
  replyTo: false,
  subject: false,
};
