import {
  Box,
  Circle,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_BASIC,
  CAMPAIGN_TYPE,
} from "../../../../common/constants/campaign";
import { JOURNEY_TYPES } from "../../../../common/constants/trigger";
import { keepClickEventRestricted } from "../../../../common/helper/commonHelper";
import { CAMPAIGN_CONTEXT } from "../../../../common/types/campaign";
import IconWithTooltip from "../../../../components/IconWithTooltip";
import { selectTag } from "../../tag/tagSlice";
import { FaRegCalendarCheck } from "react-icons/fa";
import { BsFillLightningChargeFill } from "react-icons/bs";

const STATUS_DETAILS_LABEL = {
  [CAMPAIGN_STATUS.ACTIVE]: "Active",
  [CAMPAIGN_STATUS.INACTIVE]: "Inactive",
  [CAMPAIGN_STATUS.DRAFT]: "Draft",
};

function CampaignStatusIcon({ status }: { status: CAMPAIGN_STATUS }) {
  return (
    <IconWithTooltip
      icon={CAMPAIGN_STATUS_BASIC[status].icon}
      color={CAMPAIGN_STATUS_BASIC[status].color}
      label={STATUS_DETAILS_LABEL[status]}
    />
  );
}

export function CampaignNameWithIcons({
  campaignName,
  campaignContext,
  tags,
  status,
}: {
  campaignName: string;
  campaignContext?: CAMPAIGN_CONTEXT;
  tags?: string[];
  status: CAMPAIGN_STATUS;
}) {
  const {
    tagSummaryList: { data: tagSummaryList },
  } = useSelector(selectTag);

  const typeSpecifics =
    CAMPAIGN_TYPE[campaignContext ?? CAMPAIGN_CONTEXT.PERSON];

  // const status = info.row.original.active
  // 	? CAMPAIGN_STATUS.ACTIVE
  // 	: info.row.original.draft
  // 		? CAMPAIGN_STATUS.DRAFT
  // 		: CAMPAIGN_STATUS.INACTIVE;

  return (
    <Box>
      <HStack alignItems="flex-start" flex="1">
        <CampaignStatusIcon status={status} />
        <IconWithTooltip
          icon={typeSpecifics.icon}
          color={typeSpecifics.color}
          fontSize="14px"
          label={typeSpecifics.label}
        />
        <VStack alignItems="flex-start">
          <Text>{campaignName}</Text>
          <Wrap>
            {tags?.map((tag) => {
              return tagSummaryList[tag] ? (
                <Text fontSize="12px" color="gray.500">
                  #{tagSummaryList[tag]}
                </Text>
              ) : (
                <></>
              );
            })}
          </Wrap>
        </VStack>
      </HStack>
    </Box>
  );
}

export function ExtraOptions({
  onCloneClick,
  onRenameClick,
  onDeleteClick,
}: {
  onCloneClick: () => void;
  onRenameClick: () => void;
  onDeleteClick: () => void;
}) {
  return (
    <Flex alignItems="center" justifyContent="flex-end">
      <Box onClick={(e) => keepClickEventRestricted(e)}>
        <Menu>
          <MenuButton
            size="sm"
            as={IconButton}
            aria-label="Options"
            variant="ghost"
            name="options"
            icon={<FaEllipsisV />}
          />
          <MenuList rootProps={{ style: { right: 0 } }}>
            <MenuItem onClick={onCloneClick} name="clone-item">
              Clone
            </MenuItem>
            <MenuItem onClick={onRenameClick} name="rename-item">
              Rename
            </MenuItem>
            <MenuItem onClick={onDeleteClick} name="remove-item">
              Remove
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
}

export function CellWithPercentage({
  data,
  percentage,
  color,
}: {
  data: number;
  percentage: string;
  color: string;
}) {
  return data ? (
    <Flex justifyContent="flex-end">
      <Text color={color} fontWeight="bold">
        {percentage}
      </Text>
    </Flex>
  ) : (
    <Box>-</Box>
  );
}

export const JOURNEY_TYPE_DISPLAY_DETAILS = {
  [JOURNEY_TYPES.BATCH]: {
    color: "brand.blue",
    icon: <Icon as={FaRegCalendarCheck} color="brand.blue" />,
    display: "Scheduled",
  },
  [JOURNEY_TYPES.TRIGGER]: {
    color: "brand.purple",
    icon: (
      <Circle bgColor="brand.purple" p={1}>
        <Icon as={BsFillLightningChargeFill} color="white" fontSize="8px" />
      </Circle>
    ),
    display: "Trigger",
  },
};

export function JourneyTypeWithIcon({ type }: { type: JOURNEY_TYPES }) {
  return (
    <HStack>
      {JOURNEY_TYPE_DISPLAY_DETAILS[type]?.icon}
      <Text color={JOURNEY_TYPE_DISPLAY_DETAILS[type]?.color} fontSize="14px">
        {JOURNEY_TYPE_DISPLAY_DETAILS[type]?.display}
      </Text>
    </HStack>
  );
}
