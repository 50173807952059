import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  Flex,
  Icon,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { FaExclamationCircle } from "react-icons/fa";
import IButton, { BUTTON } from "./IButton";
import ITitle from "./ITitle";

export function ConfirmationModal({
  isOpen,
  cancelRef,
  onCancel,
  submitHandler,
  title,
  cancelButtonText,
  confirmButtonText,
  confirmButtonLoading,
  children,
}: {
  isOpen: boolean;
  cancelRef: React.MutableRefObject<null>;
  onCancel: () => void;
  submitHandler: () => void;
  title: string;
  cancelButtonText: string;
  confirmButtonText: string;
  confirmButtonLoading?: boolean;
  children: any;
}) {
  return (
    <AlertDialog
      isCentered
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="md" fontWeight="bold">
            <Flex alignItems="center">
              <Icon
                color="brand.blue"
                fontSize="20"
                as={FaExclamationCircle}
                mr={3}
              ></Icon>
              <ITitle title={title} capitalize={false} />
            </Flex>
          </AlertDialogHeader>

          <AlertDialogBody>{children}</AlertDialogBody>

          <AlertDialogFooter>
            <IButton
              variant={BUTTON.SECONDARY}
              ref={cancelRef}
              onClick={onCancel}
              name="cancel-button-confirm"
              mr={3}
            >
              {cancelButtonText}
            </IButton>
            <IButton
              variant={BUTTON.PRIMARY}
              onClick={submitHandler}
              name="primary-button-confirm"
              isLoading={confirmButtonLoading}
            >
              {confirmButtonText}
            </IButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
