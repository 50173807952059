import { Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { debounce } from "lodash";
import { memo, useCallback, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { NodeProps } from "reactflow";
import { FLOW_ACTIONS } from "../../../../../../common/constants/campaign";
import { isLoading } from "../../../../../../common/helper/commonHelper";
import { FlowWebhookActionOptions } from "../../../../../../common/types/campaign";
import { ActionNodeArgs } from "../../../../../../common/types/flow";
import DropdownWithSearch from "../../../../../../components/DropdownWithSearch";
import { WebhookDropdownOption } from "../../../../../../components/OptionHelper";
import { useAppDispatch } from "../../../../../../store";

import {
  listAllWebhooks,
  selectWebhook,
} from "../../../../webhook/webhookSlice";
import {
  CampaignBuilderContext,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";
import { WIDGET_OPTIONS_DETAILS } from "../constants";
import { isActionDataSame } from "../helpers";
import WidgetContainer from "../WidgetContainer";

const optionDetails = WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.WEBHOOK];

function WebhookWidget({
  data: { action, groupId, isCandidate, props, selectedExit, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const dispatch = useAppDispatch();
  const { allWebhooksList } = useSelector(selectWebhook);
  const { flowValidity } = useSelector(selectFlow);

  const { activeErrorCheck } = useContext(CampaignBuilderContext);
  const { saveDraft, setActions, readonly } = props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);
  const actionOptions = useMemo(
    () => action.action_options as FlowWebhookActionOptions,
    [action]
  );

  useEffect(() => {
    dispatch(listAllWebhooks());
  }, [dispatch]);

  const setValidityCallback = useCallback(
    (valid: boolean) => {
      dispatch(setFlowValidity({ [identities.actionId]: valid }));
    },
    [dispatch, identities.actionId]
  );

  function setOptions(options: FlowWebhookActionOptions) {
    setActions(options, identities.actionId, groupId);
    debouncedSave(options, { actionId: identities.actionId, groupId });
  }

  const debouncedSave = useMemo(() => debounce(saveDraft, 2000), [saveDraft]);

  useEffect(() => {
    setValidityCallback(!!actionOptions.webhook_id);
  }, [actionOptions.webhook_id, setValidityCallback]);

  function handleOptionChange(value: string | null) {
    setOptions({
      webhook_id: value,
    });
    setValidityCallback(!!value);
  }

  return (
    <WidgetContainer
      invalidMessage={
        flowValidity[identities.actionId] ? "" : "Webhook is required"
      }
      identities={identities}
      title={optionDetails.label}
      icon={optionDetails.icon}
      color={optionDetails.color}
      isCandidate={isCandidate}
      selectedExit={selectedExit}
      selectedGoto={selectedGoto}
      isDisabled={readonly}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        h="100%"
        w="100%"
        px="5"
        py="8"
      >
        <FormControl
          isInvalid={activeErrorCheck && !flowValidity[identities.actionId]}
        >
          <DropdownWithSearch
            options={allWebhooksList.data}
            placeholder="Select webhook"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.webhook_id}
            value={allWebhooksList.data.find(
              (option) => option.webhook_id === actionOptions.webhook_id
            )}
            onChange={(option) =>
              handleOptionChange(option?.webhook_id ?? null)
            }
            isInvalid={activeErrorCheck && !flowValidity[identities.actionId]}
            isLoading={isLoading(allWebhooksList.loading)}
            maxMenuHeight={300}
            isDisabled={readonly}
            isSearchable
            menuPortalTarget={null}
            components={{ Option: WebhookDropdownOption }}
          />
          <FormErrorMessage>Webhook is required</FormErrorMessage>
        </FormControl>
      </Flex>
    </WidgetContainer>
  );
}

export default memo(WebhookWidget, isActionDataSame);
