import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CAMPAIGN_LIST_ALL_ACTION,
  DL_LIST_ALL_ACTION,
  FILTER_TYPE,
} from "../../common/constants/campaign";
import {
  AudienceList,
  EventKeysReq,
  EventNamesReq,
  OperatorList,
  CAMPAIGN_CONTEXT,
  TemplatePrimaryDetails,
  DynamicListSummary,
  TableList,
  DynamicListPreviewQuery,
  CampaignListSummary,
  WebsiteActivityFilter,
} from "../../common/types/campaign";
import { MarketingActivity } from "../../common/types/person";
import { RootState } from "../../store";
import { LOADING_STATES } from "../../common/constants/common";
import {
  createAsyncThunkWrapper,
  initializeLoadingData,
} from "../../common/helper/commonHelper";
import { isEmpty } from "lodash";
import { ListFilterParams, LoadingWithData } from "../../common/types/common";
import { getTemplatesOfCampaignApi } from "../../common/api/campaign/reports";
import {
  listAllDynamicListsApi,
  getDynamicListPreviewApi,
  getEventKeysApi,
  getEventNamesApi,
  getFilterListApi,
  getMarketingActivitiesApi,
  getOperatorsApi,
  getTableDescriptionApi,
  getComputeOperatorsApi,
  getDlPreviewByDlIdApi,
  getDlPreviewByCampaignIdApi,
  getWebsiteTrackingActivitiesApi,
  getCustomParamsInPageVisitsApi,
  getReferrerListApi,
  getPageVisitUrlListApi,
} from "../../common/api/campaign/dynamicList";
import {
  ComputeOperatorDetails,
  ComputeOperatorList,
} from "../../common/types/flow";
import { DESTINATION_TYPES } from "../../common/types/unifiedMapping";
import { filterOperatorsArgCount } from "../../pages/dashboard/campaign/helper/validationHelper";
import { getFormDetailsApi } from "../../common/api/integrations/form";
import { FormDetailsSummary } from "../../common/types/form";
import { listAllCampaignApi } from "../../common/api/campaign/campaign";

export const TEMPLATE_Of_CAMPAIGN_ACTION = "campaign/template-list-filter";
interface DynamicListState {
  dynamicListAll: LoadingWithData<DynamicListSummary[]>;
  filterListLoading: number;
  filterList: {
    [key in CAMPAIGN_CONTEXT]?: {
      [key in FILTER_TYPE]?: LoadingWithData<TableList | {}>;
    };
  };
  tableList: LoadingWithData<TableList | {}>;
  audienceList: LoadingWithData<AudienceList | null>;
  marketingEventNamesList: LoadingWithData<MarketingActivity[] | null>;
  productEventNamesList: LoadingWithData<string[] | null>;
  productEventKeysList: LoadingWithData<{ [eventName: string]: string[] }>;
  formDetailsList: {
    [key: string]: LoadingWithData<FormDetailsSummary | null>;
  };
  operators: LoadingWithData<OperatorList | null>;
  computeOperators: LoadingWithData<ComputeOperatorList | null>;
  computeBooleanOperators: ComputeOperatorDetails[];
  operatorsArgCount: { [key: string]: string };
  totalCampaignList: LoadingWithData<CampaignListSummary[]>;
  templateListOfCampaign: {
    [campaignId: string]: LoadingWithData<TemplatePrimaryDetails[]>;
  };

  websiteActivity: {
    eventNameList: LoadingWithData<WebsiteActivityFilter[] | null>;
  };
}

const initialState: DynamicListState = {
  dynamicListAll: initializeLoadingData([]),
  filterListLoading: 0,
  filterList: {},
  tableList: initializeLoadingData({}),
  audienceList: initializeLoadingData(null),
  marketingEventNamesList: initializeLoadingData(null),
  productEventNamesList: initializeLoadingData(null),
  productEventKeysList: initializeLoadingData({}),
  formDetailsList: {},
  operators: initializeLoadingData(null),
  computeOperators: initializeLoadingData(null),
  computeBooleanOperators: [],
  operatorsArgCount: {},
  templateListOfCampaign: {},
  totalCampaignList: initializeLoadingData([]),
  websiteActivity: {
    eventNameList: initializeLoadingData(null),
  },
};

export const getFilterList = createAsyncThunkWrapper({
  actionName: "campaign/get-filter-list",
  dispatchFn: async ({
    filterType,
    campaignContext,
  }: {
    filterType: FILTER_TYPE;
    campaignContext: CAMPAIGN_CONTEXT;
  }) => {
    return await getFilterListApi(filterType, campaignContext);
  },
  isCachable: true,
});

export const getTableDescription = createAsyncThunkWrapper({
  actionName: "campaign/get-table-description",
  dispatchFn: async () => {
    return await getTableDescriptionApi();
  },
  isCachable: true,
});

export const getDynamicListPreview = createAsyncThunk(
  "campaign/get-dynamic-list-preview",
  async (
    {
      query,
      dynamicListId,
      campaignId,
      searchKeyword,
      columnsToSearchIn,
    }: DynamicListPreviewQuery,
    thunkApi
  ) => {
    const {
      campaign: { campaignDetails },
    } = thunkApi.getState() as RootState;
    if (query) {
      return await getDynamicListPreviewApi(
        query,
        searchKeyword,
        columnsToSearchIn,
        campaignDetails.data.campaign_context
      );
    } else if (dynamicListId) {
      return await getDlPreviewByDlIdApi(dynamicListId);
    } else {
      return await getDlPreviewByCampaignIdApi(campaignId!);
    }
  }
);

export const getEventNames = createAsyncThunk(
  "campaign/get-event-names",
  async (data: EventNamesReq) => {
    return await getEventNamesApi(data);
  }
);

export const getEventKeys = createAsyncThunk(
  "campaign/get-event-keys",
  async (data: EventKeysReq) => {
    return await getEventKeysApi(data);
  }
);

export const getOperators = createAsyncThunkWrapper({
  actionName: "campaign/get-operators",
  dispatchFn: async () => {
    return await getOperatorsApi();
  },
  isCachable: true,
});

export const getComputeOperators = createAsyncThunkWrapper({
  actionName: "campaign/get-compute-operators",
  dispatchFn: async () => {
    return await getComputeOperatorsApi();
  },
  isCachable: true,
});

export const getMarketingActivities = createAsyncThunkWrapper({
  actionName: "campaign/get-marketing-activities",
  dispatchFn: async () => {
    return await getMarketingActivitiesApi();
  },
  isCachable: true,
});

export const getTemplatesOfCampaign = createAsyncThunkWrapper({
  actionName: TEMPLATE_Of_CAMPAIGN_ACTION,
  dispatchFn: async (id: string) => {
    return await getTemplatesOfCampaignApi(id);
  },
  isCachable: true,
});

export const listAllDynamicLists = createAsyncThunkWrapper({
  actionName: DL_LIST_ALL_ACTION,
  dispatchFn: async () => {
    return await listAllDynamicListsApi();
  },
  isCachable: true,
});

export const getFormDetails = createAsyncThunk(
  "form/get",
  async (formId: string) => {
    return await getFormDetailsApi(formId);
  }
);

export const listAllCampaigns = createAsyncThunkWrapper({
  actionName: CAMPAIGN_LIST_ALL_ACTION,
  dispatchFn: async () => {
    return await listAllCampaignApi();
  },
  isCachable: true,
});

//website tracking

export const getWebsiteTrackingActivities = createAsyncThunkWrapper({
  actionName: "campaign/get-website-tracking-activities",
  dispatchFn: async () => {
    return await getWebsiteTrackingActivitiesApi();
  },
  isCachable: true,
});

export const getCustomParamsInPageVisits = createAsyncThunk(
  "campaign/get-custom-parameters-page-visits",
  async (data: ListFilterParams) => {
    return await getCustomParamsInPageVisitsApi(data);
  }
);

export const getReferrerList = createAsyncThunk(
  "campaign/get-referrer-urls",
  async (data: ListFilterParams) => {
    return await getReferrerListApi(data);
  }
);

export const getPageVisitUrlList = createAsyncThunk(
  "campaign/get-page-visit-url",
  async (data: ListFilterParams) => {
    return await getPageVisitUrlListApi(data);
  }
);

const dynamicListSlice = createSlice({
  name: "dynamicList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Filter list
      .addCase(getFilterList.pending, (state, action) => {
        state.filterListLoading += 1;
        const context =
          action.meta.arg.campaignContext ?? CAMPAIGN_CONTEXT.PERSON;
        state.filterList[context] = {
          ...state.filterList[context],
          [action.meta.arg.filterType]: {
            loading: LOADING_STATES.LOADING,
            data:
              state.filterList[context]?.[action.meta.arg.filterType]?.data ??
              {},
          },
        };
      })
      .addCase(getFilterList.fulfilled, (state, action) => {
        state.filterListLoading -= 1;
        const context =
          action.meta.arg.campaignContext ?? CAMPAIGN_CONTEXT.PERSON;
        const oldData =
          state.filterList[context]?.[action.meta.arg.filterType]?.data ?? {};
        state.filterList[context] = {
          ...state.filterList[context],
          [action.meta.arg.filterType]: {
            loading: LOADING_STATES.SUCCESS,
            data: { ...oldData, ...action.payload },
          },
        };
      })
      .addCase(getFilterList.rejected, (state, action) => {
        state.filterListLoading -= 1;
        const context =
          action.meta.arg.campaignContext ?? CAMPAIGN_CONTEXT.PERSON;
        state.filterList[context] = {
          ...state.filterList[context],
          [action.meta.arg.filterType]: {
            loading: LOADING_STATES.FAILED,
            data:
              state.filterList[context]?.[action.meta.arg.filterType]?.data ??
              {},
          },
        };
      })

      // Table list
      .addCase(getTableDescription.pending, (state) => {
        state.tableList.loading = LOADING_STATES.LOADING;
      })
      .addCase(getTableDescription.fulfilled, (state, action) => {
        state.tableList.data = action.payload;
        state.tableList.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getTableDescription.rejected, (state) => {
        state.tableList.loading = LOADING_STATES.FAILED;
      })

      // Dynamic list preview
      .addCase(getDynamicListPreview.pending, (state) => {
        state.audienceList.data = {
          rows: [],
          total_count: {
            count: 0,
          },
        };
        state.audienceList.loading = LOADING_STATES.LOADING;
      })
      .addCase(getDynamicListPreview.fulfilled, (state, action) => {
        state.audienceList.data = action.payload.audience_list;
        state.audienceList.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getDynamicListPreview.rejected, (state) => {
        state.audienceList.loading = LOADING_STATES.FAILED;
      })

      .addCase(getEventNames.pending, (state) => {
        state.productEventNamesList.loading = LOADING_STATES.LOADING;
      })
      .addCase(getEventNames.fulfilled, (state, action) => {
        state.productEventNamesList.data = action.payload.rows;
        state.productEventNamesList.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getEventNames.rejected, (state) => {
        state.productEventNamesList.loading = LOADING_STATES.FAILED;
      })

      .addCase(getEventKeys.pending, (state) => {
        state.productEventKeysList.loading = LOADING_STATES.LOADING;
      })
      .addCase(getEventKeys.fulfilled, (state, action) => {
        if (!isEmpty(action.payload)) {
          state.productEventKeysList.data = {
            ...state.productEventKeysList.data,
            ...action.payload,
          };
        }
        state.productEventKeysList.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getEventKeys.rejected, (state) => {
        state.productEventKeysList.loading = LOADING_STATES.FAILED;
      })

      .addCase(getOperators.pending, (state) => {
        state.operators.loading = LOADING_STATES.LOADING;
      })
      .addCase(getOperators.fulfilled, (state, action) => {
        state.operators.data = action.payload;
        state.operatorsArgCount = filterOperatorsArgCount(action.payload);
        state.operators.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getOperators.rejected, (state) => {
        state.operators.loading = LOADING_STATES.FAILED;
      })
      .addCase(getComputeOperators.pending, (state) => {
        state.computeOperators.loading = LOADING_STATES.LOADING;
      })
      .addCase(getComputeOperators.fulfilled, (state, action) => {
        state.computeOperators.data = action.payload;
        state.computeBooleanOperators = Object.values(
          action.payload[DESTINATION_TYPES.BOOLEAN]
        );
        state.computeOperators.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getComputeOperators.rejected, (state) => {
        state.computeOperators.loading = LOADING_STATES.FAILED;
      })
      .addCase(getMarketingActivities.pending, (state) => {
        state.marketingEventNamesList.loading = LOADING_STATES.LOADING;
      })
      .addCase(getMarketingActivities.fulfilled, (state, action) => {
        if (!isEmpty(action.payload)) {
          state.marketingEventNamesList.data =
            action.payload.marketing_activity;
        }
        state.marketingEventNamesList.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getMarketingActivities.rejected, (state) => {
        state.marketingEventNamesList.loading = LOADING_STATES.FAILED;
      })

      .addCase(getTemplatesOfCampaign.pending, (state, action) => {
        state.templateListOfCampaign[action.meta.arg] = {
          loading: LOADING_STATES.LOADING,
          data: [],
        };
      })
      .addCase(getTemplatesOfCampaign.fulfilled, (state, action) => {
        state.templateListOfCampaign[action.meta.arg] = {
          data: action.payload.templates,
          loading: LOADING_STATES.SUCCESS,
        };
      })
      .addCase(getTemplatesOfCampaign.rejected, (state, action) => {
        if (!state.templateListOfCampaign[action.meta.arg]?.data?.length) {
          state.templateListOfCampaign[action.meta.arg] = {
            data: [],
            loading: LOADING_STATES.FAILED,
          };
        }
      })

      .addCase(listAllDynamicLists.pending, (state) => {
        state.dynamicListAll.loading = LOADING_STATES.LOADING;
      })
      .addCase(listAllDynamicLists.fulfilled, (state, action) => {
        state.dynamicListAll.data = action.payload.records;
        state.dynamicListAll.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(listAllDynamicLists.rejected, (state) => {
        state.dynamicListAll.loading = LOADING_STATES.FAILED;
      })

      .addCase(listAllCampaigns.pending, (state) => {
        state.totalCampaignList.loading = LOADING_STATES.LOADING;
      })
      .addCase(listAllCampaigns.fulfilled, (state, action) => {
        state.totalCampaignList = {
          data: action.payload.records,
          loading: LOADING_STATES.SUCCESS,
        };
      })
      .addCase(listAllCampaigns.rejected, (state) => {
        state.totalCampaignList.loading = LOADING_STATES.FAILED;
      })

      // get form fields
      .addCase(getFormDetails.pending, (state, { meta }) => {
        state.formDetailsList = {
          ...state.formDetailsList,
          [meta.arg]: { data: null, loading: LOADING_STATES.LOADING },
        };
      })
      .addCase(getFormDetails.fulfilled, (state, { payload, meta }) => {
        state.formDetailsList = {
          ...state.formDetailsList,
          [meta.arg]: { data: payload.form, loading: LOADING_STATES.SUCCESS },
        };
      })

      //website tracking

      //get website activities to filter in DL
      .addCase(getWebsiteTrackingActivities.pending, (state) => {
        state.websiteActivity.eventNameList.loading = LOADING_STATES.LOADING;
      })
      .addCase(getWebsiteTrackingActivities.fulfilled, (state, { payload }) => {
        state.websiteActivity.eventNameList = {
          data: payload.website_tracking_activities ?? null,
          loading: LOADING_STATES.SUCCESS,
        };
      })
      .addCase(getWebsiteTrackingActivities.rejected, (state) => {
        state.websiteActivity.eventNameList.loading = LOADING_STATES.FAILED;
      });
  },
});

export const selectDynamicList = (state: RootState) => state.dynamicList;

export default dynamicListSlice.reducer;
