import { Flex, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

export function ReadonlyField({ children }: { children?: ReactNode }) {
  return (
    <Flex alignItems="center" w="100%" wordBreak="break-all">
      <Text fontSize="sm" px="2">
        {children}
      </Text>
    </Flex>
  );
}
