import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  InputGroupProps,
} from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";

export type SearchFieldType = InputGroupProps & {
  placeholder?: string;
  name: string;
  value: string;
  onSearch: (keyword: string) => void;
};

export function SearchField({
  placeholder = "Search",
  name,
  value,
  onSearch,
  ...inputGroupProps
}: SearchFieldType) {
  return (
    <InputGroup size="sm" {...inputGroupProps} bg="white" borderRadius="4px">
      <InputRightElement children={<Icon as={FaSearch} color="gray.300" />} />
      <Input
        borderRadius="4px"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(e) => onSearch(e.target.value)}
      />
    </InputGroup>
  );
}
