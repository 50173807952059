import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputProps,
  FormLabelProps,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";
import ITitle from "./ITitle";
import ISkeleton, { SKELETON_VARIANT } from "./ISkeleton";
import { ReactNode } from "react";

interface InputWithErrorProps extends InputProps {
  labelText?: string;
  errorMsg?: string | ReactNode;
  paddingX?: string;
  paddingY?: string;
  maxWidth?: string;
  touched?: boolean;
  capitalize?: boolean;
  formLabelProps?: FormLabelProps;
  helperText?: string;
  textArea?: boolean;
  isLoading?: boolean;
}

export default function InputFieldWithError({
  labelText,
  errorMsg,
  paddingX,
  paddingY,
  maxWidth,
  helperText,
  touched = true,
  formLabelProps,
  capitalize,
  textArea,
  isLoading,
  ...props
}: InputWithErrorProps) {
  return (
    <FormControl
      px={paddingX}
      py={paddingY || "2"}
      maxWidth={maxWidth}
      isInvalid={!!errorMsg && touched}
    >
      {labelText && (
        <FormLabel {...formLabelProps}>
          <ITitle title={labelText} capitalize={capitalize} />
        </FormLabel>
      )}
      <ISkeleton
        variant={textArea ? SKELETON_VARIANT.TEXT_AREA : SKELETON_VARIANT.INPUT}
        isLoaded={!isLoading}
        border="1px solid"
        borderColor="grayV2.200"
      >
        {textArea ? (
          <Textarea {...(props as TextareaProps)} />
        ) : (
          <Input type="text" {...props} />
        )}
      </ISkeleton>
      {helperText && (
        <ITitle fontSize="xs" color="gray.600" title={helperText} mt="8px" />
      )}
      <FormErrorMessage fontSize={12}>{errorMsg}</FormErrorMessage>
    </FormControl>
  );
}
