import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  isFinished,
  isFulfilled,
  isInit,
  isLoading,
} from "../../../common/helper/commonHelper";
import IButton from "../../../components/IButton";
import ISkeleton, { SKELETON_VARIANT } from "../../../components/ISkeleton";
import { useAppDispatch } from "../../../store";
import SearchTagInput from "./SearchTagInput";
import TagList from "./TagList";
import {
  addFavouriteTag,
  listFavouriteTags,
  removeFavouriteTag,
  selectTag,
} from "./tagSlice";

export default function TagSideBar({
  onSelection,
}: {
  onSelection: (tags: string[]) => void;
}) {
  const {
    favouriteTags,
    tagSummaryList: { data: tagSummaryList },
  } = useSelector(selectTag);
  const dispatch = useAppDispatch();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (isInit(favouriteTags.loading)) dispatch(listFavouriteTags());
  }, [favouriteTags.loading, dispatch]);

  function onTagSelectionChange(ids: string[]) {
    onSelection(ids);
    setSelectedTags(ids);
  }

  async function onSelectTagOption(id: string) {
    if (!favouriteTags.data.includes(id)) {
      const response = await dispatch(addFavouriteTag(id));
      if (
        isFulfilled(response.meta.requestStatus) &&
        (response.payload as { status: boolean }).status
      ) {
        onTagSelectionChange([...selectedTags, id]);
      }
    } else {
      if (!selectedTags.includes(id)) {
        onTagSelectionChange([...selectedTags, id]);
      }
    }
  }

  async function onRemoveTag(id: string) {
    const response = await dispatch(removeFavouriteTag(id));
    if (
      isFulfilled(response.meta.requestStatus) &&
      (response.payload as { status: boolean }).status
    ) {
      if (selectedTags.includes(id)) {
        onTagSelectionChange(selectedTags.filter((tag) => tag !== id));
      }
    }
  }

  function onTagsSelect(id: string) {
    if (selectedTags.includes(id)) {
      onTagSelectionChange(selectedTags.filter((tag) => tag !== id));
    } else {
      onTagSelectionChange([...selectedTags, id]);
    }
  }

  return (
    <Box w="100%" h="100%">
      <ISkeleton
        variant={SKELETON_VARIANT.INPUT}
        isLoaded={isFinished(favouriteTags.loading)}
        h="38px"
      >
        <SearchTagInput onSelectTag={onSelectTagOption} />
      </ISkeleton>
      <VStack alignItems="flex-start" height="100%" py={4} px={1}>
        <HStack w="100%" justifyContent="space-between">
          <HStack>
            <Text fontWeight="600" color="text.100" fontSize="14px">
              Recent tags
            </Text>
            {selectedTags.length && (
              <Box
                background="gray.200"
                fontSize="12px"
                px={2}
                borderRadius="md"
              >
                {selectedTags.length} selected
              </Box>
            )}
          </HStack>
          {selectedTags.length && (
            <IButton
              size="xs"
              variant="ghost"
              fontWeight="400"
              onClick={() => onTagSelectionChange([])}
            >
              Clear
            </IButton>
          )}
        </HStack>
        <Box h="100%" w="100%">
          <TagList
            tags={favouriteTags.data.map((id) => {
              return {
                tag_id: id,
                name: tagSummaryList[id] ?? "",
                selected: selectedTags.includes(id),
              };
            })}
            isLoading={isLoading(favouriteTags.loading)}
            onTagClick={onTagsSelect}
            onRemoveRow={onRemoveTag}
          />
        </Box>
      </VStack>
    </Box>
  );
}
