import {
  Box,
  Button,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useDisclosure,
  MenuOptionGroup,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { endOfToday, startOfDay, subDays } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  DynamicListType,
  StaticListContactsData,
} from "../../../common/types/campaign";
import DateTimeRangeFilter from "../../../components/DateTimeRangeFilter";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import IButton from "../../../components/IButton";
import ITitle from "../../../components/ITitle";
import { useAppDispatch } from "../../../store";
import urls from "../../../urls";
import NumberFormatHeader from "../../../components/NumberFormatHeader";
import { staticListContactsTableHeader } from "./components/tableDefinitions";
import ContactUploaderCsv from "../contactUpload/ContactUploaderCsv";
import { UPLOAD_CONTACTS_TO } from "../../../common/types/contactUpload";
import CommonDynamicListDrawer from "../../../components/dynamic-list/CommonDynamicListDrawer";
import {
  getStaticList,
  insertStaticListContactsDl,
  listStaticListContacts,
  removeAllStaticListContacts,
  removeStaticListContacts,
  resetDlUploadState,
  resetStaticDetailsPage,
  selectStaticList,
  setStaticListContactsPage,
} from "./staticlistSlice";
import { DataTable } from "../../../components/data-table/DataTable";
import { useSearch } from "../../../common/hooks/commonHooks";
import { exportStaticList, selectExport } from "../export/exportSlice";
import { isLoading, isSuccess } from "../../../common/helper/commonHelper";
import { ALERT_TYPE } from "../../../common/constants/common";
import SlContactsEmptyState from "../../../common/img/emptyStateLogos/staticListContacts.svg";
import InitialEmptyState from "../../../components/InitialEmptyState";

enum DELETION_TYPE {
  ALL = "all",
  SOME = "some",
  SINGLE = "single",
}

function DeleteConfirmationModalContent({
  isOnlyOneContactSelected,
  textToshow,
  staticListName,
}: {
  isOnlyOneContactSelected: boolean;
  textToshow: string;
  staticListName: string;
}) {
  return (
    <Text fontSize="14px" color="blackAlpha.700" lineHeight={2}>
      Are you sure about removing{" "}
      {isOnlyOneContactSelected ? (
        "the"
      ) : (
        <Text as="span" fontWeight="bold" color="blackAlpha.900">
          {textToshow}{" "}
        </Text>
      )}{" "}
      selected contact
      {isOnlyOneContactSelected ? "" : "s"} from{" "}
      <Text as="span" fontWeight="bold" color="blackAlpha.900">
        {staticListName ?? ""}
      </Text>
      ? Once deleted, cannot be undone
    </Text>
  );
}

function AddContactsButton({
  onCSVModalOpen,
  onDLModalOpen,
  onExportList,
  isHidden,
  isExportLoading,
}: {
  onCSVModalOpen: () => void;
  onDLModalOpen: () => void;
  onExportList?: () => void;
  isHidden?: boolean;
  isExportLoading?: boolean;
}) {
  return (
    <Menu>
      <MenuButton
        colorScheme="blue"
        size="sm"
        as={Button}
        rightIcon={<FaChevronDown />}
        name="add-contacts-button"
        hidden={isHidden}
        px="16px"
        py="4px"
      >
        Actions
      </MenuButton>
      <MenuList zIndex={3} fontSize="14px" minW="180px">
        <MenuOptionGroup title="Add contacts">
          <MenuItem onClick={onCSVModalOpen} name="insert-from-csv">
            From a CSV file
          </MenuItem>
          <MenuItem onClick={onDLModalOpen} name="insert-from-dl">
            From a dynamic list
          </MenuItem>
        </MenuOptionGroup>
        {onExportList && (
          <MenuOptionGroup title="Export contacts">
            <MenuItem
              onClick={onExportList}
              name="export-contacts"
              isDisabled={isExportLoading}
            >
              Export entire List
              {isExportLoading && <Spinner size="sm" color="gray.500" ml={6} />}
            </MenuItem>
          </MenuOptionGroup>
        )}
      </MenuList>
    </Menu>
  );
}

function FilterBar({
  searchText,
  onSearch,
  startDate,
  endDate,
  onDateChange,
  dateFilterHandler,
}: {
  searchText: string;
  onSearch: (text: string) => void;
  startDate: Date;
  endDate: Date;
  onDateChange: (start: Date, end: Date) => void;
  dateFilterHandler: () => void;
}) {
  return (
    <HStack justifyContent="space-between" bg="gray.100" height="40px" px={2}>
      <Input
        type="text"
        placeholder="Search contacts"
        width="300px"
        height="32px"
        bg="white"
        name="search-input"
        borderRadius="4px"
        fontSize="14px"
        value={searchText}
        onChange={(e) => onSearch(e.target.value)}
        borderColor="gray.300"
      />
      <HStack>
        <ITitle title="Filter by date added" fontSize="14px" fontWeight="500" />
        <DateTimeRangeFilter
          startDate={startDate}
          endDate={endDate}
          onDateChange={onDateChange}
          dateFilterHandler={dateFilterHandler}
        />
      </HStack>
    </HStack>
  );
}

function ClearButtons({
  count,
  removeAll,
  removeSome,
}: {
  count: number;
  removeAll: () => void;
  removeSome: () => void;
}) {
  return (
    <HStack>
      <IButton
        colorScheme="red"
        bg="red.50"
        color="brand.red"
        borderColor="red.100"
        variant="outline"
        name="delete-selected"
        onClick={removeSome}
      >
        {`Remove selected ${count} contact${
          count > 1 ? "s" : ""
        } from the list`}
      </IButton>
      <IButton variant="outline" onClick={removeAll} name="clear-all-button">
        Clear the list
      </IButton>
    </HStack>
  );
}

export default function StaticListContacts() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    staticListContacts,
    staticListDetails,
    staticListDl,
    staticListDeleting,
  } = useSelector(selectStaticList);
  const [[startDate, endDate], setDateRange] = useState([
    startOfDay(subDays(new Date(), 365)),
    endOfToday(),
  ]);

  const [searchKeyword, setSearchKeyword] = useSearch();
  const [selectedRows, setSelectedRows] = useState<StaticListContactsData[]>(
    []
  );
  const [rowForDeletion, setRowForDeletion] =
    useState<StaticListContactsData | null>(null);

  const [removeOption, setDeleteOption] = useState<DELETION_TYPE | null>(null);

  useEffect(() => {
    switch (removeOption) {
      case DELETION_TYPE.ALL:
        setTextToShow("all");
        break;
      case DELETION_TYPE.SOME:
        setTextToShow(`${selectedRows.length}`);
        break;
      default:
        setTextToShow("");
    }
  }, [selectedRows, removeOption]);

  const lastSearchedDate = useRef({ startDate, endDate });
  const [textToshow, setTextToShow] = useState("");

  const {
    isOpen: isCSVModalOpen,
    onClose: onCSVModalClose,
    onOpen: onCSVModalOpen,
  } = useDisclosure();

  const {
    isOpen: isDLModalOpen,
    onClose: onDLModalClose,
    onOpen: onDLModalOpen,
  } = useDisclosure();

  const { id } = useParams<{ id: string }>();
  const isOnlyOneContactSelected =
    removeOption === DELETION_TYPE.SINGLE ||
    (selectedRows.length === 1 && removeOption !== DELETION_TYPE.ALL);

  const { assetsToExport } = useSelector(selectExport);

  useEffect(() => {
    if (id) dispatch(getStaticList(id));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetStaticDetailsPage());
    };
  }, [dispatch]);

  const searchIfValid = useCallback(
    (searchText: string) => {
      if (staticListContacts.currentPageNo !== 1) {
        dispatch(setStaticListContactsPage(0));
      } else {
        if (id)
          dispatch(
            listStaticListContacts({
              id,
              searchText,
              start_date: lastSearchedDate.current.startDate.toISOString(),
              end_date: lastSearchedDate.current.endDate.toISOString(),
            })
          );
      }
    },
    [staticListContacts.currentPageNo, dispatch, id]
  );

  useEffect(() => {
    if (id)
      dispatch(
        listStaticListContacts({
          id,
          searchText: searchKeyword,
          start_date: startDate.toISOString(), //utc datetime
          end_date: endDate.toISOString(),
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, staticListContacts.currentPageNo]);

  useEffect(() => {
    if (isSuccess(staticListDl.inserting) && id) {
      searchIfValid(searchKeyword);
      dispatch(getStaticList(id));
      dispatch(resetDlUploadState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, staticListDl.inserting, id]);

  useEffect(() => {
    if (isSuccess(staticListDeleting) && id) {
      searchIfValid(searchKeyword);
      dispatch(getStaticList(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, staticListDeleting, id]);

  const debouncedSearch = useMemo(
    () => debounce(searchIfValid, 1000),
    [searchIfValid]
  );

  function dateFilterHandler() {
    if (id) {
      dispatch(
        listStaticListContacts({
          id,
          searchText: searchKeyword,
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
        })
      );
      lastSearchedDate.current = { startDate, endDate };
    }
  }

  function onDateChange(startDate: Date, endDate: Date) {
    setDateRange([startDate, endDate]);
  }

  function gotoStaticLists() {
    navigate(urls.staticList);
  }

  function removeContacts(rows?: StaticListContactsData[]) {
    if (rows && id) {
      dispatch(
        removeStaticListContacts({
          staticListId: id,
          emails: rows.map((data: StaticListContactsData) => data.email),
        })
      );
    } else {
      if (id) dispatch(removeAllStaticListContacts(id));
    }
    setSelectedRows([]);
    setDeleteOption(null);
    setRowForDeletion(null);
  }

  function onSearch(text: string) {
    debouncedSearch(text);
    setSearchKeyword(text);
  }

  function onExportList() {
    if (id) {
      dispatch(exportStaticList(id));
    }
  }

  const isExportLoading =
    (!!id && isLoading(assetsToExport[id]?.isLoading)) ||
    staticListContacts.fetchingList;

  function onImportContactsViaCsv() {
    if (id) {
      setTimeout(() => {
        dispatch(getStaticList(id));
        dispatch(
          listStaticListContacts({
            id,
            searchText: searchKeyword,
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
          })
        );
      }, 500);
    }
  }

  return (
    <>
      <NumberFormatHeader
        heading={staticListDetails.data?.name ?? ""}
        count={staticListDetails.data?.num_records ?? 0}
        countLabel="contacts"
        isLoading={isLoading(staticListDetails.loading)}
        goBack={gotoStaticLists}
      >
        <AddContactsButton
          onCSVModalOpen={onCSVModalOpen}
          onDLModalOpen={onDLModalOpen}
          onExportList={
            staticListDetails.data?.num_records ? onExportList : undefined
          }
          isHidden={isLoading(staticListDetails.loading)}
          isExportLoading={isExportLoading}
        />
      </NumberFormatHeader>
      <Box px={10} py={5}>
        <Skeleton isLoaded={!isLoading(staticListDetails.loading)}>
          {selectedRows.length ? (
            <ClearButtons
              count={selectedRows.length}
              removeSome={() => setDeleteOption(DELETION_TYPE.SOME)}
              removeAll={() => setDeleteOption(DELETION_TYPE.ALL)}
            />
          ) : (
            <FilterBar
              searchText={searchKeyword}
              onSearch={onSearch}
              startDate={startDate}
              endDate={endDate}
              onDateChange={onDateChange}
              dateFilterHandler={dateFilterHandler}
            />
          )}
        </Skeleton>
        {!!staticListDetails.data?.num_records ||
        isLoading(staticListDetails.loading) ? (
          <Box py={5} height="calc(100vh - 120px)">
            <DataTable
              fetchingList={
                isLoading(staticListDetails.loading) ||
                staticListContacts.fetchingList
              }
              changingPage={staticListContacts.changingPage}
              list={staticListContacts.list}
              totalPageSize={staticListContacts.pageSize}
              totalPageCount={staticListContacts.totalPageCount}
              currentPage={staticListContacts.currentPageNo}
              setPage={(pageNo) => dispatch(setStaticListContactsPage(pageNo))}
              columns={staticListContactsTableHeader((row) => {
                setDeleteOption(DELETION_TYPE.SINGLE);
                setRowForDeletion(row);
              })}
              emptyMsg="No contacts found"
              onSelectionChange={setSelectedRows}
            />
          </Box>
        ) : (
          <InitialEmptyState
            mainText="Add new contacts using the button below from a file or by querying using a dynamic list from contact database"
            message="No contacts in this list"
            mainTextProps={{
              fontSize: "12px",
              fontWeight: 600,
              color: "gray.400",
              maxW: "400px",
            }}
            messageProps={{
              fontWeight: "600",
              fontSize: "18px",
              color: "unset",
            }}
            containerProps={{ bg: "white", height: "80vh" }}
            additionalActions={
              <AddContactsButton
                onCSVModalOpen={onCSVModalOpen}
                onDLModalOpen={onDLModalOpen}
              />
            }
          >
            <Image src={SlContactsEmptyState} alt="Contacts" />
          </InitialEmptyState>
        )}
      </Box>
      <ContactUploaderCsv
        isOpen={isCSVModalOpen}
        onClose={onCSVModalClose}
        onImport={onImportContactsViaCsv}
        uploadTo={UPLOAD_CONTACTS_TO.STATIC_LIST}
      />

      <CommonDynamicListDrawer
        isOpen={isDLModalOpen}
        onClose={onDLModalClose}
        submitButtonProps={{
          label: "Add to list",
          onSubmit: (dynamicList: DynamicListType[]) =>
            dispatch(
              insertStaticListContactsDl({
                id: staticListDetails.data?.static_list_id ?? "",
                dynamicList: dynamicList,
              })
            ),
          props: {
            isLoading: isLoading(staticListDl.inserting),
          },
        }}
        showDLCount={true}
      />
      <DeleteConfirmationModal
        isOpen={!!removeOption}
        onClose={() => setDeleteOption(null)}
        type={ALERT_TYPE.REMOVE}
        submitHandler={() => {
          switch (removeOption) {
            case DELETION_TYPE.ALL:
              removeContacts();
              break;
            case DELETION_TYPE.SOME:
              removeContacts(selectedRows);
              break;
            case DELETION_TYPE.SINGLE:
              rowForDeletion && removeContacts([rowForDeletion]);
              break;
          }
        }}
        customHeader={`Remove contact${isOnlyOneContactSelected ? "" : "s"}`}
        customContent={
          <DeleteConfirmationModalContent
            isOnlyOneContactSelected={isOnlyOneContactSelected}
            textToshow={textToshow}
            staticListName={staticListDetails.data?.name ?? ""}
          />
        }
      />
    </>
  );
}
