import React from "react";
import PrimaryHeader, { DefaultHeaderType } from "./PrimaryHeader";
import { Heading, StackProps } from "@chakra-ui/react";
import NumberFormat from "react-number-format";
import { formatLargeNumber } from "../common/helper/commonHelper";

type CountHeaderType = {
  count: number | null;
  countLabel?: string;
};

function DisplayCount({ count, countLabel }: CountHeaderType) {
  return (
    <Heading ml={2} color="gray.500" fontSize="xs">
      <NumberFormat
        value={formatLargeNumber(count ?? 0, 2)}
        isNumericString={true}
        thousandSeparator={true}
        displayType="text"
      />{" "}
      {countLabel}
    </Heading>
  );
}

export default function NumberFormatHeader({
  heading,
  goBack,
  count,
  countLabel,
  children,
  ...props
}: DefaultHeaderType & CountHeaderType & StackProps) {
  return (
    <PrimaryHeader
      heading={heading}
      children={children}
      goBack={goBack}
      addComponentToLeft={
        count ? (
          <DisplayCount count={count} countLabel={countLabel} />
        ) : undefined
      }
      {...props}
    />
  );
}
