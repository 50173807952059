import { EMAIL_CONFIG_TEXT_MAP } from "../constants/template";
import { EmailConfigChangeTracker } from "../types/template";

export default function getDisplayTextForOverridenFields(
  emailConfigChange: EmailConfigChangeTracker | undefined
) {
  const entries = Object.entries(emailConfigChange ?? {});
  return entries
    .filter(([key, value]) => value)
    .map(
      ([key]) =>
        EMAIL_CONFIG_TEXT_MAP[key as keyof typeof EMAIL_CONFIG_TEXT_MAP]
    );
}
