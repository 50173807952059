import {
  Flex,
  Box,
  Icon,
  VStack,
  Divider,
  GridItem,
  Image,
  Text,
  Grid,
  useDisclosure,
  HStack,
  GridItemProps,
  Skeleton,
  FormLabel,
  FormControl,
  ButtonProps,
} from "@chakra-ui/react";
import { cloneDeep, set } from "lodash";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FaExternalLinkAlt, FaArrowLeft, FaCog } from "react-icons/fa";
import { MdInfo } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  addPunctuationSeparators,
  isLoading,
  isSuccess,
  openAssetInNewTab,
  validateEmailConfigInputs,
} from "../../../../../common/helper/commonHelper";
import {
  EMAIL_CONFIG_DATA_INIT,
  EMAIL_CONFIG_FIELDS,
  VALUE_META_TYPE,
} from "../../../../../common/constants/template";
import { SendEmailActionOptions } from "../../../../../common/types/campaign";
import {
  EmailConfigChangeTracker,
  EmailConfigWithSubject,
  TemplateExhaustive,
  TemplateType,
} from "../../../../../common/types/template";
import EmailConfigureInput from "../../../../../components/EmailConfigureInput";
import IButton, { BUTTON } from "../../../../../components/IButton";
import IModal from "../../../../../components/IModal";
import { selectTemplate } from "../../../templates/templateSlice";
import { selectCampaign } from "../../campaignSlice";
import CommonDrawer from "../../components/CommonDrawer";
import { selectSettings } from "../../../settings/settingsSlice";
import { EMAIL_PREVIEW_VARIANTS } from "./constants";
import ChangeInFlowAlertModal from "./ChangeInFlowAlertModal";
import {
  ASSET_TYPES,
  EMPTY_CONTEXT,
} from "../../../../../common/constants/common";
import getDisplayTextForOverridenFields from "../../../../../common/helper/templateHelper";

const EMPTY_CONTENT = "(empty)";

function TemplateChangeAlertModal({
  onClose,
  isOpen,
  onConfirm,
}: {
  onClose: () => void;
  isOpen: boolean;
  onConfirm: () => void;
}) {
  return (
    <ChangeInFlowAlertModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title="Confirm email asset change"
      content="Are you sure that you want to change the email asset?"
      infoText="Please note that changing the email asset would reset the 
      email metrics as the reporting is based off the email asset."
    />
  );
}
function OverrideEmailConfigModal({
  onClose,
  isOpen,
  templateName = "",
  templateDetails,
  actionOptions,
  onSave,
}: {
  onClose: () => void;
  isOpen: boolean;
  templateName?: string;
  templateDetails: TemplateExhaustive;
  actionOptions: SendEmailActionOptions;
  onSave?: (dataOptions: SendEmailActionOptions) => void;
}) {
  const {
    globalDefault: { data: globalDefault },
  } = useSelector(selectSettings);

  const [inputs, setInputs] = useState(EMAIL_CONFIG_DATA_INIT);
  const [errors, setErrors] = useState(EMAIL_CONFIG_DATA_INIT);
  const [validity, setValidity] = useState(true);
  const [showErrors, setShowErrors] = useState(false);

  // handles change in input and validates data
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const inputName = event.target.name;
    const inputVal = event.target.value;
    const newInputs = cloneDeep(inputs);
    switch (inputName) {
      case EMAIL_CONFIG_FIELDS.FROM_EMAIL_NAME:
        set(newInputs, EMAIL_CONFIG_FIELDS.FROM_EMAIL, inputs.from_email.email);
        break;
      case EMAIL_CONFIG_FIELDS.FROM_EMAIL:
        set(
          newInputs,
          EMAIL_CONFIG_FIELDS.FROM_EMAIL_NAME,
          inputs.from_email.name
        );
        break;
    }
    set(newInputs, inputName, inputVal);
    validateInputs(newInputs);
    setInputs(newInputs);
  }

  //validates input
  const validateInputs = useCallback((inputData: EmailConfigWithSubject) => {
    const { validity, errors } = validateEmailConfigInputs(inputData);
    setErrors(errors);
    setValidity(validity);
    return validity;
  }, []);

  //loads initial data on open of Modal, and always loads the last save values
  useEffect(() => {
    const emailData: EmailConfigWithSubject = {
      subject: actionOptions.subject ?? templateDetails?.subject ?? "",
      from_email: {
        name:
          actionOptions.from_email?.name ??
          templateDetails?.from_email?.name ??
          globalDefault?.from_email?.name ??
          "",
        email:
          actionOptions.from_email?.email ??
          templateDetails?.from_email?.email ??
          globalDefault?.from_email?.email ??
          "",
      },
      reply_to:
        actionOptions.reply_to ??
        templateDetails?.reply_to ??
        globalDefault?.reply_to ??
        "",
      sender_meta: {
        from_email:
          actionOptions.sender_meta?.from_email ??
          templateDetails?.sender_meta?.from_email ??
          VALUE_META_TYPE.TEXT,
        reply_to:
          actionOptions.sender_meta?.reply_to ??
          templateDetails?.sender_meta?.reply_to ??
          VALUE_META_TYPE.TEXT,
        from_name: VALUE_META_TYPE.TOKEN,
      },
    };
    setInputs(emailData);
  }, [isOpen, actionOptions, globalDefault, templateDetails]);

  function onCloseModal() {
    onClose();
    setShowErrors(false);
  }

  // saves and shows error if data is invalid
  function onSaveEmailConfig(data: EmailConfigWithSubject) {
    if (!validateInputs(data)) setShowErrors(true);
    else {
      onSave?.({ template_id: templateDetails.template_id, ...data });
      onCloseModal();
      setShowErrors(false);
    }
  }
  return (
    <IModal
      header={{ title: "Email configuration" }}
      onClose={onCloseModal}
      isOpen={isOpen}
      size="md"
      primaryButton={{
        label: "Save",
        props: {
          onClick: () => onSaveEmailConfig(inputs),
          isDisabled: showErrors && !validity,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: onCloseModal,
        },
      }}
    >
      <FormControl mb={2}>
        <FormLabel color={"gray.500"} fontSize={"xs"}>
          Email
        </FormLabel>
        <Text fontWeight="500" fontSize="14">
          {templateName}
        </Text>
      </FormControl>
      <EmailConfigureInput
        isCodemirror={true}
        inputs={inputs}
        onChange={handleInputChange}
        errors={showErrors ? errors : EMAIL_CONFIG_DATA_INIT}
        inputPaddingX="0"
      />
    </IModal>
  );
}

function GridItemBox({
  text,
  isLoading,
  ...props
}: { text?: string | null; isLoading?: boolean } & GridItemProps) {
  return (
    <GridItem
      fontSize="sm"
      color="brandBlue.500"
      wordBreak="break-word"
      {...props}
    >
      <Skeleton isLoaded={!isLoading} minH="20px" minW="100px">
        {text ?? EMPTY_CONTENT}
      </Skeleton>
    </GridItem>
  );
}

export function EmailTemplateDetails({
  goBack,
  variant,
  actionOptions,
  onSave,
  emailConfigChange,
}: {
  goBack?: () => void;
  variant: EMAIL_PREVIEW_VARIANTS | null;
  actionOptions?: SendEmailActionOptions;
  onSave?: (dataOptions: SendEmailActionOptions) => void;
  emailConfigChange?: EmailConfigChangeTracker;
}) {
  const { templateDetails, templateName } = useSelector(selectTemplate);
  const { globalDefault } = useSelector(selectSettings);

  const {
    isOpen: isOpenConfig,
    onClose: onCloseConfig,
    onOpen: onOpenConfig,
  } = useDisclosure();

  const isReadonly = variant === EMAIL_PREVIEW_VARIANTS.READONLY;
  const isLoadingTemplateDetails = isLoading(templateDetails.loading);

  const subject = useMemo(() => {
    let subjectValue = EMPTY_CONTEXT;

    if (actionOptions?.subject) {
      subjectValue = actionOptions.subject;
    } else if (templateDetails?.data.subject) {
      subjectValue = templateDetails.data.subject;
    }
    return subjectValue;
  }, [actionOptions, templateDetails]);

  const isEmailConfigOverridden = Object.values(emailConfigChange ?? {}).some(
    (value) => value
  );
  const overridenFields = getDisplayTextForOverridenFields(emailConfigChange);

  return (
    <VStack alignItems="start">
      {variant === EMAIL_PREVIEW_VARIANTS.SELECTION && (
        <IButton
          size="md"
          aria-label="Go back"
          variant="link"
          name="return-button"
          onClick={goBack}
          fontSize="xs"
          pb={2}
          leftIcon={<Icon fontSize="xs" as={FaArrowLeft} />}
        >
          Back to email assets
        </IButton>
      )}
      <Skeleton minH="24px" minW="150px" isLoaded={!isLoadingTemplateDetails}>
        <Text
          fontSize="16"
          fontWeight="600"
          color="brandBlue.500"
          maxH="200px"
          overflow="auto"
        >
          {templateName}
        </Text>
      </Skeleton>

      {variant !== EMAIL_PREVIEW_VARIANTS.SELECTION && (
        <>
          <VStack spacing={2} align="start" w="100%">
            <Box
              bg={isEmailConfigOverridden ? "brandOrange.50" : "grayV2.200"}
              borderRadius={4}
              p={2}
              w="100%"
              fontSize="xs"
              color={
                isEmailConfigOverridden ? "brandOrange.100" : "brandBlue.500"
              }
              hidden={isReadonly && !isEmailConfigOverridden}
            >
              <HStack spacing={2}>
                <Icon as={MdInfo} fontSize="md" />
                {isEmailConfigOverridden ? (
                  <Text>
                    This asset has overrides for{" "}
                    {addPunctuationSeparators(overridenFields)}
                  </Text>
                ) : (
                  <Text>
                    You can add overrides to Subject, From, Reply-to and Sender
                    name fields
                  </Text>
                )}
              </HStack>
            </Box>
            <HStack
              spacing={2}
              color="brandBlue.500"
              fontSize="xs"
              style={{
                cursor: "pointer",
              }}
              pl={3}
              onClick={onOpenConfig}
              _hover={{
                textDecoration: "underline",
              }}
              hidden={isReadonly}
            >
              <Icon as={FaCog} fontSize="xs" />
              <Text>Email configuration</Text>
            </HStack>
          </VStack>

          {actionOptions && variant === EMAIL_PREVIEW_VARIANTS.UPDATION && (
            <OverrideEmailConfigModal
              onClose={onCloseConfig}
              isOpen={isOpenConfig}
              templateName={templateName}
              templateDetails={templateDetails.data}
              onSave={onSave}
              actionOptions={actionOptions}
            />
          )}
        </>
      )}
      <Box py={2}>
        <Text fontSize="10px" color="brandBlue.500" pb={1}>
          Subject
        </Text>
        <GridItemBox
          maxH="200px"
          overflow="auto"
          text={subject}
          isLoading={isLoadingTemplateDetails}
        />
      </Box>
      <Divider />
      <Grid templateColumns="60px 1fr" gap={2}>
        <GridItemBox text="From:" />
        <GridItemBox
          text={
            actionOptions?.from_email?.email ??
            templateDetails.data.from_email?.email ??
            globalDefault?.data?.from_email?.email
          }
          isLoading={isLoadingTemplateDetails}
        />
        <GridItemBox text="Name:" />
        <GridItemBox
          text={
            actionOptions?.from_email?.name ??
            templateDetails.data.from_email?.name ??
            globalDefault?.data?.from_email?.name
          }
          isLoading={isLoadingTemplateDetails}
        />
        <GridItemBox text="Reply to:" />
        <GridItemBox
          text={
            actionOptions?.reply_to ??
            templateDetails.data.reply_to ??
            globalDefault?.data?.reply_to
          }
          isLoading={isLoadingTemplateDetails}
        />
      </Grid>
    </VStack>
  );
}

export function EmailTemplatePreviewFrame() {
  const { templateDetails } = useSelector(selectTemplate);

  function showPreview() {
    if (isLoading(templateDetails.loading)) {
      return <Skeleton height="100%" width="500px" />;
    }
    if (templateDetails.data.previews) {
      return (
        <Image
          src={templateDetails.data.previews?.img_large}
          alt="email asset unavailable"
          height="fit-content"
          maxH="100%"
        />
      );
    } else {
      return (
        <Box
          w="500px"
          h="100%"
          bg="whiteAlpha.900"
          fontSize="12px"
          color="gray.400"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          No email asset added
        </Box>
      );
    }
  }

  return (
    <Box
      w="70%"
      h="100%"
      bg="gray.100"
      borderRightRadius={4}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      {showPreview()}
      <IButton
        variant="primary"
        name="external-link-icon"
        bg="gray.300"
        position="absolute"
        top="10px"
        right="10px"
        borderRadius={6}
        customContent={true}
        onClick={() =>
          openAssetInNewTab(
            ASSET_TYPES.TEMPLATE,
            templateDetails.data.template_id
          )
        }
        _hover={{
          bg: "gray.200",
        }}
        hidden={!isSuccess(templateDetails.loading)}
      >
        <Icon as={FaExternalLinkAlt} fontSize="sm" color="brandBlue.500" />
      </IButton>
    </Box>
  );
}

export default function EmailTemplatePreview({
  goBack,
  variant,
  isOpen,
  closeAllModal,
  handleSelectChange,
  onSave = () => {},
  actionOptions,
  emailConfigChange,
  onTemplateDrawerOpen,
  primaryButtonProps,
}: {
  goBack?: () => void;
  variant: EMAIL_PREVIEW_VARIANTS | null;
  isOpen: boolean;
  closeAllModal: () => void;
  handleSelectChange: (templateDetails: TemplateType) => void;
  actionOptions?: SendEmailActionOptions;
  onSave?: (dataOptions: SendEmailActionOptions) => void;
  emailConfigChange?: EmailConfigChangeTracker;
  onTemplateDrawerOpen: () => void;
  primaryButtonProps?: ButtonProps;
}) {
  const { templateDetails } = useSelector(selectTemplate);
  const {
    isOpen: isTemplateChangeAlertOpen,
    onClose: onTemplateChangeAlertClose,
    onOpen: onTemplateChangeAlertOpen,
  } = useDisclosure();

  function handleChangeTemplate() {
    closeAllModal();
    if (
      campaignDetails.data.activated_at ||
      campaignDetails.data.deactivated_at
    )
      onTemplateChangeAlertOpen();
    else onTemplateDrawerOpen();
  }
  const { campaignDetails } = useSelector(selectCampaign);
  const isLoadingTemplateDetails = isLoading(templateDetails.loading);

  return (
    <>
      <CommonDrawer
        size="xl"
        placement="right"
        title="Email preview"
        isOpen={isOpen}
        onClose={closeAllModal}
        drawerBodyProps={{
          pb: "0px",
          pl: "0px",
          pr: "20px",
        }}
        // providing a custom size
        drawerContentProps={{
          w: "1040px",
          maxW: "1040px",
        }}
        additionalHeaderContent={
          variant === EMAIL_PREVIEW_VARIANTS.UPDATION ? (
            <IButton
              name="drawer-secondary-button"
              mr={10}
              variant={BUTTON.SECONDARY}
              onClick={handleChangeTemplate}
            >
              Change email asset
            </IButton>
          ) : null
        }
      >
        <Flex h="100%" flexWrap="wrap" gap={4}>
          <EmailTemplatePreviewFrame />
          <Box flex={1} position="relative">
            <EmailTemplateDetails
              goBack={goBack}
              variant={variant}
              actionOptions={actionOptions}
              onSave={onSave}
              emailConfigChange={emailConfigChange}
            />
            {variant === EMAIL_PREVIEW_VARIANTS.SELECTION && (
              <Flex
                justifyContent="flex-end"
                position="absolute"
                bottom={3}
                right={0}
              >
                <IButton
                  variant="ghost"
                  fontSize="xs"
                  name="cancel-button"
                  mr={3}
                  onClick={closeAllModal}
                  isDisabled={isLoadingTemplateDetails}
                  children="Cancel"
                />
                <IButton
                  name="select-button"
                  fontSize="xs"
                  variant="primary"
                  onClick={() =>
                    handleSelectChange(templateDetails?.data ?? null)
                  }
                  isDisabled={isLoadingTemplateDetails}
                  children="Select"
                  {...primaryButtonProps}
                />
              </Flex>
            )}
          </Box>
        </Flex>
      </CommonDrawer>

      <TemplateChangeAlertModal
        isOpen={isTemplateChangeAlertOpen}
        onClose={onTemplateChangeAlertClose}
        onConfirm={onTemplateDrawerOpen}
      />
    </>
  );
}
